/* eslint-disable  */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run "yarn gql:generate" to update

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
};

/** Account mutation root */
export type AccountMutation = {
  readonly __typename: 'AccountMutation';
  /** Change enableLandlordField value in account model to activate/disable landlord tool */
  readonly activateLandlordTool?: Maybe<Account_Api_UpdateResponseOutput>;
  /** Change bank details of the account */
  readonly changebankdetails?: Maybe<Account_Api_ChangeBankDetailsResponseOutput>;
  /** get session by user's params */
  readonly createVeriffSession?: Maybe<Account_Api_CreateVeriffSessionResponseOutput>;
  /** Delete account */
  readonly delete?: Maybe<Account_Api_DeleteAccountResponseOutput>;
  /** delete user's session */
  readonly deleteVeriffSession?: Maybe<Account_Api_DeleteSessionResponseOutput>;
  /** Favourites an object */
  readonly favorite?: Maybe<Gateway_Impl_Resolvers_FavoriteResponseOutput>;
  /** Restore account */
  readonly restore?: Maybe<Account_Api_RestoreAccountResponseOutput>;
  /** Unfavourites an object */
  readonly unfavorite?: Maybe<Gateway_Impl_Resolvers_UnfavouriteResponseOutput>;
  /** get session by user's params */
  readonly updateVeriffStatus?: Maybe<Account_Api_UpdateVeriffStatusResponseOutput>;
  /** Store viewed video in account model */
  readonly wistiaVideoViewed?: Maybe<Account_Api_WistiaVideoViewedResponseOutput>;
};


/** Account mutation root */
export type AccountMutationActivateLandlordToolArgs = {
  enable_landlord_tool?: InputMaybe<Scalars['Boolean']>;
};


/** Account mutation root */
export type AccountMutationChangebankdetailsArgs = {
  bank_details?: InputMaybe<Account_Api_AccountBankDetailsInput>;
  captcha?: InputMaybe<Scalars['String']>;
  otp?: InputMaybe<Scalars['String']>;
  remote?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationCreateVeriffSessionArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  callback?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationDeleteVeriffSessionArgs = {
  account_id?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationFavoriteArgs = {
  type?: InputMaybe<Account_Api_AccountFavoriteTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationRestoreArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationUnfavoriteArgs = {
  type?: InputMaybe<Account_Api_AccountFavoriteTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationUpdateVeriffStatusArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};


/** Account mutation root */
export type AccountMutationWistiaVideoViewedArgs = {
  user_id?: InputMaybe<Scalars['String']>;
  video_id?: InputMaybe<Scalars['Int']>;
};

/** Account query root */
export type AccountQuery = {
  readonly __typename: 'AccountQuery';
  /** Get account by id */
  readonly get?: Maybe<Account_Api_GetResponseOutput>;
  /** Returns current user profile info */
  readonly info?: Maybe<Account_Api_AccountEnrichedOutput>;
  /** Get account list */
  readonly list?: Maybe<Account_Api_ServiceListResponseOutput>;
  /** Get wistia videos list */
  readonly listWistiaVideos?: Maybe<Account_Api_ListWistiaVideosResponseOutput>;
  readonly validation?: Maybe<AccountValidation>;
  /** Returns other user limited profile info */
  readonly whois?: Maybe<Account_Api_AccountEnrichedOutput>;
};


/** Account query root */
export type AccountQueryGetArgs = {
  admin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  soft?: InputMaybe<Scalars['Boolean']>;
};


/** Account query root */
export type AccountQueryWhoisArgs = {
  admin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  soft?: InputMaybe<Scalars['Boolean']>;
};

/** Account validation root */
export type AccountValidation = {
  readonly __typename: 'AccountValidation';
  /** validate phone number */
  readonly phone?: Maybe<Gateway_Impl_Resolvers_ValidatePhoneNumberOutput>;
};


/** Account validation root */
export type AccountValidationPhoneArgs = {
  phone?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** Address query root */
export type AddressQuery = {
  readonly __typename: 'AddressQuery';
  /** Autocomplete address */
  readonly autocomplete?: Maybe<Address_Api_AutocompleteAddressResponseOutput>;
  /** Get addresses by postcode */
  readonly postcode?: Maybe<Address_Api_AddressesResponseOutput>;
  /** Get addresses by query  */
  readonly query?: Maybe<Address_Api_AddressesResponseOutput>;
  /** Get address by udprn url  */
  readonly udprnurl?: Maybe<Address_Api_UdprnResponseOutput>;
};


/** Address query root */
export type AddressQueryAutocompleteArgs = {
  query?: InputMaybe<Scalars['String']>;
};


/** Address query root */
export type AddressQueryPostcodeArgs = {
  postcode?: InputMaybe<Scalars['String']>;
};


/** Address query root */
export type AddressQueryQueryArgs = {
  query?: InputMaybe<Scalars['String']>;
};


/** Address query root */
export type AddressQueryUdprnurlArgs = {
  udprn_url?: InputMaybe<Scalars['String']>;
};

/** Admin mutation root */
export type AdminMutation = {
  readonly __typename: 'AdminMutation';
  /**
   * Updates (confirms) offer payment by tenant via bank
   * @deprecated Use updatePaymentStatus
   */
  readonly OfferPaymentStatus?: Maybe<Offer_Api_PaymentStatusResponseOutput>;
  /** Generate csv with Tenant and Landlord details to send to the insurer */
  readonly RenderOfferCsv?: Maybe<Offer_Api_RenderCsvResponseOutput>;
  /** Accepts offer on behalf of a Landlord */
  readonly acceptOffer?: Maybe<Offer_Api_AcceptResponseOutput>;
  /** Add conformation information */
  readonly addConfirmationAdmin?: Maybe<Account_Api_ConfirmationResponseOutput>;
  /** Creates manually correlated payment<->transaction match */
  readonly addPaymentTransactionMatch?: Maybe<Invoice_Api_ServicePaymentTransactionAddResponseOutput>;
  /** Adds a product */
  readonly addProduct?: Maybe<Extras_Api_ServiceOutput>;
  /** Updates duration of the product, list of documents */
  readonly addProductDocuments?: Maybe<Invoice_Api_ProductAddDocumentsResponseOutput>;
  /**
   * Add an admin note to a property
   * @deprecated Use createNote
   */
  readonly addPropertyNote?: Maybe<Property_Api_AddNoteResponseOutput>;
  /**
   * Adds a service
   * @deprecated use Products
   */
  readonly addService?: Maybe<Extras_Api_ServiceOutput>;
  /** Approves prepared offer */
  readonly approveOffer?: Maybe<Offer_Api_ApproveResponseOutput>;
  /** Block admin account */
  readonly blockAdmin?: Maybe<Auth_Api_BlockUserResponseOutput>;
  /** Cancel signing offer */
  readonly cancelSigningOffer?: Maybe<Offer_Api_CancelSigningStageResponseOutput>;
  /** Checks receipt status */
  readonly checkCardpaymentReceipt?: Maybe<Cardpayment_Api_CheckReceiptResponseOutput>;
  /** Clean document files cache */
  readonly cleanFilesCache?: Maybe<Scalars['Boolean']>;
  /**
   * Clean thumbs cache
   * @deprecated Use cleanFilesCache
   */
  readonly cleanThumbsCache?: Maybe<Document_Api_CleanThumbsCacheResponseOutput>;
  /**
   * Clean webp cache
   * @deprecated Use cleanFilesCache
   */
  readonly cleanWebpCache?: Maybe<Document_Api_CleanWebpCacheResponseOutput>;
  /** Clear sitemaps cache */
  readonly clearSitemapsCache?: Maybe<Geo_Api_ClearSitemapsCacheResponseOutput>;
  /** Save order */
  readonly commitOrder?: Maybe<Invoice_Api_OrderCommitResponseOutput>;
  /**
   * Confirm a receipt item
   * @deprecated use updateInvoiceItems
   */
  readonly confirmCardpayment?: Maybe<Cardpayment_Api_ReceiptItemOutput>;
  /** Create admin account */
  readonly createAdmin?: Maybe<Account_Api_AccountEnrichedOutput>;
  /** Create link token for account information */
  readonly createAdminLinkToken?: Maybe<Plaid_Api_CreateAdminLinkTokenResponseOutput>;
  /** Create admin verification session */
  readonly createAdminVerificationSession?: Maybe<Account_Api_CreateAdminVerificationSessionResponseOutput>;
  /** Create document */
  readonly createDocument?: Maybe<Document_Api_CreateResponseOutput>;
  /** Create document type */
  readonly createDocumentType?: Maybe<Document_Api_DocumentTypeCreateResponseOutput>;
  /** Creates invoice */
  readonly createInvoice?: Maybe<Invoice_Api_InvoiceCreateResponseOutput>;
  /** Create note */
  readonly createNote?: Maybe<Notes_Api_NoteCreateResponseOutput>;
  /** Creates a payment */
  readonly createPayment?: Maybe<Invoice_Api_ServicePaymentCreateResponseOutput>;
  /** Create payment link */
  readonly createPaymentLink?: Maybe<Cardpayment_Api_CreatePaymentLinkResponseOutput>;
  /** Create payout transfer */
  readonly createPayout?: Maybe<Offer_Api_PayoutResponseOutput>;
  /** Create order/invoice/payment for remote sale */
  readonly createRemoteSalePayment?: Maybe<Invoice_Api_CreateRemoteSalePaymentResponseOutput>;
  /** Create test transaction */
  readonly createTransaction?: Maybe<Invoice_Api_ServiceTransactionCreateResponseOutput>;
  /** Create user account */
  readonly createUser?: Maybe<Auth_Api_CreateResponseOutput>;
  /** Create property */
  readonly createVersion?: Maybe<Listing_Api_VersionCreateResponseOutput>;
  /** Delete admin account */
  readonly deleteAdmin?: Maybe<Account_Api_ServiceDeleteResponseOutput>;
  /** Delete document */
  readonly deleteDocument?: Maybe<Document_Api_DeleteResponseOutput>;
  /** Delete document type */
  readonly deleteDocumentType?: Maybe<Scalars['Boolean']>;
  /** Delete messages by user id */
  readonly deleteMessagesByUserID?: Maybe<Messaging_Api_DeleteMessagesByUserIdResponseOutput>;
  /** Delete note */
  readonly deleteNote?: Maybe<Scalars['Boolean']>;
  /** Deletes a product */
  readonly deleteProduct?: Maybe<Scalars['Boolean']>;
  /**
   * Delete published property from Rightmove
   * @deprecated use unpublishVersion
   */
  readonly deleteRightmove?: Maybe<Rightmove_Api_DeletePropertyResponseOutput>;
  /** Delete published property from Rightmove directly */
  readonly deleteRightmoveDirectly?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes a service
   * @deprecated use Products
   */
  readonly deleteService?: Maybe<Scalars['Boolean']>;
  /** Delete user account */
  readonly deleteUser?: Maybe<Account_Api_ServiceDeleteResponseOutput>;
  /**
   * Delete published property from Zoopla
   * @deprecated use unpublishVersion
   */
  readonly deleteZoopla?: Maybe<Zoopla_Api_DeletePropertyResponseOutput>;
  /** Delete published property from Zoopla directly */
  readonly deleteZooplaDirectly?: Maybe<Scalars['Boolean']>;
  /**
   * Delete multiple published property from Zoopla
   * @deprecated use unpublishVersion
   */
  readonly deleteZooplaList?: Maybe<Gateway_Impl_Resolvers_Admin_DeleteZooplaResponseOutput>;
  /** Execute tenants check for offer */
  readonly executeOfferCheck?: Maybe<Offer_Api_CheckResponseOutput>;
  /**
   * File soft delete by document id
   * @deprecated Use updateDocument with removed=true or deleteDocument
   */
  readonly fileSoftDeleteByDocument?: Maybe<Document_Api_FileSoftDeleteByDocumentResponseOutput>;
  /** Versions flag update */
  readonly flagUpdate?: Maybe<Listing_Api_VersionFlagsResponseOutput>;
  /** Generate rent invoices */
  readonly generateRentInvoices?: Maybe<Scalars['Boolean']>;
  /** Generate rent plan insurances and invoices for them */
  readonly generateRentPlanInsurances?: Maybe<Scalars['Boolean']>;
  /** Generate sitemaps */
  readonly generateSitemaps?: Maybe<Geo_Api_GenerateSitemapsResponseOutput>;
  /** Get an admin user from a property */
  readonly getAdmin?: Maybe<Property_Api_GetAdminResponseOutput>;
  /**
   * An attempt to match all possible payments for offer/property and update schedule in success cases
   * @deprecated use matchTransactions
   */
  readonly matchAndUpdatePayments?: Maybe<Tokenio_Api_MatchPropertyPaymentsAllResponseOutput>;
  /** Forces repeated match on pulled transaction in given time range */
  readonly matchTransactions?: Maybe<Invoice_Api_TransactionMatchResponseOutput>;
  /** Sync lender list */
  readonly mortgageLenderSync?: Maybe<Scalars['Boolean']>;
  /** Create or update a notification definition */
  readonly notificationDefinitionEdit?: Maybe<Notification_Api_NotificationDefinitionEditResponseOutput>;
  /** Publish property */
  readonly publishProperty?: Maybe<Gateway_Impl_Resolvers_Admin_PublishResponseOutput>;
  /**
   * Publish property on Rightmove
   * @deprecated use publishVersion
   */
  readonly publishRightmove?: Maybe<Rightmove_Api_SendPropertyResponseOutput>;
  /** Publish property */
  readonly publishVersion?: Maybe<Listing_Api_VersionPublishResponseOutput>;
  /**
   * Publish property on Zoopla
   * @deprecated use publishVersion
   */
  readonly publishZoopla?: Maybe<Zoopla_Api_SendPropertyResponseOutput>;
  /**
   * Download payments from tokenio
   * @deprecated use pullTransactions
   */
  readonly pullPayments?: Maybe<Tokenio_Api_ProcessPaymentsResponseOutput>;
  /** Pulls and matches transactions in given time range */
  readonly pullTransactions?: Maybe<Invoice_Api_TransactionPullResponseOutput>;
  /** Reorder photos */
  readonly reorderPhotos?: Maybe<Property_Api_ReorderPhotosResponseOutput>;
  /** Reset login attempts */
  readonly resetCaptcha?: Maybe<Auth_Api_ResetAllAuthAttemptsResponseOutput>;
  /** Cancel rto */
  readonly rtoCancel?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Create rto */
  readonly rtoCreate?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Create rto tenancy */
  readonly rtoCreateTenancy?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Save mortgage lead */
  readonly saveMortgageLead?: Maybe<Mortgage_Api_LeadSaveResponseOutput>;
  /** Save mortgage lead deal */
  readonly saveMortgageLeadDeal?: Maybe<Mortgage_Api_LeadDealSaveResponseOutput>;
  /** Save mortgage lead deal note */
  readonly saveMortgageLeadDealNote?: Maybe<Mortgage_Api_LeadDealNoteSaveResponseOutput>;
  /** Save mortgage lead document */
  readonly saveMortgageLeadDocument?: Maybe<Mortgage_Api_LeadDocumentSaveResponseOutput>;
  /** Save order */
  readonly saveOrder?: Maybe<Invoice_Api_OrderSaveResponseOutput>;
  /** Send overdue payment notifications */
  readonly sendOverduePaymentNotifications?: Maybe<Gateway_Impl_Resolvers_Admin_SendOverduePaymentNotificationsResponseOutput>;
  /** Fetch subscription details for given emails */
  readonly sendgridListsSubscriptionsFetch?: Maybe<Notification_Api_SendgridListSubscriptionFetchResponseOutput>;
  /** Force rebuild subscriptions cache for entire platform */
  readonly sendgridListsSubscriptionsForceRebuild?: Maybe<Scalars['Boolean']>;
  /** Add an admin user to a property */
  readonly setAdmin?: Maybe<Scalars['Boolean']>;
  /**
   * Add an admin user to an invoice
   * @deprecated use updateInvoice
   */
  readonly setAdminInvoice?: Maybe<Scalars['Boolean']>;
  /** Add an admin user to an offer */
  readonly setAdminOffer?: Maybe<Scalars['Boolean']>;
  /**
   * Set payments and status to a schedule
   * @deprecated use updateInvoiceItems
   */
  readonly setMatchedPayments?: Maybe<Tokenio_Api_SetMatchPaymentResponseOutput>;
  /** Forces to set Renprofile report for specified offer and tenant or guarantor */
  readonly setRiskReport?: Maybe<Scalars['Boolean']>;
  /** Skip offer holding deposit stage */
  readonly skipHoldingDeposit?: Maybe<Offer_Api_SkipHoldingDepositResponseOutput>;
  /** Skip offer referencing stage (AWAITING_CHECKS and AWAITING_APPLICANT_CHECK_PAYMENT) */
  readonly skipReferencingStage?: Maybe<Offer_Api_SkipReferencingStageResponseOutput>;
  /** Subscribe admin to new transactions */
  readonly subscribeTransactions?: Maybe<Scalars['Boolean']>;
  /** Unblock admin account */
  readonly unblockAdmin?: Maybe<Auth_Api_BlockUserResponseOutput>;
  /**
   * Unpublish property
   * @deprecated use unpublishVersion
   */
  readonly unpublishProperty?: Maybe<Gateway_Impl_Resolvers_Admin_PublishResponseOutput>;
  /** Delete published property */
  readonly unpublishVersion?: Maybe<Listing_Api_VersionUnpublishResponseOutput>;
  /** Unsubscribe admin from new transactions */
  readonly unsubscribeTransactions?: Maybe<Scalars['Boolean']>;
  /** Update admin account */
  readonly updateAdmin?: Maybe<Account_Api_AccountEnrichedOutput>;
  /** Update access token for account information */
  readonly updateAdminAccessToken?: Maybe<Plaid_Api_UpdateAdminAccessTokenResponseOutput>;
  /** Update admin verification session */
  readonly updateAdminVerificationSession?: Maybe<Account_Api_UpdateAdminVerificationSessionResponseOutput>;
  /** Updates advance status */
  readonly updateAdvanceStatus?: Maybe<Expenses_Api_ServiceRecordAdvanceUpdateResponseOutput>;
  /** Update comment */
  readonly updateComment?: Maybe<Comments_Api_ServiceCommentUpdateResponseOutput>;
  /** Update document */
  readonly updateDocument?: Maybe<Document_Api_ServiceUpdateResponseOutput>;
  /** Update document state */
  readonly updateDocumentState?: Maybe<Document_Api_UpdateStatusResponseOutput>;
  /** Update document type */
  readonly updateDocumentType?: Maybe<Document_Api_DocumentTypeUpdateResponseOutput>;
  /** Update draft contract. Works only on Contract Review state */
  readonly updateDraftContract?: Maybe<Offer_Api_UpdateDraftContractResponseOutput>;
  /** Updates invoice */
  readonly updateInvoice?: Maybe<Invoice_Api_ServiceInvoiceUpdateResponseOutput>;
  /** Confirm an invoice item */
  readonly updateInvoiceItems?: Maybe<Invoice_Api_InvoiceOutput>;
  /** Update note */
  readonly updateNote?: Maybe<Notes_Api_NoteUpdateResponseOutput>;
  /** Updates existing offer */
  readonly updateOffer?: Maybe<Gateway_Impl_Resolvers_Admin_OfferUpdateResponseOutput>;
  /** Update ownership verification */
  readonly updateOwnershipVerification?: Maybe<Account_Api_UpdateOwnershipVerificationResponseOutput>;
  /**
   * Download payments from tokenio
   * @deprecated use updateInvoiceItems
   */
  readonly updatePayment?: Maybe<Tokenio_Api_UpdatePaymentResponseOutput>;
  /** Updates payment status, optionally providing description and manually correlated transaction id */
  readonly updatePaymentStatus?: Maybe<Invoice_Api_ServicePaymentStatusResponseOutput>;
  /** Updates a product */
  readonly updateProduct?: Maybe<Scalars['Boolean']>;
  /** Update property */
  readonly updateProperty?: Maybe<Gateway_Impl_Resolvers_Admin_PropertyDataOutput>;
  /** Updates rent plan */
  readonly updateRentPlan?: Maybe<Scalars['Boolean']>;
  /** Update reward status of the viewing */
  readonly updateRewardStatus?: Maybe<Viewing_Api_ServiceViewingRewardResponseOutput>;
  /**
   * Update a schedule
   * @deprecated use updateScheduledPayment
   */
  readonly updateSchedule?: Maybe<Tokenio_Api_UpdateScheduleResponseOutput>;
  /** Updates advancement status on scheduled pyament */
  readonly updateScheduledPayment?: Maybe<Offer_Api_ScheduledPaymentOutput>;
  /**
   * Updates a service
   * @deprecated use Products
   */
  readonly updateService?: Maybe<Scalars['Boolean']>;
  /** Update signature state */
  readonly updateSignature?: Maybe<Signature_Api_AdminResponseOutput>;
  /** Update user account */
  readonly updateUser?: Maybe<Account_Api_AccountEnrichedOutput>;
  /** Withdraw property */
  readonly withdrawProperty?: Maybe<Gateway_Impl_Resolvers_Admin_WithdrawResponseOutput>;
};


/** Admin mutation root */
export type AdminMutationOfferPaymentStatusArgs = {
  receipt_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Offer_Api_PaymentStatusEnum>;
};


/** Admin mutation root */
export type AdminMutationRenderOfferCsvArgs = {
  admin_site?: InputMaybe<Scalars['Boolean']>;
  need_to_send?: InputMaybe<Scalars['Boolean']>;
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationAcceptOfferArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationAddConfirmationAdminArgs = {
  confirm?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationAddPaymentTransactionMatchArgs = {
  admin_id?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  find?: InputMaybe<Invoice_Api_IsServicePaymentTransactionAddRequest_FindOneofInput>;
  transaction_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationAddProductArgs = {
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inventory_account?: InputMaybe<Scalars['String']>;
  inventory_code?: InputMaybe<Scalars['String']>;
  inventory_index?: InputMaybe<Scalars['Int']>;
  inventory_offset?: InputMaybe<Scalars['Int']>;
  main_icon_url?: InputMaybe<Scalars['String']>;
  obligatory?: InputMaybe<Scalars['Boolean']>;
  page_url?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  price_calculated?: InputMaybe<Scalars['Boolean']>;
  price_need_calculate?: InputMaybe<Scalars['Boolean']>;
  price_on_request?: InputMaybe<Scalars['Boolean']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  reference_id?: InputMaybe<Scalars['String']>;
  reference_provider?: InputMaybe<Scalars['String']>;
  remote_sales_prices?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  remote_sales_taxes?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  service_receivers?: InputMaybe<ReadonlyArray<Extras_Api_ServiceReceiverInput>>;
  status?: InputMaybe<Extras_Api_ServiceStatusEnum>;
  tax_amount?: InputMaybe<Scalars['Float']>;
  tax_percentage?: InputMaybe<Scalars['Float']>;
  tax_type?: InputMaybe<Extras_Api_TaxTypeEnum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};


/** Admin mutation root */
export type AdminMutationAddProductDocumentsArgs = {
  documents?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDocumentInput>>;
  product_id?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Invoice_Api_ProductDetailsTimeInput>;
};


/** Admin mutation root */
export type AdminMutationAddPropertyNoteArgs = {
  note?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationAddServiceArgs = {
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inventory_account?: InputMaybe<Scalars['String']>;
  inventory_code?: InputMaybe<Scalars['String']>;
  inventory_index?: InputMaybe<Scalars['Int']>;
  inventory_offset?: InputMaybe<Scalars['Int']>;
  main_icon_url?: InputMaybe<Scalars['String']>;
  obligatory?: InputMaybe<Scalars['Boolean']>;
  page_url?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  price_calculated?: InputMaybe<Scalars['Boolean']>;
  price_need_calculate?: InputMaybe<Scalars['Boolean']>;
  price_on_request?: InputMaybe<Scalars['Boolean']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  reference_id?: InputMaybe<Scalars['String']>;
  reference_provider?: InputMaybe<Scalars['String']>;
  remote_sales_prices?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  remote_sales_taxes?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  service_receivers?: InputMaybe<ReadonlyArray<Extras_Api_ServiceReceiverInput>>;
  status?: InputMaybe<Extras_Api_ServiceStatusEnum>;
  tax_amount?: InputMaybe<Scalars['Float']>;
  tax_percentage?: InputMaybe<Scalars['Float']>;
  tax_type?: InputMaybe<Extras_Api_TaxTypeEnum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};


/** Admin mutation root */
export type AdminMutationApproveOfferArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationBlockAdminArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCancelSigningOfferArgs = {
  offer?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCheckCardpaymentReceiptArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCleanFilesCacheArgs = {
  size?: InputMaybe<Document_Api_ImageSizeEnum>;
  variant?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCommitOrderArgs = {
  id?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationConfirmCardpaymentArgs = {
  charge_id?: InputMaybe<Scalars['String']>;
  confirm?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateAdminArgs = {
  account?: InputMaybe<Account_Api_AccountInput>;
  admin_site?: InputMaybe<Scalars['Boolean']>;
  email_otp?: InputMaybe<Scalars['String']>;
  oauth_id?: InputMaybe<Scalars['String']>;
  oauth_type?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
  remote?: InputMaybe<Scalars['String']>;
  tos_accepted?: InputMaybe<Scalars['Int']>;
  transient_session?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateAdminVerificationSessionArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  admin_id?: InputMaybe<Scalars['String']>;
  document_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  first_name?: InputMaybe<Scalars['String']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateDocumentArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  issued_at?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  skip_notify?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Document_Api_DocumentStateEnum>;
  type?: InputMaybe<Document_Api_DocumentTypeEnum>;
  type_code?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateDocumentTypeArgs = {
  code?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Document_Api_DocumentTypeGroupEnum>;
  name?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateInvoiceArgs = {
  admin?: InputMaybe<Scalars['String']>;
  base_invoice_id?: InputMaybe<Scalars['String']>;
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  ivr?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  notify_callback?: InputMaybe<Scalars['String']>;
  periodic?: InputMaybe<Scalars['Boolean']>;
  properties?: InputMaybe<Invoice_Api_InvoicePropertiesInput>;
  property?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<ReadonlyArray<Extras_Api_ServiceSnapshotInput>>;
  type?: InputMaybe<Invoice_Api_InvoiceTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateNoteArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Notes_Api_CorrelationTypeEnum>;
  scheduled?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Notes_Api_StatusEnum>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreatePaymentArgs = {
  admin_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Invoice_Api_PaymentStatusEnum>;
  system?: InputMaybe<Invoice_Api_IsPaymentSystemDataRequest_DataOneofInput>;
  transaction_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreatePaymentLinkArgs = {
  email?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<ReadonlyArray<Cardpayment_Api_PaymentLinkItemInput>>;
};


/** Admin mutation root */
export type AdminMutationCreatePayoutArgs = {
  admin?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  redirect_error?: InputMaybe<Scalars['String']>;
  redirect_success?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateRemoteSalePaymentArgs = {
  bank_transfer?: InputMaybe<Scalars['Boolean']>;
  payment_link_id?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<ReadonlyArray<Invoice_Api_RemoteSalePropertyInput>>;
  user_data?: InputMaybe<Invoice_Api_UserDataInput>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateTransactionArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  created?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  immediate_match?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Invoice_Api_TransactionStatusEnum>;
  type?: InputMaybe<Invoice_Api_TransactionTypeEnum>;
};


/** Admin mutation root */
export type AdminMutationCreateUserArgs = {
  account?: InputMaybe<Account_Api_AccountInput>;
  admin_site?: InputMaybe<Scalars['Boolean']>;
  email_otp?: InputMaybe<Scalars['String']>;
  oauth_id?: InputMaybe<Scalars['String']>;
  oauth_type?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
  remote?: InputMaybe<Scalars['String']>;
  tos_accepted?: InputMaybe<Scalars['Int']>;
  transient_session?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationCreateVersionArgs = {
  description?: InputMaybe<Listing_Api_DescriptionInput>;
  details?: InputMaybe<Listing_Api_DetailsInput>;
  location?: InputMaybe<Listing_Api_NewLocationInput>;
  origin?: InputMaybe<Listing_Api_IsVersionOrigin_OriginOneofInput>;
  people?: InputMaybe<Listing_Api_PeopleInput>;
  rental?: InputMaybe<Listing_Api_RentalInput>;
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
  viewing_availability?: InputMaybe<ReadonlyArray<Listing_Api_ViewingAvailabilitySlotInput>>;
};


/** Admin mutation root */
export type AdminMutationDeleteAdminArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteDocumentArgs = {
  adminSite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteDocumentTypeArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteMessagesByUserIdArgs = {
  user_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteNoteArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteProductArgs = {
  id?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
};


/** Admin mutation root */
export type AdminMutationDeleteRightmoveArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteRightmoveDirectlyArgs = {
  property_id?: InputMaybe<Scalars['String']>;
  publication_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteServiceArgs = {
  id?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
};


/** Admin mutation root */
export type AdminMutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteZooplaArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteZooplaDirectlyArgs = {
  property_id?: InputMaybe<Scalars['String']>;
  publication_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationDeleteZooplaListArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


/** Admin mutation root */
export type AdminMutationExecuteOfferCheckArgs = {
  id?: InputMaybe<Scalars['String']>;
  tenants?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};


/** Admin mutation root */
export type AdminMutationFileSoftDeleteByDocumentArgs = {
  adminSite?: InputMaybe<Scalars['Boolean']>;
  document?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationFlagUpdateArgs = {
  flag?: InputMaybe<Listing_Api_IsVersionFlagsRequest_FlagOneofInput>;
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};


/** Admin mutation root */
export type AdminMutationGenerateRentInvoicesArgs = {
  isImitationMode?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationGenerateRentPlanInsurancesArgs = {
  isImitationMode?: InputMaybe<Scalars['Boolean']>;
  isRollingTenancyProcess?: InputMaybe<Scalars['Boolean']>;
  offer_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationGetAdminArgs = {
  property?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationMatchAndUpdatePaymentsArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationMatchTransactionsArgs = {
  end?: InputMaybe<Scalars['Date']>;
  return_ids?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['Date']>;
  states?: InputMaybe<ReadonlyArray<Invoice_Api_TransactionReconciliationEnum>>;
};


/** Admin mutation root */
export type AdminMutationMortgageLenderSyncArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
};


/** Admin mutation root */
export type AdminMutationNotificationDefinitionEditArgs = {
  ID?: InputMaybe<Scalars['Int']>;
  action?: InputMaybe<Notification_Api_ActionEnum>;
  actor?: InputMaybe<Notification_Api_RoleEnum>;
  category?: InputMaybe<Notification_Api_CategoryEnum>;
  channel?: InputMaybe<Notification_Api_EventChannelEnum>;
  data?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  duration?: InputMaybe<Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationInput>;
  entity?: InputMaybe<Notification_Api_EntityEnum>;
  escape_html?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Notification_Api_RoleEnum>;
  template?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationPublishPropertyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationPublishRightmoveArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationPublishVersionArgs = {
  boomin?: InputMaybe<Listing_Api_VersionPublicationChangeItemInput>;
  let_agreed?: InputMaybe<Scalars['Boolean']>;
  mashroom?: InputMaybe<Listing_Api_VersionPublicationChangeItemInput>;
  rightmove?: InputMaybe<Listing_Api_VersionPublicationChangeItemInput>;
  user_id?: InputMaybe<Scalars['String']>;
  version_id?: InputMaybe<Scalars['String']>;
  zoopla?: InputMaybe<Listing_Api_VersionPublicationChangeItemInput>;
};


/** Admin mutation root */
export type AdminMutationPublishZooplaArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationPullPaymentsArgs = {
  end_at?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationPullTransactionsArgs = {
  end?: InputMaybe<Scalars['Date']>;
  force?: InputMaybe<Scalars['Boolean']>;
  return_ids?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['Date']>;
};


/** Admin mutation root */
export type AdminMutationReorderPhotosArgs = {
  photos?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  property?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Property_Api_ReorderPhotosStageEnum>;
};


/** Admin mutation root */
export type AdminMutationResetCaptchaArgs = {
  ip?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationRtoCancelArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationRtoCreateArgs = {
  landlord_id?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
  rent_plan?: InputMaybe<Offer_Api_Rto_RentPlanEnum>;
};


/** Admin mutation root */
export type AdminMutationRtoCreateTenancyArgs = {
  id?: InputMaybe<Scalars['String']>;
  landlord_id?: InputMaybe<Scalars['String']>;
  lead_tenant?: InputMaybe<Offer_Api_Rto_LeadTenantInput>;
  payment_data?: InputMaybe<Offer_Api_Rto_PaymentDataInput>;
};


/** Admin mutation root */
export type AdminMutationSaveMortgageLeadArgs = {
  admin_id?: InputMaybe<Scalars['String']>;
  assigned_to?: InputMaybe<Scalars['String']>;
  earnings?: InputMaybe<Scalars['Float']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  relationship_status?: InputMaybe<Mortgage_Api_RelationshipStatusEnum>;
  reminder_sent?: InputMaybe<Scalars['Boolean']>;
  schedule_date?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Mortgage_Api_MortgageLeadStageEnum>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSaveMortgageLeadDealArgs = {
  assigned_to?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lead_id?: InputMaybe<Scalars['String']>;
  renewal_date?: InputMaybe<Scalars['String']>;
  source_request_id?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Mortgage_Api_MortgageLeadStageEnum>;
  value?: InputMaybe<Scalars['Float']>;
};


/** Admin mutation root */
export type AdminMutationSaveMortgageLeadDealNoteArgs = {
  author?: InputMaybe<Scalars['String']>;
  deal_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSaveMortgageLeadDocumentArgs = {
  id?: InputMaybe<Scalars['String']>;
  lead_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSaveOrderArgs = {
  clientid?: InputMaybe<Scalars['String']>;
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_source?: InputMaybe<Invoice_Api_OrderSourceEnum>;
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  periodic?: InputMaybe<Scalars['Boolean']>;
  products?: InputMaybe<ReadonlyArray<Invoice_Api_ProductInput>>;
  userdata?: InputMaybe<Invoice_Api_UserDataInput>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSendOverduePaymentNotificationsArgs = {
  payments?: InputMaybe<ReadonlyArray<Gateway_Impl_Resolvers_Admin_OverduePaymentInput>>;
};


/** Admin mutation root */
export type AdminMutationSendgridListsSubscriptionsFetchArgs = {
  emails?: InputMaybe<ReadonlyArray<Notification_Api_SendgridListSubscriptionFetchEmailInput>>;
  force?: InputMaybe<Scalars['Boolean']>;
};


/** Admin mutation root */
export type AdminMutationSetAdminArgs = {
  admin?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSetAdminInvoiceArgs = {
  admin?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  periodic?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ReadonlyArray<Extras_Api_ServiceSnapshotInput>>;
  xero_code?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSetAdminOfferArgs = {
  admin?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSetMatchedPaymentsArgs = {
  payments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  schedule?: InputMaybe<Scalars['String']>;
  schedule_order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Tokenio_Api_PaymentReconcileStatusEnum>;
};


/** Admin mutation root */
export type AdminMutationSetRiskReportArgs = {
  file_id?: InputMaybe<Scalars['String']>;
  offer_id?: InputMaybe<Scalars['String']>;
  risk?: InputMaybe<Offer_Api_TenantRiskEnum>;
  risk_url?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSkipHoldingDepositArgs = {
  offer?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationSkipReferencingStageArgs = {
  offer?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUnblockAdminArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUnpublishPropertyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUnpublishVersionArgs = {
  boomin?: InputMaybe<Scalars['Boolean']>;
  mashroom?: InputMaybe<Scalars['Boolean']>;
  rightmove?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['String']>;
  version_id?: InputMaybe<Scalars['String']>;
  zoopla?: InputMaybe<Scalars['Boolean']>;
};


/** Admin mutation root */
export type AdminMutationUpdateAdminArgs = {
  account?: InputMaybe<Account_Api_AccountInput>;
  new_password?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateAdminAccessTokenArgs = {
  public_token?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateAdminVerificationSessionArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  admin_id?: InputMaybe<Scalars['String']>;
  document_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  first_name?: InputMaybe<Scalars['String']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateAdvanceStatusArgs = {
  admin_id?: InputMaybe<Scalars['String']>;
  request_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Expenses_Api_AdvanceStatusEnum>;
};


/** Admin mutation root */
export type AdminMutationUpdateCommentArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Comments_Api_CorrelationTypeEnum>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Comments_Api_StatusEnum>;
  text?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateDocumentArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  issued_at?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  skip_notify?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Document_Api_DocumentStateEnum>;
  type?: InputMaybe<Document_Api_DocumentTypeEnum>;
  type_code?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateDocumentStateArgs = {
  file_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Document_Api_DocumentStateEnum>;
};


/** Admin mutation root */
export type AdminMutationUpdateDocumentTypeArgs = {
  code?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Document_Api_DocumentTypeGroupEnum>;
  name?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Document_Api_IsDocumentTypeSpec_DetailsOneofInput>;
};


/** Admin mutation root */
export type AdminMutationUpdateDraftContractArgs = {
  offer?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateInvoiceArgs = {
  admin?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  periodic?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ReadonlyArray<Extras_Api_ServiceSnapshotInput>>;
  xero_code?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateInvoiceItemsArgs = {
  admin_id?: InputMaybe<Scalars['String']>;
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  invoice_reference?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<ReadonlyArray<Invoice_Api_ServiceItemStatusInput>>;
  system_reference?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateNoteArgs = {
  id?: InputMaybe<Scalars['String']>;
  scheduled?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Notes_Api_StatusEnum>;
  text?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateOfferArgs = {
  offer?: InputMaybe<Offer_Api_OfferInput>;
  render_contract?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateOwnershipVerificationArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  document_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  first_name?: InputMaybe<Scalars['String']>;
  is_company?: InputMaybe<Scalars['Boolean']>;
  is_purchased?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  ownership_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Account_Api_OwnershipStatusEnum>;
  verified_by_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdatePaymentArgs = {
  payment?: InputMaybe<Tokenio_Api_PaymentUpdateInput>;
};


/** Admin mutation root */
export type AdminMutationUpdatePaymentStatusArgs = {
  admin_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Invoice_Api_PaymentStatusEnum>;
  transaction_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateProductArgs = {
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inventory_account?: InputMaybe<Scalars['String']>;
  inventory_code?: InputMaybe<Scalars['String']>;
  inventory_index?: InputMaybe<Scalars['Int']>;
  inventory_offset?: InputMaybe<Scalars['Int']>;
  main_icon_url?: InputMaybe<Scalars['String']>;
  obligatory?: InputMaybe<Scalars['Boolean']>;
  page_url?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  price_calculated?: InputMaybe<Scalars['Boolean']>;
  price_need_calculate?: InputMaybe<Scalars['Boolean']>;
  price_on_request?: InputMaybe<Scalars['Boolean']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  reference_id?: InputMaybe<Scalars['String']>;
  reference_provider?: InputMaybe<Scalars['String']>;
  remote_sales_prices?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  remote_sales_taxes?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  service_receivers?: InputMaybe<ReadonlyArray<Extras_Api_ServiceReceiverInput>>;
  status?: InputMaybe<Extras_Api_ServiceStatusEnum>;
  tax_amount?: InputMaybe<Scalars['Float']>;
  tax_percentage?: InputMaybe<Scalars['Float']>;
  tax_type?: InputMaybe<Extras_Api_TaxTypeEnum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};


/** Admin mutation root */
export type AdminMutationUpdatePropertyArgs = {
  admin_verify?: InputMaybe<Property_Api_AdminVerifyInput>;
  bank_details?: InputMaybe<Property_Api_PropertyBankDetailsInput>;
  boomin?: InputMaybe<Property_Api_PropertyBoominInput>;
  certificates_statuses?: InputMaybe<ReadonlyArray<Property_Api_CertificateStateInput>>;
  completion_state?: InputMaybe<Property_Api_PropertyCompleteEnum>;
  created_at?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Property_Api_PropertyDescriptionInput>;
  details?: InputMaybe<Property_Api_PropertyDetailsInput>;
  documents?: InputMaybe<ReadonlyArray<Document_Api_DocumentInput>>;
  extra_listing_payments?: InputMaybe<ReadonlyArray<Property_Api_ExtraListingPaymentInput>>;
  finances?: InputMaybe<Property_Api_PropertyFinancesInput>;
  id?: InputMaybe<Scalars['String']>;
  insurances?: InputMaybe<Property_Api_InsurancesInput>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  is_created_when_ordering?: InputMaybe<Scalars['Boolean']>;
  ivr?: InputMaybe<Scalars['String']>;
  legacy_id?: InputMaybe<Scalars['String']>;
  legacy_ivr?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Property_Api_PropertyListingInput>;
  listing_expiry_date?: InputMaybe<Scalars['String']>;
  listing_fee?: InputMaybe<ReadonlyArray<Cardpayment_Api_ReceiptInput>>;
  location?: InputMaybe<Property_Api_PropertyLocationInput>;
  log?: InputMaybe<ReadonlyArray<Property_Api_LogInput>>;
  missed_extra_services?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  nearby?: InputMaybe<ReadonlyArray<Geo_Api_PointRelativeInput>>;
  notes?: InputMaybe<ReadonlyArray<Property_Api_PropertyNoteInput>>;
  origin?: InputMaybe<Listing_Api_IsVersionOrigin_OriginOneofInput>;
  pending_extra_services?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  people?: InputMaybe<Property_Api_PropertyPeopleInput>;
  property_of_day_date?: InputMaybe<Scalars['String']>;
  rental?: InputMaybe<Property_Api_PropertyRentalInput>;
  rightmove?: InputMaybe<Property_Api_PropertyRightmoveInput>;
  rto_state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Property_Api_PropertyStatusEnum>;
  test?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['String']>;
  valuation?: InputMaybe<Property_Api_ValuationInput>;
  version_id?: InputMaybe<Scalars['String']>;
  viewings?: InputMaybe<ReadonlyArray<Viewing_Api_ViewingInput>>;
  withdrawn_reason?: InputMaybe<Property_Api_PropertyWithdrawnReasonEnum>;
  zoopla?: InputMaybe<Property_Api_PropertyZooplaInput>;
};


/** Admin mutation root */
export type AdminMutationUpdateRentPlanArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Expenses_Api_CorrelationTypeEnum>;
  rent_collection_fee_percent?: InputMaybe<Scalars['Float']>;
};


/** Admin mutation root */
export type AdminMutationUpdateRewardStatusArgs = {
  reward?: InputMaybe<Viewing_Api_ManagerRewardStatusEnum>;
  spec?: InputMaybe<Viewing_Api_ViewingSpecInput>;
};


/** Admin mutation root */
export type AdminMutationUpdateScheduleArgs = {
  schedule?: InputMaybe<Tokenio_Api_UpdateScheduleInput>;
};


/** Admin mutation root */
export type AdminMutationUpdateScheduledPaymentArgs = {
  advance?: InputMaybe<Offer_Api_AdvanceInput>;
  date?: InputMaybe<Offer_Api_ScheduledPaymentDateInput>;
  due?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  parts?: InputMaybe<ReadonlyArray<Offer_Api_ScheduledPaymentPartInput>>;
  payout?: InputMaybe<Offer_Api_PayoutInput>;
  services?: InputMaybe<ReadonlyArray<Extras_Api_ServiceSnapshotInput>>;
};


/** Admin mutation root */
export type AdminMutationUpdateServiceArgs = {
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  inventory_account?: InputMaybe<Scalars['String']>;
  inventory_code?: InputMaybe<Scalars['String']>;
  inventory_index?: InputMaybe<Scalars['Int']>;
  inventory_offset?: InputMaybe<Scalars['Int']>;
  main_icon_url?: InputMaybe<Scalars['String']>;
  obligatory?: InputMaybe<Scalars['Boolean']>;
  page_url?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  price_calculated?: InputMaybe<Scalars['Boolean']>;
  price_need_calculate?: InputMaybe<Scalars['Boolean']>;
  price_on_request?: InputMaybe<Scalars['Boolean']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  reference_id?: InputMaybe<Scalars['String']>;
  reference_provider?: InputMaybe<Scalars['String']>;
  remote_sales_prices?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  remote_sales_taxes?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  service_receivers?: InputMaybe<ReadonlyArray<Extras_Api_ServiceReceiverInput>>;
  status?: InputMaybe<Extras_Api_ServiceStatusEnum>;
  tax_amount?: InputMaybe<Scalars['Float']>;
  tax_percentage?: InputMaybe<Scalars['Float']>;
  tax_type?: InputMaybe<Extras_Api_TaxTypeEnum>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};


/** Admin mutation root */
export type AdminMutationUpdateSignatureArgs = {
  event_type?: InputMaybe<Scalars['String']>;
  has_error?: InputMaybe<Scalars['Boolean']>;
  is_complete?: InputMaybe<Scalars['Boolean']>;
  is_declined?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Signature_Api_AdminRequestMetadataInput>;
  signature_request_id?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationUpdateUserArgs = {
  account?: InputMaybe<Account_Api_AccountInput>;
  new_password?: InputMaybe<Scalars['String']>;
};


/** Admin mutation root */
export type AdminMutationWithdrawPropertyArgs = {
  id?: InputMaybe<Scalars['String']>;
};

/** Admin query root */
export type AdminQuery = {
  readonly __typename: 'AdminQuery';
  /** Get admin by id */
  readonly Admin?: Maybe<Account_Api_AccountEnrichedOutput>;
  /**
   * Retrieves specified cardpayment item
   * @deprecated use Invoice
   */
  readonly Cardpayment?: Maybe<Cardpayment_Api_ReceiptItemOutput>;
  /** Returns history of given contract at given depth */
  readonly ContractHistory?: Maybe<Contract_Api_HistoryResponseOutput>;
  /** Get document by id */
  readonly Document?: Maybe<Document_Api_GetResponseOutput>;
  /** Get document type by spec */
  readonly DocumentType?: Maybe<Document_Api_DocumentTypeDescOutput>;
  /** Retrieves specified invitation */
  readonly Invitation?: Maybe<Invitation_Api_InvitationInfoOutput>;
  /** Retrieves specified invoice */
  readonly Invoice?: Maybe<Invoice_Api_InvoiceOutput>;
  /** Retrieves property/listing versions list */
  readonly ListingVersion?: Maybe<Listing_Api_VersionOutput>;
  /** Retrieves specified offer */
  readonly Offer?: Maybe<Gateway_Impl_Resolvers_Admin_OfferDataOutput>;
  /** Calculates service price */
  readonly PriceCalculate?: Maybe<Extras_Api_PriceCalculateResponseOutput>;
  /** Retrieves specified product */
  readonly Product?: Maybe<Extras_Api_ServiceOutput>;
  /** Retrieves specified property */
  readonly Property?: Maybe<Gateway_Impl_Resolvers_Admin_PropertyDataOutput>;
  /**
   * Retrieves specified service
   * @deprecated use Products
   */
  readonly Service?: Maybe<Extras_Api_ServiceOutput>;
  /** Fetches transaction by ID */
  readonly Transaction?: Maybe<Invoice_Api_TransactionOutput>;
  /** Get user by id */
  readonly User?: Maybe<Account_Api_AccountEnrichedOutput>;
  /** Get user by email */
  readonly UserByEmail?: Maybe<Account_Api_AccountEnrichedOutput>;
  /** Returns admin list */
  readonly allAdmins?: Maybe<Gateway_Impl_Resolvers_Admin_AllAdminsResultOutput>;
  /**
   * Retrieves advance payments list
   * @deprecated use allScheduledPayments
   */
  readonly allAdvancedPayments?: Maybe<Gateway_Impl_Resolvers_Admin_AllScheduledPaymentsResultOutput>;
  /**
   * Get all receipts
   * @deprecated use allInvoices
   */
  readonly allCardpayments?: Maybe<Gateway_Impl_Resolvers_Admin_AllCardpaymentsResultOutput>;
  /** List all comments */
  readonly allComments?: Maybe<Comments_Api_CommentFilterResponseOutput>;
  /** Returns list of children contracts */
  readonly allContracts?: Maybe<Contract_Api_ListResponseOutput>;
  /** Get documents by filters */
  readonly allDocumentTypes?: Maybe<Document_Api_DocumentTypeFilterResponseOutput>;
  /** Get documents by filters */
  readonly allDocuments?: Maybe<Document_Api_ListResponseOutput>;
  /** Retrieves invitation list */
  readonly allInvitations?: Maybe<Gateway_Impl_Resolvers_Admin_AllInvitationsResultOutput>;
  /** Get all invoices */
  readonly allInvoices?: Maybe<Invoice_Api_InvoiceFilterListResponseOutput>;
  /** Retrieves property/listing versions list */
  readonly allListingVersions?: Maybe<Listing_Api_VersionFilterResponseOutput>;
  /** Retrieves maintenance filtered list */
  readonly allMaintenances?: Maybe<Gateway_Impl_Resolvers_Admin_AllMaintenancesResultOutput>;
  /** List all notes */
  readonly allNotes?: Maybe<Notes_Api_NoteFilterResponseOutput>;
  /** Retrieves offer list */
  readonly allOffers?: Maybe<Gateway_Impl_Resolvers_Admin_AllOffersResultOutput>;
  /** List orders */
  readonly allOrders?: Maybe<Invoice_Api_OrderListResponseOutput>;
  /** Get all payments */
  readonly allPayments?: Maybe<Invoice_Api_PaymentFilterListResponseOutput>;
  /** Get all Products */
  readonly allProducts?: Maybe<Gateway_Impl_Resolvers_Admin_AllServicesResultOutput>;
  /** Retrieves property list */
  readonly allProperties?: Maybe<Gateway_Impl_Resolvers_Admin_AllPropertiesResultOutput>;
  /** Retrieves scheduled payments list */
  readonly allScheduledPayments?: Maybe<Gateway_Impl_Resolvers_Admin_AllScheduledPaymentsResultOutput>;
  /** List all sendgrid list subscriptions */
  readonly allSendgridListsSubscriptions?: Maybe<Notification_Api_SendgridListSubscriptionListResponseOutput>;
  /**
   * Get all Services
   * @deprecated use Products
   */
  readonly allServices?: Maybe<Gateway_Impl_Resolvers_Admin_AllServicesResultOutput>;
  /** Retrieves tenancy list */
  readonly allTenancies?: Maybe<Gateway_Impl_Resolvers_Admin_AllTenanciesResultOutput>;
  /** Fetches transaction list */
  readonly allTransactions?: Maybe<Gateway_Impl_Resolvers_Admin_AllTransactionsResponseOutput>;
  /** Returns users list */
  readonly allUsers?: Maybe<Gateway_Impl_Resolvers_Admin_AllUsersResultOutput>;
  /** Returns users list */
  readonly downloadAllUsersCsv?: Maybe<Account_Api_DownloadUsersCsvResponseOutput>;
  /**
   * Download invoices csv file
   * @deprecated use downloadReport
   */
  readonly downloadInvoicesCsv?: Maybe<Invoice_Api_DownloadReportResponseOutput>;
  /** Download report */
  readonly downloadReport?: Maybe<Invoice_Api_DownloadReportResponseOutput>;
  /** Returns ownership list */
  readonly getOwnerships?: Maybe<Account_Api_GetOwnershipVerificationResponseOutput>;
  /**
   * Retrieves payments list
   * @deprecated use Invoice
   */
  readonly getPayments?: Maybe<Tokenio_Api_GetPaymentsResponseOutput>;
  /**
   * Retrieves payments list with sort and pagination
   * @deprecated use allInvoices
   */
  readonly getPaymentsFilter?: Maybe<Gateway_Impl_Resolvers_Admin_GetPaymentsFilterResultOutput>;
  /**
   * Get a schedule
   * @deprecated use allScheduledPayments
   */
  readonly getSchedule?: Maybe<Tokenio_Api_GetScheduleResponseOutput>;
  /** List scheduled records including matched records by time and correlation_id */
  readonly listScheduledRecords?: Maybe<Expenses_Api_ScheduledRecordListResponseOutput>;
  /**
   * An attempt to match payments to the current payment amount for offer/property
   * @deprecated use matchTransactions
   */
  readonly matchPayments?: Maybe<Tokenio_Api_MatchPropertyPaymentResponseOutput>;
  /** List all mortgage bookings */
  readonly mortgageAllBookings?: Maybe<Mortgage_Api_BookingFilterListResponseOutput>;
  /** List all mortgage leads */
  readonly mortgageAllLeads?: Maybe<Mortgage_Api_LeadListResponseOutput>;
  /** List notification definitions */
  readonly notificationDefinitionList?: Maybe<Notification_Api_NotificationDefinitionListResponseOutput>;
  /** List all property products */
  readonly products?: Maybe<Invoice_Api_ProductListResponseOutput>;
  /** List records by filters */
  readonly records?: Maybe<Expenses_Api_RecordFilterListResponseOutput>;
  /** Retrieves rto by landlord id */
  readonly rtosByLandlordId?: Maybe<Offer_Api_Rto_RtosResponseOutput>;
};


/** Admin query root */
export type AdminQueryAdminArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryCardpaymentArgs = {
  charge_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryContractHistoryArgs = {
  depth?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryDocumentArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryDocumentTypeArgs = {
  spec?: InputMaybe<Document_Api_IsDocumentTypeSpec_DetailsOneofInput>;
};


/** Admin query root */
export type AdminQueryInvitationArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryInvoiceArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  invoice_reference?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  payment_statuses?: InputMaybe<ReadonlyArray<Invoice_Api_PaymentStatusEnum>>;
  property?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<ReadonlyArray<Invoice_Api_InvoiceStatusEnum>>;
  system_reference?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Invoice_Api_InvoiceTypeEnum>;
  types?: InputMaybe<ReadonlyArray<Extras_Api_ServiceTypeEnum>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryListingVersionArgs = {
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};


/** Admin query root */
export type AdminQueryOfferArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Admin query root */
export type AdminQueryPriceCalculateArgs = {
  property?: InputMaybe<Extras_Api_PropertyInput>;
  service_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryProductArgs = {
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
  property_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryPropertyArgs = {
  id?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Admin query root */
export type AdminQueryServiceArgs = {
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
  property_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryTransactionArgs = {
  transaction_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryUserArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryUserByEmailArgs = {
  admin?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryAllAdminsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllAdvancedPaymentsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllCardpaymentsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllCommentsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllContractsArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  parent_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryAllDocumentTypesArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};


/** Admin query root */
export type AdminQueryAllDocumentsArgs = {
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Common_Api_Generic_Query_QueryParamsPaginationInput>;
  property?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  sorts?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
  type_codes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<Document_Api_DocumentTypeEnum>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryAllInvitationsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllInvoicesArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllListingVersionsArgs = {
  in_radius?: InputMaybe<Listing_Api_SearchInRadiusInput>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryAllMaintenancesArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllNotesArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllOffersArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllOrdersArgs = {
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryAllPaymentsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllProductsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllPropertiesArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllScheduledPaymentsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllSendgridListsSubscriptionsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllServicesArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllTenanciesArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllTransactionsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryAllUsersArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryDownloadAllUsersCsvArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryDownloadInvoicesCsvArgs = {
  report?: InputMaybe<Invoice_Api_IsDownloadReportRequest_ReportOneofInput>;
  report_format?: InputMaybe<Invoice_Api_ReportFormatEnum>;
  report_type?: InputMaybe<Invoice_Api_ReportTypeEnum>;
};


/** Admin query root */
export type AdminQueryDownloadReportArgs = {
  report?: InputMaybe<Invoice_Api_IsDownloadReportRequest_ReportOneofInput>;
  report_format?: InputMaybe<Invoice_Api_ReportFormatEnum>;
  report_type?: InputMaybe<Invoice_Api_ReportTypeEnum>;
};


/** Admin query root */
export type AdminQueryGetOwnershipsArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  ownership_id?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  property_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryGetPaymentsArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  amount_from?: InputMaybe<Scalars['Float']>;
  amount_to?: InputMaybe<Scalars['Float']>;
  end_at?: InputMaybe<Scalars['String']>;
  payment_destinations?: InputMaybe<ReadonlyArray<Tokenio_Api_PaymentDestinationEnum>>;
  property?: InputMaybe<Scalars['String']>;
  refNum_query?: InputMaybe<Scalars['String']>;
  start_at?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryGetPaymentsFilterArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryGetScheduleArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryListScheduledRecordsArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Expenses_Api_CorrelationTypeEnum>;
  invoice_id?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['String']>;
  payment_status?: InputMaybe<Expenses_Api_PaymentStatusEnum>;
  payout_options?: InputMaybe<Expenses_Api_ScheduledRecordListPayoutOptionsInput>;
  property_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Expenses_Api_RecordTypeEnum>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryMatchPaymentsArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<Scalars['String']>;
  schedule_order?: InputMaybe<Scalars['Int']>;
};


/** Admin query root */
export type AdminQueryMortgageAllBookingsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryMortgageAllLeadsArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  pagination?: InputMaybe<Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};


/** Admin query root */
export type AdminQueryNotificationDefinitionListArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};


/** Admin query root */
export type AdminQueryProductsArgs = {
  invoice_type?: InputMaybe<Invoice_Api_InvoiceTypeEnum>;
  listing?: InputMaybe<Scalars['String']>;
  order_correlation_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_correlation_targets?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_correlation_types?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_created_range?: InputMaybe<Invoice_Api_TimeRangeInput>;
  order_statuses?: InputMaybe<ReadonlyArray<Invoice_Api_OrderStatusEnum>>;
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Admin query root */
export type AdminQueryRecordsArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};


/** Admin query root */
export type AdminQueryRtosByLandlordIdArgs = {
  states?: InputMaybe<ReadonlyArray<Offer_Api_Rto_RtoStateEnum>>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Auth mutation root */
export type AuthMutation = {
  readonly __typename: 'AuthMutation';
  /** Performs user login by username and password */
  readonly login?: Maybe<Auth_Api_LoginResponseEnrichedOutput>;
  /** Logs out of current session */
  readonly logout?: Maybe<Auth_Api_LogoutResponseOutput>;
  /** Refreshes access token */
  readonly refresh?: Maybe<Auth_Api_RefreshResponseOutput>;
  /** Resends OTP or email verification link challenge */
  readonly resend?: Maybe<Scalars['Boolean']>;
  /** Password reset */
  readonly reset?: Maybe<Auth_Api_ForgotPasswordResponseOutput>;
  /** Resumes previous session */
  readonly session?: Maybe<Common_Graphql_Websocket_TransientUserOutput>;
  /** Signs up for user account */
  readonly signup?: Maybe<Auth_Api_SignupResponseEnrichedOutput>;
  /** Updates user details */
  readonly update?: Maybe<Auth_Api_UpdateResponseOutput>;
  readonly verify?: Maybe<AuthVerifyMutation>;
};


/** Auth mutation root */
export type AuthMutationLoginArgs = {
  admin_site?: InputMaybe<Scalars['Boolean']>;
  captcha?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_otp?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  remote?: InputMaybe<Scalars['String']>;
  tos_accepted?: InputMaybe<Scalars['Int']>;
};


/** Auth mutation root */
export type AuthMutationRefreshArgs = {
  access_token?: InputMaybe<Scalars['String']>;
  admin_site?: InputMaybe<Scalars['Boolean']>;
  refresh_token?: InputMaybe<Scalars['String']>;
};


/** Auth mutation root */
export type AuthMutationResendArgs = {
  key?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Invitation_Api_ContactValidationTypeEnum>;
};


/** Auth mutation root */
export type AuthMutationResetArgs = {
  captcha?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  email_otp?: InputMaybe<Scalars['String']>;
  new_password?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  remote?: InputMaybe<Scalars['String']>;
};


/** Auth mutation root */
export type AuthMutationSessionArgs = {
  session?: InputMaybe<Scalars['String']>;
};


/** Auth mutation root */
export type AuthMutationSignupArgs = {
  account?: InputMaybe<Account_Api_AccountInput>;
  admin_site?: InputMaybe<Scalars['Boolean']>;
  email_otp?: InputMaybe<Scalars['String']>;
  oauth_id?: InputMaybe<Scalars['String']>;
  oauth_type?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  remote?: InputMaybe<Scalars['String']>;
  tos_accepted?: InputMaybe<Scalars['Int']>;
  transient_session?: InputMaybe<Scalars['String']>;
};


/** Auth mutation root */
export type AuthMutationUpdateArgs = {
  access_token?: InputMaybe<Scalars['String']>;
  account?: InputMaybe<Account_Api_AccountInput>;
  email_otp?: InputMaybe<Scalars['String']>;
  new_password?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  pin?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
};

/** Auth query root */
export type AuthQuery = {
  readonly __typename: 'AuthQuery';
  /** Returns current session */
  readonly session?: Maybe<Scalars['String']>;
  /** Returns terms of service */
  readonly tos?: Maybe<Gateway_Impl_Resolvers_TosOutputOutput>;
};

/** AuthVerify mutation root */
export type AuthVerifyMutation = {
  readonly __typename: 'AuthVerifyMutation';
  /** Verify email */
  readonly email?: Maybe<Auth_Api_VerifyEmailResponseOutput>;
  /** Verify phone */
  readonly phone?: Maybe<Auth_Api_VerifyPhoneResponseOutput>;
};


/** AuthVerify mutation root */
export type AuthVerifyMutationEmailArgs = {
  access_token?: InputMaybe<Scalars['String']>;
  email_otp?: InputMaybe<Scalars['String']>;
};


/** AuthVerify mutation root */
export type AuthVerifyMutationPhoneArgs = {
  access_token?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_otp?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
};

/** Card payment mutation root */
export type CardPaymentMutation = {
  readonly __typename: 'CardPaymentMutation';
  /**
   * Cancel a receipt
   * @deprecated use orders
   */
  readonly cancel?: Maybe<Cardpayment_Api_CancelReceiptResponseOutput>;
  /** Check receipt status */
  readonly check?: Maybe<Cardpayment_Api_CheckReceiptResponseOutput>;
  /**
   * Form a receipt
   * @deprecated use orders
   */
  readonly form?: Maybe<Cardpayment_Api_FormReceiptResponseOutput>;
  /**
   * Mark as paid
   * @deprecated use check instead
   */
  readonly mark?: Maybe<Cardpayment_Api_MarkReceiptPaidResponseOutput>;
  /**
   * Mark as paid via bank
   * @deprecated use orders/check
   */
  readonly markbank?: Maybe<Cardpayment_Api_MarkReceiptPaidResponseOutput>;
};


/** Card payment mutation root */
export type CardPaymentMutationCancelArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Card payment mutation root */
export type CardPaymentMutationCheckArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Card payment mutation root */
export type CardPaymentMutationFormArgs = {
  invoice_id?: InputMaybe<Scalars['String']>;
  invoice_reference?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  notify_address?: InputMaybe<Scalars['String']>;
  notify_id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Cardpayment_Api_PaymentTypeEnum>;
  user_email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};


/** Card payment mutation root */
export type CardPaymentMutationMarkArgs = {
  id?: InputMaybe<Scalars['String']>;
  insurance_data?: InputMaybe<Cardpayment_Api_InsuranceDataInput>;
  rent_guarantee_insurance_data?: InputMaybe<Cardpayment_Api_RentGuaranteeInsuranceDataInput>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Card payment mutation root */
export type CardPaymentMutationMarkbankArgs = {
  id?: InputMaybe<Scalars['String']>;
  insurance_data?: InputMaybe<Cardpayment_Api_InsuranceDataInput>;
  rent_guarantee_insurance_data?: InputMaybe<Cardpayment_Api_RentGuaranteeInsuranceDataInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Card payment query root */
export type CardPaymentQuery = {
  readonly __typename: 'CardPaymentQuery';
  /**
   * Get services list
   * @deprecated Use property { products } enrichment or invoice list
   */
  readonly property?: Maybe<Cardpayment_Api_GetPropertyResponseOutput>;
  /**
   * Get current user receipts
   * @deprecated use invoice list
   */
  readonly user?: Maybe<Cardpayment_Api_GetUserResponseOutput>;
};


/** Card payment query root */
export type CardPaymentQueryPropertyArgs = {
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** CloseCom lead query root */
export type CloseComLeadQuery = {
  readonly __typename: 'CloseComLeadQuery';
  /** Get lead by id */
  readonly get?: Maybe<Closecom_Api_LeadGetResponseOutput>;
};


/** CloseCom lead query root */
export type CloseComLeadQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
};

/** CloseCom query root */
export type CloseComQuery = {
  readonly __typename: 'CloseComQuery';
  readonly lead?: Maybe<CloseComLeadQuery>;
};

/** Comments mutation root */
export type CommentsMutation = {
  readonly __typename: 'CommentsMutation';
  /** Creates comment */
  readonly create?: Maybe<Comments_Api_CommentCreateResponseOutput>;
  /** Deletes comment */
  readonly delete?: Maybe<Scalars['Boolean']>;
  /** Updates comment */
  readonly update?: Maybe<Comments_Api_CommentUpdateResponseOutput>;
};


/** Comments mutation root */
export type CommentsMutationCreateArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Comments_Api_CorrelationTypeEnum>;
  parent_id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};


/** Comments mutation root */
export type CommentsMutationDeleteArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Comments_Api_CorrelationTypeEnum>;
  id?: InputMaybe<Scalars['String']>;
};


/** Comments mutation root */
export type CommentsMutationUpdateArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Comments_Api_CorrelationTypeEnum>;
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

/** Comments query root */
export type CommentsQuery = {
  readonly __typename: 'CommentsQuery';
  /** Returns comments list */
  readonly list?: Maybe<Comments_Api_CommentFilterResponseOutput>;
};


/** Comments query root */
export type CommentsQueryListArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Comments_Api_CorrelationTypeEnum>;
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  page?: InputMaybe<Scalars['Int']>;
  parent_id?: InputMaybe<Scalars['String']>;
  per_page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};

/** Contact mutation root */
export type ContactMutation = {
  readonly __typename: 'ContactMutation';
  /** Ask a question to the support */
  readonly question?: Maybe<Gateway_Impl_Resolvers_QuestionResponseOutput>;
};


/** Contact mutation root */
export type ContactMutationQuestionArgs = {
  email?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** ContentsInsurance mutation root */
export type ContentsInsuranceMutation = {
  readonly __typename: 'ContentsInsuranceMutation';
  /** Create invoice to pay contents insurance premium policy. */
  readonly premiumPay?: Maybe<Insurance_Api_ContentsInsurancePremium_PayResponseOutput>;
  /** Attach current logged-in user to the contents insurance premium policy if previously it did not contain him */
  readonly premiumRegisterUser?: Maybe<Insurance_Api_ContentsInsurancePremium_RegisterUserResponseOutput>;
  /** Save contents insurance premium policy. */
  readonly premiumSave?: Maybe<Insurance_Api_ContentsInsurancePremium_PolicyOutput>;
};


/** ContentsInsurance mutation root */
export type ContentsInsuranceMutationPremiumPayArgs = {
  id?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  redirect_cancel?: InputMaybe<Scalars['String']>;
  redirect_success?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
};


/** ContentsInsurance mutation root */
export type ContentsInsuranceMutationPremiumRegisterUserArgs = {
  session?: InputMaybe<Scalars['String']>;
};


/** ContentsInsurance mutation root */
export type ContentsInsuranceMutationPremiumSaveArgs = {
  assumptions?: InputMaybe<Insurance_Api_ContentsInsurancePremium_AssumptionsInput>;
  calculate?: InputMaybe<Scalars['Boolean']>;
  cover?: InputMaybe<Insurance_Api_ContentsInsurancePremium_CoverInput>;
  id?: InputMaybe<Scalars['String']>;
  preconditions?: InputMaybe<Insurance_Api_ContentsInsurancePremium_PreconditionsInput>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
};

/** ContentsInsurance query root */
export type ContentsInsuranceQuery = {
  readonly __typename: 'ContentsInsuranceQuery';
  /** Returns contents insurance premium policy. */
  readonly premiumGet?: Maybe<Insurance_Api_ContentsInsurancePremium_PolicyOutput>;
  /** Returns profession dictionary data. */
  readonly professionsLookup?: Maybe<Insurance_Api_ProfessionsLookupResponseOutput>;
};


/** ContentsInsurance query root */
export type ContentsInsuranceQueryPremiumGetArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** ContentsInsurance query root */
export type ContentsInsuranceQueryProfessionsLookupArgs = {
  name_prefix?: InputMaybe<Scalars['String']>;
};

/** Contract mutation root */
export type ContractMutation = {
  readonly __typename: 'ContractMutation';
  /** Creates new contract */
  readonly create?: Maybe<Contract_Api_CreateResponseOutput>;
  /** Deletes contract */
  readonly delete?: Maybe<Contract_Api_DeleteResponseOutput>;
  /** Updates revision of a contract */
  readonly edit?: Maybe<Contract_Api_EditResponseOutput>;
  /** Updates existing contract */
  readonly update?: Maybe<Contract_Api_UpdateResponseOutput>;
};


/** Contract mutation root */
export type ContractMutationCreateArgs = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['String']>;
};


/** Contract mutation root */
export type ContractMutationDeleteArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
};


/** Contract mutation root */
export type ContractMutationEditArgs = {
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};


/** Contract mutation root */
export type ContractMutationUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_id?: InputMaybe<Scalars['String']>;
};

/** Contract query root */
export type ContractQuery = {
  readonly __typename: 'ContractQuery';
  /** Returns history of given contract at given depth */
  readonly history?: Maybe<Contract_Api_HistoryResponseOutput>;
  /** Returns list of children contracts */
  readonly list?: Maybe<Contract_Api_ListResponseOutput>;
  /** Renders given contract with specified format and values provided */
  readonly render?: Maybe<Scalars['String']>;
};


/** Contract query root */
export type ContractQueryHistoryArgs = {
  depth?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
};


/** Contract query root */
export type ContractQueryListArgs = {
  deleted?: InputMaybe<Scalars['Boolean']>;
  parent_id?: InputMaybe<Scalars['String']>;
};


/** Contract query root */
export type ContractQueryRenderArgs = {
  description?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Contract_Api_ContractFormatEnum>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  values_json?: InputMaybe<Scalars['String']>;
};

export type DataSitemap = {
  readonly __typename: 'DataSitemap';
  /** Get counties by country and region */
  readonly counties?: Maybe<Geo_Api_GetCountiesSitemapResponseOutput>;
  /** Get regions by country */
  readonly regions?: Maybe<Geo_Api_GetRegionsSitemapResponseOutput>;
  /** Get towns by country, region and county */
  readonly towns?: Maybe<Geo_Api_GetTownsSitemapResponseOutput>;
};


export type DataSitemapCountiesArgs = {
  country?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};


export type DataSitemapRegionsArgs = {
  country?: InputMaybe<Scalars['String']>;
};


export type DataSitemapTownsArgs = {
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** Document mutation root */
export type DocumentMutation = {
  readonly __typename: 'DocumentMutation';
  /** Create document */
  readonly create?: Maybe<Document_Api_CreateResponseOutput>;
  /** Delete document */
  readonly delete?: Maybe<Document_Api_DeleteResponseOutput>;
  /** Update document */
  readonly update?: Maybe<Document_Api_UpdateResponseOutput>;
};


/** Document mutation root */
export type DocumentMutationCreateArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  issued_at?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  skip_notify?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Document_Api_DocumentTypeEnum>;
  type_code?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Document mutation root */
export type DocumentMutationDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Document mutation root */
export type DocumentMutationUpdateArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  issued_at?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  skip_notify?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Document_Api_DocumentTypeEnum>;
  type_code?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Document query root */
export type DocumentQuery = {
  readonly __typename: 'DocumentQuery';
  /** Get document by id */
  readonly get?: Maybe<Document_Api_GetResponseOutput>;
  /** Get documents by filters */
  readonly list?: Maybe<Document_Api_ListResponseOutput>;
};


/** Document query root */
export type DocumentQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Document query root */
export type DocumentQueryListArgs = {
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Common_Api_Generic_Query_QueryParamsPaginationInput>;
  property?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  sorts?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
  type_codes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<Document_Api_DocumentTypeEnum>>;
  user?: InputMaybe<Scalars['String']>;
};

/** DocumentType query root */
export type DocumentTypeQuery = {
  readonly __typename: 'DocumentTypeQuery';
  /** Get document type */
  readonly get?: Maybe<Document_Api_DocumentTypeGetResponseOutput>;
  /** List document types */
  readonly list?: Maybe<Document_Api_DocumentTypeFilterResponseOutput>;
};


/** DocumentType query root */
export type DocumentTypeQueryGetArgs = {
  spec?: InputMaybe<Document_Api_IsDocumentTypeSpec_DetailsOneofInput>;
};


/** DocumentType query root */
export type DocumentTypeQueryListArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};

/** Expenses Advance mutation root */
export type ExpensesAdvanceMutation = {
  readonly __typename: 'ExpensesAdvanceMutation';
  /** Cancel an advance for rent payment */
  readonly cancel?: Maybe<Expenses_Api_RecordAdvanceCancelResponseOutput>;
  /** Request an advance for rent payment */
  readonly request?: Maybe<Expenses_Api_RecordAdvanceRequestResponseOutput>;
};


/** Expenses Advance mutation root */
export type ExpensesAdvanceMutationCancelArgs = {
  request_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses Advance mutation root */
export type ExpensesAdvanceMutationRequestArgs = {
  record_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Expenses Aggregate mutation root */
export type ExpensesAggregateMutation = {
  readonly __typename: 'ExpensesAggregateMutation';
  /** Save property expense/tax tracker setup parameters */
  readonly propertyExpenseTaxTrackerSetupSave?: Maybe<Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupSaveResponseOutput>;
};


/** Expenses Aggregate mutation root */
export type ExpensesAggregateMutationPropertyExpenseTaxTrackerSetupSaveArgs = {
  annual_salary?: InputMaybe<Scalars['Float']>;
  monthly_allowable_expenses?: InputMaybe<Scalars['Float']>;
  monthly_mortgage_interest?: InputMaybe<Scalars['Float']>;
  monthly_mortgage_payment?: InputMaybe<Scalars['Float']>;
  monthly_rent?: InputMaybe<Scalars['Float']>;
  property_id?: InputMaybe<Scalars['String']>;
  tax_year?: InputMaybe<Scalars['Date']>;
  tenancy_period_end?: InputMaybe<Scalars['Date']>;
  tenancy_period_start?: InputMaybe<Scalars['Date']>;
};

/** Expenses Aggregate query root */
export type ExpensesAggregateQuery = {
  readonly __typename: 'ExpensesAggregateQuery';
  /** Get property expense/tax tracker setup parameters */
  readonly propertyExpenseTaxTrackerSetupGet?: Maybe<Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupGetResponseOutput>;
};


/** Expenses Aggregate query root */
export type ExpensesAggregateQueryPropertyExpenseTaxTrackerSetupGetArgs = {
  property_id?: InputMaybe<Scalars['String']>;
};

/** Expenses configs mutation root */
export type ExpensesConfigsMutation = {
  readonly __typename: 'ExpensesConfigsMutation';
  /** Create config */
  readonly create?: Maybe<Expenses_Api_ConfigCreateResponseOutput>;
  /** Update config */
  readonly update?: Maybe<Expenses_Api_ConfigUpdateResponseOutput>;
};


/** Expenses configs mutation root */
export type ExpensesConfigsMutationCreateArgs = {
  type?: InputMaybe<Expenses_Api_IsConfigCreateRequest_TypeOneofInput>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses configs mutation root */
export type ExpensesConfigsMutationUpdateArgs = {
  type?: InputMaybe<Expenses_Api_IsConfigUpdateRequest_TypeOneofInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Expenses Configs query root */
export type ExpensesConfigsQuery = {
  readonly __typename: 'ExpensesConfigsQuery';
  /** Get config */
  readonly get?: Maybe<Expenses_Api_ConfigGetResponseOutput>;
  /** List Configs */
  readonly list?: Maybe<Expenses_Api_ConfigFilterListResponseOutput>;
};


/** Expenses Configs query root */
export type ExpensesConfigsQueryGetArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Expenses_Api_CorrelationTypeEnum>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses Configs query root */
export type ExpensesConfigsQueryListArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};

/** Expenses mutation root */
export type ExpensesMutation = {
  readonly __typename: 'ExpensesMutation';
  readonly advance?: Maybe<ExpensesAdvanceMutation>;
  readonly aggregate?: Maybe<ExpensesAggregateMutation>;
  readonly configs?: Maybe<ExpensesConfigsMutation>;
  readonly records?: Maybe<ExpensesRecordsMutation>;
  readonly schedules?: Maybe<ExpensesSchedulesMutation>;
};

/** Expenses query root */
export type ExpensesQuery = {
  readonly __typename: 'ExpensesQuery';
  readonly aggregate?: Maybe<ExpensesAggregateQuery>;
  readonly configs?: Maybe<ExpensesConfigsQuery>;
  readonly records?: Maybe<ExpensesRecordsQuery>;
  readonly schedules?: Maybe<ExpensesSchedulesQuery>;
};

/** Expenses records mutation root */
export type ExpensesRecordsMutation = {
  readonly __typename: 'ExpensesRecordsMutation';
  /** Create record */
  readonly create?: Maybe<Expenses_Api_RecordCreateResponseOutput>;
  /** Delete record */
  readonly delete?: Maybe<Scalars['Boolean']>;
  /** Update record */
  readonly update?: Maybe<Expenses_Api_RecordUpdateResponseOutput>;
};


/** Expenses records mutation root */
export type ExpensesRecordsMutationCreateArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  config?: InputMaybe<Expenses_Api_ConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Expenses_Api_TaxInput>;
  time?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Expenses_Api_IsRecordCreateRequest_TypeOneofInput>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses records mutation root */
export type ExpensesRecordsMutationDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses records mutation root */
export type ExpensesRecordsMutationUpdateArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  config?: InputMaybe<Expenses_Api_ConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Expenses_Api_TaxInput>;
  time?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Expenses_Api_IsRecordUpdateRequest_TypeOneofInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Expenses records query root */
export type ExpensesRecordsQuery = {
  readonly __typename: 'ExpensesRecordsQuery';
  /** Get CSV report of expenses */
  readonly csv?: Maybe<Expenses_Api_ExpenseReportResponseOutput>;
  /** Get record */
  readonly get?: Maybe<Expenses_Api_RecordGetResponseOutput>;
  /** List records */
  readonly list?: Maybe<Expenses_Api_RecordFilterListResponseOutput>;
  /** List scheduled records including matched records by time and correlation_id */
  readonly listScheduledRecords?: Maybe<Expenses_Api_ScheduledRecordListResponseOutput>;
  /** Get records total in range */
  readonly range?: Maybe<Expenses_Api_RecordRangeListResponseOutput>;
};


/** Expenses records query root */
export type ExpensesRecordsQueryCsvArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};


/** Expenses records query root */
export type ExpensesRecordsQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses records query root */
export type ExpensesRecordsQueryListArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};


/** Expenses records query root */
export type ExpensesRecordsQueryListScheduledRecordsArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Expenses_Api_CorrelationTypeEnum>;
  invoice_id?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['String']>;
  payment_status?: InputMaybe<Expenses_Api_PaymentStatusEnum>;
  payout_options?: InputMaybe<Expenses_Api_ScheduledRecordListPayoutOptionsInput>;
  property_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Expenses_Api_RecordTypeEnum>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses records query root */
export type ExpensesRecordsQueryRangeArgs = {
  correlation?: InputMaybe<Expenses_Api_CorrelationInput>;
  range?: InputMaybe<Expenses_Api_RangeInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Expenses Schedules mutation root */
export type ExpensesSchedulesMutation = {
  readonly __typename: 'ExpensesSchedulesMutation';
  /** Create schedule */
  readonly create?: Maybe<Expenses_Api_ScheduleCreateResponseOutput>;
  /** Delete schedule */
  readonly delete?: Maybe<Scalars['Boolean']>;
  /** Update schedule */
  readonly update?: Maybe<Expenses_Api_ScheduleUpdateResponseOutput>;
};


/** Expenses Schedules mutation root */
export type ExpensesSchedulesMutationCreateArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  config?: InputMaybe<Expenses_Api_ConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
  immediate?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Expenses_Api_SchedulePeriodInput>;
  property_id?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Expenses_Api_TaxInput>;
  type?: InputMaybe<Expenses_Api_IsScheduleCreateRequest_TypeOneofInput>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses Schedules mutation root */
export type ExpensesSchedulesMutationDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses Schedules mutation root */
export type ExpensesSchedulesMutationUpdateArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  config?: InputMaybe<Expenses_Api_ConfigInput>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Expenses_Api_SchedulePeriodInput>;
  property_id?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['String']>;
  tax?: InputMaybe<Expenses_Api_TaxInput>;
  type?: InputMaybe<Expenses_Api_IsScheduleUpdateRequest_TypeOneofInput>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Expenses schedules query root */
export type ExpensesSchedulesQuery = {
  readonly __typename: 'ExpensesSchedulesQuery';
  /** Get schedule */
  readonly get?: Maybe<Expenses_Api_ScheduleGetResponseOutput>;
  /** List schedules */
  readonly list?: Maybe<Expenses_Api_ScheduleFilterListResponseOutput>;
};


/** Expenses schedules query root */
export type ExpensesSchedulesQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Expenses schedules query root */
export type ExpensesSchedulesQueryListArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};

/** File query root */
export type FileQuery = {
  readonly __typename: 'FileQuery';
  /** Get files by filters */
  readonly list?: Maybe<Document_Api_FileAttributeFilterResponseOutput>;
};


/** File query root */
export type FileQueryListArgs = {
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
};

/** Geo query root */
export type GeoQuery = {
  readonly __typename: 'GeoQuery';
  /** Get isochrones */
  readonly isochrones?: Maybe<Geo_Api_IsochroneResponseOutput>;
  /** Get raw points */
  readonly points?: Maybe<GetPoints>;
  /** Get pois */
  readonly pois?: Maybe<Geo_Api_PoiResponseOutput>;
  /** Get raw radius */
  readonly radius?: Maybe<GetPlacesInRadius>;
  /** Get raw places */
  readonly search?: Maybe<GetPlaces>;
  /** Get data for sitemap */
  readonly sitemap?: Maybe<DataSitemap>;
  /** Get raw towns */
  readonly towns?: Maybe<GetTowns>;
};


/** Geo query root */
export type GeoQueryIsochronesArgs = {
  denoise?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Geo_Api_LocationInput>;
  movingType?: InputMaybe<Geo_Api_IsochroneMovingTypeEnum>;
  times?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};


/** Geo query root */
export type GeoQueryPoisArgs = {
  Tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  location?: InputMaybe<Geo_Api_LocationInput>;
  radiusInMeters?: InputMaybe<Scalars['Int']>;
};

export type GetPlaces = {
  readonly __typename: 'GetPlaces';
  /** Get two stations by type */
  readonly locality?: Maybe<Geo_Api_GetLocalityResponseOutput>;
};


export type GetPlacesLocalityArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

export type GetPlacesInRadius = {
  readonly __typename: 'GetPlacesInRadius';
  /** Get towns by query */
  readonly locality?: Maybe<Geo_Api_GetLocalityResponseOutput>;
};


export type GetPlacesInRadiusLocalityArgs = {
  center?: InputMaybe<Geo_Api_LocationInput>;
  limit?: InputMaybe<Scalars['Int']>;
  radiusInMeters?: InputMaybe<Scalars['Int']>;
};

export type GetPoints = {
  readonly __typename: 'GetPoints';
  /** Get points within box */
  readonly box?: Maybe<Geo_Api_GetPointsResponseOutput>;
  /** Get nearest points by locations */
  readonly nearestbylocations?: Maybe<Geo_Api_GetNearestPointsResponseOutput>;
  /** Get points within radius */
  readonly radius?: Maybe<Geo_Api_GetPointsResponseOutput>;
};


export type GetPointsBoxArgs = {
  bottomright?: InputMaybe<Geo_Api_LocationInput>;
  topleft?: InputMaybe<Geo_Api_LocationInput>;
};


export type GetPointsNearestbylocationsArgs = {
  locations?: InputMaybe<ReadonlyArray<Geo_Api_LocationInput>>;
  pointTypes?: InputMaybe<ReadonlyArray<Geo_Api_PointTypeEnum>>;
  radiusInMeters?: InputMaybe<Scalars['Int']>;
};


export type GetPointsRadiusArgs = {
  center?: InputMaybe<Geo_Api_LocationInput>;
  meters?: InputMaybe<Scalars['Int']>;
};

export type GetTowns = {
  readonly __typename: 'GetTowns';
  /** Get counties in region */
  readonly counties?: Maybe<Geo_Api_GetCountiesInRegionResponseOutput>;
  /** Get towns within radius */
  readonly radius?: Maybe<Geo_Api_GetTownsResponseOutput>;
  /** Get two stations by type */
  readonly stations?: Maybe<Geo_Api_GetStationsResponseOutput>;
  /** Get towns within current towns or city */
  readonly sub?: Maybe<Geo_Api_GetTownsResponseOutput>;
  /** Get towns by query */
  readonly towns?: Maybe<Geo_Api_GetTownsResponseOutput>;
};


export type GetTownsCountiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Geo_Api_RegionInput>;
};


export type GetTownsRadiusArgs = {
  center?: InputMaybe<Geo_Api_LocationInput>;
  limit?: InputMaybe<Scalars['Int']>;
  radiusInMeters?: InputMaybe<Scalars['Int']>;
};


export type GetTownsStationsArgs = {
  center?: InputMaybe<Geo_Api_LocationInput>;
  limit?: InputMaybe<Scalars['Int']>;
  pointType?: InputMaybe<Geo_Api_PointTypeEnum>;
  radiusInMeters?: InputMaybe<Scalars['Int']>;
};


export type GetTownsSubArgs = {
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type GetTownsTownsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Grabdata query root */
export type GrabdataQuery = {
  readonly __typename: 'GrabdataQuery';
  /** Returns area info */
  readonly areainfo?: Maybe<Grabdata_Api_AreaInfoResponseOutput>;
  /** Returns agents counters list by locations */
  readonly getagentscounters?: Maybe<Grabdata_Api_GetAgentsCountByLocationsResponseOutput>;
  /** Returns letting agents list */
  readonly lettingagents?: Maybe<Grabdata_Api_ListLettingAgentsResponseOutput>;
};


/** Grabdata query root */
export type GrabdataQueryAreainfoArgs = {
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
};


/** Grabdata query root */
export type GrabdataQueryGetagentscountersArgs = {
  location?: InputMaybe<Grabdata_Api_LocationInput>;
};


/** Grabdata query root */
export type GrabdataQueryLettingagentsArgs = {
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  region?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
};

/** Invitation mutation root */
export type InvitationMutation = {
  readonly __typename: 'InvitationMutation';
  /** Accept invitation */
  readonly accept?: Maybe<Invitation_Api_InvitationActionResponseOutput>;
  /** Cancel invitation by id for inviter */
  readonly cancel?: Maybe<Invitation_Api_InvitationActionResponseOutput>;
  /** Cancel all property invitations by property id and invitation type */
  readonly cancelall?: Maybe<Scalars['Boolean']>;
  /** Create invitation */
  readonly create?: Maybe<Invitation_Api_CreateInvitationResponseOutput>;
  /** Decline invitation by id, no auth */
  readonly decline?: Maybe<Invitation_Api_InvitationActionResponseOutput>;
  /** Save refer landlord data to google sheet */
  readonly referlandlord?: Maybe<Scalars['Boolean']>;
  /** Reject invitation */
  readonly reject?: Maybe<Invitation_Api_InvitationActionResponseOutput>;
};


/** Invitation mutation root */
export type InvitationMutationAcceptArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  otp?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Invitation_Api_InvitationTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Invitation mutation root */
export type InvitationMutationCancelArgs = {
  admin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Invitation mutation root */
export type InvitationMutationCancelallArgs = {
  invitation_type?: InputMaybe<Invitation_Api_InvitationTypeEnum>;
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Invitation mutation root */
export type InvitationMutationCreateArgs = {
  body?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitee_email?: InputMaybe<Scalars['String']>;
  invitee_first_name?: InputMaybe<Scalars['String']>;
  invitee_id?: InputMaybe<Scalars['String']>;
  invitee_last_name?: InputMaybe<Scalars['String']>;
  invitee_phone?: InputMaybe<Scalars['String']>;
  inviter_id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Invitation_Api_InvitationStatusEnum>;
  type?: InputMaybe<Invitation_Api_InvitationTypeEnum>;
  updated_at?: InputMaybe<Scalars['String']>;
};


/** Invitation mutation root */
export type InvitationMutationDeclineArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  otp?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Invitation_Api_InvitationTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Invitation mutation root */
export type InvitationMutationReferlandlordArgs = {
  host?: InputMaybe<Invitation_Api_UserInput>;
  invitee?: InputMaybe<Invitation_Api_UserInput>;
};


/** Invitation mutation root */
export type InvitationMutationRejectArgs = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  otp?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Invitation_Api_InvitationTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};

/** Invitation query root */
export type InvitationQuery = {
  readonly __typename: 'InvitationQuery';
  /** Get incoming invitations */
  readonly incoming?: Maybe<Invitation_Api_InvitationListResponseOutput>;
  /** Get outgoing invitations */
  readonly outgoing?: Maybe<Invitation_Api_InvitationListResponseOutput>;
};

/** invoice query root */
export type InvoiceQuery = {
  readonly __typename: 'InvoiceQuery';
  /** Get own invoices */
  readonly list?: Maybe<Invoice_Api_InvoiceListResponseOutput>;
};


/** invoice query root */
export type InvoiceQueryListArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  not_correlation_target?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_id?: InputMaybe<Scalars['String']>;
  payment_statuses?: InputMaybe<ReadonlyArray<Invoice_Api_PaymentStatusEnum>>;
  property?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<ReadonlyArray<Invoice_Api_InvoiceStatusEnum>>;
  system_reference?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Invoice_Api_InvoiceTypeEnum>;
  types?: InputMaybe<ReadonlyArray<Extras_Api_ServiceTypeEnum>>;
  user?: InputMaybe<Scalars['String']>;
};

/** Listing mutation root */
export type ListingMutation = {
  readonly __typename: 'ListingMutation';
  readonly preface?: Maybe<ListingPrefaceMutation>;
  readonly version?: Maybe<ListingVersionMutation>;
};

/** Listing preface mutation root */
export type ListingPrefaceMutation = {
  readonly __typename: 'ListingPrefaceMutation';
  /** Save version */
  readonly save?: Maybe<Listing_Api_PrefaceSaveResponseOutput>;
};


/** Listing preface mutation root */
export type ListingPrefaceMutationSaveArgs = {
  client_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Listing_Api_DescriptionInput>;
  details?: InputMaybe<Listing_Api_DetailsInput>;
  location?: InputMaybe<Listing_Api_NewLocationInput>;
  rental?: InputMaybe<Listing_Api_RentalInput>;
};

/** Listing preface query root */
export type ListingPrefaceQuery = {
  readonly __typename: 'ListingPrefaceQuery';
  /** Get preface */
  readonly get?: Maybe<Listing_Api_PrefaceGetResponseOutput>;
};


/** Listing preface query root */
export type ListingPrefaceQueryGetArgs = {
  client_id?: InputMaybe<Scalars['String']>;
};

/** Listing query root */
export type ListingQuery = {
  readonly __typename: 'ListingQuery';
  readonly preface?: Maybe<ListingPrefaceQuery>;
  readonly valuation?: Maybe<ListingValuationQuery>;
  readonly version?: Maybe<ListingVersionQuery>;
};

/** Listing valuation query root */
export type ListingValuationQuery = {
  readonly __typename: 'ListingValuationQuery';
  /** Get valuation */
  readonly get?: Maybe<Listing_Api_ValuationGetResponseOutput>;
};


/** Listing valuation query root */
export type ListingValuationQueryGetArgs = {
  bedrooms_no?: InputMaybe<Scalars['Int']>;
  building?: InputMaybe<Scalars['String']>;
  flat?: InputMaybe<Scalars['String']>;
  furniture?: InputMaybe<Listing_Api_PropertyFurnitureEnum>;
  postcode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Listing_Api_PropertyTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};

/** Listing version invitation mutation root */
export type ListingVersionInvitationMutation = {
  readonly __typename: 'ListingVersionInvitationMutation';
  /** Create version invitation */
  readonly create?: Maybe<Scalars['Boolean']>;
};


/** Listing version invitation mutation root */
export type ListingVersionInvitationMutationCreateArgs = {
  host_id?: InputMaybe<Scalars['String']>;
  invitee?: InputMaybe<Listing_Api_InvitationUserInput>;
  message?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Listing_Api_IsVersionInvitationCreateRequest_RequestOneofInput>;
  site_url?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};

/** Listing version mutation root */
export type ListingVersionMutation = {
  readonly __typename: 'ListingVersionMutation';
  /** Create version */
  readonly create?: Maybe<Listing_Api_VersionCreateResponseOutput>;
  readonly invitation?: Maybe<ListingVersionInvitationMutation>;
  /** Submit version */
  readonly submit?: Maybe<Listing_Api_VersionSubmitResponseOutput>;
  /** Unsubmit version */
  readonly unsubmit?: Maybe<Listing_Api_VersionUnsubmitResponseOutput>;
  /** Update version */
  readonly update?: Maybe<Listing_Api_VersionUpdateResponseOutput>;
  /** Validate version */
  readonly validate?: Maybe<Listing_Api_VersionValidateResponseOutput>;
  readonly viewing?: Maybe<ListingVersionViewingMutation>;
};


/** Listing version mutation root */
export type ListingVersionMutationCreateArgs = {
  description?: InputMaybe<Listing_Api_DescriptionInput>;
  details?: InputMaybe<Listing_Api_DetailsInput>;
  location?: InputMaybe<Listing_Api_NewLocationInput>;
  origin?: InputMaybe<Listing_Api_IsVersionOrigin_OriginOneofInput>;
  people?: InputMaybe<Listing_Api_PeopleInput>;
  rental?: InputMaybe<Listing_Api_RentalInput>;
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
  viewing_availability?: InputMaybe<ReadonlyArray<Listing_Api_ViewingAvailabilitySlotInput>>;
};


/** Listing version mutation root */
export type ListingVersionMutationSubmitArgs = {
  user_id?: InputMaybe<Scalars['String']>;
  version_id?: InputMaybe<Scalars['String']>;
};


/** Listing version mutation root */
export type ListingVersionMutationUnsubmitArgs = {
  user_id?: InputMaybe<Scalars['String']>;
  version_id?: InputMaybe<Scalars['String']>;
};


/** Listing version mutation root */
export type ListingVersionMutationUpdateArgs = {
  description?: InputMaybe<Listing_Api_DescriptionInput>;
  details?: InputMaybe<Listing_Api_DetailsInput>;
  location?: InputMaybe<Listing_Api_NewLocationInput>;
  people?: InputMaybe<Listing_Api_PeopleInput>;
  rental?: InputMaybe<Listing_Api_RentalInput>;
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};


/** Listing version mutation root */
export type ListingVersionMutationValidateArgs = {
  user_id?: InputMaybe<Scalars['String']>;
  version_id?: InputMaybe<Scalars['String']>;
};

/** Listing version query root */
export type ListingVersionQuery = {
  readonly __typename: 'ListingVersionQuery';
  /** Get version */
  readonly get?: Maybe<Listing_Api_VersionGetResponseOutput>;
  /** List versions */
  readonly list?: Maybe<Listing_Api_VersionListResponseOutput>;
};


/** Listing version query root */
export type ListingVersionQueryGetArgs = {
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};


/** Listing version query root */
export type ListingVersionQueryListArgs = {
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};

/** Listing version viewing mutation root */
export type ListingVersionViewingMutation = {
  readonly __typename: 'ListingVersionViewingMutation';
  /** Create version invitation */
  readonly availability?: Maybe<Listing_Api_VersionViewingAvailabilityResponseOutput>;
};


/** Listing version viewing mutation root */
export type ListingVersionViewingMutationAvailabilityArgs = {
  slots?: InputMaybe<ReadonlyArray<Listing_Api_ViewingAvailabilitySlotInput>>;
  spec?: InputMaybe<Listing_Api_VersionSpecInput>;
};

/** Maintenance mutation root */
export type MaintenanceMutation = {
  readonly __typename: 'MaintenanceMutation';
  /** Assign issue to local heroes */
  readonly assignlh?: Maybe<Maintenance_Api_AssignIssueLocalHeroesResponseOutput>;
  /** Assign issue to private contractor */
  readonly assignpriv?: Maybe<Maintenance_Api_AssignIssueOwnContractorResponseOutput>;
  /** Creates new maintenance */
  readonly create?: Maybe<Maintenance_Api_CreateResponseOutput>;
  /** Removes existing maintenance task */
  readonly remove?: Maybe<Scalars['Boolean']>;
  /** Updates existing maintenance */
  readonly update?: Maybe<Maintenance_Api_UpdateResponseOutput>;
};


/** Maintenance mutation root */
export type MaintenanceMutationAssignlhArgs = {
  category?: InputMaybe<Maintenance_Api_LocalHeroesCategoryEnum>;
  job_description?: InputMaybe<Scalars['String']>;
  maintenance?: InputMaybe<Scalars['String']>;
  subcategory?: InputMaybe<Maintenance_Api_LocalHeroesSubcategoryEnum>;
  taxonomy_id?: InputMaybe<Scalars['String']>;
  time_slot?: InputMaybe<Maintenance_Api_TimeSlotInput>;
  user?: InputMaybe<Scalars['String']>;
};


/** Maintenance mutation root */
export type MaintenanceMutationAssignprivArgs = {
  description?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  maintenance?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  visit_at?: InputMaybe<Scalars['String']>;
};


/** Maintenance mutation root */
export type MaintenanceMutationCreateArgs = {
  description?: InputMaybe<Scalars['String']>;
  desired_repair_date?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  listing_id?: InputMaybe<Scalars['String']>;
  maintenance?: InputMaybe<Maintenance_Api_MaintenanceInput>;
  place?: InputMaybe<Maintenance_Api_MaintenancePlaceEnum>;
  property_id?: InputMaybe<Scalars['String']>;
  taxonomy_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Maintenance_Api_MaintenanceTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Maintenance mutation root */
export type MaintenanceMutationRemoveArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Maintenance mutation root */
export type MaintenanceMutationUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  desired_repair_date?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  listing_id?: InputMaybe<Scalars['String']>;
  maintenance?: InputMaybe<Maintenance_Api_MaintenanceInput>;
  place?: InputMaybe<Maintenance_Api_MaintenancePlaceEnum>;
  property_id?: InputMaybe<Scalars['String']>;
  repair_date?: InputMaybe<Scalars['String']>;
  resolution?: InputMaybe<Maintenance_Api_MaintenanceResolutionEnum>;
  response?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Maintenance_Api_MaintenanceStatusEnum>;
  taxonomy_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Maintenance query root */
export type MaintenanceQuery = {
  readonly __typename: 'MaintenanceQuery';
  /** Retrieves specified maintenance */
  readonly get?: Maybe<Maintenance_Api_GetResponseOutput>;
  /** Get job descriptions of local heroes for title */
  readonly getjobdescriptions?: Maybe<Maintenance_Api_GetJobDescriptionListResponseOutput>;
  /**
   * Get subcategories of local heroes
   * @deprecated Use getjobdescriptions with query only
   */
  readonly getsubcategories?: Maybe<Maintenance_Api_GetSubcategoryListResponseOutput>;
  /**
   * Retrieves owned maintenance
   * @deprecated Use list with direction=INCOMING
   */
  readonly incoming?: Maybe<Maintenance_Api_ListResponseOutput>;
  /** Retrieves owned maintenance */
  readonly list?: Maybe<Maintenance_Api_ListResponseOutput>;
  /**
   * Retrieves owned maintenance
   * @deprecated Use list with direction=OUTGOING
   */
  readonly outgoing?: Maybe<Maintenance_Api_ListResponseOutput>;
};


/** Maintenance query root */
export type MaintenanceQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Maintenance query root */
export type MaintenanceQueryGetjobdescriptionsArgs = {
  category?: InputMaybe<Maintenance_Api_LocalHeroesCategoryEnum>;
  query?: InputMaybe<Scalars['String']>;
  subcategory?: InputMaybe<Maintenance_Api_LocalHeroesSubcategoryEnum>;
};


/** Maintenance query root */
export type MaintenanceQueryGetsubcategoriesArgs = {
  category?: InputMaybe<Maintenance_Api_LocalHeroesCategoryEnum>;
};


/** Maintenance query root */
export type MaintenanceQueryIncomingArgs = {
  direction?: InputMaybe<Maintenance_Api_IssueDirectionEnum>;
  property?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Maintenance_Api_MaintenanceTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Maintenance query root */
export type MaintenanceQueryListArgs = {
  direction?: InputMaybe<Maintenance_Api_IssueDirectionEnum>;
  property?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Maintenance_Api_MaintenanceTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Maintenance query root */
export type MaintenanceQueryOutgoingArgs = {
  direction?: InputMaybe<Maintenance_Api_IssueDirectionEnum>;
  property?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Maintenance_Api_MaintenanceTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};

export type MessagingEvent = {
  readonly __typename: 'MessagingEvent';
  readonly delete?: Maybe<Messaging_Api_DeleteEventEnrichedOutput>;
  readonly edit?: Maybe<Messaging_Api_EditEventOutput>;
  readonly history?: Maybe<Messaging_Api_HistoryEventOutput>;
  readonly info?: Maybe<MessagingEventInfo>;
  readonly init?: Maybe<Messaging_Api_InitEventOutput>;
  readonly join?: Maybe<Messaging_Api_JoinEventEnrichedOutput>;
  readonly leave?: Maybe<Messaging_Api_LeaveEventEnrichedOutput>;
  readonly ping?: Maybe<Messaging_Api_PingEventOutput>;
  readonly presence?: Maybe<Messaging_Api_PresenceEventOutput>;
  readonly seen?: Maybe<Messaging_Api_SeenEventEnrichedOutput>;
  readonly send?: Maybe<Messaging_Api_SendEventOutput>;
  readonly typing?: Maybe<Messaging_Api_TypingEventEnrichedOutput>;
  readonly viewing?: Maybe<Messaging_Api_ViewingEventEnrichedOutput>;
};

export type MessagingEventInfo = {
  readonly __typename: 'MessagingEventInfo';
  readonly joined?: Maybe<Messaging_Api_ChannelEnrichedOutput>;
  readonly left?: Maybe<Messaging_Api_ChannelEnrichedOutput>;
};

/** Messaging mutation root */
export type MessagingMutation = {
  readonly __typename: 'MessagingMutation';
  /** Deletes message in a given channel */
  readonly delete?: Maybe<Messaging_Api_DeleteResponseOutputOutput>;
  /** Edits message in a given channel */
  readonly edit?: Maybe<Messaging_Api_EditResponseOutputOutput>;
  /** Asks for history of a given channel */
  readonly history?: Maybe<Messaging_Api_HistoryResponseOutputOutput>;
  /** Joins to given channel */
  readonly join?: Maybe<Messaging_Api_JoinResponseOutputOutput>;
  /** Leaves given channel */
  readonly leave?: Maybe<Messaging_Api_LeaveResponseOutputOutput>;
  /** Marks all messages in channel as seen */
  readonly markasread?: Maybe<Messaging_Api_MarkAsReadResponseOutputOutput>;
  /** Replies to ping */
  readonly pong?: Maybe<Messaging_Api_PongResponseOutputOutput>;
  /** Notifies about user presence */
  readonly presence?: Maybe<Messaging_Api_PresenceResponseOutputOutput>;
  /** Marks message as seen in a given channel */
  readonly seen?: Maybe<Messaging_Api_SeenResponseOutputOutput>;
  /** Sends message to a given channel */
  readonly send?: Maybe<Messaging_Api_SendResponseOutputOutput>;
  /** Notifies that the user is typing */
  readonly typing?: Maybe<Messaging_Api_TypingResponseOutputOutput>;
  /** Get unseen notifications */
  readonly unseen?: Maybe<Messaging_Api_UnseenNotificationsResponseOutput>;
  /** Save an SDP answer from broadcaster and notify peer with 'sdpAnswer' subject */
  readonly viewingAnswer?: Maybe<Scalars['Boolean']>;
  /** Save an SDP offer from peer and notify broadcaster with 'sdpOffer' subject */
  readonly viewingOffer?: Maybe<Scalars['Boolean']>;
  /** Register a viewing and notify members with 'viewingState' subject */
  readonly viewingRegister?: Maybe<Scalars['Boolean']>;
  /** Set broadcasting state and notify members with 'viewingState' subject */
  readonly viewingState?: Maybe<Scalars['Boolean']>;
};


/** Messaging mutation root */
export type MessagingMutationDeleteArgs = {
  channel?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationEditArgs = {
  channel?: InputMaybe<Scalars['String']>;
  embeds?: InputMaybe<ReadonlyArray<Messaging_Api_EmbedInput>>;
  message?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationHistoryArgs = {
  channel?: InputMaybe<Scalars['String']>;
  forward?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  refid?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  synchronous?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationJoinArgs = {
  channel?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationLeaveArgs = {
  channel?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationMarkasreadArgs = {
  channel?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationPongArgs = {
  corr?: InputMaybe<Scalars['String']>;
  sent?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationPresenceArgs = {
  presence?: InputMaybe<Messaging_Api_PresenceEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationSeenArgs = {
  channel?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationSendArgs = {
  channel?: InputMaybe<Scalars['String']>;
  embeds?: InputMaybe<ReadonlyArray<Messaging_Api_EmbedInput>>;
  event?: InputMaybe<Scalars['Int']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationTypingArgs = {
  channel?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Messaging_Api_ChannelScopeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationUnseenArgs = {
  channel?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  refid?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationViewingAnswerArgs = {
  peer?: InputMaybe<Messaging_Api_Viewing_PeerInput>;
  ref?: InputMaybe<Messaging_Api_Viewing_ReferenceInput>;
  sdp?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationViewingOfferArgs = {
  peer?: InputMaybe<Messaging_Api_Viewing_PeerInput>;
  ref?: InputMaybe<Messaging_Api_Viewing_ReferenceInput>;
  sdp?: InputMaybe<Scalars['String']>;
};


/** Messaging mutation root */
export type MessagingMutationViewingRegisterArgs = {
  ref?: InputMaybe<Messaging_Api_Viewing_ReferenceInput>;
};


/** Messaging mutation root */
export type MessagingMutationViewingStateArgs = {
  ref?: InputMaybe<Messaging_Api_Viewing_ReferenceInput>;
  state?: InputMaybe<Messaging_Api_Viewing_StateEnum>;
};

/** Messaging query root */
export type MessagingQuery = {
  readonly __typename: 'MessagingQuery';
  /** Returns available viewing broadcasting metadata */
  readonly viewingMetadata?: Maybe<Messaging_Api_ViewingOutput>;
};


/** Messaging query root */
export type MessagingQueryViewingMetadataArgs = {
  property?: InputMaybe<Scalars['String']>;
  view_request?: InputMaybe<Scalars['String']>;
};

/** Messaging subscription root */
export type MessagingSubscription = {
  readonly __typename: 'MessagingSubscription';
  /** Connects to messaging interface */
  readonly connect?: Maybe<Messaging_Api_EventEnrichedOutput>;
};


/** Messaging subscription root */
export type MessagingSubscriptionConnectArgs = {
  user?: InputMaybe<Scalars['String']>;
};

/** Mortgage leads query root */
export type MortgageLeadsQuery = {
  readonly __typename: 'MortgageLeadsQuery';
  /** Get lead */
  readonly get?: Maybe<Mortgage_Api_LeadGetResponseOutput>;
  /** Get lead deal */
  readonly getdeal?: Maybe<Mortgage_Api_LeadDealGetResponseOutput>;
  /** Get lead deal note */
  readonly getdealnote?: Maybe<Mortgage_Api_LeadDealNoteGetResponseOutput>;
  /** List queryLeads */
  readonly list?: Maybe<Mortgage_Api_LeadListResponseOutput>;
};


/** Mortgage leads query root */
export type MortgageLeadsQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Mortgage leads query root */
export type MortgageLeadsQueryGetdealArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Mortgage leads query root */
export type MortgageLeadsQueryGetdealnoteArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Mortgage leads query root */
export type MortgageLeadsQueryListArgs = {
  assigned_to?: InputMaybe<Scalars['String']>;
  create_date?: InputMaybe<Mortgage_Api_DateRequestInput>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  only_active?: InputMaybe<Scalars['Boolean']>;
  only_not_reminded?: InputMaybe<Scalars['Boolean']>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  schedule_date?: InputMaybe<Mortgage_Api_DateRequestInput>;
  stages?: InputMaybe<ReadonlyArray<Mortgage_Api_MortgageLeadStageEnum>>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Mortgage mutation root */
export type MortgageMutation = {
  readonly __typename: 'MortgageMutation';
  /** Book a call */
  readonly book?: Maybe<Mortgage_Api_BookCallResponseOutput>;
  /** Run mortgage sourcing */
  readonly source?: Maybe<Mortgage_Api_RunSourceResponseOutput>;
};


/** Mortgage mutation root */
export type MortgageMutationBookArgs = {
  contacts?: InputMaybe<Mortgage_Api_ContactsInput>;
  selected_product_code?: InputMaybe<Scalars['String']>;
  source_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Mortgage mutation root */
export type MortgageMutationSourceArgs = {
  applicant?: InputMaybe<Mortgage_Api_ApplicantTypeEnum>;
  deposit?: InputMaybe<Scalars['Float']>;
  mortgage_type?: InputMaybe<Mortgage_Api_MortgageTypeEnum>;
  property_value?: InputMaybe<Scalars['Float']>;
  reason_for_mortgage?: InputMaybe<Mortgage_Api_ReasonForMortgageEnum>;
  term?: InputMaybe<Scalars['Int']>;
};

/** Mortgage query root */
export type MortgageQuery = {
  readonly __typename: 'MortgageQuery';
  readonly leads?: Maybe<MortgageLeadsQuery>;
  /** Get lenders list */
  readonly lenders?: Maybe<ReadonlyArray<Mortgage_Api_LenderEnrichedOutput>>;
};

/** Notification mutation root */
export type NotificationMutation = {
  readonly __typename: 'NotificationMutation';
  readonly sendgrid_lists?: Maybe<NotificationSendgridListsMutation>;
  /** @deprecated Use sendgrid_lists { subscribe } */
  readonly subscribe?: Maybe<NotificationSubscriptionMutation>;
};

/** Notification query root */
export type NotificationQuery = {
  readonly __typename: 'NotificationQuery';
  readonly sendgrid_lists?: Maybe<NotificationSendgridListsQuery>;
};

/** Notification sendgrid lists mutation root */
export type NotificationSendgridListsMutation = {
  readonly __typename: 'NotificationSendgridListsMutation';
  /** Subscribe to sendgrid mail list */
  readonly subscribe?: Maybe<Notification_Api_SendgridListSubscriptionCreateResponseOutput>;
};


/** Notification sendgrid lists mutation root */
export type NotificationSendgridListsMutationSubscribeArgs = {
  address?: InputMaybe<Notification_Api_SendgridListSubscribeAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<ReadonlyArray<Notification_Api_SendgridListSubscriptionTypeEnum>>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Notification sendgrid lists query root */
export type NotificationSendgridListsQuery = {
  readonly __typename: 'NotificationSendgridListsQuery';
  /** Returns subscription list */
  readonly list?: Maybe<Notification_Api_SendgridListSubscriptionFetchResponseOutput>;
};

/** Notification subscription mutation root */
export type NotificationSubscriptionMutation = {
  readonly __typename: 'NotificationSubscriptionMutation';
  /**
   * Subscribe to leads list
   * @deprecated Use sendgrid_lists { subscribe }
   */
  readonly leads?: Maybe<Scalars['Boolean']>;
};


/** Notification subscription mutation root */
export type NotificationSubscriptionMutationLeadsArgs = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** Oauth mutation root */
export type OauthMutation = {
  readonly __typename: 'OauthMutation';
  /** Login using facebook token */
  readonly facebookLogin?: Maybe<Auth_Api_OauthResponseEnrichedOutput>;
  /** Signup using facebook token */
  readonly facebookSignup?: Maybe<Auth_Api_SignupResponseEnrichedOutput>;
  /** Sign in using google authorization code */
  readonly googleSignIn?: Maybe<Auth_Api_OauthResponseEnrichedOutput>;
};


/** Oauth mutation root */
export type OauthMutationFacebookLoginArgs = {
  info?: InputMaybe<Auth_Api_OauthRequestInput>;
  token?: InputMaybe<Oauth_Api_TokenAccessRequestInput>;
};


/** Oauth mutation root */
export type OauthMutationFacebookSignupArgs = {
  info?: InputMaybe<Auth_Api_SignupRequestInput>;
  token?: InputMaybe<Oauth_Api_TokenAccessRequestInput>;
};


/** Oauth mutation root */
export type OauthMutationGoogleSignInArgs = {
  authorization_code?: InputMaybe<Scalars['String']>;
};

/** Oauth query root */
export type OauthQuery = {
  readonly __typename: 'OauthQuery';
  /** Get facebook user info using access token */
  readonly facebookUserInfoWithToken?: Maybe<Oauth_Api_FacebookGetUserInfoResponseOutput>;
};


/** Oauth query root */
export type OauthQueryFacebookUserInfoWithTokenArgs = {
  access_token?: InputMaybe<Scalars['String']>;
};

/** Offer mutation root */
export type OfferMutation = {
  readonly __typename: 'OfferMutation';
  /** Accepts incoming offer */
  readonly accept?: Maybe<Offer_Api_AcceptResponseOutput>;
  /** Accept updates */
  readonly acceptupdate?: Maybe<Offer_Api_AcceptUpdateResponseOutput>;
  /** Add new contract clause by landlord */
  readonly addcontractclause?: Maybe<Offer_Api_AddContractClauseResponseOutput>;
  /** Add new guarantors by tenant */
  readonly addguarantors?: Maybe<Offer_Api_AddGuarantorsResponseOutput>;
  /**
   * Cancel advance payment request
   * @deprecated use expenses to cancel advances
   */
  readonly advancecancel?: Maybe<Offer_Api_ScheduledPaymentAdvanceCancelResponseOutput>;
  /**
   * Request advance payment
   * @deprecated use expenses to request advances
   */
  readonly advancerequest?: Maybe<Offer_Api_ScheduledPaymentAdvanceCreateResponseOutput>;
  /** Cancels own outgoing offer */
  readonly cancel?: Maybe<Offer_Api_CancelResponseOutput>;
  /** Add new contract clause by landlord */
  readonly changestateclauses?: Maybe<Offer_Api_ChangeStateContractClausesResponseOutput>;
  /** Start tenant checks */
  readonly checks?: Maybe<Offer_Api_CheckResponseOutput>;
  /** Create new offer */
  readonly create?: Maybe<Offer_Api_CreateResponseOutput>;
  /** Delete offer */
  readonly delete?: Maybe<Offer_Api_DeleteOfferByIdResponseOutput>;
  /** Denies incoming offer */
  readonly deny?: Maybe<Offer_Api_DenyResponseOutput>;
  /** Invite tenant to join offer */
  readonly invite?: Maybe<Offer_Api_InviteResponseOutput>;
  /** Request payment url */
  readonly payment?: Maybe<Offer_Api_PaymentResponseOutput>;
  /** Progress to signing */
  readonly progresstosigning?: Maybe<Offer_Api_ProgressToSigningResponseOutput>;
  /** Remove second landlord from offer */
  readonly removesecondlandlord?: Maybe<Offer_Api_RemoveSecondLandlordResponseOutput>;
  /** Renew tenancy */
  readonly renewtenancy?: Maybe<Offer_Api_RenewTenancyResponseOutput>;
  /**
   * Create rent payment
   * @deprecated Do not use
   */
  readonly rentpayment?: Maybe<Offer_Api_ScheduledPaymentPayResponseOutput>;
  /** Request the rentprofile's check PDF file */
  readonly rentprofilepdf?: Maybe<Identity_Api_DownloadPdfResponseOutput>;
  /** Request change for incoming offer */
  readonly requestchange?: Maybe<Offer_Api_RequestChangeResponseOutput>;
  /** Request to add a new guarantor to the current tenant */
  readonly requiredguarantor?: Maybe<Offer_Api_RequestRequiredGuarantorResponseOutput>;
  /** Restore offer */
  readonly restore?: Maybe<Offer_Api_RestoreOfferByIdResponseOutput>;
  /** Select services */
  readonly servicesselect?: Maybe<Offer_Api_SelectServicesResponseOutput>;
  /** Request first deposit replacement payment and Stripe's Direct Debit mandate */
  readonly setupdirectdebit?: Maybe<Gateway_Impl_Resolvers_DirectDebitResponseOutput>;
  /** Request standing order url */
  readonly standingorder?: Maybe<Offer_Api_StandingOrderResponseOutput>;
  /** Submit offer for review */
  readonly submit?: Maybe<Offer_Api_SubmitResponseOutput>;
  /** Updates existing offer */
  readonly update?: Maybe<Offer_Api_UpdateResponseOutput>;
  /** Update text of a clause or to remove clause by landlord */
  readonly updatecontractclauses?: Maybe<Offer_Api_UpdateContractClausesResponseOutput>;
};


/** Offer mutation root */
export type OfferMutationAcceptArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationAcceptupdateArgs = {
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationAddcontractclauseArgs = {
  clause_text?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationAddguarantorsArgs = {
  offer?: InputMaybe<Scalars['String']>;
  tenant_value?: InputMaybe<ReadonlyArray<Offer_Api_AddGuarantorTenantInput>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationAdvancecancelArgs = {
  offer?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationAdvancerequestArgs = {
  consent?: InputMaybe<Scalars['Boolean']>;
  offer?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['String']>;
  payment_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationCancelArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationChangestateclausesArgs = {
  clause_id?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Offer_Api_ClauseStateEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationChecksArgs = {
  forceskip?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  need_guarantor?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  tenants?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationCreateArgs = {
  offer?: InputMaybe<Offer_Api_OfferInput>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationDenyArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationInviteArgs = {
  email?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationPaymentArgs = {
  applicant_check?: InputMaybe<Offer_Api_ApplicantCheckInput>;
  offer?: InputMaybe<Scalars['String']>;
  payment_procedure?: InputMaybe<Offer_Api_PaymentProcedureEnum>;
  redirect_error?: InputMaybe<Scalars['String']>;
  redirect_success?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Offer_Api_PaymentTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationProgresstosigningArgs = {
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRemovesecondlandlordArgs = {
  offer_id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRenewtenancyArgs = {
  old_offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRentpaymentArgs = {
  offer?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['String']>;
  redirect_error?: InputMaybe<Scalars['String']>;
  redirect_success?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRentprofilepdfArgs = {
  request_id?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRequestchangeArgs = {
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRequiredguarantorArgs = {
  id?: InputMaybe<Scalars['String']>;
  tenants?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationRestoreArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationServicesselectArgs = {
  offer?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationSetupdirectdebitArgs = {
  email?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationStandingorderArgs = {
  offer?: InputMaybe<Scalars['String']>;
  redirect_error?: InputMaybe<Scalars['String']>;
  redirect_success?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationSubmitArgs = {
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationUpdateArgs = {
  offer?: InputMaybe<Offer_Api_OfferInput>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer mutation root */
export type OfferMutationUpdatecontractclausesArgs = {
  clause?: InputMaybe<ReadonlyArray<Offer_Api_ClauseInput>>;
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Offer query root */
export type OfferQuery = {
  readonly __typename: 'OfferQuery';
  /** Request Stripe's Direct Debit params */
  readonly depositselect?: Maybe<Gateway_Impl_Resolvers_DepositSelectResponseOutput>;
  /** Retrieves specified offer */
  readonly get?: Maybe<Offer_Api_GetResponseOutput>;
  /** Retrieves specified Stripe's mandate */
  readonly getmandate?: Maybe<Cardpayment_Api_GetMandateResponseOutput>;
  /** Retrieves all offers by property */
  readonly listbyproperty?: Maybe<Offer_Api_ListResponseOutput>;
  /** Retrieves current user incoming offers */
  readonly myin?: Maybe<Offer_Api_ListResponseOutput>;
  /** Retrieves current user outgoing offers */
  readonly myout?: Maybe<Offer_Api_ListResponseOutput>;
  /** Payment schedule for signed offer */
  readonly paymentschedule?: Maybe<Offer_Api_ScheduledPaymentListResponseOutput>;
  /** List services */
  readonly serviceslist?: Maybe<Offer_Api_ListServicesResponseOutput>;
};


/** Offer query root */
export type OfferQueryDepositselectArgs = {
  email?: InputMaybe<Scalars['String']>;
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer query root */
export type OfferQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Offer query root */
export type OfferQueryGetmandateArgs = {
  mandate_id?: InputMaybe<Scalars['String']>;
};


/** Offer query root */
export type OfferQueryListbypropertyArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Offer query root */
export type OfferQueryMyinArgs = {
  listing?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<ReadonlyArray<Offer_Api_OfferStateEnum>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer query root */
export type OfferQueryMyoutArgs = {
  listing?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<ReadonlyArray<Offer_Api_OfferStateEnum>>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer query root */
export type OfferQueryPaymentscheduleArgs = {
  offer?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  user?: InputMaybe<Scalars['String']>;
};


/** Offer query root */
export type OfferQueryServiceslistArgs = {
  offer?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Order mutation root */
export type OrderMutation = {
  readonly __typename: 'OrderMutation';
  /** Cancel current saved order */
  readonly cancel?: Maybe<Scalars['Boolean']>;
  /** Commit order and generate invoice */
  readonly commit?: Maybe<Invoice_Api_OrderCommitResponseOutput>;
  /** Create payment for standing order/invoice */
  readonly payment?: Maybe<Invoice_Api_PaymentCreateResponseOutput>;
  /** Render documents for order product */
  readonly render?: Maybe<Invoice_Api_ProductRenderDocumentsResponseOutput>;
  /** Save order */
  readonly save?: Maybe<Invoice_Api_OrderSaveResponseOutput>;
};


/** Order mutation root */
export type OrderMutationCancelArgs = {
  clientid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Order mutation root */
export type OrderMutationCommitArgs = {
  id?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Order mutation root */
export type OrderMutationPaymentArgs = {
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  invoice_reference?: InputMaybe<Scalars['String']>;
  system?: InputMaybe<Invoice_Api_IsPaymentSystemDataRequest_DataOneofInput>;
  user?: InputMaybe<Scalars['String']>;
};


/** Order mutation root */
export type OrderMutationRenderArgs = {
  clientid?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['String']>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Order mutation root */
export type OrderMutationSaveArgs = {
  clientid?: InputMaybe<Scalars['String']>;
  correlation_id?: InputMaybe<Scalars['String']>;
  correlation_target?: InputMaybe<Scalars['String']>;
  correlation_type?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  products?: InputMaybe<ReadonlyArray<Invoice_Api_ProductInput>>;
  userdata?: InputMaybe<Invoice_Api_UserDataInput>;
  userid?: InputMaybe<Scalars['String']>;
};

/** Order query root */
export type OrderQuery = {
  readonly __typename: 'OrderQuery';
  /** List orders */
  readonly list?: Maybe<Invoice_Api_OrderListResponseOutput>;
  /** Load saved cart or specific order by id */
  readonly load?: Maybe<Invoice_Api_OrderLoadResponseOutput>;
  /** List all user products */
  readonly products?: Maybe<Invoice_Api_ProductListResponseOutput>;
};


/** Order query root */
export type OrderQueryListArgs = {
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  params?: InputMaybe<Common_Api_Generic_Query_QueryParamsInput>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Order query root */
export type OrderQueryLoadArgs = {
  clientid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order_product?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Invoice_Api_OrderTypeEnum>;
  userid?: InputMaybe<Scalars['String']>;
};


/** Order query root */
export type OrderQueryProductsArgs = {
  invoice_type?: InputMaybe<Invoice_Api_InvoiceTypeEnum>;
  listing?: InputMaybe<Scalars['String']>;
  order_correlation_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_correlation_targets?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_correlation_types?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  order_created_range?: InputMaybe<Invoice_Api_TimeRangeInput>;
  order_statuses?: InputMaybe<ReadonlyArray<Invoice_Api_OrderStatusEnum>>;
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Ownership mutation root */
export type OwnershipMutation = {
  readonly __typename: 'OwnershipMutation';
  /** Create verification */
  readonly create?: Maybe<Account_Api_CreateOwnershipVerificationResponseOutput>;
};


/** Ownership mutation root */
export type OwnershipMutationCreateArgs = {
  account_id?: InputMaybe<Scalars['String']>;
  company_name?: InputMaybe<Scalars['String']>;
  document_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  first_name?: InputMaybe<Scalars['String']>;
  is_company?: InputMaybe<Scalars['Boolean']>;
  is_purchased?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
};

/** Ownership query root */
export type OwnershipQuery = {
  readonly __typename: 'OwnershipQuery';
  /** Check ownership is verified by id */
  readonly checkIsVerified?: Maybe<Account_Api_OwnershipIsVerifiedResponseOutput>;
};


/** Ownership query root */
export type OwnershipQueryCheckIsVerifiedArgs = {
  property_id?: InputMaybe<Scalars['String']>;
};

/** Payment mutation root */
export type PaymentMutation = {
  readonly __typename: 'PaymentMutation';
  /** Create payment for existent invoice. */
  readonly create?: Maybe<Invoice_Api_PaymentCreateResponseOutput>;
};


/** Payment mutation root */
export type PaymentMutationCreateArgs = {
  by_invoice_id?: InputMaybe<Scalars['String']>;
  by_invoice_reference?: InputMaybe<Scalars['String']>;
  payment_system?: InputMaybe<Invoice_Api_IsPaymentSystemDataRequest_DataOneofInput>;
};

/** Products query root */
export type ProductsQuery = {
  readonly __typename: 'ProductsQuery';
  /** Get product by id */
  readonly get?: Maybe<Extras_Api_GetResponseOutput>;
  /** Get product list */
  readonly list?: Maybe<Extras_Api_ListResponseOutput>;
};


/** Products query root */
export type ProductsQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
};


/** Products query root */
export type ProductsQueryListArgs = {
  attribute?: InputMaybe<Extras_Api_AttributeInput>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};

export type PropertyFinancesOutput = {
  readonly __typename: 'PropertyFinancesOutput';
  readonly income?: Maybe<ReadonlyArray<Invoice_Api_InvoiceOutput>>;
  readonly invoices?: Maybe<ReadonlyArray<Invoice_Api_InvoiceOutput>>;
  readonly mortgage?: Maybe<ReadonlyArray<Property_Api_MortgageEntryOutput>>;
  readonly scheduled?: Maybe<ReadonlyArray<Invoice_Api_InvoiceOutput>>;
};

/** Property mutation root */
export type PropertyMutation = {
  readonly __typename: 'PropertyMutation';
  /**
   * Accept property view
   * @deprecated use viewing { status }
   */
  readonly acceptview?: Maybe<Property_Api_AcceptViewResponseOutput>;
  /**
   * Cancel property view
   * @deprecated use viewing { status }
   */
  readonly cancelview?: Maybe<Property_Api_CancelViewResponseOutput>;
  readonly changebankdetails?: Maybe<Property_Api_ChangeBankDetailsResponseOutput>;
  /** Claim property */
  readonly claim?: Maybe<Property_Api_ClaimResponseOutput>;
  /** Create from preface */
  readonly commitpreface?: Maybe<Property_Api_CreateResponseOutput>;
  /** Commit state */
  readonly commitstate?: Maybe<Property_Api_CommitStateResponseOutput>;
  /**
   * Complete property view
   * @deprecated use viewing { status }
   */
  readonly completeview?: Maybe<Property_Api_CompleteViewResponseOutput>;
  /** Creates new property */
  readonly create?: Maybe<Property_Api_CreateResponseOutput>;
  /** Deletes existing property */
  readonly delete?: Maybe<Property_Api_DeleteResponseOutput>;
  /**
   * Fail property view
   * @deprecated use viewing { status }
   */
  readonly failview?: Maybe<Property_Api_FailViewResponseOutput>;
  /** Leave from listing */
  readonly leavefromlisting?: Maybe<Property_Api_LeaveFromListingFormResponseOutput>;
  /** Publish listing request */
  readonly publish?: Maybe<Property_Api_PublishResponseOutput>;
  /** Publish listing consent request */
  readonly publishconsent?: Maybe<Property_Api_PublishConsentResponseOutput>;
  /**
   * Reject property view
   * @deprecated use viewing { status }
   */
  readonly rejectview?: Maybe<Property_Api_RejectViewResponseOutput>;
  /** Republish listing request */
  readonly republish?: Maybe<Property_Api_RepublishResponseOutput>;
  /**
   * Request property view
   * @deprecated use viewing { request }
   */
  readonly requestview?: Maybe<Property_Api_RequestViewResponseOutput>;
  /** Restore property */
  readonly restore?: Maybe<Property_Api_RestoreResponseOutput>;
  /** Save listing reminder */
  readonly savereminder?: Maybe<Property_Api_SaveListingReminderResponseOutput>;
  /** Set landlord intention */
  readonly setLandlordIntention?: Maybe<Gateway_Impl_Resolvers_LandlordIntentionResponseOutput>;
  /** Set tenants intention */
  readonly setTenantsIntention?: Maybe<Property_Api_UpdateTenancyResponseOutput>;
  /** Create tenancy claim for completed tenancy (property supervisors only). */
  readonly tenancyClaimCreate?: Maybe<Property_Api_TenancyClaim_CreateResponseOutput>;
  /** Completely remove tenancy claim using one of provided IDs (property supervisors only). */
  readonly tenancyClaimRemove?: Maybe<Property_Api_TenancyClaim_RemoveResponseOutput>;
  /** Remove specified claim damage using one of provided IDs (property supervisors only). */
  readonly tenancyClaimRemoveDamage?: Maybe<Property_Api_TenancyClaim_RemoveDamageResponseOutput>;
  /** Move tenancy claim state into SETTLEMENT and create invoice to pay damages using one of provided IDs (tenants only). */
  readonly tenancyClaimSubmitSettlement?: Maybe<Invoice_Api_InvoiceOutput>;
  /** Update specified tenancy claim damage using one of provided IDs. */
  readonly tenancyClaimUpdateDamage?: Maybe<Property_Api_TenancyClaim_UpdateDamageResponseOutput>;
  /** Unpublish listing request */
  readonly unpublish?: Maybe<Property_Api_UnpublishResponseOutput>;
  /** Updates existing property */
  readonly update?: Maybe<Property_Api_UpdateResponseOutput>;
  /** Withdraw property */
  readonly withdraw?: Maybe<Property_Api_WithdrawnResponseOutput>;
};


/** Property mutation root */
export type PropertyMutationAcceptviewArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationCancelviewArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationChangebankdetailsArgs = {
  captcha?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  otp?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Property_Api_PropertyInput>;
  remote?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationClaimArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationCommitprefaceArgs = {
  session_id?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationCommitstateArgs = {
  id?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Property_Api_PropertyCompleteEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationCompleteviewArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationCreateArgs = {
  landlord_email?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Property_Api_PropertyInput>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  user_role?: InputMaybe<Property_Api_UserRoleEnum>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Property mutation root */
export type PropertyMutationDeleteArgs = {
  admin_site?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationFailviewArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationLeavefromlistingArgs = {
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationPublishArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationPublishconsentArgs = {
  listing?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  reply?: InputMaybe<Property_Api_ConsentReplyEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationRejectviewArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationRepublishArgs = {
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationRequestviewArgs = {
  comment?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  request?: InputMaybe<Property_Api_ViewRequestInput>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationRestoreArgs = {
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationSavereminderArgs = {
  date?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationSetLandlordIntentionArgs = {
  expiration_date?: InputMaybe<Scalars['String']>;
  intention?: InputMaybe<Property_Api_TenancyIntentionEnum>;
  offer_conditions?: InputMaybe<Offer_Api_OfferConditionsInput>;
  property?: InputMaybe<Scalars['String']>;
  tenancy?: InputMaybe<Scalars['String']>;
  termination_date?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationSetTenantsIntentionArgs = {
  intention?: InputMaybe<Property_Api_TenancyIntentionEnum>;
  penalty_consent?: InputMaybe<Scalars['Boolean']>;
  tenancy?: InputMaybe<Scalars['String']>;
  termination_date?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationTenancyClaimCreateArgs = {
  damages?: InputMaybe<ReadonlyArray<Property_Api_TenancyClaim_Damage_DetailsInput>>;
  tenancy_id?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationTenancyClaimRemoveArgs = {
  by_claim_id?: InputMaybe<Scalars['String']>;
  by_tenancy_id?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationTenancyClaimRemoveDamageArgs = {
  by_claim_id?: InputMaybe<Scalars['String']>;
  by_tenancy_id?: InputMaybe<Scalars['String']>;
  damage_id?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationTenancyClaimSubmitSettlementArgs = {
  by_claim_id?: InputMaybe<Scalars['String']>;
  by_tenancy_id?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationTenancyClaimUpdateDamageArgs = {
  by_claim_id?: InputMaybe<Scalars['String']>;
  by_tenancy_id?: InputMaybe<Scalars['String']>;
  damage?: InputMaybe<Property_Api_TenancyClaim_DamageInput>;
};


/** Property mutation root */
export type PropertyMutationUnpublishArgs = {
  admin_site?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property mutation root */
export type PropertyMutationUpdateArgs = {
  property?: InputMaybe<Property_Api_PropertyInput>;
  user?: InputMaybe<Scalars['String']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Property mutation root */
export type PropertyMutationWithdrawArgs = {
  adminSite?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Property_Api_PropertyWithdrawnReasonEnum>;
  user?: InputMaybe<Scalars['String']>;
};

/** Property query root */
export type PropertyQuery = {
  readonly __typename: 'PropertyQuery';
  /** Retrieves specified property */
  readonly get?: Maybe<Property_Api_GetResponseOutput>;
  /** Retrieves active tenancy for property */
  readonly getactivetenancy?: Maybe<Gateway_Impl_Resolvers_GetTenancyResponseOutput>;
  /** Get average rent prices by bedrooms */
  readonly getaveragerentprices?: Maybe<Property_Api_GetAverageRentPricesResponseOutput>;
  /** Retrieves specified property */
  readonly getprivate?: Maybe<Property_Api_GetResponseOutput>;
  /** Retrieves specified property for preface user */
  readonly getprivatepreface?: Maybe<Property_Api_GetResponseOutput>;
  /** Retrieves specified tenancy */
  readonly gettenancy?: Maybe<Gateway_Impl_Resolvers_GetTenancyResponseOutput>;
  /** Retrieves specified tenancy by offer */
  readonly gettenancybyoffer?: Maybe<Gateway_Impl_Resolvers_GetTenancyResponseOutput>;
  /** Retrieves list of tenancy by property id */
  readonly gettenancybyproperty?: Maybe<Property_Api_GetTenancyResponseOutput>;
  /** Retrieves owned property */
  readonly list?: Maybe<Property_Api_PropertyListResponseOutput>;
  /**
   * Lists view requests for property
   * @deprecated use viewing { list }
   */
  readonly listview?: Maybe<Property_Api_ListViewResponseOutput>;
  /**
   * Retrieves owned property
   * @deprecated Use list
   */
  readonly my?: Maybe<Property_Api_PropertyListResponseOutput>;
  /** Get ownership verification */
  readonly ownershipIsVerified?: Maybe<Account_Api_OwnershipIsVerifiedResponseOutput>;
  /** Searches property by provided criteria */
  readonly search?: Maybe<Property_Api_PropertySearchResponseOutput>;
  /** Searches property within a geographical polygon */
  readonly searchinpolygon?: Maybe<Property_Api_PropertySearchResponseOutput>;
  /** Retrieve existent tenancy claim using one of provided IDs. */
  readonly tenancyClaimGet?: Maybe<Property_Api_TenancyClaim_GetResponseOutput>;
  /** Retrieve tenancy claim settlement invoice using one of provided IDs. */
  readonly tenancyClaimGetSettlement?: Maybe<Invoice_Api_InvoiceOutput>;
  /** Get property valuation */
  readonly valuation?: Maybe<Property_Api_GetValuationResponseOutput>;
  /**
   * Reserved view intervals
   * @deprecated use viewing { reserved }
   */
  readonly viewreserved?: Maybe<Property_Api_ViewReservedResponseOutput>;
};


/** Property query root */
export type PropertyQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  test?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
  verify?: InputMaybe<Scalars['Boolean']>;
};


/** Property query root */
export type PropertyQueryGetactivetenancyArgs = {
  property_id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryGetaveragerentpricesArgs = {
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryGetprivateArgs = {
  id?: InputMaybe<Scalars['String']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  test?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryGetprivateprefaceArgs = {
  id?: InputMaybe<Scalars['String']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  test?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryGettenancyArgs = {
  id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryGettenancybyofferArgs = {
  offer_id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryGettenancybypropertyArgs = {
  property_id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryListArgs = {
  admin_site?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  roles?: InputMaybe<ReadonlyArray<Property_Api_UserRoleEnum>>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
  statuses?: InputMaybe<ReadonlyArray<Listing_Api_VersionStatusEnum>>;
  test?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryListviewArgs = {
  listing?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  sender?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryMyArgs = {
  admin_site?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  roles?: InputMaybe<ReadonlyArray<Property_Api_UserRoleEnum>>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
  statuses?: InputMaybe<ReadonlyArray<Listing_Api_VersionStatusEnum>>;
  test?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryOwnershipIsVerifiedArgs = {
  property_id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQuerySearchArgs = {
  filter?: InputMaybe<Property_Api_PropertySearchFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort_type?: InputMaybe<Property_Api_PropertySearchSortTypeEnum>;
};


/** Property query root */
export type PropertyQuerySearchinpolygonArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  polygon?: InputMaybe<ReadonlyArray<Property_Api_GpsCoordinatesInput>>;
};


/** Property query root */
export type PropertyQueryTenancyClaimGetArgs = {
  by_claim_id?: InputMaybe<Scalars['String']>;
  by_tenancy_id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryTenancyClaimGetSettlementArgs = {
  by_claim_id?: InputMaybe<Scalars['String']>;
  by_tenancy_id?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryValuationArgs = {
  bedrooms_no?: InputMaybe<Scalars['Int']>;
  building?: InputMaybe<Scalars['String']>;
  flat?: InputMaybe<Scalars['String']>;
  furniture?: InputMaybe<Property_Api_PropertyFurnitureEnum>;
  postcode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Property_Api_PropertyTypeEnum>;
  user?: InputMaybe<Scalars['String']>;
};


/** Property query root */
export type PropertyQueryViewreservedArgs = {
  property?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Root of all mutations */
export type RootMutation = {
  readonly __typename: 'RootMutation';
  readonly account?: Maybe<AccountMutation>;
  readonly admin?: Maybe<AdminMutation>;
  readonly auth?: Maybe<AuthMutation>;
  readonly cardpayment?: Maybe<CardPaymentMutation>;
  readonly comments?: Maybe<CommentsMutation>;
  readonly contact?: Maybe<ContactMutation>;
  readonly contentsInsurance?: Maybe<ContentsInsuranceMutation>;
  readonly contract?: Maybe<ContractMutation>;
  readonly document?: Maybe<DocumentMutation>;
  readonly expenses?: Maybe<ExpensesMutation>;
  readonly invitation?: Maybe<InvitationMutation>;
  readonly listing?: Maybe<ListingMutation>;
  readonly maintenance?: Maybe<MaintenanceMutation>;
  readonly messaging?: Maybe<MessagingMutation>;
  readonly mortgage?: Maybe<MortgageMutation>;
  readonly notification?: Maybe<NotificationMutation>;
  readonly oauth?: Maybe<OauthMutation>;
  readonly offer?: Maybe<OfferMutation>;
  readonly order?: Maybe<OrderMutation>;
  readonly ownership?: Maybe<OwnershipMutation>;
  readonly payment?: Maybe<PaymentMutation>;
  readonly property?: Maybe<PropertyMutation>;
  readonly rto?: Maybe<RtoMutation>;
  readonly services?: Maybe<ServicesMutation>;
  readonly signature?: Maybe<SignatureMutation>;
  readonly viewing?: Maybe<ViewingMutation>;
};

/** Root of all queries */
export type RootQuery = {
  readonly __typename: 'RootQuery';
  readonly account?: Maybe<AccountQuery>;
  readonly address?: Maybe<AddressQuery>;
  readonly admin?: Maybe<AdminQuery>;
  readonly auth?: Maybe<AuthQuery>;
  readonly cardpayment?: Maybe<CardPaymentQuery>;
  readonly closecom?: Maybe<CloseComQuery>;
  readonly comments?: Maybe<CommentsQuery>;
  readonly contentsInsurance?: Maybe<ContentsInsuranceQuery>;
  readonly contract?: Maybe<ContractQuery>;
  readonly document?: Maybe<DocumentQuery>;
  readonly document_type?: Maybe<DocumentTypeQuery>;
  readonly expenses?: Maybe<ExpensesQuery>;
  readonly file?: Maybe<FileQuery>;
  readonly geo?: Maybe<GeoQuery>;
  readonly grabdata?: Maybe<GrabdataQuery>;
  readonly invitation?: Maybe<InvitationQuery>;
  readonly invoice?: Maybe<InvoiceQuery>;
  readonly listing?: Maybe<ListingQuery>;
  readonly maintenance?: Maybe<MaintenanceQuery>;
  readonly messaging?: Maybe<MessagingQuery>;
  readonly mortgage?: Maybe<MortgageQuery>;
  readonly notification?: Maybe<NotificationQuery>;
  readonly oauth?: Maybe<OauthQuery>;
  readonly offer?: Maybe<OfferQuery>;
  readonly order?: Maybe<OrderQuery>;
  readonly ownership?: Maybe<OwnershipQuery>;
  readonly products?: Maybe<ProductsQuery>;
  readonly property?: Maybe<PropertyQuery>;
  readonly rto?: Maybe<RtoQuery>;
  readonly services?: Maybe<ServicesQuery>;
  readonly signature?: Maybe<SignatureQuery>;
  readonly viewing?: Maybe<ViewingQuery>;
};

/** Root of all subscriptions */
export type RootSubscription = {
  readonly __typename: 'RootSubscription';
  readonly messaging?: Maybe<MessagingSubscription>;
};

/** Rto mutation root */
export type RtoMutation = {
  readonly __typename: 'RtoMutation';
  /** Rto answer from tenant */
  readonly answerfromtenant?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto create */
  readonly create?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto create by guest */
  readonly createbyguest?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto create tenancy */
  readonly createtenancy?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto renewal */
  readonly renewal?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto update landlord */
  readonly updatelandlord?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto update rent plan */
  readonly updaterentplan?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto update rto end date */
  readonly updatertoenddate?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Rto update tenancy */
  readonly updatetenancy?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
};


/** Rto mutation root */
export type RtoMutationAnswerfromtenantArgs = {
  accept?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['String']>;
};


/** Rto mutation root */
export type RtoMutationCreateArgs = {
  landlord_id?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
  rent_plan?: InputMaybe<Offer_Api_Rto_RentPlanEnum>;
};


/** Rto mutation root */
export type RtoMutationCreatebyguestArgs = {
  property_id?: InputMaybe<Scalars['String']>;
  rent_plan?: InputMaybe<Offer_Api_Rto_RentPlanEnum>;
};


/** Rto mutation root */
export type RtoMutationCreatetenancyArgs = {
  id?: InputMaybe<Scalars['String']>;
  landlord_id?: InputMaybe<Scalars['String']>;
  lead_tenant?: InputMaybe<Offer_Api_Rto_LeadTenantInput>;
  payment_data?: InputMaybe<Offer_Api_Rto_PaymentDataInput>;
};


/** Rto mutation root */
export type RtoMutationRenewalArgs = {
  end_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landlord_id?: InputMaybe<Scalars['String']>;
};


/** Rto mutation root */
export type RtoMutationUpdatelandlordArgs = {
  id?: InputMaybe<Scalars['String']>;
  landlord_id?: InputMaybe<Scalars['String']>;
};


/** Rto mutation root */
export type RtoMutationUpdaterentplanArgs = {
  id?: InputMaybe<Scalars['String']>;
  landlord_id?: InputMaybe<Scalars['String']>;
  rent_plan?: InputMaybe<Offer_Api_Rto_RentPlanEnum>;
};


/** Rto mutation root */
export type RtoMutationUpdatertoenddateArgs = {
  end_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landlord_id?: InputMaybe<Scalars['String']>;
};


/** Rto mutation root */
export type RtoMutationUpdatetenancyArgs = {
  lead_tenant_email?: InputMaybe<Scalars['String']>;
  lead_tenant_first_name?: InputMaybe<Scalars['String']>;
  lead_tenant_last_name?: InputMaybe<Scalars['String']>;
  lead_tenant_phone?: InputMaybe<Scalars['String']>;
  rto_id?: InputMaybe<Scalars['String']>;
};

/** Rto query root */
export type RtoQuery = {
  readonly __typename: 'RtoQuery';
  /** Retrieves specified rto by property id */
  readonly getbypropertyid?: Maybe<Offer_Api_Rto_RtoResponseOutput>;
  /** Retrieves specified rtos by property ids */
  readonly getbypropertyids?: Maybe<Offer_Api_Rto_RtosResponseOutput>;
};


/** Rto query root */
export type RtoQueryGetbypropertyidArgs = {
  property_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};


/** Rto query root */
export type RtoQueryGetbypropertyidsArgs = {
  property_ids?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** Services mutation root */
export type ServicesMutation = {
  readonly __typename: 'ServicesMutation';
  /**
   * Add service
   * @deprecated use admin Product mutation
   */
  readonly add?: Maybe<Extras_Api_AddResponseOutput>;
  /**
   * Delete service
   * @deprecated use admin Product mutation
   */
  readonly delete?: Maybe<Extras_Api_DeleteResponseOutput>;
  /**
   * Update service
   * @deprecated use admin Product mutation
   */
  readonly update?: Maybe<Extras_Api_UpdateResponseOutput>;
};


/** Services mutation root */
export type ServicesMutationAddArgs = {
  extra_service?: InputMaybe<Extras_Api_ServiceInput>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
};


/** Services mutation root */
export type ServicesMutationDeleteArgs = {
  id?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
};


/** Services mutation root */
export type ServicesMutationUpdateArgs = {
  extra_service?: InputMaybe<Extras_Api_ServiceInput>;
  placeholder?: InputMaybe<Scalars['Boolean']>;
};

/** Services query root */
export type ServicesQuery = {
  readonly __typename: 'ServicesQuery';
  /**
   * Get service by id
   * @deprecated Use products;
   */
  readonly get?: Maybe<Extras_Api_GetResponseOutput>;
  /**
   * Get service list
   * @deprecated Use products;
   */
  readonly list?: Maybe<Extras_Api_ListResponseOutput>;
  /**
   * Get service list
   * @deprecated Use products; Use get / list
   */
  readonly listwithprice?: Maybe<Extras_Api_ListResponseOutput>;
};


/** Services query root */
export type ServicesQueryGetArgs = {
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
  property_id?: InputMaybe<Scalars['String']>;
};


/** Services query root */
export type ServicesQueryListArgs = {
  attribute?: InputMaybe<Extras_Api_AttributeInput>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
  property_id?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};


/** Services query root */
export type ServicesQueryListwithpriceArgs = {
  attribute?: InputMaybe<Extras_Api_AttributeInput>;
  cart_product?: InputMaybe<Scalars['Boolean']>;
  property?: InputMaybe<Extras_Api_PropertyInput>;
  property_id?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};

/** Signature mutation root */
export type SignatureMutation = {
  readonly __typename: 'SignatureMutation';
  /** Cancel signature request */
  readonly cancel?: Maybe<Signature_Api_CancelResponseOutput>;
  /** Create signature request */
  readonly create?: Maybe<Signature_Api_SignatureResponseOutput>;
};


/** Signature mutation root */
export type SignatureMutationCancelArgs = {
  signature_request_id?: InputMaybe<Scalars['String']>;
};


/** Signature mutation root */
export type SignatureMutationCreateArgs = {
  contract_id?: InputMaybe<Scalars['String']>;
  formFieldsPerDocument?: InputMaybe<ReadonlyArray<ReadonlyArray<Signature_Api_DocumentFormFieldInput>>>;
  message?: InputMaybe<Scalars['String']>;
  offer_id?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['String']>;
  signers?: InputMaybe<ReadonlyArray<Signature_Api_SignerInput>>;
  subject?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  viewing_id?: InputMaybe<Scalars['String']>;
};

/** Signature query root */
export type SignatureQuery = {
  readonly __typename: 'SignatureQuery';
  /** Get signature info by signature request id */
  readonly info?: Maybe<Signature_Api_SignatureResponseOutput>;
  /** Get pdf media object id by signature request id */
  readonly pdf?: Maybe<Signature_Api_GetPdfResponseOutput>;
};


/** Signature query root */
export type SignatureQueryInfoArgs = {
  signature_request_id?: InputMaybe<Scalars['String']>;
};


/** Signature query root */
export type SignatureQueryPdfArgs = {
  signature_request_id?: InputMaybe<Scalars['String']>;
};

export type ViewingEventSubject = {
  readonly __typename: 'ViewingEventSubject';
  /** SDP answer payload from broadcaster to peer */
  readonly sdpAnswer?: Maybe<Messaging_Api_Viewing_SdpOutput>;
  /** SDP offer payload from peer to broadcaster */
  readonly sdpOffer?: Maybe<Messaging_Api_Viewing_SdpOutput>;
  /** Viewing broadcasting state */
  readonly viewingState?: Maybe<Messaging_Api_Viewing_StateEnum>;
};

/** Viewing mutation root */
export type ViewingMutation = {
  readonly __typename: 'ViewingMutation';
  /** Request property view */
  readonly request?: Maybe<Viewing_Api_ViewingSaveResponseOutput>;
  /** Cancel property view */
  readonly status?: Maybe<Property_Api_CancelViewResponseOutput>;
};


/** Viewing mutation root */
export type ViewingMutationRequestArgs = {
  details?: InputMaybe<Viewing_Api_ViewingDetailsInput>;
  listing?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  recaptcha_token?: InputMaybe<Scalars['String']>;
  remotely?: InputMaybe<Scalars['Boolean']>;
  time?: InputMaybe<Viewing_Api_ViewingTimeInput>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  viewing_id?: InputMaybe<Scalars['String']>;
};


/** Viewing mutation root */
export type ViewingMutationStatusArgs = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  property?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

/** Viewing query root */
export type ViewingQuery = {
  readonly __typename: 'ViewingQuery';
  /** Lists view requests for property */
  readonly list?: Maybe<Viewing_Api_ViewingListResponseOutput>;
  /** Reserved view intervals */
  readonly reserved?: Maybe<Viewing_Api_ViewingReservedResponseOutput>;
};


/** Viewing query root */
export type ViewingQueryListArgs = {
  spec?: InputMaybe<Viewing_Api_ViewingSpecInput>;
};


/** Viewing query root */
export type ViewingQueryReservedArgs = {
  spec?: InputMaybe<Viewing_Api_ViewingSpecInput>;
};

export type _Int32_Offer_Api_ScheduleNotificationInputMap = {
  readonly key: Scalars['Int'];
  readonly value?: InputMaybe<Offer_Api_ScheduleNotificationInput>;
};

export type _Int32_Offer_Api_ScheduleNotificationOutputMap = {
  readonly __typename: '_int32_offer_api_ScheduleNotificationOutputMap';
  readonly key: Scalars['Int'];
  readonly value: Offer_Api_ScheduleNotificationOutput;
};

export type _String__StringInputMap = {
  readonly key: Scalars['String'];
  readonly value?: InputMaybe<Scalars['String']>;
};

export type _String__StringOutputMap = {
  readonly __typename: '_string__stringOutputMap';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type _String_Expenses_Api_ExpensePayoutInputMap = {
  readonly key: Scalars['String'];
  readonly value?: InputMaybe<Expenses_Api_ExpensePayoutInput>;
};

export type _String_Expenses_Api_ExpensePayoutOutputMap = {
  readonly __typename: '_string_expenses_api_ExpensePayoutOutputMap';
  readonly key: Scalars['String'];
  readonly value: Expenses_Api_ExpensePayoutOutput;
};

export type _String_Expenses_Api_IncomePayoutInputMap = {
  readonly key: Scalars['String'];
  readonly value?: InputMaybe<Expenses_Api_IncomePayoutInput>;
};

export type _String_Expenses_Api_IncomePayoutOutputMap = {
  readonly __typename: '_string_expenses_api_IncomePayoutOutputMap';
  readonly key: Scalars['String'];
  readonly value: Expenses_Api_IncomePayoutOutput;
};

export type _String_Google_Golang_Org_Protobuf_Types_Known_Timestamppb_TimestampInputMap = {
  readonly key: Scalars['String'];
  readonly value?: InputMaybe<Scalars['String']>;
};

export type _String_Google_Golang_Org_Protobuf_Types_Known_Timestamppb_TimestampOutputMap = {
  readonly __typename: '_string_google_golang_org_protobuf_types_known_timestamppb_TimestampOutputMap';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type _String_Signature_Api_SignatureOutputMap = {
  readonly __typename: '_string_signature_api_SignatureOutputMap';
  readonly key: Scalars['String'];
  readonly value: Signature_Api_SignatureOutput;
};

export type Account_Api_AccountBankDetailsInput = {
  readonly account_name?: InputMaybe<Scalars['String']>;
  readonly account_number?: InputMaybe<Scalars['String']>;
  readonly personal_reference?: InputMaybe<Scalars['String']>;
  readonly society?: InputMaybe<Scalars['String']>;
  readonly sort_code?: InputMaybe<Scalars['String']>;
};

export type Account_Api_AccountBankDetailsOutput = {
  readonly __typename: 'account_api_AccountBankDetailsOutput';
  readonly account_name: Scalars['String'];
  readonly account_number: Scalars['String'];
  readonly personal_reference: Scalars['String'];
  readonly society: Scalars['String'];
  readonly sort_code: Scalars['String'];
};

export type Account_Api_AccountContactInput = {
  readonly contact_status?: InputMaybe<Account_Api_AccountContactStatusInput>;
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
  readonly phones_secondary?: InputMaybe<ReadonlyArray<Account_Api_PhoneInput>>;
};

export type Account_Api_AccountContactOutput = {
  readonly __typename: 'account_api_AccountContactOutput';
  readonly contact_status?: Maybe<Account_Api_AccountContactStatusOutput>;
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
  readonly phones_secondary?: Maybe<ReadonlyArray<Account_Api_PhoneOutput>>;
};

export type Account_Api_AccountContactStatusInput = {
  readonly email_verified?: InputMaybe<Scalars['Boolean']>;
  readonly phone_verified?: InputMaybe<Scalars['Boolean']>;
};

export type Account_Api_AccountContactStatusOutput = {
  readonly __typename: 'account_api_AccountContactStatusOutput';
  readonly email_verified?: Maybe<Scalars['Boolean']>;
  readonly phone_verified?: Maybe<Scalars['Boolean']>;
};

export type Account_Api_AccountContactVerificationOutput = {
  readonly __typename: 'account_api_AccountContactVerificationOutput';
  readonly email_primary?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly phone_primary?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly phones_secondary?: Maybe<ReadonlyArray<ReadonlyArray<Account_Api_AccountErrorEnum>>>;
};

export enum Account_Api_AccountCreationTypeEnum {
  AccountCreationTypeEmail = 'ACCOUNT_CREATION_TYPE_EMAIL',
  AccountCreationTypeGoogle = 'ACCOUNT_CREATION_TYPE_GOOGLE'
}

export type Account_Api_AccountDetailsEnrichedOutput = {
  readonly __typename: 'account_api_AccountDetailsEnrichedOutput';
  readonly avatar?: Maybe<Document_Api_FileOutput>;
  readonly birthday?: Maybe<Scalars['String']>;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly full_name?: Maybe<Scalars['String']>;
  readonly gender?: Maybe<Account_Api_AccountGenderEnum>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly uk_ni?: Maybe<Scalars['String']>;
};

export type Account_Api_AccountDetailsInput = {
  readonly avatar?: InputMaybe<Scalars['String']>;
  readonly birthday?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly full_name?: InputMaybe<Scalars['String']>;
  readonly gender?: InputMaybe<Account_Api_AccountGenderEnum>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly uk_ni?: InputMaybe<Scalars['String']>;
};

export type Account_Api_AccountDetailsVerificationOutput = {
  readonly __typename: 'account_api_AccountDetailsVerificationOutput';
  readonly avatar?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly birthday?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly first_name?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly gender?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly last_name?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly uk_ni?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
};

export type Account_Api_AccountEnrichedOutput = {
  readonly __typename: 'account_api_AccountEnrichedOutput';
  readonly admin?: Maybe<Scalars['Boolean']>;
  readonly authorization_origin: Account_Api_AuthorizationOriginEnum;
  readonly bank_details?: Maybe<Account_Api_AccountBankDetailsOutput>;
  readonly block?: Maybe<Scalars['Boolean']>;
  readonly contact?: Maybe<Account_Api_AccountContactOutput>;
  readonly created_at?: Maybe<Scalars['String']>;
  readonly creation_type?: Maybe<Account_Api_AccountCreationTypeEnum>;
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly details?: Maybe<Account_Api_AccountDetailsEnrichedOutput>;
  readonly documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly enable_landlord_tool?: Maybe<Scalars['Boolean']>;
  readonly entrypoint: Account_Api_EntryPointEnum;
  readonly favorites?: Maybe<Account_Api_AccountFavoritesOutput>;
  readonly generated?: Maybe<Scalars['Boolean']>;
  readonly id: Scalars['String'];
  readonly identity_verification: Scalars['Boolean'];
  readonly is_verified: Scalars['Boolean'];
  readonly last_login?: Maybe<Scalars['String']>;
  readonly location?: Maybe<Account_Api_AccountLocationOutput>;
  readonly log?: Maybe<ReadonlyArray<Account_Api_LogOutput>>;
  readonly resources?: Maybe<Account_Api_AccountResourcesOutput>;
  readonly test?: Maybe<Scalars['Boolean']>;
  readonly tos_version?: Maybe<Scalars['Int']>;
  readonly verification_info: Account_Api_GetVerificationResponseOutput;
  readonly viewed_videos_ids?: Maybe<ReadonlyArray<Scalars['Int']>>;
};

export enum Account_Api_AccountErrorEnum {
  AccountAlreadyDeleted = 'ACCOUNT_ALREADY_DELETED',
  AccountErrorInvalidEmail = 'ACCOUNT_ERROR_INVALID_EMAIL',
  AccountErrorInvalidGender = 'ACCOUNT_ERROR_INVALID_GENDER',
  AccountErrorInvalidId = 'ACCOUNT_ERROR_INVALID_ID',
  AccountErrorInvalidLocation = 'ACCOUNT_ERROR_INVALID_LOCATION',
  AccountErrorInvalidName = 'ACCOUNT_ERROR_INVALID_NAME',
  AccountErrorInvalidPhone = 'ACCOUNT_ERROR_INVALID_PHONE',
  AccountErrorInvalidPhoneType = 'ACCOUNT_ERROR_INVALID_PHONE_TYPE',
  AccountErrorInvalidTime = 'ACCOUNT_ERROR_INVALID_TIME',
  AccountNotDeleted = 'ACCOUNT_NOT_DELETED',
  UnknownAccountError = 'UNKNOWN_ACCOUNT_ERROR'
}

export enum Account_Api_AccountFavoriteTypeEnum {
  AccountFavoriteProperty = 'ACCOUNT_FAVORITE_PROPERTY',
  UnknownAccountFavoriteType = 'UNKNOWN_ACCOUNT_FAVORITE_TYPE'
}

export type Account_Api_AccountFavoritesInput = {
  readonly placeholder?: InputMaybe<Scalars['Boolean']>;
  readonly properties?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Account_Api_AccountFavoritesOutput = {
  readonly __typename: 'account_api_AccountFavoritesOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly properties?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum Account_Api_AccountGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  UnknownGender = 'UNKNOWN_GENDER'
}

export type Account_Api_AccountInput = {
  readonly admin?: InputMaybe<Scalars['Boolean']>;
  readonly authorization_origin?: InputMaybe<Account_Api_AuthorizationOriginEnum>;
  readonly bank_details?: InputMaybe<Account_Api_AccountBankDetailsInput>;
  readonly block?: InputMaybe<Scalars['Boolean']>;
  readonly contact?: InputMaybe<Account_Api_AccountContactInput>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly creation_type?: InputMaybe<Account_Api_AccountCreationTypeEnum>;
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
  readonly details?: InputMaybe<Account_Api_AccountDetailsInput>;
  readonly enable_landlord_tool?: InputMaybe<Scalars['Boolean']>;
  readonly entrypoint?: InputMaybe<Account_Api_EntryPointEnum>;
  readonly favorites?: InputMaybe<Account_Api_AccountFavoritesInput>;
  readonly generated?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly identity_verification?: InputMaybe<Scalars['Boolean']>;
  readonly is_verified?: InputMaybe<Scalars['Boolean']>;
  readonly last_login?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Account_Api_AccountLocationInput>;
  readonly log?: InputMaybe<ReadonlyArray<Account_Api_LogInput>>;
  readonly resources?: InputMaybe<Account_Api_AccountResourcesInput>;
  readonly test?: InputMaybe<Scalars['Boolean']>;
  readonly tos_version?: InputMaybe<Scalars['Int']>;
  readonly viewed_videos_ids?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type Account_Api_AccountLocationInput = {
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly address3?: InputMaybe<Scalars['String']>;
  readonly address4?: InputMaybe<Scalars['String']>;
  readonly building?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly flat?: InputMaybe<Scalars['String']>;
  readonly gps?: InputMaybe<Account_Api_GpsCoordinatesInput>;
  readonly postcode?: InputMaybe<Scalars['String']>;
  readonly street?: InputMaybe<Scalars['String']>;
};

export type Account_Api_AccountLocationOutput = {
  readonly __typename: 'account_api_AccountLocationOutput';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly address4?: Maybe<Scalars['String']>;
  readonly building?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly flat?: Maybe<Scalars['String']>;
  readonly gps?: Maybe<Account_Api_GpsCoordinatesOutput>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
};

export type Account_Api_AccountLocationVerificationOutput = {
  readonly __typename: 'account_api_AccountLocationVerificationOutput';
  readonly address1?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly address2?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly address3?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly address4?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly building?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly city?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly country?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly flat?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly gps?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly postcode?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly street?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
};

export enum Account_Api_AccountPhoneTypeEnum {
  PhoneTypeHome = 'PHONE_TYPE_HOME',
  PhoneTypeMobile = 'PHONE_TYPE_MOBILE',
  PhoneTypeWork = 'PHONE_TYPE_WORK',
  UnknownPhoneType = 'UNKNOWN_PHONE_TYPE'
}

export type Account_Api_AccountResourcesInput = {
  readonly owned_properties?: InputMaybe<Scalars['Int']>;
  readonly viewing_requests?: InputMaybe<Scalars['Int']>;
};

export type Account_Api_AccountResourcesOutput = {
  readonly __typename: 'account_api_AccountResourcesOutput';
  readonly owned_properties: Scalars['Int'];
  readonly viewing_requests: Scalars['Int'];
};

export type Account_Api_AccountVerificationOutput = {
  readonly __typename: 'account_api_AccountVerificationOutput';
  readonly contact: Account_Api_AccountContactVerificationOutput;
  readonly created_at?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly details: Account_Api_AccountDetailsVerificationOutput;
  readonly id?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly location: Account_Api_AccountLocationVerificationOutput;
};

export enum Account_Api_AuthorizationOriginEnum {
  AuthorizationOriginRightmove = 'AUTHORIZATION_ORIGIN_RIGHTMOVE',
  AuthorizationOriginUnknown = 'AUTHORIZATION_ORIGIN_UNKNOWN',
  AuthorizationOriginZoopla = 'AUTHORIZATION_ORIGIN_ZOOPLA'
}

export enum Account_Api_ChangeBankDetailsErrorEnum {
  BankDetailsRequired = 'BANK_DETAILS_REQUIRED',
  CaptchaInvalid = 'CAPTCHA_INVALID',
  CaptchaRequired = 'CAPTCHA_REQUIRED',
  PhoneOtpInvalid = 'PHONE_OTP_INVALID',
  PhoneOtpRequired = 'PHONE_OTP_REQUIRED'
}

export type Account_Api_ChangeBankDetailsResponseOutput = {
  readonly __typename: 'account_api_ChangeBankDetailsResponseOutput';
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly encoded_phone_number: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Account_Api_ChangeBankDetailsErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Account_Api_ConfirmationResponseOutput = {
  readonly __typename: 'account_api_ConfirmationResponseOutput';
  readonly error: Scalars['Boolean'];
  readonly success: Scalars['Boolean'];
};

export type Account_Api_CreateAdminVerificationSessionResponseOutput = {
  readonly __typename: 'account_api_CreateAdminVerificationSessionResponseOutput';
  readonly session: Account_Api_VeriffSessionOutput;
};

export type Account_Api_CreateOwnershipVerificationResponseOutput = {
  readonly __typename: 'account_api_CreateOwnershipVerificationResponseOutput';
  readonly ownership_id: Scalars['String'];
};

export type Account_Api_CreateVeriffSessionResponseOutput = {
  readonly __typename: 'account_api_CreateVeriffSessionResponseOutput';
  readonly session: Account_Api_VeriffSessionOutput;
};

export type Account_Api_DeleteAccountResponseOutput = {
  readonly __typename: 'account_api_DeleteAccountResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Account_Api_DeleteSessionResponseOutput = {
  readonly __typename: 'account_api_DeleteSessionResponseOutput';
  readonly code: Scalars['String'];
  readonly id: Scalars['String'];
  readonly message: Scalars['String'];
  readonly status: Scalars['String'];
};

export type Account_Api_DownloadUsersCsvResponseOutput = {
  readonly __typename: 'account_api_DownloadUsersCsvResponseOutput';
  readonly file: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export enum Account_Api_EntryPointEnum {
  EntryPointAdminRemoteSale = 'ENTRY_POINT_ADMIN_REMOTE_SALE',
  EntryPointBookMortgageAdvice = 'ENTRY_POINT_BOOK_MORTGAGE_ADVICE',
  EntryPointComplianceEicr = 'ENTRY_POINT_COMPLIANCE_EICR',
  EntryPointComplianceEpc = 'ENTRY_POINT_COMPLIANCE_EPC',
  EntryPointComplianceGasSafety = 'ENTRY_POINT_COMPLIANCE_GAS_SAFETY',
  EntryPointContentBlogPost = 'ENTRY_POINT_CONTENT_BLOG_POST',
  EntryPointContentWebinar = 'ENTRY_POINT_CONTENT_WEBINAR',
  EntryPointGetFreeAdvice = 'ENTRY_POINT_GET_FREE_ADVICE',
  EntryPointInsuranceHei = 'ENTRY_POINT_INSURANCE_HEI',
  EntryPointInsuranceRgi = 'ENTRY_POINT_INSURANCE_RGI',
  EntryPointJoinMashroom = 'ENTRY_POINT_JOIN_MASHROOM',
  EntryPointListing = 'ENTRY_POINT_LISTING',
  EntryPointManagementAdvanceRent = 'ENTRY_POINT_MANAGEMENT_ADVANCE_RENT',
  EntryPointManagementDepositReplacement = 'ENTRY_POINT_MANAGEMENT_DEPOSIT_REPLACEMENT',
  EntryPointManagementInventory = 'ENTRY_POINT_MANAGEMENT_INVENTORY',
  EntryPointManagementMaintenance = 'ENTRY_POINT_MANAGEMENT_MAINTENANCE',
  EntryPointManagementProfessionalPhotography = 'ENTRY_POINT_MANAGEMENT_PROFESSIONAL_PHOTOGRAPHY',
  EntryPointManagementTenantReferencing = 'ENTRY_POINT_MANAGEMENT_TENANT_REFERENCING',
  EntryPointMortgageBuyToLet = 'ENTRY_POINT_MORTGAGE_BUY_TO_LET',
  EntryPointMortgageFirstTimeBuy = 'ENTRY_POINT_MORTGAGE_FIRST_TIME_BUY',
  EntryPointMortgageRemortgage = 'ENTRY_POINT_MORTGAGE_REMORTGAGE',
  EntryPointPaymentPost = 'ENTRY_POINT_PAYMENT_POST',
  EntryPointTenantBrowseHome = 'ENTRY_POINT_TENANT_BROWSE_HOME',
  EntryPointTenantContentsInsurance = 'ENTRY_POINT_TENANT_CONTENTS_INSURANCE',
  EntryPointTenantDepositReplacement = 'ENTRY_POINT_TENANT_DEPOSIT_REPLACEMENT',
  EntryPointTenantMessageProperty = 'ENTRY_POINT_TENANT_MESSAGE_PROPERTY',
  EntryPointTenantOfferProperty = 'ENTRY_POINT_TENANT_OFFER_PROPERTY',
  EntryPointTenantTeamUp = 'ENTRY_POINT_TENANT_TEAM_UP',
  EntryPointTenantViewProperty = 'ENTRY_POINT_TENANT_VIEW_PROPERTY',
  EntryPointToolCertificateRepository = 'ENTRY_POINT_TOOL_CERTIFICATE_REPOSITORY',
  EntryPointToolExpenseTracker = 'ENTRY_POINT_TOOL_EXPENSE_TRACKER',
  EntryPointToolMarketValuation = 'ENTRY_POINT_TOOL_MARKET_VALUATION',
  EntryPointUnknown = 'ENTRY_POINT_UNKNOWN',
  EntryPointWebinar = 'ENTRY_POINT_WEBINAR',
  EntryPointZoom = 'ENTRY_POINT_ZOOM'
}

export type Account_Api_GetOwnershipVerificationResponseOutput = {
  readonly __typename: 'account_api_GetOwnershipVerificationResponseOutput';
  readonly ownerships?: Maybe<ReadonlyArray<Account_Api_OwnershipVerificationOutput>>;
};

export type Account_Api_GetResponseOutput = {
  readonly __typename: 'account_api_GetResponseOutput';
  readonly account: Account_Api_AccountEnrichedOutput;
};

export type Account_Api_GetVerificationResponseOutput = {
  readonly __typename: 'account_api_GetVerificationResponseOutput';
  readonly is_verified: Scalars['Boolean'];
  readonly sessions?: Maybe<ReadonlyArray<Account_Api_VeriffSessionOutput>>;
};

export type Account_Api_GpsCoordinatesInput = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
};

export type Account_Api_GpsCoordinatesOutput = {
  readonly __typename: 'account_api_GpsCoordinatesOutput';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Account_Api_ListWistiaVideosResponseOutput = {
  readonly __typename: 'account_api_ListWistiaVideosResponseOutput';
  readonly wistia_videos?: Maybe<ReadonlyArray<Account_Api_WistiaVideoOutput>>;
};

export type Account_Api_LogInput = {
  readonly action?: InputMaybe<Scalars['String']>;
  readonly dt?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Account_Api_LogOutput = {
  readonly __typename: 'account_api_LogOutput';
  readonly action: Scalars['String'];
  readonly dt: Scalars['String'];
  readonly user: Scalars['String'];
};

export type Account_Api_OwnershipIsVerifiedResponseOutput = {
  readonly __typename: 'account_api_OwnershipIsVerifiedResponseOutput';
  readonly status: Account_Api_OwnershipStatusEnum;
};

export enum Account_Api_OwnershipStatusEnum {
  OwnershipApproved = 'OWNERSHIP_APPROVED',
  OwnershipDeclined = 'OWNERSHIP_DECLINED',
  OwnershipInitial = 'OWNERSHIP_INITIAL',
  OwnershipPending = 'OWNERSHIP_PENDING'
}

export type Account_Api_OwnershipVerificationOutput = {
  readonly __typename: 'account_api_OwnershipVerificationOutput';
  readonly account_id: Scalars['String'];
  readonly company_name: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly document_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly first_name: Scalars['String'];
  readonly is_company: Scalars['Boolean'];
  readonly is_purchased: Scalars['Boolean'];
  readonly last_name: Scalars['String'];
  readonly notes: Scalars['String'];
  readonly ownership_id: Scalars['String'];
  readonly status: Account_Api_OwnershipStatusEnum;
  readonly updated_at: Scalars['String'];
  readonly verified_by_id: Scalars['String'];
};

export type Account_Api_PhoneInput = {
  readonly number?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Account_Api_AccountPhoneTypeEnum>;
};

export type Account_Api_PhoneOutput = {
  readonly __typename: 'account_api_PhoneOutput';
  readonly number: Scalars['String'];
  readonly type: Account_Api_AccountPhoneTypeEnum;
};

export type Account_Api_RestoreAccountResponseOutput = {
  readonly __typename: 'account_api_RestoreAccountResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Account_Api_ServiceDeleteResponseOutput = {
  readonly __typename: 'account_api_ServiceDeleteResponseOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly success: Scalars['Boolean'];
};

export type Account_Api_ServiceListResponseOutput = {
  readonly __typename: 'account_api_ServiceListResponseOutput';
  readonly accounts?: Maybe<ReadonlyArray<Account_Api_AccountEnrichedOutput>>;
  readonly errors?: Maybe<ReadonlyArray<Account_Api_AccountErrorEnum>>;
  readonly success: Scalars['Boolean'];
  readonly total: Scalars['Int'];
};

export type Account_Api_UpdateAdminVerificationSessionResponseOutput = {
  readonly __typename: 'account_api_UpdateAdminVerificationSessionResponseOutput';
  readonly session: Account_Api_VeriffSessionOutput;
};

export type Account_Api_UpdateOwnershipVerificationResponseOutput = {
  readonly __typename: 'account_api_UpdateOwnershipVerificationResponseOutput';
  readonly ownership_id: Scalars['String'];
};

export type Account_Api_UpdateResponseOutput = {
  readonly __typename: 'account_api_UpdateResponseOutput';
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly success: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verification: Account_Api_AccountVerificationOutput;
};

export type Account_Api_UpdateVeriffStatusResponseOutput = {
  readonly __typename: 'account_api_UpdateVeriffStatusResponseOutput';
  readonly id: Scalars['String'];
  readonly status: Scalars['String'];
};

export type Account_Api_VeriffSessionOutput = {
  readonly __typename: 'account_api_VeriffSessionOutput';
  readonly account_id: Scalars['String'];
  readonly admin_id: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly decision_code: Scalars['Int'];
  readonly documents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly first_name: Scalars['String'];
  readonly is_admin: Scalars['Boolean'];
  readonly last_name: Scalars['String'];
  readonly notes: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly reason_code: Scalars['Int'];
  readonly session_id: Scalars['String'];
  readonly status: Account_Api_VeriffStatusEnum;
  readonly updated_at: Scalars['String'];
  readonly url: Scalars['String'];
};

export enum Account_Api_VeriffStatusEnum {
  Abandoned = 'ABANDONED',
  Approved = 'APPROVED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  ResubmissionRequested = 'RESUBMISSION_REQUESTED',
  Review = 'REVIEW',
  Undefined = 'UNDEFINED',
  Unknown = 'UNKNOWN'
}

export enum Account_Api_WistiaProjectTypeEnum {
  UnknownWistiaProjectType = 'UNKNOWN_WISTIA_PROJECT_TYPE',
  WistiaProjectTypeHowTo = 'WISTIA_PROJECT_TYPE_HOW_TO',
  WistiaProjectTypeWebinars = 'WISTIA_PROJECT_TYPE_WEBINARS'
}

export type Account_Api_WistiaVideoOutput = {
  readonly __typename: 'account_api_WistiaVideoOutput';
  readonly created: Scalars['String'];
  readonly description: Scalars['String'];
  readonly duration: Scalars['Float'];
  readonly hashed_id: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly project: Account_Api_WistiaVideoProjectOutput;
  readonly thumbnail: Account_Api_WistiaVideoThumbnailOutput;
  readonly type: Scalars['String'];
  readonly updated: Scalars['String'];
};

export type Account_Api_WistiaVideoProjectOutput = {
  readonly __typename: 'account_api_WistiaVideoProjectOutput';
  readonly hashed_id: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly type: Account_Api_WistiaProjectTypeEnum;
};

export type Account_Api_WistiaVideoThumbnailOutput = {
  readonly __typename: 'account_api_WistiaVideoThumbnailOutput';
  readonly height: Scalars['Int'];
  readonly url: Scalars['String'];
  readonly width: Scalars['Int'];
};

export type Account_Api_WistiaVideoViewedResponseOutput = {
  readonly __typename: 'account_api_WistiaVideoViewedResponseOutput';
  readonly account: Account_Api_AccountEnrichedOutput;
};

export type Address_Api_AddressOutput = {
  readonly __typename: 'address_api_AddressOutput';
  readonly address: Scalars['String'];
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly address3: Scalars['String'];
  readonly address4: Scalars['String'];
  readonly administrative_county: Scalars['String'];
  readonly building: Scalars['String'];
  readonly city: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county: Scalars['String'];
  readonly district: Scalars['String'];
  readonly flat: Scalars['String'];
  readonly gps: Address_Api_LocationOutput;
  readonly post_town: Scalars['String'];
  readonly postal_county: Scalars['String'];
  readonly postcode: Scalars['String'];
  readonly street: Scalars['String'];
  readonly traditional_county: Scalars['String'];
};

export type Address_Api_AddressesResponseOutput = {
  readonly __typename: 'address_api_AddressesResponseOutput';
  readonly addresses?: Maybe<ReadonlyArray<Address_Api_AddressOutput>>;
  readonly placeholder: Scalars['String'];
};

export type Address_Api_AutocompleteAddressResponseOutput = {
  readonly __typename: 'address_api_AutocompleteAddressResponseOutput';
  readonly suggestions?: Maybe<ReadonlyArray<Address_Api_AutocompleteSuggestionOutput>>;
};

export type Address_Api_AutocompleteSuggestionOutput = {
  readonly __typename: 'address_api_AutocompleteSuggestionOutput';
  readonly suggestion: Scalars['String'];
  readonly udprn_url: Scalars['String'];
};

export type Address_Api_LocationOutput = {
  readonly __typename: 'address_api_LocationOutput';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Address_Api_UdprnResponseOutput = {
  readonly __typename: 'address_api_UdprnResponseOutput';
  readonly address: Address_Api_AddressOutput;
};

export enum Auth_Api_AuthErrorsEnum {
  AuthErrorAccountDoesNotExist = 'AUTH_ERROR_ACCOUNT_DOES_NOT_EXIST',
  AuthErrorAccountIsBlocked = 'AUTH_ERROR_ACCOUNT_IS_BLOCKED',
  AuthErrorAccountIsLinkedWithGoogle = 'AUTH_ERROR_ACCOUNT_IS_LINKED_WITH_GOOGLE',
  AuthErrorCaptchaInvalid = 'AUTH_ERROR_CAPTCHA_INVALID',
  AuthErrorCaptchaRequired = 'AUTH_ERROR_CAPTCHA_REQUIRED',
  AuthErrorEmailInvalid = 'AUTH_ERROR_EMAIL_INVALID',
  AuthErrorEmailOtpExpired = 'AUTH_ERROR_EMAIL_OTP_EXPIRED',
  AuthErrorEmailOtpInvalid = 'AUTH_ERROR_EMAIL_OTP_INVALID',
  AuthErrorEmailOtpRequired = 'AUTH_ERROR_EMAIL_OTP_REQUIRED',
  AuthErrorEmailTaken = 'AUTH_ERROR_EMAIL_TAKEN',
  AuthErrorInvalidPassword = 'AUTH_ERROR_INVALID_PASSWORD',
  AuthErrorLoginRequired = 'AUTH_ERROR_LOGIN_REQUIRED',
  AuthErrorPasswordRequired = 'AUTH_ERROR_PASSWORD_REQUIRED',
  AuthErrorPasswordTooLong = 'AUTH_ERROR_PASSWORD_TOO_LONG',
  AuthErrorPasswordTooShort = 'AUTH_ERROR_PASSWORD_TOO_SHORT',
  AuthErrorPasswordTooSimple = 'AUTH_ERROR_PASSWORD_TOO_SIMPLE',
  AuthErrorPhoneExists = 'AUTH_ERROR_PHONE_EXISTS',
  AuthErrorPhoneOtpExpired = 'AUTH_ERROR_PHONE_OTP_EXPIRED',
  AuthErrorPhoneOtpInvalid = 'AUTH_ERROR_PHONE_OTP_INVALID',
  AuthErrorPhoneOtpRequired = 'AUTH_ERROR_PHONE_OTP_REQUIRED',
  AuthErrorPhoneTaken = 'AUTH_ERROR_PHONE_TAKEN',
  AuthErrorPinInvalid = 'AUTH_ERROR_PIN_INVALID',
  AuthErrorTosReviewInvalid = 'AUTH_ERROR_TOS_REVIEW_INVALID',
  AuthErrorTosReviewRequired = 'AUTH_ERROR_TOS_REVIEW_REQUIRED',
  AuthErrorVerificationInvalid = 'AUTH_ERROR_VERIFICATION_INVALID',
  AuthErrorVerificationRequired = 'AUTH_ERROR_VERIFICATION_REQUIRED',
  UnknownAuthError = 'UNKNOWN_AUTH_ERROR'
}

export type Auth_Api_BlockUserResponseOutput = {
  readonly __typename: 'auth_api_BlockUserResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_CreateResponseOutput = {
  readonly __typename: 'auth_api_CreateResponseOutput';
  readonly access_token: Scalars['String'];
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verification: Account_Api_AccountVerificationOutput;
};

export type Auth_Api_ForgotPasswordResponseOutput = {
  readonly __typename: 'auth_api_ForgotPasswordResponseOutput';
  readonly access_token: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_LoginResponseEnrichedOutput = {
  readonly __typename: 'auth_api_LoginResponseEnrichedOutput';
  readonly access_token: Scalars['String'];
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly auth_attempts: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_LogoutResponseOutput = {
  readonly __typename: 'auth_api_LogoutResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_OauthRequestInput = {
  readonly captcha?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly email_otp?: InputMaybe<Scalars['String']>;
  readonly oauth_id?: InputMaybe<Scalars['String']>;
  readonly oauth_type?: InputMaybe<Scalars['String']>;
  readonly phone_otp?: InputMaybe<Scalars['String']>;
  readonly remote?: InputMaybe<Scalars['String']>;
  readonly tos_accepted?: InputMaybe<Scalars['Int']>;
};

export type Auth_Api_OauthResponseEnrichedOutput = {
  readonly __typename: 'auth_api_OauthResponseEnrichedOutput';
  readonly access_token: Scalars['String'];
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly auth_attempts: Scalars['Int'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_RefreshResponseOutput = {
  readonly __typename: 'auth_api_RefreshResponseOutput';
  readonly access_token: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_ResetAllAuthAttemptsResponseOutput = {
  readonly __typename: 'auth_api_ResetAllAuthAttemptsResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_SignupRequestInput = {
  readonly account?: InputMaybe<Account_Api_AccountInput>;
  readonly admin_site?: InputMaybe<Scalars['Boolean']>;
  readonly email_otp?: InputMaybe<Scalars['String']>;
  readonly oauth_id?: InputMaybe<Scalars['String']>;
  readonly oauth_type?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly phone_otp?: InputMaybe<Scalars['String']>;
  readonly pin?: InputMaybe<Scalars['String']>;
  readonly remote?: InputMaybe<Scalars['String']>;
  readonly tos_accepted?: InputMaybe<Scalars['Int']>;
  readonly transient_session?: InputMaybe<Scalars['String']>;
};

export type Auth_Api_SignupResponseEnrichedOutput = {
  readonly __typename: 'auth_api_SignupResponseEnrichedOutput';
  readonly access_token: Scalars['String'];
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verification: Account_Api_AccountVerificationOutput;
};

export type Auth_Api_UpdateResponseOutput = {
  readonly __typename: 'auth_api_UpdateResponseOutput';
  readonly access_token: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verification: Account_Api_AccountVerificationOutput;
};

export type Auth_Api_VerifyEmailResponseOutput = {
  readonly __typename: 'auth_api_VerifyEmailResponseOutput';
  readonly access_token: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Auth_Api_VerifyPhoneResponseOutput = {
  readonly __typename: 'auth_api_VerifyPhoneResponseOutput';
  readonly access_token: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Auth_Api_AuthErrorsEnum>>;
  readonly refresh_token: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Cardpayment_Api_CancelReceiptResponseOutput = {
  readonly __typename: 'cardpayment_api_CancelReceiptResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Cardpayment_Api_CheckReceiptResponseOutput = {
  readonly __typename: 'cardpayment_api_CheckReceiptResponseOutput';
  readonly receipt: Cardpayment_Api_ReceiptOutput;
};

export type Cardpayment_Api_CreatePaymentLinkResponseOutput = {
  readonly __typename: 'cardpayment_api_CreatePaymentLinkResponseOutput';
  readonly id: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Cardpayment_Api_FormReceiptResponseOutput = {
  readonly __typename: 'cardpayment_api_FormReceiptResponseOutput';
  readonly client_secret: Scalars['String'];
  readonly id: Scalars['String'];
};

export type Cardpayment_Api_GetMandateResponseOutput = {
  readonly __typename: 'cardpayment_api_GetMandateResponseOutput';
  readonly mandate: Cardpayment_Api_MandateOutput;
};

export type Cardpayment_Api_GetPropertyResponseOutput = {
  readonly __typename: 'cardpayment_api_GetPropertyResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Cardpayment_Api_ReceiptOutput>>;
};

export type Cardpayment_Api_GetUserResponseOutput = {
  readonly __typename: 'cardpayment_api_GetUserResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Cardpayment_Api_ReceiptOutput>>;
};

export type Cardpayment_Api_InsuranceDataInput = {
  readonly corresp_address?: InputMaybe<Scalars['String']>;
  readonly start_insurance?: InputMaybe<Scalars['String']>;
};

export type Cardpayment_Api_MandateOutput = {
  readonly __typename: 'cardpayment_api_MandateOutput';
  readonly created_at: Scalars['String'];
  readonly email: Scalars['String'];
  readonly id: Scalars['String'];
  readonly mandate_id: Scalars['String'];
  readonly offers?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly status: Cardpayment_Api_MandateStatusEnum;
  readonly updated_at: Scalars['String'];
  readonly user: Scalars['String'];
  readonly valid: Scalars['Boolean'];
};

export enum Cardpayment_Api_MandateStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING'
}

export type Cardpayment_Api_MarkReceiptPaidResponseOutput = {
  readonly __typename: 'cardpayment_api_MarkReceiptPaidResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Cardpayment_Api_PaymentLinkItemInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly productID?: InputMaybe<Scalars['String']>;
  readonly quantity?: InputMaybe<Scalars['Int']>;
};

export enum Cardpayment_Api_PaymentStatusEnum {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Succeeded = 'SUCCEEDED',
  UnknownPaymentStatus = 'UNKNOWN_PAYMENT_STATUS',
  Unpaid = 'UNPAID'
}

export enum Cardpayment_Api_PaymentTypeEnum {
  Account = 'ACCOUNT',
  BankAccount = 'BANK_ACCOUNT',
  Bitcoin = 'BITCOIN',
  Card = 'CARD',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitWithMandate = 'DIRECT_DEBIT_WITH_MANDATE',
  Source = 'SOURCE',
  Subscription = 'SUBSCRIPTION',
  UnknownPaymentType = 'UNKNOWN_PAYMENT_TYPE'
}

export type Cardpayment_Api_ReceiptInput = {
  readonly client_secret?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly invoice_number?: InputMaybe<Scalars['String']>;
  readonly items?: InputMaybe<ReadonlyArray<Extras_Api_ServiceSnapshotInput>>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Cardpayment_Api_PaymentStatusEnum>;
  readonly type?: InputMaybe<Cardpayment_Api_PaymentTypeEnum>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
  readonly user_email?: InputMaybe<Scalars['String']>;
  readonly user_id?: InputMaybe<Scalars['String']>;
  readonly user_name?: InputMaybe<Scalars['String']>;
};

export type Cardpayment_Api_ReceiptItemOutput = {
  readonly __typename: 'cardpayment_api_ReceiptItemOutput';
  readonly admin: Scalars['String'];
  readonly charge_id: Scalars['String'];
  readonly charge_status: Cardpayment_Api_PaymentStatusEnum;
  readonly charge_type: Cardpayment_Api_PaymentTypeEnum;
  readonly client_secret: Scalars['String'];
  readonly code?: Maybe<Scalars['String']>;
  readonly confirmed: Scalars['Boolean'];
  readonly confirmed_at: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly invoice_number: Scalars['String'];
  readonly learn_more?: Maybe<Scalars['String']>;
  readonly obligatory?: Maybe<Scalars['Boolean']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly price_calculated?: Maybe<Scalars['Boolean']>;
  readonly price_need_calculate?: Maybe<Scalars['Boolean']>;
  readonly primary?: Maybe<Scalars['Boolean']>;
  readonly property: Scalars['String'];
  readonly role?: Maybe<Extras_Api_ServiceRoleEnum>;
  readonly service_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Extras_Api_ServiceStatusEnum>;
  readonly type?: Maybe<Extras_Api_ServiceTypeEnum>;
  readonly updated_at: Scalars['String'];
  readonly user_email: Scalars['String'];
  readonly user_id: Scalars['String'];
  readonly user_name: Scalars['String'];
};

export type Cardpayment_Api_ReceiptOutput = {
  readonly __typename: 'cardpayment_api_ReceiptOutput';
  readonly client_secret: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly invoice_number: Scalars['String'];
  readonly items?: Maybe<ReadonlyArray<Extras_Api_ServiceSnapshotOutput>>;
  readonly property: Scalars['String'];
  readonly status: Cardpayment_Api_PaymentStatusEnum;
  readonly type: Cardpayment_Api_PaymentTypeEnum;
  readonly updated_at: Scalars['String'];
  readonly user_email: Scalars['String'];
  readonly user_id: Scalars['String'];
  readonly user_name: Scalars['String'];
};

export type Cardpayment_Api_RentGuaranteeInsuranceDataInput = {
  readonly corresp_address?: InputMaybe<Scalars['String']>;
  readonly start_insurance?: InputMaybe<Scalars['String']>;
};

export type Closecom_Api_LeadContactOutput = {
  readonly __typename: 'closecom_api_LeadContactOutput';
  readonly contact_id: Scalars['String'];
  readonly created: Scalars['String'];
  readonly email: Scalars['String'];
  readonly entry_point: Scalars['String'];
  readonly id: Scalars['String'];
  readonly lead_id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phone: Scalars['String'];
  readonly signup: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly user_id: Scalars['String'];
};

export type Closecom_Api_LeadGetResponseOutput = {
  readonly __typename: 'closecom_api_LeadGetResponseOutput';
  readonly lead: Closecom_Api_LeadOutput;
};

export type Closecom_Api_LeadOutput = {
  readonly __typename: 'closecom_api_LeadOutput';
  readonly contacts?: Maybe<ReadonlyArray<Closecom_Api_LeadContactOutput>>;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type Comments_Api_CommentCreateResponseOutput = {
  readonly __typename: 'comments_api_CommentCreateResponseOutput';
  readonly comment: Comments_Api_CommentOutput;
};

export type Comments_Api_CommentFilterResponseOutput = {
  readonly __typename: 'comments_api_CommentFilterResponseOutput';
  readonly comments?: Maybe<ReadonlyArray<Comments_Api_CommentOutput>>;
  readonly total: Scalars['Int'];
};

export type Comments_Api_CommentOutput = {
  readonly __typename: 'comments_api_CommentOutput';
  readonly correlation_id: Scalars['String'];
  readonly correlation_type: Comments_Api_CorrelationTypeEnum;
  readonly created: Scalars['String'];
  readonly id: Scalars['String'];
  readonly nested: ReadonlyArray<Maybe<Comments_Api_CommentOutput>>;
  readonly parent_id: Scalars['String'];
  readonly replies: Scalars['Int'];
  readonly session: Scalars['String'];
  readonly status: Comments_Api_StatusEnum;
  readonly text: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly user?: Maybe<Account_Api_AccountEnrichedOutput>;
};


export type Comments_Api_CommentOutputNestedArgs = {
  filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};

export type Comments_Api_CommentUpdateResponseOutput = {
  readonly __typename: 'comments_api_CommentUpdateResponseOutput';
  readonly comment: Comments_Api_CommentOutput;
};

export enum Comments_Api_CorrelationTypeEnum {
  CorrelationTypeBlogPost = 'CORRELATION_TYPE_BLOG_POST',
  CorrelationTypeUnknown = 'CORRELATION_TYPE_UNKNOWN'
}

export type Comments_Api_ServiceCommentUpdateResponseOutput = {
  readonly __typename: 'comments_api_ServiceCommentUpdateResponseOutput';
  readonly comment: Comments_Api_CommentOutput;
};

export enum Comments_Api_StatusEnum {
  StatusActive = 'STATUS_ACTIVE',
  StatusDisabled = 'STATUS_DISABLED',
  StatusUnknown = 'STATUS_UNKNOWN'
}

export enum Common_Api_Generic_Query_QueryFilterOperatorEnum {
  All = 'ALL',
  Any = 'ANY',
  Contains = 'CONTAINS',
  ContainsAny = 'CONTAINS_ANY',
  /** @deprecated deprecated */
  Dash = 'DASH',
  /** @deprecated deprecated */
  DashAny = 'DASH_ANY',
  /** @deprecated deprecated */
  DashContains = 'DASH_CONTAINS',
  Equals = 'EQUALS',
  Freeform = 'FREEFORM',
  Less = 'LESS',
  More = 'MORE',
  Oneof = 'ONEOF',
  Range = 'RANGE',
  Startwith = 'STARTWITH',
  UnknownOperator = 'UNKNOWN_OPERATOR'
}

export enum Common_Api_Generic_Query_QueryFilterTransformationPreprocessorEnum {
  RegexpReplace = 'REGEXP_REPLACE',
  Replace = 'REPLACE',
  UnknownTransformationPreprocessor = 'UNKNOWN_TRANSFORMATION_PREPROCESSOR'
}

export type Common_Api_Generic_Query_QueryParamsFilterInput = {
  readonly fields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly not?: InputMaybe<Scalars['Boolean']>;
  readonly operator?: InputMaybe<Common_Api_Generic_Query_QueryFilterOperatorEnum>;
  readonly transformation?: InputMaybe<Common_Api_Generic_Query_QueryParamsTransformationInput>;
  readonly value?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Common_Api_Generic_Query_QueryParamsInput = {
  readonly filter?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsFilterInput>>;
  readonly pagination?: InputMaybe<Common_Api_Generic_Query_QueryParamsPaginationInput>;
  readonly partition?: InputMaybe<Common_Api_Generic_Query_QueryParamsPartitionInput>;
  readonly sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};

export type Common_Api_Generic_Query_QueryParamsPaginationInput = {
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly per_page?: InputMaybe<Scalars['Int']>;
};

export type Common_Api_Generic_Query_QueryParamsPartitionInput = {
  readonly key?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly limit?: InputMaybe<Scalars['Int']>;
  readonly sort?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
};

export type Common_Api_Generic_Query_QueryParamsSortInput = {
  readonly field?: InputMaybe<Scalars['String']>;
  readonly order?: InputMaybe<Scalars['Int']>;
};

export type Common_Api_Generic_Query_QueryParamsTransformationInput = {
  readonly param?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly preprocessor?: InputMaybe<Common_Api_Generic_Query_QueryFilterTransformationPreprocessorEnum>;
};

export type Common_Graphql_Websocket_TransientUserOutput = {
  readonly __typename: 'common_graphql_websocket_TransientUserOutput';
  readonly session: Scalars['String'];
};

export enum Contract_Api_ContractFormatEnum {
  Html = 'HTML',
  Pdf = 'PDF',
  UnknownContractFormat = 'UNKNOWN_CONTRACT_FORMAT'
}

export type Contract_Api_ContractInfoOutput = {
  readonly __typename: 'contract_api_ContractInfoOutput';
  readonly deleted: Scalars['Boolean'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly last_revision: Scalars['String'];
  readonly name: Scalars['String'];
  readonly parent_id: Scalars['String'];
  readonly time_created: Scalars['Int'];
  readonly time_updated: Scalars['Int'];
};

export type Contract_Api_ContractRevisionOutput = {
  readonly __typename: 'contract_api_ContractRevisionOutput';
  readonly revision_id: Scalars['String'];
  readonly text: Scalars['String'];
  readonly time_committed: Scalars['Int'];
};

export enum Contract_Api_ContractStatusEnum {
  Success = 'SUCCESS',
  UnknownContractStatus = 'UNKNOWN_CONTRACT_STATUS',
  UnknownError = 'UNKNOWN_ERROR'
}

export type Contract_Api_CreateResponseOutput = {
  readonly __typename: 'contract_api_CreateResponseOutput';
  readonly info: Contract_Api_ContractInfoOutput;
  readonly status: Contract_Api_ContractStatusEnum;
};

export type Contract_Api_DeleteResponseOutput = {
  readonly __typename: 'contract_api_DeleteResponseOutput';
  readonly info: Contract_Api_ContractInfoOutput;
  readonly status: Contract_Api_ContractStatusEnum;
};

export type Contract_Api_EditResponseOutput = {
  readonly __typename: 'contract_api_EditResponseOutput';
  readonly info: Contract_Api_ContractInfoOutput;
  readonly status: Contract_Api_ContractStatusEnum;
};

export type Contract_Api_HistoryResponseOutput = {
  readonly __typename: 'contract_api_HistoryResponseOutput';
  readonly info: Contract_Api_ContractInfoOutput;
  readonly revisions?: Maybe<ReadonlyArray<Contract_Api_ContractRevisionOutput>>;
  readonly status: Contract_Api_ContractStatusEnum;
};

export type Contract_Api_ListResponseOutput = {
  readonly __typename: 'contract_api_ListResponseOutput';
  readonly children?: Maybe<ReadonlyArray<Contract_Api_ContractInfoOutput>>;
  readonly info: Contract_Api_ContractInfoOutput;
  readonly status: Contract_Api_ContractStatusEnum;
};

export type Contract_Api_UpdateResponseOutput = {
  readonly __typename: 'contract_api_UpdateResponseOutput';
  readonly info: Contract_Api_ContractInfoOutput;
  readonly status: Contract_Api_ContractStatusEnum;
};

export type Document_Api_CleanThumbsCacheResponseOutput = {
  readonly __typename: 'document_api_CleanThumbsCacheResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Document_Api_CleanWebpCacheResponseOutput = {
  readonly __typename: 'document_api_CleanWebpCacheResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Document_Api_CreateResponseOutput = {
  readonly __typename: 'document_api_CreateResponseOutput';
  readonly id: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Document_Api_DeleteResponseOutput = {
  readonly __typename: 'document_api_DeleteResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Document_Api_DocumentInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expiration?: InputMaybe<Scalars['String']>;
  readonly file?: InputMaybe<Document_Api_FileInput>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly issued_at?: InputMaybe<Scalars['String']>;
  readonly product_id?: InputMaybe<Scalars['String']>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly removed?: InputMaybe<Scalars['Boolean']>;
  readonly state?: InputMaybe<Document_Api_DocumentStateEnum>;
  /** Deprecated. Do not use. */
  readonly type?: InputMaybe<Document_Api_DocumentTypeEnum>;
  readonly type_code?: InputMaybe<Scalars['String']>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Document_Api_DocumentOutput = {
  readonly __typename: 'document_api_DocumentOutput';
  readonly amount: Scalars['Float'];
  readonly attributes: ReadonlyArray<_String__StringOutputMap>;
  readonly code: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly description: Scalars['String'];
  readonly expiration: Scalars['String'];
  readonly file: Document_Api_FileOutput;
  readonly id: Scalars['String'];
  readonly issued_at: Scalars['String'];
  readonly product?: Maybe<Invoice_Api_ProductOutput>;
  readonly product_id: Scalars['String'];
  /** @deprecated use property_id */
  readonly property: Scalars['String'];
  readonly property_data?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly property_id: Scalars['String'];
  readonly removed: Scalars['Boolean'];
  readonly state: Document_Api_DocumentStateEnum;
  readonly type: Document_Api_DocumentTypeEnum;
  readonly type_code: Scalars['String'];
  readonly updated_at: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Document_Api_DocumentStateEnum {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Unapproved = 'UNAPPROVED',
  UnknownDocumentState = 'UNKNOWN_DOCUMENT_STATE'
}

export type Document_Api_DocumentTypeCreateResponseOutput = {
  readonly __typename: 'document_api_DocumentTypeCreateResponseOutput';
  readonly document_type_desc: Document_Api_DocumentTypeDescOutput;
};

export type Document_Api_DocumentTypeDescOutput = {
  readonly __typename: 'document_api_DocumentTypeDescOutput';
  readonly code: Scalars['String'];
  readonly group: Document_Api_DocumentTypeGroupEnum;
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly services?: Maybe<ReadonlyArray<Extras_Api_ServiceOutput>>;
};

export enum Document_Api_DocumentTypeEnum {
  Bill = 'BILL',
  Certificate = 'CERTIFICATE',
  CheckInReport = 'CHECK_IN_REPORT',
  CheckOutReport = 'CHECK_OUT_REPORT',
  ContentsInsurance = 'CONTENTS_INSURANCE',
  Contract = 'CONTRACT',
  DepositRegistrationCertificate = 'DEPOSIT_REGISTRATION_CERTIFICATE',
  HomeEmergencyInsurance = 'HOME_EMERGENCY_INSURANCE',
  Identity = 'IDENTITY',
  Inventory = 'INVENTORY',
  LifeInsurance = 'LIFE_INSURANCE',
  Maintenance = 'MAINTENANCE',
  Other = 'OTHER',
  Photo = 'PHOTO',
  PhotoRaw = 'PHOTO_RAW',
  Plan = 'PLAN',
  PlanRaw = 'PLAN_RAW',
  ReferencingReport = 'REFERENCING_REPORT',
  RentGuaranteeInsurance = 'RENT_GUARANTEE_INSURANCE',
  UnknownType = 'UNKNOWN_TYPE'
}

export type Document_Api_DocumentTypeFilterResponseOutput = {
  readonly __typename: 'document_api_DocumentTypeFilterResponseOutput';
  readonly document_type_descs?: Maybe<ReadonlyArray<Document_Api_DocumentTypeDescOutput>>;
  readonly total: Scalars['Int'];
};

export type Document_Api_DocumentTypeGetResponseOutput = {
  readonly __typename: 'document_api_DocumentTypeGetResponseOutput';
  readonly document_type_desc: Document_Api_DocumentTypeDescOutput;
};

export enum Document_Api_DocumentTypeGroupEnum {
  DocumentTypeGroupComplianceCertificates = 'DOCUMENT_TYPE_GROUP_COMPLIANCE_CERTIFICATES',
  DocumentTypeGroupInsurance = 'DOCUMENT_TYPE_GROUP_INSURANCE',
  DocumentTypeGroupOther = 'DOCUMENT_TYPE_GROUP_OTHER',
  DocumentTypeGroupRentalDocuments = 'DOCUMENT_TYPE_GROUP_RENTAL_DOCUMENTS',
  DocumentTypeGroupUnknown = 'DOCUMENT_TYPE_GROUP_UNKNOWN'
}

export type Document_Api_DocumentTypeUpdateResponseOutput = {
  readonly __typename: 'document_api_DocumentTypeUpdateResponseOutput';
  readonly document_type_desc: Document_Api_DocumentTypeDescOutput;
};

export type Document_Api_FileAttributeFilterResponseOutput = {
  readonly __typename: 'document_api_FileAttributeFilterResponseOutput';
  readonly data?: Maybe<ReadonlyArray<Document_Api_FileAttributeOutput>>;
  readonly total: Scalars['Int'];
};

export type Document_Api_FileAttributeOutput = {
  readonly __typename: 'document_api_FileAttributeOutput';
  readonly data: ReadonlyArray<_String__StringOutputMap>;
  readonly file: Document_Api_FileOutput;
  readonly remote: Scalars['String'];
  readonly user: Scalars['String'];
};

export type Document_Api_FileInput = {
  readonly created_at?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly deleted_on?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly mime?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly sha256?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
  readonly size?: InputMaybe<Scalars['Int']>;
};

export type Document_Api_FileOutput = {
  readonly __typename: 'document_api_FileOutput';
  readonly created_at: Scalars['String'];
  readonly deleted_on: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly mime: Scalars['String'];
  readonly name: Scalars['String'];
  readonly sha256?: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly size: Scalars['Int'];
};

export type Document_Api_FileSoftDeleteByDocumentResponseOutput = {
  readonly __typename: 'document_api_FileSoftDeleteByDocumentResponseOutput';
  readonly document: Document_Api_DocumentOutput;
  readonly success: Scalars['Boolean'];
};

export type Document_Api_GetResponseOutput = {
  readonly __typename: 'document_api_GetResponseOutput';
  readonly document: Document_Api_DocumentOutput;
  readonly total: Scalars['Int'];
};

export enum Document_Api_ImageSizeEnum {
  Original = 'ORIGINAL',
  Thumbnail = 'THUMBNAIL',
  Webp = 'WEBP'
}

export type Document_Api_ListResponseOutput = {
  readonly __typename: 'document_api_ListResponseOutput';
  readonly documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
};

export type Document_Api_ServiceUpdateResponseOutput = {
  readonly __typename: 'document_api_ServiceUpdateResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Document_Api_UpdateResponseOutput = {
  readonly __typename: 'document_api_UpdateResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Document_Api_UpdateStatusResponseOutput = {
  readonly __typename: 'document_api_UpdateStatusResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Document_Api_IsDocumentTypeSpec_DetailsOneofInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
};

export enum Expenses_Api_AdvanceStatusEnum {
  AdvanceStatusCancelled = 'ADVANCE_STATUS_CANCELLED',
  AdvanceStatusDenied = 'ADVANCE_STATUS_DENIED',
  AdvanceStatusGranted = 'ADVANCE_STATUS_GRANTED',
  AdvanceStatusRequested = 'ADVANCE_STATUS_REQUESTED',
  AdvanceStatusUnknown = 'ADVANCE_STATUS_UNKNOWN'
}

export type Expenses_Api_ConfigCreateResponseOutput = {
  readonly __typename: 'expenses_api_ConfigCreateResponseOutput';
  readonly config: Expenses_Api_ConfigOutput;
};

export type Expenses_Api_ConfigFilterListResponseOutput = {
  readonly __typename: 'expenses_api_ConfigFilterListResponseOutput';
  readonly configs?: Maybe<ReadonlyArray<Expenses_Api_ConfigOutput>>;
  readonly total: Scalars['Int'];
};

export type Expenses_Api_ConfigGetResponseOutput = {
  readonly __typename: 'expenses_api_ConfigGetResponseOutput';
  readonly config: Expenses_Api_ConfigOutput;
};

export type Expenses_Api_ConfigInput = {
  readonly type?: InputMaybe<Expenses_Api_IsConfig_TypeOneofInput>;
  readonly user_id?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_ConfigOfferInput = {
  readonly offer_id?: InputMaybe<Scalars['String']>;
  readonly role?: InputMaybe<Expenses_Api_ConfigOfferRoleEnum>;
};

export type Expenses_Api_ConfigOfferOutput = {
  readonly __typename: 'expenses_api_ConfigOfferOutput';
  readonly offer_id: Scalars['String'];
  readonly role: Expenses_Api_ConfigOfferRoleEnum;
};

export enum Expenses_Api_ConfigOfferRoleEnum {
  ConfigOfferRoleLandlord = 'CONFIG_OFFER_ROLE_LANDLORD',
  ConfigOfferRoleTenant = 'CONFIG_OFFER_ROLE_TENANT',
  ConfigOfferRoleUnknown = 'CONFIG_OFFER_ROLE_UNKNOWN'
}

export type Expenses_Api_ConfigOutput = {
  readonly __typename: 'expenses_api_ConfigOutput';
  readonly type: Expenses_Api_IsConfig_TypeOneofOutput;
  readonly user_id: Scalars['String'];
};

export type Expenses_Api_ConfigPropertyInput = {
  readonly monthly_allowable_expenses?: InputMaybe<Scalars['Float']>;
  readonly property_id?: InputMaybe<Scalars['String']>;
  readonly tax_year?: InputMaybe<Scalars['Date']>;
};

export type Expenses_Api_ConfigPropertyOutput = {
  readonly __typename: 'expenses_api_ConfigPropertyOutput';
  readonly monthly_allowable_expenses?: Maybe<Scalars['Float']>;
  readonly property_id: Scalars['String'];
  readonly tax_year: Scalars['Date'];
};

export type Expenses_Api_ConfigUpdateResponseOutput = {
  readonly __typename: 'expenses_api_ConfigUpdateResponseOutput';
  readonly config: Expenses_Api_ConfigOutput;
};

export type Expenses_Api_CorrelationInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Expenses_Api_CorrelationTypeEnum>;
};

export enum Expenses_Api_CorrelationTypeEnum {
  CorrelationTypeOffer = 'CORRELATION_TYPE_OFFER',
  CorrelationTypeProperty = 'CORRELATION_TYPE_PROPERTY',
  CorrelationTypeUnknown = 'CORRELATION_TYPE_UNKNOWN',
  CorrelationTypeUser = 'CORRELATION_TYPE_USER'
}

export type Expenses_Api_ExpenseDrpInput = {
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly payment_id?: InputMaybe<Scalars['String']>;
  readonly serial?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<Expenses_Api_PaymentStatusEnum>;
};

export type Expenses_Api_ExpenseDrpOutput = {
  readonly __typename: 'expenses_api_ExpenseDrpOutput';
  readonly invoice_id: Scalars['String'];
  readonly overdue_payment_notice_count?: Maybe<Scalars['Int']>;
  readonly payment_id: Scalars['String'];
  readonly serial: Scalars['Int'];
  readonly status: Expenses_Api_PaymentStatusEnum;
};

export type Expenses_Api_ExpenseMortgageInput = {
  readonly monthly_interest?: InputMaybe<Scalars['Float']>;
  /** Deprecated. Do not use. */
  readonly stub?: InputMaybe<Scalars['Boolean']>;
};

export type Expenses_Api_ExpenseMortgageOutput = {
  readonly __typename: 'expenses_api_ExpenseMortgageOutput';
  readonly monthly_interest: Scalars['Float'];
  readonly stub: Scalars['Boolean'];
};

export type Expenses_Api_ExpensePayoutInput = {
  readonly placeholder?: InputMaybe<Scalars['Boolean']>;
  readonly status?: InputMaybe<Expenses_Api_PayoutStatusEnum>;
};

export type Expenses_Api_ExpensePayoutOutput = {
  readonly __typename: 'expenses_api_ExpensePayoutOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly status: Expenses_Api_PayoutStatusEnum;
};

export type Expenses_Api_ExpenseRecordsOutput = {
  readonly __typename: 'expenses_api_ExpenseRecordsOutput';
  readonly records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
  readonly sum: Scalars['Float'];
  readonly type: Expenses_Api_ExpenseTypeEnum;
};

export type Expenses_Api_ExpenseRentInput = {
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly landlord_id?: InputMaybe<Scalars['String']>;
  readonly payment_id?: InputMaybe<Scalars['String']>;
  readonly payment_paid_at?: InputMaybe<Scalars['String']>;
  readonly payouts?: InputMaybe<ReadonlyArray<_String_Expenses_Api_ExpensePayoutInputMap>>;
  readonly serial?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<Expenses_Api_PaymentStatusEnum>;
};

export type Expenses_Api_ExpenseRentOutput = {
  readonly __typename: 'expenses_api_ExpenseRentOutput';
  readonly invoice_id: Scalars['String'];
  readonly landlord_id: Scalars['String'];
  readonly overdue_payment_notice_count?: Maybe<Scalars['Int']>;
  readonly payment_id: Scalars['String'];
  readonly payment_paid_at: Scalars['String'];
  readonly payouts: ReadonlyArray<_String_Expenses_Api_ExpensePayoutOutputMap>;
  readonly serial: Scalars['Int'];
  readonly status: Expenses_Api_PaymentStatusEnum;
};

export type Expenses_Api_ExpenseReportResponseOutput = {
  readonly __typename: 'expenses_api_ExpenseReportResponseOutput';
  readonly CSV: Scalars['String'];
};

export enum Expenses_Api_ExpenseTypeEnum {
  ExpenseTypeDrp = 'EXPENSE_TYPE_DRP',
  ExpenseTypeMaintenance = 'EXPENSE_TYPE_MAINTENANCE',
  ExpenseTypeMortgage = 'EXPENSE_TYPE_MORTGAGE',
  ExpenseTypeOther = 'EXPENSE_TYPE_OTHER',
  ExpenseTypeRent = 'EXPENSE_TYPE_RENT',
  ExpenseTypeTax = 'EXPENSE_TYPE_TAX',
  ExpenseTypeUnknown = 'EXPENSE_TYPE_UNKNOWN'
}

export type Expenses_Api_IncomeAdvanceInput = {
  readonly admin?: InputMaybe<Scalars['String']>;
  readonly advance_status?: InputMaybe<Expenses_Api_AdvanceStatusEnum>;
  readonly available?: InputMaybe<Scalars['Boolean']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly fee_percentage?: InputMaybe<Scalars['Float']>;
  readonly fee_percentage_advance?: InputMaybe<Scalars['Float']>;
  readonly fee_percentage_rent_plan?: InputMaybe<Scalars['Float']>;
  readonly fee_value?: InputMaybe<Scalars['Float']>;
  readonly fee_value_advance?: InputMaybe<Scalars['Float']>;
  readonly fee_value_rent_plan?: InputMaybe<Scalars['Float']>;
  readonly landlord_consent?: InputMaybe<Scalars['String']>;
  readonly payouts?: InputMaybe<ReadonlyArray<_String_Expenses_Api_IncomePayoutInputMap>>;
  readonly request_id?: InputMaybe<Scalars['String']>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_IncomeAdvanceOutput = {
  readonly __typename: 'expenses_api_IncomeAdvanceOutput';
  readonly admin: Scalars['String'];
  readonly advance_status: Expenses_Api_AdvanceStatusEnum;
  readonly available: Scalars['Boolean'];
  readonly created_at: Scalars['String'];
  readonly fee_percentage: Scalars['Float'];
  readonly fee_percentage_advance: Scalars['Float'];
  readonly fee_percentage_rent_plan: Scalars['Float'];
  readonly fee_value: Scalars['Float'];
  readonly fee_value_advance: Scalars['Float'];
  readonly fee_value_rent_plan: Scalars['Float'];
  readonly landlord_consent: Scalars['String'];
  readonly payouts: ReadonlyArray<_String_Expenses_Api_IncomePayoutOutputMap>;
  readonly request_id: Scalars['String'];
  readonly updated_at: Scalars['String'];
};

export type Expenses_Api_IncomePayoutInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly payout_id?: InputMaybe<Scalars['String']>;
  readonly payout_status?: InputMaybe<Expenses_Api_PayoutStatusEnum>;
};

export type Expenses_Api_IncomePayoutOutput = {
  readonly __typename: 'expenses_api_IncomePayoutOutput';
  readonly amount: Scalars['Float'];
  readonly invoice_id: Scalars['String'];
  readonly payout_id: Scalars['String'];
  readonly payout_status: Expenses_Api_PayoutStatusEnum;
};

export type Expenses_Api_IncomeRecordsOutput = {
  readonly __typename: 'expenses_api_IncomeRecordsOutput';
  readonly records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
  readonly sum: Scalars['Float'];
  readonly tax: Scalars['Float'];
  readonly type: Expenses_Api_IncomeTypeEnum;
};

export type Expenses_Api_IncomeRentInput = {
  readonly advance_available?: InputMaybe<Scalars['Float']>;
  readonly fee_percentage?: InputMaybe<Scalars['Float']>;
  readonly fee_percentage_rent_plan?: InputMaybe<Scalars['Float']>;
  readonly fee_value?: InputMaybe<Scalars['Float']>;
  readonly fee_value_rent_plan?: InputMaybe<Scalars['Float']>;
  readonly payment_paid_at?: InputMaybe<Scalars['String']>;
  readonly payment_status?: InputMaybe<Expenses_Api_PaymentStatusEnum>;
  readonly payouts?: InputMaybe<ReadonlyArray<_String_Expenses_Api_IncomePayoutInputMap>>;
  readonly serial?: InputMaybe<Scalars['Int']>;
};

export type Expenses_Api_IncomeRentOutput = {
  readonly __typename: 'expenses_api_IncomeRentOutput';
  readonly advance_available: Scalars['Float'];
  readonly fee_percentage: Scalars['Float'];
  readonly fee_percentage_rent_plan: Scalars['Float'];
  readonly fee_value: Scalars['Float'];
  readonly fee_value_rent_plan: Scalars['Float'];
  readonly overdue_payment_notice_count?: Maybe<Scalars['Int']>;
  readonly payment_paid_at: Scalars['String'];
  readonly payment_status: Expenses_Api_PaymentStatusEnum;
  readonly payouts: ReadonlyArray<_String_Expenses_Api_IncomePayoutOutputMap>;
  readonly serial: Scalars['Int'];
};

export enum Expenses_Api_IncomeTypeEnum {
  IncomeTypeAdvance = 'INCOME_TYPE_ADVANCE',
  IncomeTypeOther = 'INCOME_TYPE_OTHER',
  IncomeTypeRent = 'INCOME_TYPE_RENT',
  IncomeTypeSalary = 'INCOME_TYPE_SALARY',
  IncomeTypeUnknown = 'INCOME_TYPE_UNKNOWN'
}

export enum Expenses_Api_PaymentStatusEnum {
  PaymentStatusConfirm = 'PAYMENT_STATUS_CONFIRM',
  PaymentStatusFailed = 'PAYMENT_STATUS_FAILED',
  PaymentStatusPending = 'PAYMENT_STATUS_PENDING',
  PaymentStatusSuccess = 'PAYMENT_STATUS_SUCCESS',
  PaymentStatusUnknown = 'PAYMENT_STATUS_UNKNOWN'
}

export enum Expenses_Api_PayoutStatusEnum {
  PayoutStatusConfirmed = 'PAYOUT_STATUS_CONFIRMED',
  PayoutStatusPaid = 'PAYOUT_STATUS_PAID',
  PayoutStatusPending = 'PAYOUT_STATUS_PENDING',
  PayoutStatusUnknown = 'PAYOUT_STATUS_UNKNOWN'
}

export type Expenses_Api_RangeInput = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_RecordAdvanceCancelResponseOutput = {
  readonly __typename: 'expenses_api_RecordAdvanceCancelResponseOutput';
  readonly records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
};

export type Expenses_Api_RecordAdvanceRequestResponseOutput = {
  readonly __typename: 'expenses_api_RecordAdvanceRequestResponseOutput';
  readonly records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
};

export type Expenses_Api_RecordCreateResponseOutput = {
  readonly __typename: 'expenses_api_RecordCreateResponseOutput';
  readonly record: Expenses_Api_RecordOutput;
};

export type Expenses_Api_RecordFilterListResponseOutput = {
  readonly __typename: 'expenses_api_RecordFilterListResponseOutput';
  readonly records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
  readonly total: Scalars['Int'];
};

export type Expenses_Api_RecordGetResponseOutput = {
  readonly __typename: 'expenses_api_RecordGetResponseOutput';
  readonly record: Expenses_Api_RecordOutput;
};

export type Expenses_Api_RecordOutput = {
  readonly __typename: 'expenses_api_RecordOutput';
  readonly amount: Scalars['Float'];
  readonly config: Expenses_Api_ConfigOutput;
  readonly correlation_id: Scalars['String'];
  readonly correlation_type: Expenses_Api_CorrelationTypeEnum;
  readonly created: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly listing_id: Scalars['String'];
  readonly offer?: Maybe<Offer_Api_OfferEnrichedOutput>;
  readonly property_id: Scalars['String'];
  readonly schedule_id: Scalars['String'];
  readonly tax: Expenses_Api_TaxOutput;
  readonly time: Scalars['String'];
  readonly type: Expenses_Api_IsRecord_TypeOneofOutput;
  readonly updated: Scalars['String'];
  readonly user_id: Scalars['String'];
};

export type Expenses_Api_RecordRangeListResponseOutput = {
  readonly __typename: 'expenses_api_RecordRangeListResponseOutput';
  readonly expenses?: Maybe<ReadonlyArray<Expenses_Api_ExpenseRecordsOutput>>;
  readonly incomes?: Maybe<ReadonlyArray<Expenses_Api_IncomeRecordsOutput>>;
  readonly total: Scalars['Float'];
};

export enum Expenses_Api_RecordTypeEnum {
  RecordTypeExpense = 'RECORD_TYPE_EXPENSE',
  RecordTypeIncome = 'RECORD_TYPE_INCOME',
  RecordTypeUnknown = 'RECORD_TYPE_UNKNOWN'
}

export type Expenses_Api_RecordUpdateResponseOutput = {
  readonly __typename: 'expenses_api_RecordUpdateResponseOutput';
  readonly record: Expenses_Api_RecordOutput;
};

export type Expenses_Api_ScheduleCreateResponseOutput = {
  readonly __typename: 'expenses_api_ScheduleCreateResponseOutput';
  readonly schedule: Expenses_Api_ScheduleOutput;
};

export type Expenses_Api_ScheduleFilterListResponseOutput = {
  readonly __typename: 'expenses_api_ScheduleFilterListResponseOutput';
  readonly schedules?: Maybe<ReadonlyArray<Expenses_Api_ScheduleOutput>>;
  readonly total: Scalars['Int'];
};

export type Expenses_Api_ScheduleGetResponseOutput = {
  readonly __typename: 'expenses_api_ScheduleGetResponseOutput';
  readonly schedule: Expenses_Api_ScheduleOutput;
};

export type Expenses_Api_ScheduleOutput = {
  readonly __typename: 'expenses_api_ScheduleOutput';
  readonly active: Scalars['Boolean'];
  readonly amount: Scalars['Float'];
  readonly config: Expenses_Api_ConfigOutput;
  readonly correlation_id: Scalars['String'];
  readonly correlation_type: Expenses_Api_CorrelationTypeEnum;
  readonly created: Scalars['String'];
  readonly description: Scalars['String'];
  readonly end: Scalars['String'];
  readonly id: Scalars['String'];
  readonly listing_id: Scalars['String'];
  readonly period: Expenses_Api_SchedulePeriodOutput;
  readonly property_id: Scalars['String'];
  readonly start: Scalars['String'];
  readonly tax: Expenses_Api_TaxOutput;
  readonly type: Expenses_Api_IsSchedule_TypeOneofOutput;
  readonly updated: Scalars['String'];
  readonly user_id: Scalars['String'];
};

export type Expenses_Api_SchedulePeriodInput = {
  readonly days?: InputMaybe<Scalars['Int']>;
  readonly months?: InputMaybe<Scalars['Int']>;
  readonly years?: InputMaybe<Scalars['Int']>;
};

export type Expenses_Api_SchedulePeriodOutput = {
  readonly __typename: 'expenses_api_SchedulePeriodOutput';
  readonly days: Scalars['Int'];
  readonly months: Scalars['Int'];
  readonly years: Scalars['Int'];
};

export type Expenses_Api_ScheduleUpdateResponseOutput = {
  readonly __typename: 'expenses_api_ScheduleUpdateResponseOutput';
  readonly schedule: Expenses_Api_ScheduleOutput;
};

export type Expenses_Api_ScheduledRecordListPayoutOptionsInput = {
  readonly payout_id?: InputMaybe<Scalars['String']>;
  readonly payout_invoice_id?: InputMaybe<Scalars['String']>;
  readonly payout_status?: InputMaybe<Expenses_Api_PayoutStatusEnum>;
  readonly transaction_id?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_ScheduledRecordListResponseOutput = {
  readonly __typename: 'expenses_api_ScheduledRecordListResponseOutput';
  readonly scheduled_records?: Maybe<ReadonlyArray<Expenses_Api_ScheduledRecordOutput>>;
};

export type Expenses_Api_ScheduledRecordOutput = {
  readonly __typename: 'expenses_api_ScheduledRecordOutput';
  readonly matched_records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
  readonly record: Expenses_Api_RecordOutput;
};

export type Expenses_Api_ServiceRecordAdvanceUpdateResponseOutput = {
  readonly __typename: 'expenses_api_ServiceRecordAdvanceUpdateResponseOutput';
  readonly records?: Maybe<ReadonlyArray<Expenses_Api_RecordOutput>>;
};

export type Expenses_Api_TaxInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly type?: InputMaybe<Expenses_Api_IsTax_TypeOneofInput>;
};

export type Expenses_Api_TaxOutput = {
  readonly __typename: 'expenses_api_TaxOutput';
  readonly amount: Scalars['Float'];
  readonly type: Expenses_Api_IsTax_TypeOneofOutput;
};

export type Expenses_Api_TaxVatInput = {
  readonly percent?: InputMaybe<Scalars['Float']>;
  /** Deprecated. Do not use. */
  readonly stub?: InputMaybe<Scalars['Boolean']>;
};

export type Expenses_Api_TaxVatOutput = {
  readonly __typename: 'expenses_api_TaxVATOutput';
  readonly percent: Scalars['Float'];
  /** @deprecated stub, do not use */
  readonly stub?: Maybe<Scalars['Boolean']>;
};

export type Expenses_Api_IsConfigCreateRequest_TypeOneofInput = {
  readonly offer?: InputMaybe<Expenses_Api_ConfigOfferInput>;
  readonly property?: InputMaybe<Expenses_Api_ConfigPropertyInput>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_IsConfigUpdateRequest_TypeOneofInput = {
  readonly offer?: InputMaybe<Expenses_Api_ConfigOfferInput>;
  readonly property?: InputMaybe<Expenses_Api_ConfigPropertyInput>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_IsConfig_TypeOneofInput = {
  readonly offer?: InputMaybe<Expenses_Api_ConfigOfferInput>;
  readonly property?: InputMaybe<Expenses_Api_ConfigPropertyInput>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Expenses_Api_IsConfig_TypeOneofOutput = {
  readonly __typename: 'expenses_api_isConfig_TypeOneofOutput';
  readonly offer?: Maybe<Expenses_Api_ConfigOfferOutput>;
  readonly property?: Maybe<Expenses_Api_ConfigPropertyOutput>;
  readonly user?: Maybe<Scalars['String']>;
};

export type Expenses_Api_IsExpense_ExpenseOneofInput = {
  readonly drp?: InputMaybe<Expenses_Api_ExpenseDrpInput>;
  readonly maintenance?: InputMaybe<Scalars['String']>;
  readonly mortgage?: InputMaybe<Expenses_Api_ExpenseMortgageInput>;
  readonly other?: InputMaybe<Scalars['Boolean']>;
  readonly rent?: InputMaybe<Expenses_Api_ExpenseRentInput>;
  readonly tax?: InputMaybe<Scalars['Boolean']>;
};

export type Expenses_Api_IsExpense_ExpenseOneofOutput = {
  readonly __typename: 'expenses_api_isExpense_ExpenseOneofOutput';
  readonly drp?: Maybe<Expenses_Api_ExpenseDrpOutput>;
  readonly maintenance?: Maybe<Scalars['String']>;
  readonly mortgage?: Maybe<Expenses_Api_ExpenseMortgageOutput>;
  readonly other?: Maybe<Scalars['Boolean']>;
  readonly rent?: Maybe<Expenses_Api_ExpenseRentOutput>;
  readonly tax?: Maybe<Scalars['Boolean']>;
};

export type Expenses_Api_IsIncome_IncomeOneofInput = {
  readonly advance?: InputMaybe<Expenses_Api_IncomeAdvanceInput>;
  readonly other?: InputMaybe<Scalars['Boolean']>;
  readonly rent?: InputMaybe<Expenses_Api_IncomeRentInput>;
  readonly salary?: InputMaybe<Scalars['Boolean']>;
};

export type Expenses_Api_IsIncome_IncomeOneofOutput = {
  readonly __typename: 'expenses_api_isIncome_IncomeOneofOutput';
  readonly advance?: Maybe<Expenses_Api_IncomeAdvanceOutput>;
  readonly other?: Maybe<Scalars['Boolean']>;
  readonly rent?: Maybe<Expenses_Api_IncomeRentOutput>;
  readonly salary?: Maybe<Scalars['Boolean']>;
};

export type Expenses_Api_IsRecordCreateRequest_TypeOneofInput = {
  readonly expense?: InputMaybe<Expenses_Api_IsExpense_ExpenseOneofInput>;
  readonly income?: InputMaybe<Expenses_Api_IsIncome_IncomeOneofInput>;
};

export type Expenses_Api_IsRecordUpdateRequest_TypeOneofInput = {
  readonly expense?: InputMaybe<Expenses_Api_IsExpense_ExpenseOneofInput>;
  readonly income?: InputMaybe<Expenses_Api_IsIncome_IncomeOneofInput>;
};

export type Expenses_Api_IsRecord_TypeOneofOutput = {
  readonly __typename: 'expenses_api_isRecord_TypeOneofOutput';
  readonly expense?: Maybe<Expenses_Api_IsExpense_ExpenseOneofOutput>;
  readonly income?: Maybe<Expenses_Api_IsIncome_IncomeOneofOutput>;
};

export type Expenses_Api_IsScheduleCreateRequest_TypeOneofInput = {
  readonly expense?: InputMaybe<Expenses_Api_IsExpense_ExpenseOneofInput>;
  readonly income?: InputMaybe<Expenses_Api_IsIncome_IncomeOneofInput>;
};

export type Expenses_Api_IsScheduleUpdateRequest_TypeOneofInput = {
  readonly expense?: InputMaybe<Expenses_Api_IsExpense_ExpenseOneofInput>;
  readonly income?: InputMaybe<Expenses_Api_IsIncome_IncomeOneofInput>;
};

export type Expenses_Api_IsSchedule_TypeOneofOutput = {
  readonly __typename: 'expenses_api_isSchedule_TypeOneofOutput';
  readonly expense?: Maybe<Expenses_Api_IsExpense_ExpenseOneofOutput>;
  readonly income?: Maybe<Expenses_Api_IsIncome_IncomeOneofOutput>;
};

export type Expenses_Api_IsTax_TypeOneofInput = {
  readonly stub?: InputMaybe<Scalars['Boolean']>;
  readonly vat?: InputMaybe<Expenses_Api_TaxVatInput>;
};

export type Expenses_Api_IsTax_TypeOneofOutput = {
  readonly __typename: 'expenses_api_isTax_TypeOneofOutput';
  readonly stub?: Maybe<Scalars['Boolean']>;
  readonly vat?: Maybe<Expenses_Api_TaxVatOutput>;
};

export type Extras_Api_AddResponseOutput = {
  readonly __typename: 'extras_api_AddResponseOutput';
  readonly id: Scalars['String'];
  readonly placeholder: Scalars['Boolean'];
};

export type Extras_Api_AttributeInput = {
  readonly key?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
};

export type Extras_Api_DeleteResponseOutput = {
  readonly __typename: 'extras_api_DeleteResponseOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly success: Scalars['Boolean'];
};

export type Extras_Api_GetResponseOutput = {
  readonly __typename: 'extras_api_GetResponseOutput';
  readonly extra_service: Extras_Api_ServiceOutput;
  readonly property: Extras_Api_PropertyOutput;
};

export type Extras_Api_ListResponseOutput = {
  readonly __typename: 'extras_api_ListResponseOutput';
  readonly extra_services?: Maybe<ReadonlyArray<Extras_Api_ServiceOutput>>;
  readonly property: Extras_Api_PropertyOutput;
};

export type Extras_Api_PriceCalculateResponseOutput = {
  readonly __typename: 'extras_api_PriceCalculateResponseOutput';
  readonly price: Scalars['Float'];
  readonly tax_amount: Scalars['Float'];
};

export type Extras_Api_PropertyInput = {
  readonly conditions?: InputMaybe<Scalars['Float']>;
  readonly details?: InputMaybe<Property_Api_PropertyDetailsInput>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly people?: InputMaybe<Property_Api_PropertyPeopleInput>;
  readonly rental?: InputMaybe<Property_Api_PropertyRentalInput>;
  readonly status?: InputMaybe<Property_Api_PropertyStatusEnum>;
};

export type Extras_Api_PropertyOutput = {
  readonly __typename: 'extras_api_PropertyOutput';
  readonly conditions?: Maybe<Scalars['Float']>;
  readonly details: Property_Api_PropertyDetailsOutput;
  readonly id: Scalars['String'];
  readonly people: Property_Api_PropertyPeopleEnrichedOutput;
  readonly rental: Property_Api_PropertyRentalOutput;
  readonly status: Property_Api_PropertyStatusEnum;
};

export type Extras_Api_ServiceInput = {
  readonly attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  readonly cart_product?: InputMaybe<Scalars['Boolean']>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly disabled?: InputMaybe<Scalars['Boolean']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly inventory_account?: InputMaybe<Scalars['String']>;
  readonly inventory_code?: InputMaybe<Scalars['String']>;
  readonly inventory_index?: InputMaybe<Scalars['Int']>;
  readonly inventory_offset?: InputMaybe<Scalars['Int']>;
  readonly main_icon_url?: InputMaybe<Scalars['String']>;
  readonly obligatory?: InputMaybe<Scalars['Boolean']>;
  readonly page_url?: InputMaybe<Scalars['String']>;
  readonly price?: InputMaybe<Scalars['Float']>;
  readonly price_calculated?: InputMaybe<Scalars['Boolean']>;
  readonly price_need_calculate?: InputMaybe<Scalars['Boolean']>;
  readonly price_on_request?: InputMaybe<Scalars['Boolean']>;
  readonly primary?: InputMaybe<Scalars['Boolean']>;
  readonly reference_id?: InputMaybe<Scalars['String']>;
  readonly reference_provider?: InputMaybe<Scalars['String']>;
  readonly remote_sales_prices?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly remote_sales_taxes?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
  readonly role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  readonly service_receivers?: InputMaybe<ReadonlyArray<Extras_Api_ServiceReceiverInput>>;
  readonly status?: InputMaybe<Extras_Api_ServiceStatusEnum>;
  readonly tax_amount?: InputMaybe<Scalars['Float']>;
  readonly tax_percentage?: InputMaybe<Scalars['Float']>;
  readonly tax_type?: InputMaybe<Extras_Api_TaxTypeEnum>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
};

export type Extras_Api_ServiceOutput = {
  readonly __typename: 'extras_api_ServiceOutput';
  readonly attributes?: Maybe<ReadonlyArray<_String__StringOutputMap>>;
  readonly cart_product?: Maybe<Scalars['Boolean']>;
  readonly code?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly disabled?: Maybe<Scalars['Boolean']>;
  readonly id?: Maybe<Scalars['String']>;
  readonly inventory_account?: Maybe<Scalars['String']>;
  readonly inventory_code?: Maybe<Scalars['String']>;
  readonly inventory_index?: Maybe<Scalars['Int']>;
  readonly inventory_offset?: Maybe<Scalars['Int']>;
  /** @deprecated Use description */
  readonly learn_more?: Maybe<Scalars['String']>;
  readonly main_icon_url?: Maybe<Scalars['String']>;
  readonly obligatory?: Maybe<Scalars['Boolean']>;
  readonly page_url?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly price_calculated?: Maybe<Scalars['Boolean']>;
  readonly price_need_calculate?: Maybe<Scalars['Boolean']>;
  readonly price_on_request?: Maybe<Scalars['Boolean']>;
  readonly primary?: Maybe<Scalars['Boolean']>;
  readonly reference_id?: Maybe<Scalars['String']>;
  readonly reference_provider?: Maybe<Scalars['String']>;
  readonly remote_sales_prices?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly remote_sales_taxes?: Maybe<ReadonlyArray<Scalars['Float']>>;
  readonly role?: Maybe<Extras_Api_ServiceRoleEnum>;
  readonly service_receivers?: Maybe<ReadonlyArray<Extras_Api_ServiceReceiverOutput>>;
  readonly status?: Maybe<Extras_Api_ServiceStatusEnum>;
  readonly tax_amount?: Maybe<Scalars['Float']>;
  readonly tax_percentage?: Maybe<Scalars['Float']>;
  readonly tax_type?: Maybe<Extras_Api_TaxTypeEnum>;
  readonly title?: Maybe<Scalars['String']>;
  readonly type?: Maybe<Extras_Api_ServiceTypeEnum>;
};

export type Extras_Api_ServiceReceiverInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly percent?: InputMaybe<Scalars['Float']>;
  readonly sum?: InputMaybe<Scalars['Float']>;
};

export type Extras_Api_ServiceReceiverOutput = {
  readonly __typename: 'extras_api_ServiceReceiverOutput';
  readonly code: Scalars['String'];
  readonly percent: Scalars['Float'];
  readonly sum: Scalars['Float'];
};

export enum Extras_Api_ServiceRoleEnum {
  LandlordClaim = 'LANDLORD_CLAIM',
  LandlordInsurance = 'LANDLORD_INSURANCE',
  /** @deprecated deprecated */
  LandlordListing = 'LANDLORD_LISTING',
  LandlordServices = 'LANDLORD_SERVICES',
  ManagerServices = 'MANAGER_SERVICES',
  Mortgage = 'MORTGAGE',
  Pricing = 'PRICING',
  TenantDeposit = 'TENANT_DEPOSIT',
  TenantInsurance = 'TENANT_INSURANCE',
  TenantServices = 'TENANT_SERVICES',
  UnknownServiceRole = 'UNKNOWN_SERVICE_ROLE'
}

export type Extras_Api_ServiceSnapshotInput = {
  readonly attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  readonly code?: InputMaybe<Scalars['String']>;
  readonly created?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly inventory_account?: InputMaybe<Scalars['String']>;
  readonly inventory_code?: InputMaybe<Scalars['String']>;
  readonly inventory_index?: InputMaybe<Scalars['Int']>;
  readonly price?: InputMaybe<Scalars['Float']>;
  readonly primary?: InputMaybe<Scalars['Boolean']>;
  readonly reference_id?: InputMaybe<Scalars['String']>;
  readonly reference_provider?: InputMaybe<Scalars['String']>;
  readonly role?: InputMaybe<Extras_Api_ServiceRoleEnum>;
  readonly service_id?: InputMaybe<Scalars['String']>;
  readonly target_id?: InputMaybe<Scalars['String']>;
  readonly target_type?: InputMaybe<Scalars['String']>;
  readonly tax_amount?: InputMaybe<Scalars['Float']>;
  readonly tax_percentage?: InputMaybe<Scalars['Float']>;
  readonly tax_type?: InputMaybe<Extras_Api_TaxTypeEnum>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Extras_Api_ServiceTypeEnum>;
  readonly updated?: InputMaybe<Scalars['String']>;
};

export type Extras_Api_ServiceSnapshotOutput = {
  readonly __typename: 'extras_api_ServiceSnapshotOutput';
  readonly attributes: ReadonlyArray<_String__StringOutputMap>;
  readonly code: Scalars['String'];
  readonly created: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly inventory_account: Scalars['String'];
  readonly inventory_code: Scalars['String'];
  readonly inventory_index: Scalars['Int'];
  /** @deprecated Use description */
  readonly learn_more: Scalars['String'];
  readonly price: Scalars['Float'];
  readonly primary: Scalars['Boolean'];
  readonly reference_id: Scalars['String'];
  readonly reference_provider: Scalars['String'];
  readonly role: Extras_Api_ServiceRoleEnum;
  readonly service_id: Scalars['String'];
  readonly target_id: Scalars['String'];
  readonly target_type: Scalars['String'];
  readonly tax_amount: Scalars['Float'];
  readonly tax_percentage: Scalars['Float'];
  readonly tax_type: Extras_Api_TaxTypeEnum;
  readonly title: Scalars['String'];
  readonly type: Extras_Api_ServiceTypeEnum;
  readonly updated: Scalars['String'];
};

export type Extras_Api_ServiceSnapshotStatusOutput = {
  readonly __typename: 'extras_api_ServiceSnapshotStatusOutput';
  readonly admin: Scalars['String'];
  readonly service: Extras_Api_ServiceSnapshotOutput;
  readonly status: Extras_Api_ServiceStatusEnum;
  readonly updated_at: Scalars['String'];
};

export enum Extras_Api_ServiceStatusEnum {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  UnknownServiceStatus = 'UNKNOWN_SERVICE_STATUS'
}

export enum Extras_Api_ServiceTypeEnum {
  AdvancedRentPlan = 'ADVANCED_RENT_PLAN',
  BuildingsInsurance = 'BUILDINGS_INSURANCE',
  Bundle = 'BUNDLE',
  Certificate = 'CERTIFICATE',
  CheckInReport = 'CHECK_IN_REPORT',
  CheckOutReport = 'CHECK_OUT_REPORT',
  ContentsInsurance = 'CONTENTS_INSURANCE',
  DamageSettlement = 'DAMAGE_SETTLEMENT',
  Deposit = 'DEPOSIT',
  DepositInsurance = 'DEPOSIT_INSURANCE',
  EicReport = 'EIC_REPORT',
  ExtraListing = 'EXTRA_LISTING',
  HomeEmergencyInsurance = 'HOME_EMERGENCY_INSURANCE',
  InventoryReport = 'INVENTORY_REPORT',
  LegalExpensesInsurance = 'LEGAL_EXPENSES_INSURANCE',
  ListingFee = 'LISTING_FEE',
  Payout = 'PAYOUT',
  PhotosFloorPlan = 'PHOTOS_FLOOR_PLAN',
  ReferencingCheck = 'REFERENCING_CHECK',
  Rent = 'RENT',
  RentGuaranteeInsurance = 'RENT_GUARANTEE_INSURANCE',
  UnknownServiceType = 'UNKNOWN_SERVICE_TYPE'
}

export enum Extras_Api_TaxTypeEnum {
  TaxTypeIpt = 'TAX_TYPE_IPT',
  TaxTypeNone = 'TAX_TYPE_NONE',
  TaxTypeVat = 'TAX_TYPE_VAT',
  UnknownTaxType = 'UNKNOWN_TAX_TYPE'
}

export type Extras_Api_UpdateResponseOutput = {
  readonly __typename: 'extras_api_UpdateResponseOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_DepositSelectResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_DepositSelectResponseOutput';
  readonly error: Scalars['String'];
  readonly invoice: Scalars['String'];
  readonly mandate: Scalars['String'];
  readonly schedule_deposit: Gateway_Impl_Resolvers_ScheduleDepositParamsOutput;
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_DirectDebitResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_DirectDebitResponseOutput';
  readonly error: Scalars['String'];
  readonly session_id: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_FavoriteResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_FavoriteResponseOutput';
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly favourites?: Maybe<ReadonlyArray<Property_Api_PropertyEnrichedOutput>>;
};

export type Gateway_Impl_Resolvers_GetTenancyResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_GetTenancyResponseOutput';
  readonly success: Scalars['Boolean'];
  readonly tenancy: Property_Api_TenancyOutput;
};

export type Gateway_Impl_Resolvers_LandlordIntentionResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_LandlordIntentionResponseOutput';
  readonly new_offer: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly tenancy: Scalars['String'];
};

export type Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupGetResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_PropertyExpenseTaxTrackerSetupGetResponseOutput';
  readonly expense_mortgage_schedule: Expenses_Api_ScheduleOutput;
  readonly income_rent_schedule: Expenses_Api_ScheduleOutput;
  readonly income_salary_schedule: Expenses_Api_ScheduleOutput;
  readonly setup: Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupOutput;
};

export type Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupOutput = {
  readonly __typename: 'gateway_impl_resolvers_PropertyExpenseTaxTrackerSetupOutput';
  readonly annual_salary: Scalars['Float'];
  readonly monthly_allowable_expenses: Scalars['Float'];
  readonly monthly_mortgage_interest: Scalars['Float'];
  readonly monthly_mortgage_payment: Scalars['Float'];
  readonly monthly_rent: Scalars['Float'];
  readonly property_id: Scalars['String'];
  readonly tax_year: Scalars['Date'];
  readonly tenancy_period_end: Scalars['Date'];
  readonly tenancy_period_start: Scalars['Date'];
};

export type Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupSaveResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_PropertyExpenseTaxTrackerSetupSaveResponseOutput';
  readonly expense_mortgage_schedule: Expenses_Api_ScheduleOutput;
  readonly income_rent_schedule: Expenses_Api_ScheduleOutput;
  readonly income_salary_schedule: Expenses_Api_ScheduleOutput;
  readonly setup: Gateway_Impl_Resolvers_PropertyExpenseTaxTrackerSetupOutput;
};

export type Gateway_Impl_Resolvers_QuestionResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_QuestionResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_ScheduleDepositParamsOutput = {
  readonly __typename: 'gateway_impl_resolvers_ScheduleDepositParamsOutput';
  readonly due_day: Scalars['Int'];
  readonly monthly_payment: Scalars['Float'];
  readonly months: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_TosOutputOutput = {
  readonly __typename: 'gateway_impl_resolvers_TosOutputOutput';
  readonly text: Scalars['String'];
  readonly version: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_UnfavouriteResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_UnfavouriteResponseOutput';
  readonly account: Account_Api_AccountEnrichedOutput;
  readonly favourites?: Maybe<ReadonlyArray<Property_Api_PropertyEnrichedOutput>>;
};

export type Gateway_Impl_Resolvers_ValidatePhoneNumberOutput = {
  readonly __typename: 'gateway_impl_resolvers_ValidatePhoneNumberOutput';
  readonly country_code: Scalars['Int'];
  readonly e164: Scalars['String'];
  readonly error: Scalars['String'];
  readonly national_number: Scalars['String'];
};

export type Gateway_Impl_Resolvers_Admin_AllTransactionsResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_AllTransactionsResponseOutput';
  readonly total: Scalars['Int'];
  readonly transactions?: Maybe<ReadonlyArray<Invoice_Api_TransactionOutput>>;
};

export type Gateway_Impl_Resolvers_Admin_OverduePaymentInput = {
  readonly landlord_id?: InputMaybe<Scalars['String']>;
  readonly overdue_days?: InputMaybe<Scalars['Int']>;
  readonly payment_amount?: InputMaybe<Scalars['Float']>;
  readonly property?: InputMaybe<Gateway_Impl_Resolvers_Admin_OverduePaymentPropertyInput>;
  readonly tenant_id?: InputMaybe<Scalars['String']>;
};

export type Gateway_Impl_Resolvers_Admin_OverduePaymentNotificationStatusOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_OverduePaymentNotificationStatusOutput';
  readonly error: Scalars['String'];
  readonly property_id: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_Admin_OverduePaymentPropertyInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly property_address?: InputMaybe<Scalars['String']>;
  readonly property_ivr?: InputMaybe<Scalars['String']>;
  readonly secured_rentplan?: InputMaybe<Scalars['Boolean']>;
};

export type Gateway_Impl_Resolvers_Admin_SendOverduePaymentNotificationsResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_SendOverduePaymentNotificationsResponseOutput';
  readonly statuses?: Maybe<ReadonlyArray<Gateway_Impl_Resolvers_Admin_OverduePaymentNotificationStatusOutput>>;
};

export type Gateway_Impl_Resolvers_Admin_AllAdminsResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allAdminsResultOutput';
  readonly data?: Maybe<ReadonlyArray<Account_Api_AccountEnrichedOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllCardpaymentsResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allCardpaymentsResultOutput';
  readonly data?: Maybe<ReadonlyArray<Cardpayment_Api_ReceiptItemOutput>>;
  readonly non_confirmed: Scalars['Int'];
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllInvitationsResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allInvitationsResultOutput';
  readonly data?: Maybe<ReadonlyArray<Invitation_Api_InvitationInfoOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllMaintenancesResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allMaintenancesResultOutput';
  readonly data?: Maybe<ReadonlyArray<Maintenance_Api_MaintenanceEnrichedOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllOffersResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allOffersResultOutput';
  readonly data?: Maybe<ReadonlyArray<Offer_Api_OfferEnrichedOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllParamsPaginationInput = {
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly perPage?: InputMaybe<Scalars['Int']>;
};

export type Gateway_Impl_Resolvers_Admin_AllPropertiesResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allPropertiesResultOutput';
  readonly data?: Maybe<ReadonlyArray<Property_Api_PropertyEnrichedOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllScheduledPaymentsResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allScheduledPaymentsResultOutput';
  readonly data?: Maybe<ReadonlyArray<Offer_Api_ScheduledPaymentOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllServicesResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allServicesResultOutput';
  readonly data?: Maybe<ReadonlyArray<Extras_Api_ServiceOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllTenanciesResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allTenanciesResultOutput';
  readonly data?: Maybe<ReadonlyArray<Property_Api_TenancyOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_AllUsersResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_allUsersResultOutput';
  readonly data?: Maybe<ReadonlyArray<Account_Api_AccountEnrichedOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_DeleteZooplaResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_deleteZooplaResponseOutput';
  readonly result?: Maybe<ReadonlyArray<Zoopla_Api_DeletePropertyResponseOutput>>;
};

export type Gateway_Impl_Resolvers_Admin_GetPaymentsFilterResultOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_getPaymentsFilterResultOutput';
  readonly data?: Maybe<ReadonlyArray<Tokenio_Api_PaymentOutput>>;
  readonly total: Scalars['Int'];
};

export type Gateway_Impl_Resolvers_Admin_OfferDataOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_offerDataOutput';
  readonly accepted_at_value: Scalars['String'];
  readonly administrator?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly approve?: Maybe<Offer_Api_OfferApproveOutput>;
  readonly cancelled_at_value: Scalars['String'];
  readonly changelog?: Maybe<ReadonlyArray<Offer_Api_OfferChangelogOutput>>;
  readonly clause?: Maybe<ReadonlyArray<Offer_Api_ClauseOutput>>;
  readonly conditions: Offer_Api_OfferConditionsOutput;
  readonly contract: Scalars['String'];
  readonly contract_created_at: Scalars['String'];
  readonly created_at_value: Scalars['String'];
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly denied_at_value: Scalars['String'];
  readonly details: Offer_Api_OfferDetailsOutput;
  readonly draft_contract: Scalars['String'];
  readonly draft_contract_created_at: Scalars['String'];
  readonly guarantor_required: Scalars['Boolean'];
  readonly guarantors?: Maybe<ReadonlyArray<Offer_Api_GuarantorEnrichedOutput>>;
  readonly id: Scalars['String'];
  readonly invoices: ReadonlyArray<Invoice_Api_InvoiceOutput>;
  readonly is_locked: Scalars['Boolean'];
  readonly is_rto: Scalars['Boolean'];
  readonly landlord: Offer_Api_LandlordOutput;
  readonly landlords?: Maybe<ReadonlyArray<Offer_Api_LandlordOutput>>;
  readonly log?: Maybe<ReadonlyArray<Offer_Api_LogOutput>>;
  readonly manager: Scalars['String'];
  readonly notifications: ReadonlyArray<_String_Google_Golang_Org_Protobuf_Types_Known_Timestamppb_TimestampOutputMap>;
  readonly offers?: Maybe<ReadonlyArray<Offer_Api_OfferEnrichedOutput>>;
  readonly original_offer: Scalars['String'];
  readonly paid_statuses: Offer_Api_PaidStatusesOutput;
  /** @deprecated use invoices field */
  readonly payment?: Maybe<Offer_Api_OfferPaymentOutput>;
  /** @deprecated use invoices field */
  readonly payment_history: ReadonlyArray<Offer_Api_OfferPaymentOutput>;
  readonly property: Property_Api_PropertyEnrichedOutput;
  readonly property_ivr: Scalars['String'];
  readonly property_listing: Scalars['String'];
  readonly ready_for_signing: Offer_Api_OfferReadySigningOutput;
  readonly rent_insurances_generated: Scalars['Boolean'];
  readonly rent_plan: Offer_Api_RentPlanEnum;
  readonly rent_plan_selected_at: Scalars['String'];
  readonly schedule_generated: Scalars['Boolean'];
  readonly schedule_notifications: ReadonlyArray<_Int32_Offer_Api_ScheduleNotificationOutputMap>;
  readonly state: Offer_Api_OfferStateEnum;
  readonly tenants?: Maybe<ReadonlyArray<Offer_Api_TenantEnrichedOutput>>;
  readonly test?: Maybe<Scalars['Boolean']>;
  readonly updated_at_value: Scalars['String'];
  readonly viewing: Viewing_Api_ViewingOutput;
  readonly viewings?: Maybe<ReadonlyArray<Viewing_Api_ViewingOutput>>;
  readonly workflow_instance_id: Scalars['String'];
};

export type Gateway_Impl_Resolvers_Admin_OfferUpdateResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_offerUpdateResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Gateway_Impl_Resolvers_Admin_OfferDataOutput;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Gateway_Impl_Resolvers_Admin_PropertyDataOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_propertyDataOutput';
  readonly admin_verify: Property_Api_AdminVerifyOutput;
  readonly bank_details: Property_Api_PropertyBankDetailsOutput;
  readonly boomin: Property_Api_PropertyBoominOutput;
  readonly certificates_statuses?: Maybe<ReadonlyArray<Property_Api_CertificateStateOutput>>;
  readonly completion_state: Property_Api_PropertyCompleteEnum;
  readonly created_at: Scalars['String'];
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly description: Property_Api_PropertyDescriptionEnrichedOutput;
  readonly details: Property_Api_PropertyDetailsOutput;
  readonly documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly extra_listing_payments?: Maybe<ReadonlyArray<Property_Api_ExtraListingPaymentOutput>>;
  readonly favorited?: Maybe<Scalars['Boolean']>;
  readonly finances: PropertyFinancesOutput;
  readonly id: Scalars['String'];
  readonly incoming_invitations: ReadonlyArray<Maybe<Invitation_Api_InvitationInfoOutput>>;
  readonly insurances: Property_Api_InsurancesOutput;
  readonly is_archived?: Maybe<Scalars['Boolean']>;
  readonly is_created_when_ordering?: Maybe<Scalars['Boolean']>;
  readonly ivr: Scalars['String'];
  readonly legacy_id?: Maybe<Scalars['String']>;
  readonly legacy_ivr?: Maybe<Scalars['String']>;
  readonly listing: Property_Api_PropertyListingOutput;
  readonly listing_expiry_date: Scalars['String'];
  readonly listing_fee?: Maybe<ReadonlyArray<Cardpayment_Api_ReceiptOutput>>;
  readonly location: Property_Api_PropertyLocationOutput;
  readonly log?: Maybe<ReadonlyArray<Property_Api_LogOutput>>;
  readonly maintenance: ReadonlyArray<Maintenance_Api_MaintenanceEnrichedOutput>;
  readonly missed_extra_services?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nearby?: Maybe<ReadonlyArray<Geo_Api_PointRelativeOutput>>;
  readonly notes?: Maybe<ReadonlyArray<Property_Api_PropertyNoteOutput>>;
  readonly offers_list: ReadonlyArray<Offer_Api_OfferEnrichedOutput>;
  readonly origin?: Maybe<Listing_Api_IsVersionOrigin_OriginOneofOutput>;
  readonly pending_extra_services: ReadonlyArray<Scalars['String']>;
  readonly people: Property_Api_PropertyPeopleEnrichedOutput;
  readonly products: ReadonlyArray<Invoice_Api_ProductOutput>;
  readonly property_of_day_date: Scalars['String'];
  readonly rental: Property_Api_PropertyRentalOutput;
  readonly rightmove: Property_Api_PropertyRightmoveOutput;
  readonly rto_state: Scalars['String'];
  readonly status: Property_Api_PropertyStatusEnum;
  readonly status_history: ReadonlyArray<Property_Api_PropertyStatusDataOutput>;
  readonly test?: Maybe<Scalars['Boolean']>;
  readonly updated_at: Scalars['String'];
  readonly valuation: Property_Api_ValuationOutput;
  readonly version_id: Scalars['String'];
  readonly viewings?: Maybe<ReadonlyArray<Viewing_Api_ViewingOutput>>;
  readonly viewings_list: ReadonlyArray<Viewing_Api_ViewingOutput>;
  readonly withdrawn_reason: Property_Api_PropertyWithdrawnReasonEnum;
  readonly zoopla: Property_Api_PropertyZooplaOutput;
};

export type Gateway_Impl_Resolvers_Admin_PublishResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_publishResponseOutput';
  readonly error_msg: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly property: Gateway_Impl_Resolvers_Admin_PropertyDataOutput;
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_Admin_WithdrawResponseOutput = {
  readonly __typename: 'gateway_impl_resolvers_admin_withdrawResponseOutput';
  readonly error_msg: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Gateway_Impl_Resolvers_ScheduledRecordsTransactionOutput = {
  readonly __typename: 'gateway_impl_resolvers_scheduledRecordsTransactionOutput';
  readonly scheduledRecords?: Maybe<ReadonlyArray<Expenses_Api_ScheduledRecordOutput>>;
};

export type Geo_Api_ClearSitemapsCacheResponseOutput = {
  readonly __typename: 'geo_api_ClearSitemapsCacheResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Geo_Api_CountyOutput = {
  readonly __typename: 'geo_api_CountyOutput';
  readonly country: Scalars['String'];
  readonly location: Geo_Api_LocationOutput;
  readonly name: Scalars['String'];
  readonly region: Scalars['String'];
};

export type Geo_Api_GenerateSitemapsResponseOutput = {
  readonly __typename: 'geo_api_GenerateSitemapsResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Geo_Api_GetCountiesInRegionResponseOutput = {
  readonly __typename: 'geo_api_GetCountiesInRegionResponseOutput';
  readonly counties?: Maybe<ReadonlyArray<Geo_Api_CountyOutput>>;
};

export type Geo_Api_GetCountiesSitemapResponseOutput = {
  readonly __typename: 'geo_api_GetCountiesSitemapResponseOutput';
  readonly counties?: Maybe<ReadonlyArray<Geo_Api_CountyOutput>>;
};

export type Geo_Api_GetLocalityResponseOutput = {
  readonly __typename: 'geo_api_GetLocalityResponseOutput';
  readonly counties?: Maybe<ReadonlyArray<Geo_Api_CountyOutput>>;
  readonly countries?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly postcodes?: Maybe<ReadonlyArray<Geo_Api_PostcodeOutput>>;
  readonly regions?: Maybe<ReadonlyArray<Geo_Api_RegionOutput>>;
  readonly towns?: Maybe<ReadonlyArray<Geo_Api_TownOutput>>;
};

export type Geo_Api_GetNearestPointsResponseOutput = {
  readonly __typename: 'geo_api_GetNearestPointsResponseOutput';
  readonly points_for_location?: Maybe<ReadonlyArray<ReadonlyArray<Geo_Api_PointRelativeOutput>>>;
};

export type Geo_Api_GetPointsResponseOutput = {
  readonly __typename: 'geo_api_GetPointsResponseOutput';
  readonly points?: Maybe<ReadonlyArray<Geo_Api_PointOutput>>;
};

export type Geo_Api_GetRegionsSitemapResponseOutput = {
  readonly __typename: 'geo_api_GetRegionsSitemapResponseOutput';
  readonly regions?: Maybe<ReadonlyArray<Geo_Api_RegionOutput>>;
};

export type Geo_Api_GetStationsResponseOutput = {
  readonly __typename: 'geo_api_GetStationsResponseOutput';
  readonly points?: Maybe<ReadonlyArray<Geo_Api_PointRelativeOutput>>;
};

export type Geo_Api_GetTownsResponseOutput = {
  readonly __typename: 'geo_api_GetTownsResponseOutput';
  readonly towns?: Maybe<ReadonlyArray<Geo_Api_TownOutput>>;
};

export type Geo_Api_GetTownsSitemapResponseOutput = {
  readonly __typename: 'geo_api_GetTownsSitemapResponseOutput';
  readonly towns?: Maybe<ReadonlyArray<Geo_Api_TownOutput>>;
};

export enum Geo_Api_IsochroneMovingTypeEnum {
  Cycling = 'CYCLING',
  Driving = 'DRIVING',
  Walking = 'WALKING'
}

export type Geo_Api_IsochroneOutput = {
  readonly __typename: 'geo_api_IsochroneOutput';
  readonly contours?: Maybe<ReadonlyArray<ReadonlyArray<Geo_Api_LocationOutput>>>;
  readonly time: Scalars['Int'];
};

export type Geo_Api_IsochroneResponseOutput = {
  readonly __typename: 'geo_api_IsochroneResponseOutput';
  readonly isochrones?: Maybe<ReadonlyArray<Geo_Api_IsochroneOutput>>;
};

export type Geo_Api_LocationInput = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
};

export type Geo_Api_LocationOutput = {
  readonly __typename: 'geo_api_LocationOutput';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Geo_Api_PoiOutput = {
  readonly __typename: 'geo_api_PoiOutput';
  readonly location: Geo_Api_LocationOutput;
  readonly makiType: Scalars['String'];
  readonly name: Scalars['String'];
  readonly tag: Scalars['String'];
};

export type Geo_Api_PoiResponseOutput = {
  readonly __typename: 'geo_api_PoiResponseOutput';
  readonly pois?: Maybe<ReadonlyArray<Geo_Api_PoiOutput>>;
};

export type Geo_Api_PointInput = {
  readonly count?: InputMaybe<Scalars['Int']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Geo_Api_LocationInput>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Geo_Api_PointTypeEnum>;
};

export type Geo_Api_PointOutput = {
  readonly __typename: 'geo_api_PointOutput';
  readonly count: Scalars['Int'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly location: Geo_Api_LocationOutput;
  readonly name: Scalars['String'];
  readonly type: Geo_Api_PointTypeEnum;
};

export type Geo_Api_PointRelativeInput = {
  readonly distance?: InputMaybe<Scalars['Float']>;
  readonly point?: InputMaybe<Geo_Api_PointInput>;
};

export type Geo_Api_PointRelativeOutput = {
  readonly __typename: 'geo_api_PointRelativeOutput';
  readonly distance: Scalars['Float'];
  readonly point: Geo_Api_PointOutput;
};

export enum Geo_Api_PointTypeEnum {
  PointAirport = 'POINT_AIRPORT',
  PointBusStation = 'POINT_BUS_STATION',
  PointClinic = 'POINT_CLINIC',
  PointCluster = 'POINT_CLUSTER',
  PointFerryStation = 'POINT_FERRY_STATION',
  PointHospital = 'POINT_HOSPITAL',
  PointLondonPoi = 'POINT_LONDON_POI',
  PointLondonStation = 'POINT_LONDON_STATION',
  PointMetroStation = 'POINT_METRO_STATION',
  PointNaptan = 'POINT_NAPTAN',
  PointParking = 'POINT_PARKING',
  PointRailwayStation = 'POINT_RAILWAY_STATION',
  PointSchool = 'POINT_SCHOOL',
  PointUkStation = 'POINT_UK_STATION',
  UnknownPointType = 'UNKNOWN_POINT_TYPE'
}

export type Geo_Api_PostcodeOutput = {
  readonly __typename: 'geo_api_PostcodeOutput';
  readonly code: Scalars['String'];
  readonly country: Scalars['String'];
  readonly county: Scalars['String'];
  readonly location: Geo_Api_LocationOutput;
  readonly name: Scalars['String'];
  readonly region: Scalars['String'];
};

export type Geo_Api_RegionInput = {
  readonly country?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Geo_Api_LocationInput>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type Geo_Api_RegionOutput = {
  readonly __typename: 'geo_api_RegionOutput';
  readonly country: Scalars['String'];
  readonly location: Geo_Api_LocationOutput;
  readonly name: Scalars['String'];
};

export type Geo_Api_TownOutput = {
  readonly __typename: 'geo_api_TownOutput';
  readonly country: Scalars['String'];
  readonly county: Scalars['String'];
  readonly id: Scalars['String'];
  readonly location: Geo_Api_LocationOutput;
  readonly name: Scalars['String'];
  readonly postcode: Scalars['String'];
  readonly region: Scalars['String'];
};

export type Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationInput = {
  readonly nanos?: InputMaybe<Scalars['Int']>;
  readonly seconds?: InputMaybe<Scalars['Int']>;
};

export type Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationOutput = {
  readonly __typename: 'google_golang_org_protobuf_types_known_durationpb_DurationOutput';
  readonly nanos: Scalars['Int'];
  readonly seconds: Scalars['Int'];
};

export type Grabdata_Api_AgentReviewOutput = {
  readonly __typename: 'grabdata_api_AgentReviewOutput';
  readonly author_name: Scalars['String'];
  readonly author_url: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly language: Scalars['String'];
  readonly profile_photo_url: Scalars['String'];
  readonly rating: Scalars['Float'];
  readonly relative_time_description: Scalars['String'];
  readonly text: Scalars['String'];
  readonly time: Scalars['Int'];
};

export type Grabdata_Api_AgentsCountOutput = {
  readonly __typename: 'grabdata_api_AgentsCountOutput';
  readonly location: Grabdata_Api_LocationOutput;
  readonly total: Scalars['Int'];
};

export type Grabdata_Api_AreaGuideOutput = {
  readonly __typename: 'grabdata_api_AreaGuideOutput';
  readonly created: Scalars['String'];
  readonly id: Scalars['Int'];
  readonly img_url: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Grabdata_Api_AreaInfoOutput = {
  readonly __typename: 'grabdata_api_AreaInfoOutput';
  readonly avg_price_1_bedroom: Scalars['Float'];
  readonly avg_price_2_bedroom: Scalars['Float'];
  readonly avg_price_3_bedroom: Scalars['Float'];
  readonly avg_price_4plus_bedroom: Scalars['Float'];
  readonly guides?: Maybe<ReadonlyArray<Grabdata_Api_AreaGuideOutput>>;
  readonly location: Grabdata_Api_LocationOutput;
};

export type Grabdata_Api_AreaInfoResponseOutput = {
  readonly __typename: 'grabdata_api_AreaInfoResponseOutput';
  readonly area_info: Grabdata_Api_AreaInfoOutput;
};

export type Grabdata_Api_GetAgentsCountByLocationsResponseOutput = {
  readonly __typename: 'grabdata_api_GetAgentsCountByLocationsResponseOutput';
  readonly agents_count?: Maybe<ReadonlyArray<Grabdata_Api_AgentsCountOutput>>;
};

export type Grabdata_Api_LettingAgentOutput = {
  readonly __typename: 'grabdata_api_LettingAgentOutput';
  readonly address: Scalars['String'];
  readonly address_locality: Scalars['String'];
  readonly average_price: Scalars['Float'];
  readonly created: Scalars['String'];
  readonly external_id: Scalars['Int'];
  readonly fees: Scalars['Float'];
  readonly id: Scalars['Int'];
  readonly location: Grabdata_Api_LocationOutput;
  readonly logo_http_status: Scalars['Int'];
  readonly logo_img: Scalars['String'];
  readonly name: Scalars['String'];
  readonly post_code: Scalars['String'];
  readonly properties_total: Scalars['Int'];
  readonly rating: Scalars['Float'];
  readonly reviews?: Maybe<ReadonlyArray<Grabdata_Api_AgentReviewOutput>>;
  readonly reviews_count: Scalars['Int'];
  readonly street_address: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly user_ratings_total: Scalars['Int'];
};

export type Grabdata_Api_ListLettingAgentsResponseOutput = {
  readonly __typename: 'grabdata_api_ListLettingAgentsResponseOutput';
  readonly agents?: Maybe<ReadonlyArray<Grabdata_Api_LettingAgentOutput>>;
  readonly total: Scalars['Int'];
};

export type Grabdata_Api_LocationInput = {
  readonly country?: InputMaybe<Scalars['String']>;
  readonly county?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly town?: InputMaybe<Scalars['String']>;
};

export type Grabdata_Api_LocationOutput = {
  readonly __typename: 'grabdata_api_LocationOutput';
  readonly country: Scalars['String'];
  readonly county: Scalars['String'];
  readonly region: Scalars['String'];
  readonly town: Scalars['String'];
};

export type Identity_Api_DownloadPdfResponseOutput = {
  readonly __typename: 'identity_api_DownloadPDFResponseOutput';
  readonly file_id: Scalars['String'];
};

export type Insurance_Api_BicycleInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Float']>;
};

export type Insurance_Api_BicycleOutput = {
  readonly __typename: 'insurance_api_BicycleOutput';
  readonly description: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type Insurance_Api_ClaimInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly date?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Insurance_Api_ClaimTypeEnum>;
};

export type Insurance_Api_ClaimOutput = {
  readonly __typename: 'insurance_api_ClaimOutput';
  readonly amount: Scalars['Float'];
  readonly date: Scalars['String'];
  readonly type: Insurance_Api_ClaimTypeEnum;
};

export enum Insurance_Api_ClaimTypeEnum {
  ClaimTypeAccidentalDamage = 'CLAIM_TYPE_ACCIDENTAL_DAMAGE',
  ClaimTypeAccidentalLoss = 'CLAIM_TYPE_ACCIDENTAL_LOSS',
  ClaimTypeArson = 'CLAIM_TYPE_ARSON',
  ClaimTypeEscapeOfWater = 'CLAIM_TYPE_ESCAPE_OF_WATER',
  ClaimTypeFire = 'CLAIM_TYPE_FIRE',
  ClaimTypeFlood = 'CLAIM_TYPE_FLOOD',
  ClaimTypeFreezerContents = 'CLAIM_TYPE_FREEZER_CONTENTS',
  ClaimTypeImpact = 'CLAIM_TYPE_IMPACT',
  ClaimTypeLiability = 'CLAIM_TYPE_LIABILITY',
  ClaimTypeMaliciousDamageOrRiot = 'CLAIM_TYPE_MALICIOUS_DAMAGE_OR_RIOT',
  ClaimTypeOther = 'CLAIM_TYPE_OTHER',
  ClaimTypeStormDamage = 'CLAIM_TYPE_STORM_DAMAGE',
  ClaimTypeStormSubsidenceHeaveOrLandslip = 'CLAIM_TYPE_STORM_SUBSIDENCE_HEAVE_OR_LANDSLIP',
  ClaimTypeTheft = 'CLAIM_TYPE_THEFT',
  ClaimTypeTheftAwayFromHome = 'CLAIM_TYPE_THEFT_AWAY_FROM_HOME',
  ClaimTypeUnknown = 'CLAIM_TYPE_UNKNOWN'
}

export type Insurance_Api_ContentsInsurancePremium_AssumptionsInput = {
  readonly claims_amount?: InputMaybe<Scalars['Boolean']>;
  readonly claims_num?: InputMaybe<Scalars['Boolean']>;
  readonly home_terms?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. Do not use. */
  readonly no_legal_proceedings?: InputMaybe<Scalars['Boolean']>;
  readonly non_bankrupt?: InputMaybe<Scalars['Boolean']>;
  readonly security_protections?: InputMaybe<Scalars['Boolean']>;
};

export type Insurance_Api_ContentsInsurancePremium_AssumptionsOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_AssumptionsOutput';
  readonly claims_amount?: Maybe<Scalars['Boolean']>;
  readonly claims_num?: Maybe<Scalars['Boolean']>;
  readonly home_terms?: Maybe<Scalars['Boolean']>;
  readonly no_legal_proceedings?: Maybe<Scalars['Boolean']>;
  readonly non_bankrupt?: Maybe<Scalars['Boolean']>;
  readonly security_protections?: Maybe<Scalars['Boolean']>;
};

export type Insurance_Api_ContentsInsurancePremium_CalculationOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_CalculationOutput';
  readonly accidental_damage_amount: Scalars['Float'];
  readonly additional_amount: Scalars['Float'];
  readonly base_premium_amount: Scalars['Float'];
  readonly excess_amount: Scalars['Float'];
  readonly minimal_premium: Scalars['Float'];
  readonly net_premium: Scalars['Float'];
  readonly per_month_premium: Scalars['Float'];
  readonly tax_amount: Scalars['Float'];
  readonly total_premium: Scalars['Float'];
  readonly unspecified_amount: Scalars['Float'];
};

export type Insurance_Api_ContentsInsurancePremium_ConclusionOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_ConclusionOutput';
  readonly payment: Insurance_Api_PaymentOutput;
  readonly result: Insurance_Api_IsContentsInsurancePremium_Conclusion_ResultOneofOutput;
};

export type Insurance_Api_ContentsInsurancePremium_CoverInput = {
  readonly accidental_damage?: InputMaybe<Scalars['Boolean']>;
  readonly bicycles?: InputMaybe<ReadonlyArray<Insurance_Api_BicycleInput>>;
  readonly home_contents?: InputMaybe<Scalars['Float']>;
  readonly starting_on?: InputMaybe<Scalars['String']>;
  readonly valuable_items?: InputMaybe<ReadonlyArray<Insurance_Api_ValuableItemInput>>;
  readonly voluntary_excess?: InputMaybe<Scalars['Float']>;
};

export type Insurance_Api_ContentsInsurancePremium_CoverOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_CoverOutput';
  readonly accidental_damage?: Maybe<Scalars['Boolean']>;
  readonly bicycles?: Maybe<ReadonlyArray<Insurance_Api_BicycleOutput>>;
  readonly home_contents?: Maybe<Scalars['Float']>;
  readonly starting_on: Scalars['String'];
  readonly valuable_items?: Maybe<ReadonlyArray<Insurance_Api_ValuableItemOutput>>;
  readonly voluntary_excess?: Maybe<Scalars['Float']>;
};

export type Insurance_Api_ContentsInsurancePremium_DenialOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_DenialOutput';
  readonly reason: Insurance_Api_DenialReasonEnum;
  readonly validation: Insurance_Api_ContentsInsuranceValidationOutput;
};

export type Insurance_Api_ContentsInsurancePremium_LoadFactorsOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_LoadFactorsOutput';
  readonly bicycles: Scalars['Float'];
  readonly claim_discount: Scalars['Float'];
  readonly commission: Scalars['Float'];
  readonly unspecified: Scalars['Float'];
  readonly valuables: Insurance_Api_ContentsInsurancePremium_LoadFactorsValuablesOutput;
};

export type Insurance_Api_ContentsInsurancePremium_LoadFactorsValuablesOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_LoadFactorsValuablesOutput';
  readonly hearing_aids: Scalars['Float'];
  readonly jewelry_and_watches: Scalars['Float'];
  readonly laptops: Scalars['Float'];
  readonly mobile_phones: Scalars['Float'];
  readonly musical_instruments_non_prof: Scalars['Float'];
  readonly other: Scalars['Float'];
  readonly photographic_equipment: Scalars['Float'];
  readonly sports_equipment: Scalars['Float'];
};

export type Insurance_Api_ContentsInsurancePremium_PayResponseOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_PayResponseOutput';
  readonly client_secret: Scalars['String'];
  readonly session_id: Scalars['String'];
  readonly stripe_id: Scalars['String'];
  readonly stripe_url: Scalars['String'];
};

export type Insurance_Api_ContentsInsurancePremium_PolicyOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_PolicyOutput';
  readonly assumptions: Insurance_Api_ContentsInsurancePremium_AssumptionsOutput;
  readonly conclusion: Insurance_Api_ContentsInsurancePremium_ConclusionOutput;
  readonly cover: Insurance_Api_ContentsInsurancePremium_CoverOutput;
  readonly id: Scalars['String'];
  readonly load_factors: Insurance_Api_ContentsInsurancePremium_LoadFactorsOutput;
  readonly preconditions: Insurance_Api_ContentsInsurancePremium_PreconditionsOutput;
  readonly user: Scalars['String'];
};

export type Insurance_Api_ContentsInsurancePremium_PreconditionsInput = {
  readonly adults_num?: InputMaybe<Scalars['Int']>;
  readonly claims?: InputMaybe<ReadonlyArray<Insurance_Api_ClaimInput>>;
  readonly partner?: InputMaybe<Insurance_Api_PersonInput>;
  readonly policy_holders?: InputMaybe<ReadonlyArray<Insurance_Api_PersonInput>>;
  readonly property_details?: InputMaybe<Insurance_Api_PropertyDetailsInput>;
  readonly property_id?: InputMaybe<Scalars['String']>;
  readonly property_location?: InputMaybe<Insurance_Api_PropertyLocationInput>;
  readonly proposer?: InputMaybe<Insurance_Api_PersonInput>;
  readonly tenancy_years_num?: InputMaybe<Scalars['Int']>;
};

export type Insurance_Api_ContentsInsurancePremium_PreconditionsOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_PreconditionsOutput';
  readonly adults_num?: Maybe<Scalars['Int']>;
  readonly claims?: Maybe<ReadonlyArray<Insurance_Api_ClaimOutput>>;
  readonly partner: Insurance_Api_PersonOutput;
  readonly policy_holders?: Maybe<ReadonlyArray<Insurance_Api_PersonOutput>>;
  readonly property_details: Insurance_Api_PropertyDetailsOutput;
  readonly property_id?: Maybe<Scalars['String']>;
  readonly property_location: Insurance_Api_PropertyLocationOutput;
  readonly proposer: Insurance_Api_PersonOutput;
  readonly tenancy_years_num?: Maybe<Scalars['Int']>;
};

export type Insurance_Api_ContentsInsurancePremium_RegisterUserResponseOutput = {
  readonly __typename: 'insurance_api_ContentsInsurancePremium_RegisterUserResponseOutput';
  readonly policy: Insurance_Api_ContentsInsurancePremium_PolicyOutput;
};

export type Insurance_Api_ContentsInsuranceValidationOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidationOutput';
  readonly cover: Insurance_Api_ContentsInsuranceValidation_CoverOutput;
  readonly preconditions: Insurance_Api_ContentsInsuranceValidation_PreconditionsOutput;
};

export type Insurance_Api_ContentsInsuranceValidation_BicycleOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_BicycleOutput';
  readonly description: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly value: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export type Insurance_Api_ContentsInsuranceValidation_CoverOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_CoverOutput';
  readonly accidental_damage: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly bicycles?: Maybe<ReadonlyArray<Insurance_Api_ContentsInsuranceValidation_BicycleOutput>>;
  readonly home_contents: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly starting_on: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly valuable_items?: Maybe<ReadonlyArray<Insurance_Api_ContentsInsuranceValidation_ValuableItemOutput>>;
  readonly voluntary_excess: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export enum Insurance_Api_ContentsInsuranceValidation_ErrorEnum {
  ErrorInvalid = 'ERROR_INVALID',
  ErrorUnacceptable = 'ERROR_UNACCEPTABLE',
  ErrorUnknown = 'ERROR_UNKNOWN'
}

export type Insurance_Api_ContentsInsuranceValidation_FieldOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_FieldOutput';
  readonly errors?: Maybe<ReadonlyArray<Insurance_Api_ContentsInsuranceValidation_ErrorEnum>>;
  readonly message: Scalars['String'];
};

export type Insurance_Api_ContentsInsuranceValidation_PersonOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_PersonOutput';
  readonly birth_date: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly employment_status: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly first_name: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly last_name: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly profession: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly title: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export type Insurance_Api_ContentsInsuranceValidation_PreconditionsOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_PreconditionsOutput';
  readonly adults_num: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly claims: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly partner: Insurance_Api_ContentsInsuranceValidation_PersonOutput;
  readonly policy_holders?: Maybe<ReadonlyArray<Insurance_Api_ContentsInsuranceValidation_PersonOutput>>;
  readonly property_details: Insurance_Api_ContentsInsuranceValidation_PropertyDetailsOutput;
  readonly property_id: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly property_location: Insurance_Api_ContentsInsuranceValidation_PropertyLocationOutput;
  readonly proposer: Insurance_Api_ContentsInsuranceValidation_PersonOutput;
  readonly tenancy_years_num: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export type Insurance_Api_ContentsInsuranceValidation_PropertyDetailsOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_PropertyDetailsOutput';
  readonly bedrooms_num: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly door_lock: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly door_material: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly property_subtype: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly property_type: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly windows_have_locks: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export type Insurance_Api_ContentsInsuranceValidation_PropertyLocationOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_PropertyLocationOutput';
  readonly address: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly address1: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly address2: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly address3: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly address4: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly building: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly city: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly country: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly flat: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly postcode: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly street: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly udprn: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export type Insurance_Api_ContentsInsuranceValidation_ValuableItemOutput = {
  readonly __typename: 'insurance_api_ContentsInsuranceValidation_ValuableItemOutput';
  readonly category: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly description: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
  readonly value: Insurance_Api_ContentsInsuranceValidation_FieldOutput;
};

export enum Insurance_Api_DenialReasonEnum {
  DenialReasonAdultsNum = 'DENIAL_REASON_ADULTS_NUM',
  DenialReasonBedroomsNum = 'DENIAL_REASON_BEDROOMS_NUM',
  DenialReasonBicyclesTotalValue = 'DENIAL_REASON_BICYCLES_TOTAL_VALUE',
  DenialReasonBicycleValue = 'DENIAL_REASON_BICYCLE_VALUE',
  DenialReasonBuildingsArea = 'DENIAL_REASON_BUILDINGS_AREA',
  DenialReasonClaimsFreeYears = 'DENIAL_REASON_CLAIMS_FREE_YEARS',
  DenialReasonClaimsNum = 'DENIAL_REASON_CLAIMS_NUM',
  DenialReasonDoorsWindowsCheck = 'DENIAL_REASON_DOORS_WINDOWS_CHECK',
  DenialReasonFloodRisk = 'DENIAL_REASON_FLOOD_RISK',
  DenialReasonHomeContentsCoverValue = 'DENIAL_REASON_HOME_CONTENTS_COVER_VALUE',
  DenialReasonInsufficientData = 'DENIAL_REASON_INSUFFICIENT_DATA',
  DenialReasonItemsTotalValue = 'DENIAL_REASON_ITEMS_TOTAL_VALUE',
  DenialReasonItemValue = 'DENIAL_REASON_ITEM_VALUE',
  DenialReasonLaptopValue = 'DENIAL_REASON_LAPTOP_VALUE',
  DenialReasonLocationPostcode = 'DENIAL_REASON_LOCATION_POSTCODE',
  DenialReasonPartnerProfession = 'DENIAL_REASON_PARTNER_PROFESSION',
  DenialReasonPolicyHolderEmployment = 'DENIAL_REASON_POLICY_HOLDER_EMPLOYMENT',
  DenialReasonProposerAge = 'DENIAL_REASON_PROPOSER_AGE',
  DenialReasonProposerEmployment = 'DENIAL_REASON_PROPOSER_EMPLOYMENT',
  DenialReasonProposerProfession = 'DENIAL_REASON_PROPOSER_PROFESSION',
  DenialReasonSubsidenceRisk = 'DENIAL_REASON_SUBSIDENCE_RISK',
  DenialReasonUnknown = 'DENIAL_REASON_UNKNOWN',
  DenialReasonValuableItemCategory = 'DENIAL_REASON_VALUABLE_ITEM_CATEGORY',
  DenialReasonVoluntaryExcessValue = 'DENIAL_REASON_VOLUNTARY_EXCESS_VALUE'
}

export enum Insurance_Api_DoorLockTypeEnum {
  DoorLockTypeFiveLeverMorticeDeadlock = 'DOOR_LOCK_TYPE_FIVE_LEVER_MORTICE_DEADLOCK',
  DoorLockTypeOther = 'DOOR_LOCK_TYPE_OTHER',
  DoorLockTypeThreePointLocking = 'DOOR_LOCK_TYPE_THREE_POINT_LOCKING',
  DoorLockTypeUnknown = 'DOOR_LOCK_TYPE_UNKNOWN'
}

export enum Insurance_Api_DoorMaterialTypeEnum {
  DoorMaterialTypeOther = 'DOOR_MATERIAL_TYPE_OTHER',
  DoorMaterialTypeUnknown = 'DOOR_MATERIAL_TYPE_UNKNOWN',
  DoorMaterialTypeUpvcAluminiumComposite = 'DOOR_MATERIAL_TYPE_UPVC_ALUMINIUM_COMPOSITE',
  DoorMaterialTypeWood = 'DOOR_MATERIAL_TYPE_WOOD'
}

export enum Insurance_Api_EmploymentStatusEnum {
  EmploymentStatusEmployed = 'EMPLOYMENT_STATUS_EMPLOYED',
  EmploymentStatusHouseKeeper = 'EMPLOYMENT_STATUS_HOUSE_KEEPER',
  EmploymentStatusIndependentMeans = 'EMPLOYMENT_STATUS_INDEPENDENT_MEANS',
  EmploymentStatusRetired = 'EMPLOYMENT_STATUS_RETIRED',
  EmploymentStatusSelfEmployed = 'EMPLOYMENT_STATUS_SELF_EMPLOYED',
  EmploymentStatusStudent = 'EMPLOYMENT_STATUS_STUDENT',
  EmploymentStatusUnemployed = 'EMPLOYMENT_STATUS_UNEMPLOYED',
  EmploymentStatusUnknown = 'EMPLOYMENT_STATUS_UNKNOWN'
}

export type Insurance_Api_GpsInput = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
};

export type Insurance_Api_GpsOutput = {
  readonly __typename: 'insurance_api_GPSOutput';
  readonly latitude?: Maybe<Scalars['Float']>;
  readonly longitude?: Maybe<Scalars['Float']>;
};

export type Insurance_Api_PaymentOutput = {
  readonly __typename: 'insurance_api_PaymentOutput';
  readonly order?: Maybe<Invoice_Api_OrderOutput>;
  readonly order_id: Scalars['String'];
  readonly placeholder: Scalars['Boolean'];
};

export type Insurance_Api_PersonInput = {
  readonly birth_date?: InputMaybe<Scalars['String']>;
  readonly email?: InputMaybe<Scalars['String']>;
  readonly employment_status?: InputMaybe<Insurance_Api_EmploymentStatusEnum>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly profession?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type Insurance_Api_PersonOutput = {
  readonly __typename: 'insurance_api_PersonOutput';
  readonly birth_date: Scalars['String'];
  readonly email?: Maybe<Scalars['String']>;
  readonly employment_status: Insurance_Api_EmploymentStatusEnum;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly phone?: Maybe<Scalars['String']>;
  readonly profession?: Maybe<Scalars['String']>;
  readonly title?: Maybe<Scalars['String']>;
};

export type Insurance_Api_ProfessionOutput = {
  readonly __typename: 'insurance_api_ProfessionOutput';
  readonly acceptable: Scalars['Boolean'];
  readonly name: Scalars['String'];
};

export type Insurance_Api_ProfessionsLookupResponseOutput = {
  readonly __typename: 'insurance_api_ProfessionsLookupResponseOutput';
  readonly professions?: Maybe<ReadonlyArray<Insurance_Api_ProfessionOutput>>;
};

export type Insurance_Api_PropertyDetailsInput = {
  readonly bedrooms_num?: InputMaybe<Scalars['Int']>;
  readonly door_lock?: InputMaybe<Insurance_Api_DoorLockTypeEnum>;
  readonly door_material?: InputMaybe<Insurance_Api_DoorMaterialTypeEnum>;
  readonly property_subtype?: InputMaybe<Insurance_Api_PropertySubtypeEnum>;
  readonly property_type?: InputMaybe<Insurance_Api_PropertyTypeEnum>;
  readonly windows_have_locks?: InputMaybe<Scalars['Boolean']>;
};

export type Insurance_Api_PropertyDetailsOutput = {
  readonly __typename: 'insurance_api_PropertyDetailsOutput';
  readonly bedrooms_num?: Maybe<Scalars['Int']>;
  readonly door_lock?: Maybe<Insurance_Api_DoorLockTypeEnum>;
  readonly door_material?: Maybe<Insurance_Api_DoorMaterialTypeEnum>;
  readonly property_subtype?: Maybe<Insurance_Api_PropertySubtypeEnum>;
  readonly property_type?: Maybe<Insurance_Api_PropertyTypeEnum>;
  readonly windows_have_locks?: Maybe<Scalars['Boolean']>;
};

export type Insurance_Api_PropertyLocationInput = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly address3?: InputMaybe<Scalars['String']>;
  readonly address4?: InputMaybe<Scalars['String']>;
  readonly building?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly flat?: InputMaybe<Scalars['String']>;
  readonly gps?: InputMaybe<Insurance_Api_GpsInput>;
  readonly postcode?: InputMaybe<Scalars['String']>;
  readonly street?: InputMaybe<Scalars['String']>;
  readonly udprn?: InputMaybe<Scalars['String']>;
};

export type Insurance_Api_PropertyLocationOutput = {
  readonly __typename: 'insurance_api_PropertyLocationOutput';
  readonly address?: Maybe<Scalars['String']>;
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly address4?: Maybe<Scalars['String']>;
  readonly building?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly flat?: Maybe<Scalars['String']>;
  readonly gps: Insurance_Api_GpsOutput;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly udprn?: Maybe<Scalars['String']>;
};

export enum Insurance_Api_PropertySubtypeEnum {
  PropertySubtypeBasement = 'PROPERTY_SUBTYPE_BASEMENT',
  PropertySubtypeBedsit = 'PROPERTY_SUBTYPE_BEDSIT',
  PropertySubtypeChalet = 'PROPERTY_SUBTYPE_CHALET',
  PropertySubtypeDetached = 'PROPERTY_SUBTYPE_DETACHED',
  PropertySubtypeEndTerrace = 'PROPERTY_SUBTYPE_END_TERRACE',
  PropertySubtypeFirstFloorOrAbove = 'PROPERTY_SUBTYPE_FIRST_FLOOR_OR_ABOVE',
  PropertySubtypeGroundFloor = 'PROPERTY_SUBTYPE_GROUND_FLOOR',
  PropertySubtypeHallsOfResidence = 'PROPERTY_SUBTYPE_HALLS_OF_RESIDENCE',
  PropertySubtypeHouseBoat = 'PROPERTY_SUBTYPE_HOUSE_BOAT',
  PropertySubtypeLinkDetached = 'PROPERTY_SUBTYPE_LINK_DETACHED',
  PropertySubtypeMobileHome = 'PROPERTY_SUBTYPE_MOBILE_HOME',
  PropertySubtypeParkHome = 'PROPERTY_SUBTYPE_PARK_HOME',
  PropertySubtypeSemiDetached = 'PROPERTY_SUBTYPE_SEMI_DETACHED',
  PropertySubtypeTerrace = 'PROPERTY_SUBTYPE_TERRACE',
  PropertySubtypeUnknown = 'PROPERTY_SUBTYPE_UNKNOWN'
}

export enum Insurance_Api_PropertyTypeEnum {
  PropertyTypeBungalow = 'PROPERTY_TYPE_BUNGALOW',
  PropertyTypeFlatApartment = 'PROPERTY_TYPE_FLAT_APARTMENT',
  PropertyTypeHouse = 'PROPERTY_TYPE_HOUSE',
  PropertyTypeOther = 'PROPERTY_TYPE_OTHER',
  PropertyTypeUnknown = 'PROPERTY_TYPE_UNKNOWN'
}

export enum Insurance_Api_ValuableItemCategoryEnum {
  ValuableItemCategoryDrones = 'VALUABLE_ITEM_CATEGORY_DRONES',
  ValuableItemCategoryGuns = 'VALUABLE_ITEM_CATEGORY_GUNS',
  ValuableItemCategoryHearingAids = 'VALUABLE_ITEM_CATEGORY_HEARING_AIDS',
  ValuableItemCategoryJewelryAndWatches = 'VALUABLE_ITEM_CATEGORY_JEWELRY_AND_WATCHES',
  ValuableItemCategoryLaptopsAndPortableComputers = 'VALUABLE_ITEM_CATEGORY_LAPTOPS_AND_PORTABLE_COMPUTERS',
  ValuableItemCategoryMobilePhones = 'VALUABLE_ITEM_CATEGORY_MOBILE_PHONES',
  ValuableItemCategoryMusicalInstrumentsNonProf = 'VALUABLE_ITEM_CATEGORY_MUSICAL_INSTRUMENTS_NON_PROF',
  ValuableItemCategoryMusicalInstrumentsProf = 'VALUABLE_ITEM_CATEGORY_MUSICAL_INSTRUMENTS_PROF',
  ValuableItemCategoryOther = 'VALUABLE_ITEM_CATEGORY_OTHER',
  ValuableItemCategoryPhotographicEquipment = 'VALUABLE_ITEM_CATEGORY_PHOTOGRAPHIC_EQUIPMENT',
  ValuableItemCategorySportsEquipment = 'VALUABLE_ITEM_CATEGORY_SPORTS_EQUIPMENT',
  ValuableItemCategoryUnknown = 'VALUABLE_ITEM_CATEGORY_UNKNOWN'
}

export type Insurance_Api_ValuableItemInput = {
  readonly category?: InputMaybe<Insurance_Api_ValuableItemCategoryEnum>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Float']>;
};

export type Insurance_Api_ValuableItemOutput = {
  readonly __typename: 'insurance_api_ValuableItemOutput';
  readonly category: Insurance_Api_ValuableItemCategoryEnum;
  readonly description: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type Insurance_Api_IsContentsInsurancePremium_Conclusion_ResultOneofOutput = {
  readonly __typename: 'insurance_api_isContentsInsurancePremium_Conclusion_ResultOneofOutput';
  readonly conclusion__calculation?: Maybe<Insurance_Api_ContentsInsurancePremium_CalculationOutput>;
  readonly conclusion__denial?: Maybe<Insurance_Api_ContentsInsurancePremium_DenialOutput>;
  readonly conclusion__incomplete?: Maybe<Scalars['Boolean']>;
};

export enum Invitation_Api_ContactValidationTypeEnum {
  ContactValidationEmail = 'CONTACT_VALIDATION_EMAIL',
  ContactValidationPhone = 'CONTACT_VALIDATION_PHONE',
  ContactValidationUnknown = 'CONTACT_VALIDATION_UNKNOWN'
}

export type Invitation_Api_CreateInvitationResponseOutput = {
  readonly __typename: 'invitation_api_CreateInvitationResponseOutput';
  readonly invitation_id: Scalars['String'];
  readonly otp: Scalars['String'];
  readonly type: Invitation_Api_InvitationTypeEnum;
};

export enum Invitation_Api_InvitationActionErrorEnum {
  AlreadyAccepted = 'ALREADY_ACCEPTED',
  AlreadyCanceled = 'ALREADY_CANCELED',
  AlreadyDeclined = 'ALREADY_DECLINED',
  DoesNotExist = 'DOES_NOT_EXIST',
  Expired = 'EXPIRED',
  UnknownError = 'UNKNOWN_ERROR'
}

export type Invitation_Api_InvitationActionResponseOutput = {
  readonly __typename: 'invitation_api_InvitationActionResponseOutput';
  readonly error: Invitation_Api_InvitationActionErrorEnum;
  readonly info: Invitation_Api_InvitationInfoOutput;
  readonly success: Scalars['Boolean'];
};

export type Invitation_Api_InvitationInfoOutput = {
  readonly __typename: 'invitation_api_InvitationInfoOutput';
  readonly body: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly expires_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly invitee_email: Scalars['String'];
  readonly invitee_first_name: Scalars['String'];
  readonly invitee_id: Scalars['String'];
  readonly invitee_last_name: Scalars['String'];
  readonly invitee_phone: Scalars['String'];
  readonly inviter_id: Scalars['String'];
  readonly message: Scalars['String'];
  readonly offer: Scalars['String'];
  readonly property_id: Scalars['String'];
  readonly status: Invitation_Api_InvitationStatusEnum;
  readonly type: Invitation_Api_InvitationTypeEnum;
  readonly updated_at: Scalars['String'];
};

export type Invitation_Api_InvitationListResponseOutput = {
  readonly __typename: 'invitation_api_InvitationListResponseOutput';
  readonly invitations?: Maybe<ReadonlyArray<Invitation_Api_InvitationInfoOutput>>;
};

export enum Invitation_Api_InvitationStatusEnum {
  InvitationStatusAccepted = 'INVITATION_STATUS_ACCEPTED',
  InvitationStatusCanceled = 'INVITATION_STATUS_CANCELED',
  InvitationStatusDeclined = 'INVITATION_STATUS_DECLINED',
  InvitationStatusPending = 'INVITATION_STATUS_PENDING',
  InvitationStatusTimeout = 'INVITATION_STATUS_TIMEOUT',
  InvitationStatusUnknown = 'INVITATION_STATUS_UNKNOWN'
}

export enum Invitation_Api_InvitationTypeEnum {
  InvitationTypeLandlordToManager = 'INVITATION_TYPE_LANDLORD_TO_MANAGER',
  InvitationTypeRelatedToLandlord = 'INVITATION_TYPE_RELATED_TO_LANDLORD',
  InvitationTypeRelatedToTenant = 'INVITATION_TYPE_RELATED_TO_TENANT',
  InvitationTypeUnknown = 'INVITATION_TYPE_UNKNOWN'
}

export type Invitation_Api_UserInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly user_id?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_CreateRemoteSalePaymentResponseOutput = {
  readonly __typename: 'invoice_api_CreateRemoteSalePaymentResponseOutput';
  readonly stripe_token: Scalars['String'];
};

export type Invoice_Api_DownloadReportResponseOutput = {
  readonly __typename: 'invoice_api_DownloadReportResponseOutput';
  readonly file: Scalars['String'];
};

export type Invoice_Api_InvoiceCreateResponseOutput = {
  readonly __typename: 'invoice_api_InvoiceCreateResponseOutput';
  readonly invoice: Invoice_Api_InvoiceOutput;
};

export type Invoice_Api_InvoiceFilterListResponseOutput = {
  readonly __typename: 'invoice_api_InvoiceFilterListResponseOutput';
  readonly invoices?: Maybe<ReadonlyArray<Invoice_Api_InvoiceOutput>>;
  readonly total: Scalars['Int'];
};

export type Invoice_Api_InvoiceListResponseOutput = {
  readonly __typename: 'invoice_api_InvoiceListResponseOutput';
  readonly invoices?: Maybe<ReadonlyArray<Invoice_Api_InvoiceOutput>>;
};

export type Invoice_Api_InvoiceOutput = {
  readonly __typename: 'invoice_api_InvoiceOutput';
  /** @deprecated use administrator */
  readonly admin: Scalars['String'];
  readonly administrator?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly amount: Scalars['Float'];
  readonly correlation_id: Scalars['String'];
  readonly correlation_target: Scalars['String'];
  readonly correlation_type: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly invoice_id: Scalars['String'];
  readonly ivr: Scalars['String'];
  readonly listing: Scalars['String'];
  readonly order?: Maybe<Invoice_Api_OrderOutput>;
  readonly order_id: Scalars['String'];
  readonly payments?: Maybe<ReadonlyArray<Invoice_Api_PaymentOutput>>;
  readonly periodic: Scalars['Boolean'];
  readonly properties: Invoice_Api_InvoicePropertiesOutput;
  readonly property: Scalars['String'];
  readonly property_data?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly reference: Scalars['String'];
  readonly render: Scalars['String'];
  readonly services?: Maybe<ReadonlyArray<Extras_Api_ServiceSnapshotStatusOutput>>;
  readonly setup: Scalars['Boolean'];
  readonly status: Invoice_Api_InvoiceStatusEnum;
  readonly type: Invoice_Api_InvoiceTypeEnum;
  readonly updated_at: Scalars['String'];
  readonly user: Scalars['String'];
  readonly user_data?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly xero_code?: Maybe<Scalars['String']>;
};

export type Invoice_Api_InvoicePropertiesInput = {
  readonly corresp_address?: InputMaybe<Scalars['String']>;
  readonly insurance_start?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_InvoicePropertiesOutput = {
  readonly __typename: 'invoice_api_InvoicePropertiesOutput';
  readonly corresp_address: Scalars['String'];
  readonly insurance_start: Scalars['String'];
};

export enum Invoice_Api_InvoiceStatusEnum {
  InvoiceCanceled = 'INVOICE_CANCELED',
  InvoiceFulfilled = 'INVOICE_FULFILLED',
  InvoiceOpen = 'INVOICE_OPEN',
  UnknownInvoiceStatus = 'UNKNOWN_INVOICE_STATUS'
}

export enum Invoice_Api_InvoiceTypeEnum {
  InvoiceTypePayment = 'INVOICE_TYPE_PAYMENT',
  InvoiceTypePayout = 'INVOICE_TYPE_PAYOUT',
  InvoiceTypeRolling = 'INVOICE_TYPE_ROLLING',
  InvoiceTypeUnknown = 'INVOICE_TYPE_UNKNOWN'
}

export type Invoice_Api_LocationInput = {
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly address3?: InputMaybe<Scalars['String']>;
  readonly address4?: InputMaybe<Scalars['String']>;
  readonly building?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly flat?: InputMaybe<Scalars['String']>;
  readonly postcode?: InputMaybe<Scalars['String']>;
  readonly street?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_LocationOutput = {
  readonly __typename: 'invoice_api_LocationOutput';
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly address4?: Maybe<Scalars['String']>;
  readonly building?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly flat?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
};

export type Invoice_Api_OrderCommitResponseOutput = {
  readonly __typename: 'invoice_api_OrderCommitResponseOutput';
  readonly invoice: Invoice_Api_InvoiceOutput;
  readonly order: Invoice_Api_OrderOutput;
};

export type Invoice_Api_OrderListResponseOutput = {
  readonly __typename: 'invoice_api_OrderListResponseOutput';
  readonly orders?: Maybe<ReadonlyArray<Invoice_Api_OrderOutput>>;
  readonly total: Scalars['Int'];
};

export type Invoice_Api_OrderLoadResponseOutput = {
  readonly __typename: 'invoice_api_OrderLoadResponseOutput';
  readonly order: Invoice_Api_OrderOutput;
};

export type Invoice_Api_OrderOutput = {
  readonly __typename: 'invoice_api_OrderOutput';
  readonly correlation_id: Scalars['String'];
  readonly correlation_target: Scalars['String'];
  readonly correlation_type: Scalars['String'];
  readonly created: Scalars['String'];
  readonly id: Scalars['String'];
  readonly invoices: ReadonlyArray<Invoice_Api_InvoiceOutput>;
  readonly order_type: Invoice_Api_OrderTypeEnum;
  readonly periodic: Scalars['Boolean'];
  readonly products?: Maybe<ReadonlyArray<Invoice_Api_ProductOutput>>;
  readonly source: Invoice_Api_OrderSourceEnum;
  readonly status: Invoice_Api_OrderStatusEnum;
  readonly updated: Scalars['String'];
  readonly user_data?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly userdata: Invoice_Api_UserDataOutput;
  readonly userid: Scalars['String'];
};

export type Invoice_Api_OrderSaveResponseOutput = {
  readonly __typename: 'invoice_api_OrderSaveResponseOutput';
  readonly order: Invoice_Api_OrderOutput;
};

export enum Invoice_Api_OrderSourceEnum {
  /** @deprecated deprecated */
  Documents = 'DOCUMENTS',
  Ecommerce = 'ECOMMERCE',
  External = 'EXTERNAL',
  Listing = 'LISTING',
  Offer = 'OFFER',
  /** @deprecated deprecated */
  Online = 'ONLINE',
  RemoteSales = 'REMOTE_SALES',
  RentCollection = 'RENT_COLLECTION',
  Unknown = 'UNKNOWN'
}

export enum Invoice_Api_OrderStatusEnum {
  OrderActive = 'ORDER_ACTIVE',
  OrderCancelled = 'ORDER_CANCELLED',
  OrderPaid = 'ORDER_PAID',
  OrderPending = 'ORDER_PENDING',
  UnknownOrderStatus = 'UNKNOWN_ORDER_STATUS'
}

export enum Invoice_Api_OrderTypeEnum {
  ContentsInsuranceOrder = 'CONTENTS_INSURANCE_ORDER',
  IndividualOrder = 'INDIVIDUAL_ORDER',
  OrderTypeUnknown = 'ORDER_TYPE_UNKNOWN',
  RegularOrder = 'REGULAR_ORDER'
}

export type Invoice_Api_PaymentCreateResponseOutput = {
  readonly __typename: 'invoice_api_PaymentCreateResponseOutput';
  readonly data?: Maybe<Invoice_Api_IsPaymentSystemDataResponse_DataOneofOutput>;
  readonly payment: Invoice_Api_PaymentOutput;
};

export type Invoice_Api_PaymentFilterListResponseOutput = {
  readonly __typename: 'invoice_api_PaymentFilterListResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Invoice_Api_PaymentOutput>>;
  readonly total: Scalars['Int'];
};

export type Invoice_Api_PaymentOutput = {
  readonly __typename: 'invoice_api_PaymentOutput';
  readonly amount: Scalars['Float'];
  readonly correlation_id: Scalars['String'];
  readonly correlation_target: Scalars['String'];
  readonly correlation_type: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly invoice?: Maybe<Invoice_Api_InvoiceOutput>;
  readonly invoice_id: Scalars['String'];
  readonly invoice_type: Invoice_Api_InvoiceTypeEnum;
  readonly ivr: Scalars['String'];
  readonly listing: Scalars['String'];
  readonly matches?: Maybe<ReadonlyArray<Invoice_Api_TransactionPaymentMatchOutput>>;
  readonly overdue_payment_notice_count?: Maybe<Scalars['Int']>;
  readonly payment_id: Scalars['String'];
  readonly periodic: Scalars['Boolean'];
  readonly property?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly reference: Scalars['String'];
  readonly scheduled_at: Scalars['String'];
  readonly serial: Scalars['Int'];
  readonly status: Invoice_Api_PaymentStatusEnum;
  readonly system: Invoice_Api_PaymentSystemDataOutput;
  readonly transaction_id: Scalars['String'];
  readonly updated_at: Scalars['String'];
  readonly user: Scalars['String'];
  readonly user_data?: Maybe<Account_Api_AccountEnrichedOutput>;
};

export enum Invoice_Api_PaymentStatusEnum {
  PaymentCanceled = 'PAYMENT_CANCELED',
  PaymentConfirm = 'PAYMENT_CONFIRM',
  PaymentEstablished = 'PAYMENT_ESTABLISHED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSubscribed = 'PAYMENT_SUBSCRIBED',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  UnknownPaymentStatus = 'UNKNOWN_PAYMENT_STATUS'
}

export type Invoice_Api_PaymentSystemBankOutput = {
  readonly __typename: 'invoice_api_PaymentSystemBankOutput';
  readonly request?: Maybe<Scalars['Boolean']>;
  readonly response?: Maybe<Scalars['Boolean']>;
};

export type Invoice_Api_PaymentSystemDataOutput = {
  readonly __typename: 'invoice_api_PaymentSystemDataOutput';
  readonly data: Invoice_Api_IsPaymentSystemData_DataOneofOutput;
  readonly system_reference: Scalars['String'];
};

export type Invoice_Api_PaymentSystemDuologiOutput = {
  readonly __typename: 'invoice_api_PaymentSystemDuologiOutput';
  readonly request: Invoice_Api_PaymentSystemDuologiRequestOutput;
  readonly response: Invoice_Api_PaymentSystemDuologiResponseOutput;
};

export type Invoice_Api_PaymentSystemDuologiRequestInput = {
  readonly country?: InputMaybe<Scalars['String']>;
  readonly deposit?: InputMaybe<Scalars['Float']>;
  readonly finance?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_PaymentSystemDuologiRequestOutput = {
  readonly __typename: 'invoice_api_PaymentSystemDuologiRequestOutput';
  readonly country: Scalars['String'];
  readonly deposit: Scalars['Float'];
  readonly finance: Scalars['String'];
};

export type Invoice_Api_PaymentSystemDuologiResponseOutput = {
  readonly __typename: 'invoice_api_PaymentSystemDuologiResponseOutput';
  readonly application_id: Scalars['String'];
  readonly token: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Invoice_Api_PaymentSystemPlaidOutput = {
  readonly __typename: 'invoice_api_PaymentSystemPlaidOutput';
  readonly request: Invoice_Api_PaymentSystemPlaidRequestOutput;
  readonly response?: Maybe<Scalars['String']>;
};

export type Invoice_Api_PaymentSystemPlaidRequestInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly end_at?: InputMaybe<Scalars['String']>;
  readonly execution_day?: InputMaybe<Scalars['Int']>;
  readonly frequency?: InputMaybe<Invoice_Api_PeriodicPaymentFrequencyEnum>;
  readonly start_at?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_PaymentSystemPlaidRequestOutput = {
  readonly __typename: 'invoice_api_PaymentSystemPlaidRequestOutput';
  readonly amount: Scalars['Float'];
  readonly description: Scalars['String'];
  readonly end_at: Scalars['String'];
  readonly execution_day: Scalars['Int'];
  readonly frequency: Invoice_Api_PeriodicPaymentFrequencyEnum;
  readonly start_at: Scalars['String'];
};

export type Invoice_Api_PaymentSystemStripeOutput = {
  readonly __typename: 'invoice_api_PaymentSystemStripeOutput';
  readonly request: Invoice_Api_PaymentSystemStripeRequestOutput;
  readonly response: Invoice_Api_PaymentSystemStripeResponseOutput;
};

export type Invoice_Api_PaymentSystemStripeRequestInput = {
  readonly daily_amount?: InputMaybe<Scalars['Float']>;
  readonly end_at?: InputMaybe<Scalars['String']>;
  readonly frequency?: InputMaybe<Invoice_Api_PeriodicPaymentFrequencyEnum>;
  readonly prepaid_amount?: InputMaybe<Scalars['Float']>;
  readonly redirect_cancel?: InputMaybe<Scalars['String']>;
  readonly redirect_success?: InputMaybe<Scalars['String']>;
  readonly start_at?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Cardpayment_Api_PaymentTypeEnum>;
};

export type Invoice_Api_PaymentSystemStripeRequestOutput = {
  readonly __typename: 'invoice_api_PaymentSystemStripeRequestOutput';
  readonly daily_amount: Scalars['Float'];
  readonly end_at: Scalars['String'];
  readonly frequency: Invoice_Api_PeriodicPaymentFrequencyEnum;
  readonly prepaid_amount: Scalars['Float'];
  readonly redirect_cancel: Scalars['String'];
  readonly redirect_success: Scalars['String'];
  readonly start_at: Scalars['String'];
  readonly type: Cardpayment_Api_PaymentTypeEnum;
};

export type Invoice_Api_PaymentSystemStripeResponseOutput = {
  readonly __typename: 'invoice_api_PaymentSystemStripeResponseOutput';
  readonly client_secret: Scalars['String'];
  readonly reoccurrences?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly session_id: Scalars['String'];
  readonly stripe_id: Scalars['String'];
  readonly subscription_id: Scalars['String'];
  readonly subscription_url: Scalars['String'];
};

export type Invoice_Api_PaymentSystemTokenioOutput = {
  readonly __typename: 'invoice_api_PaymentSystemTokenioOutput';
  readonly request: Invoice_Api_PaymentSystemTokenioRequestOutput;
  readonly response: Invoice_Api_PaymentSystemTokenioResponseOutput;
};

export type Invoice_Api_PaymentSystemTokenioRequestInput = {
  readonly daily_amount?: InputMaybe<Scalars['Float']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly end_at?: InputMaybe<Scalars['String']>;
  readonly frequency?: InputMaybe<Invoice_Api_PeriodicPaymentFrequencyEnum>;
  /** Deprecated. Do not use. */
  readonly ivr?: InputMaybe<Scalars['String']>;
  readonly prepaid_amount?: InputMaybe<Scalars['Float']>;
  readonly purpose?: InputMaybe<Tokenio_Api_PaymentPurposeEnum>;
  readonly redirect_error?: InputMaybe<Scalars['String']>;
  readonly redirect_success?: InputMaybe<Scalars['String']>;
  readonly start_at?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_PaymentSystemTokenioRequestOutput = {
  readonly __typename: 'invoice_api_PaymentSystemTokenioRequestOutput';
  readonly daily_amount: Scalars['Float'];
  readonly description: Scalars['String'];
  readonly end_at: Scalars['String'];
  readonly frequency: Invoice_Api_PeriodicPaymentFrequencyEnum;
  readonly ivr: Scalars['String'];
  readonly prepaid_amount: Scalars['Float'];
  readonly purpose: Tokenio_Api_PaymentPurposeEnum;
  readonly redirect_error: Scalars['String'];
  readonly redirect_success: Scalars['String'];
  readonly start_at: Scalars['String'];
};

export type Invoice_Api_PaymentSystemTokenioResponseOutput = {
  readonly __typename: 'invoice_api_PaymentSystemTokenioResponseOutput';
  readonly csrf: Scalars['String'];
  readonly reoccurrences?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly url: Scalars['String'];
};

export enum Invoice_Api_PeriodicPaymentFrequencyEnum {
  FrequencyDaily = 'FREQUENCY_DAILY',
  FrequencyMonthly = 'FREQUENCY_MONTHLY',
  FrequencyQuarterly = 'FREQUENCY_QUARTERLY',
  FrequencyTwiceAYear = 'FREQUENCY_TWICE_A_YEAR',
  FrequencyTwiceMonthly = 'FREQUENCY_TWICE_MONTHLY',
  FrequencyTwiceWeekly = 'FREQUENCY_TWICE_WEEKLY',
  FrequencyUnknown = 'FREQUENCY_UNKNOWN',
  FrequencyWeekly = 'FREQUENCY_WEEKLY',
  FrequencyYearly = 'FREQUENCY_YEARLY'
}

export type Invoice_Api_ProductAddDocumentsResponseOutput = {
  readonly __typename: 'invoice_api_ProductAddDocumentsResponseOutput';
  readonly product: Invoice_Api_ProductOutput;
};

export type Invoice_Api_ProductDetailsContentsInsuranceInput = {
  readonly accidental?: InputMaybe<Scalars['Boolean']>;
  readonly claims?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ClaimInput>>;
  readonly endorsements?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ItemInput>>;
  readonly excess?: InputMaybe<Scalars['Float']>;
  readonly flood_claims_ex_clause?: InputMaybe<Scalars['Boolean']>;
  readonly insureds?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_InsuredInput>>;
  readonly pedal_cycles?: InputMaybe<Invoice_Api_ProductDetailsContentsInsurance_PedalCyclesInput>;
  readonly policy_id?: InputMaybe<Scalars['String']>;
  readonly property?: InputMaybe<Invoice_Api_ProductDetailsContentsInsurance_PropertyInput>;
  readonly specified?: InputMaybe<Invoice_Api_ProductDetailsContentsInsurance_SpecifiedInput>;
  readonly unspecified?: InputMaybe<Invoice_Api_ProductDetailsContentsInsurance_UnspecifiedInput>;
};

export type Invoice_Api_ProductDetailsContentsInsuranceOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsuranceOutput';
  readonly accidental?: Maybe<Scalars['Boolean']>;
  readonly claims?: Maybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ClaimOutput>>;
  readonly endorsements?: Maybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ItemOutput>>;
  readonly excess?: Maybe<Scalars['Float']>;
  readonly flood_claims_ex_clause: Scalars['Boolean'];
  readonly insureds?: Maybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_InsuredOutput>>;
  readonly pedal_cycles: Invoice_Api_ProductDetailsContentsInsurance_PedalCyclesOutput;
  readonly policy_id: Scalars['String'];
  readonly property: Invoice_Api_ProductDetailsContentsInsurance_PropertyOutput;
  readonly specified: Invoice_Api_ProductDetailsContentsInsurance_SpecifiedOutput;
  readonly unspecified: Invoice_Api_ProductDetailsContentsInsurance_UnspecifiedOutput;
};

export type Invoice_Api_ProductDetailsContentsInsurance_ClaimInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly date?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_ClaimOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_ClaimOutput';
  readonly amount: Scalars['Float'];
  readonly date: Scalars['String'];
  readonly type: Scalars['String'];
};

export type Invoice_Api_ProductDetailsContentsInsurance_InsuredInput = {
  readonly employment_status?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly occupation?: InputMaybe<Scalars['String']>;
  readonly title?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_InsuredOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_InsuredOutput';
  readonly employment_status: Scalars['String'];
  readonly first_name: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly occupation: Scalars['String'];
  readonly title: Scalars['String'];
};

export type Invoice_Api_ProductDetailsContentsInsurance_ItemInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Float']>;
  readonly wording?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_ItemOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_ItemOutput';
  readonly description: Scalars['String'];
  readonly name: Scalars['String'];
  readonly value: Scalars['Float'];
  readonly wording: Scalars['String'];
};

export type Invoice_Api_ProductDetailsContentsInsurance_PedalCyclesInput = {
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  readonly items?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ItemInput>>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_PedalCyclesOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_PedalCyclesOutput';
  readonly enabled: Scalars['Boolean'];
  readonly items?: Maybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ItemOutput>>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_PropertyInput = {
  readonly adults?: InputMaybe<Scalars['Int']>;
  readonly door_lock_type?: InputMaybe<Scalars['String']>;
  readonly has_door_locks?: InputMaybe<Scalars['Boolean']>;
  readonly has_partner?: InputMaybe<Scalars['Boolean']>;
  readonly has_window_locks?: InputMaybe<Scalars['Boolean']>;
  readonly subtype?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_PropertyOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_PropertyOutput';
  readonly adults: Scalars['Int'];
  readonly door_lock_type: Scalars['String'];
  readonly has_door_locks: Scalars['Boolean'];
  readonly has_partner: Scalars['Boolean'];
  readonly has_window_locks: Scalars['Boolean'];
  readonly subtype: Scalars['String'];
  readonly type: Scalars['String'];
};

export type Invoice_Api_ProductDetailsContentsInsurance_SpecifiedInput = {
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  readonly items?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ItemInput>>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_SpecifiedOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_SpecifiedOutput';
  readonly enabled: Scalars['Boolean'];
  readonly items?: Maybe<ReadonlyArray<Invoice_Api_ProductDetailsContentsInsurance_ItemOutput>>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_UnspecifiedInput = {
  readonly claim_limit?: InputMaybe<Scalars['Float']>;
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
};

export type Invoice_Api_ProductDetailsContentsInsurance_UnspecifiedOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContentsInsurance_UnspecifiedOutput';
  readonly claim_limit: Scalars['Float'];
  readonly enabled: Scalars['Boolean'];
};

export type Invoice_Api_ProductDetailsContractInput = {
  readonly conditions?: InputMaybe<Invoice_Api_RentConditionsInput>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly tenant?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsContractOutput = {
  readonly __typename: 'invoice_api_ProductDetailsContractOutput';
  readonly conditions: Invoice_Api_RentConditionsOutput;
  readonly id?: Maybe<Scalars['String']>;
  readonly tenant?: Maybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsInput = {
  readonly contents_insurance?: InputMaybe<Invoice_Api_ProductDetailsContentsInsuranceInput>;
  readonly contract?: InputMaybe<Invoice_Api_ProductDetailsContractInput>;
  readonly performance?: InputMaybe<Invoice_Api_ProductDetailsPerformanceInput>;
  readonly property?: InputMaybe<Invoice_Api_ProductDetailsPropertyInput>;
  readonly quantity?: InputMaybe<Scalars['Float']>;
  readonly time?: InputMaybe<Invoice_Api_ProductDetailsTimeInput>;
};

export type Invoice_Api_ProductDetailsOutput = {
  readonly __typename: 'invoice_api_ProductDetailsOutput';
  readonly contents_insurance: Invoice_Api_ProductDetailsContentsInsuranceOutput;
  readonly contract: Invoice_Api_ProductDetailsContractOutput;
  readonly performance: Invoice_Api_ProductDetailsPerformanceOutput;
  readonly property: Invoice_Api_ProductDetailsPropertyOutput;
  readonly quantity?: Maybe<Scalars['Float']>;
  readonly time: Invoice_Api_ProductDetailsTimeOutput;
};

export type Invoice_Api_ProductDetailsPerformanceInput = {
  /** Deprecated. Do not use. */
  readonly desired_performance_date?: InputMaybe<Scalars['String']>;
  readonly performed_date?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsPerformanceOutput = {
  readonly __typename: 'invoice_api_ProductDetailsPerformanceOutput';
  /** @deprecated Use time.start */
  readonly desired_performance_date: Scalars['String'];
  readonly performed_date: Scalars['String'];
};

export type Invoice_Api_ProductDetailsPropertyInput = {
  readonly details?: InputMaybe<Property_Api_PropertyDetailsInput>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly listing?: InputMaybe<Scalars['String']>;
  readonly location?: InputMaybe<Invoice_Api_LocationInput>;
};

export type Invoice_Api_ProductDetailsPropertyOutput = {
  readonly __typename: 'invoice_api_ProductDetailsPropertyOutput';
  readonly details: Property_Api_PropertyDetailsOutput;
  readonly id?: Maybe<Scalars['String']>;
  readonly listing?: Maybe<Scalars['String']>;
  readonly location: Invoice_Api_LocationOutput;
};

export type Invoice_Api_ProductDetailsTimeInput = {
  readonly end?: InputMaybe<Scalars['String']>;
  readonly start?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDetailsTimeOutput = {
  readonly __typename: 'invoice_api_ProductDetailsTimeOutput';
  readonly end: Scalars['String'];
  readonly start: Scalars['String'];
};

export type Invoice_Api_ProductDocumentInput = {
  readonly code?: InputMaybe<Scalars['String']>;
  readonly document_id?: InputMaybe<Scalars['String']>;
  readonly fileid?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductDocumentOutput = {
  readonly __typename: 'invoice_api_ProductDocumentOutput';
  readonly code: Scalars['String'];
  readonly document_id: Scalars['String'];
  readonly file?: Maybe<Document_Api_FileOutput>;
  readonly fileid: Scalars['String'];
};

export type Invoice_Api_ProductInput = {
  readonly attributes?: InputMaybe<ReadonlyArray<_String__StringInputMap>>;
  readonly details?: InputMaybe<Invoice_Api_ProductDetailsInput>;
  readonly documents?: InputMaybe<ReadonlyArray<Invoice_Api_ProductDocumentInput>>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly order_id?: InputMaybe<Scalars['String']>;
  readonly remote_sales_price?: InputMaybe<Scalars['Float']>;
  readonly remote_sales_tax?: InputMaybe<Scalars['Float']>;
  readonly service_id?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ProductListResponseOutput = {
  readonly __typename: 'invoice_api_ProductListResponseOutput';
  readonly product?: Maybe<ReadonlyArray<Invoice_Api_ProductOutput>>;
};

export type Invoice_Api_ProductOutput = {
  readonly __typename: 'invoice_api_ProductOutput';
  readonly details: Invoice_Api_ProductDetailsOutput;
  readonly documents?: Maybe<ReadonlyArray<Invoice_Api_ProductDocumentOutput>>;
  readonly id: Scalars['String'];
  readonly order_id: Scalars['String'];
  readonly remote_sales_price: Scalars['Float'];
  readonly remote_sales_tax: Scalars['Float'];
  readonly service?: Maybe<Extras_Api_ServiceSnapshotStatusOutput>;
  readonly service_id: Scalars['String'];
};

export type Invoice_Api_ProductRenderDocumentsResponseOutput = {
  readonly __typename: 'invoice_api_ProductRenderDocumentsResponseOutput';
  readonly product: Invoice_Api_ProductOutput;
};

export type Invoice_Api_RemoteSalePropertyInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly products?: InputMaybe<ReadonlyArray<Invoice_Api_ProductInput>>;
};

export type Invoice_Api_RentConditionsInput = {
  readonly rent?: InputMaybe<Scalars['Float']>;
  readonly rent_plan?: InputMaybe<Invoice_Api_RentPlanEnum>;
};

export type Invoice_Api_RentConditionsOutput = {
  readonly __typename: 'invoice_api_RentConditionsOutput';
  readonly rent?: Maybe<Scalars['Float']>;
  readonly rent_plan: Invoice_Api_RentPlanEnum;
};

export enum Invoice_Api_RentPlanEnum {
  RentPlanEssential = 'RENT_PLAN_ESSENTIAL',
  RentPlanLegacy = 'RENT_PLAN_LEGACY',
  RentPlanManual = 'RENT_PLAN_MANUAL',
  RentPlanSecured = 'RENT_PLAN_SECURED',
  RentPlanUnknown = 'RENT_PLAN_UNKNOWN'
}

export enum Invoice_Api_ReportFormatEnum {
  Bacs = 'BACS',
  Csv = 'CSV',
  UnknownReportFormat = 'UNKNOWN_REPORT_FORMAT'
}

export type Invoice_Api_ReportHeiAndDrpBordeauxInput = {
  readonly from_time?: InputMaybe<Scalars['String']>;
  readonly to_time?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ReportHeiBordeauxInput = {
  readonly from_time?: InputMaybe<Scalars['String']>;
  readonly to_time?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ReportRgiBordeauxInput = {
  readonly from_time?: InputMaybe<Scalars['String']>;
  readonly to_time?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_ReportTransactionsInput = {
  readonly format?: InputMaybe<Invoice_Api_ReportFormatEnum>;
  readonly invoice_id?: InputMaybe<Scalars['String']>;
};

export enum Invoice_Api_ReportTypeEnum {
  AddOnProducts = 'ADD_ON_PRODUCTS',
  InsuranceProducts = 'INSURANCE_PRODUCTS',
  UnknownReportType = 'UNKNOWN_REPORT_TYPE'
}

export type Invoice_Api_ServiceInvoiceUpdateResponseOutput = {
  readonly __typename: 'invoice_api_ServiceInvoiceUpdateResponseOutput';
  readonly invoice: Invoice_Api_InvoiceOutput;
};

export type Invoice_Api_ServiceItemStatusInput = {
  readonly item_id?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Extras_Api_ServiceStatusEnum>;
};

export type Invoice_Api_ServicePaymentCreateResponseOutput = {
  readonly __typename: 'invoice_api_ServicePaymentCreateResponseOutput';
  readonly payment: Invoice_Api_PaymentOutput;
};

export type Invoice_Api_ServicePaymentStatusResponseOutput = {
  readonly __typename: 'invoice_api_ServicePaymentStatusResponseOutput';
  readonly ok: Scalars['Boolean'];
  readonly payment: Invoice_Api_PaymentOutput;
};

export type Invoice_Api_ServicePaymentTransactionAddResponseOutput = {
  readonly __typename: 'invoice_api_ServicePaymentTransactionAddResponseOutput';
  readonly payment: Invoice_Api_PaymentOutput;
};

export type Invoice_Api_ServiceTransactionCreateResponseOutput = {
  readonly __typename: 'invoice_api_ServiceTransactionCreateResponseOutput';
  readonly transaction: Invoice_Api_TransactionOutput;
};

export type Invoice_Api_TimeRangeInput = {
  readonly after?: InputMaybe<Scalars['String']>;
  readonly before?: InputMaybe<Scalars['String']>;
};

export enum Invoice_Api_TransactionMatchEnum {
  TransactionMatchAmount = 'TRANSACTION_MATCH_AMOUNT',
  TransactionMatchAmountApproximate = 'TRANSACTION_MATCH_AMOUNT_APPROXIMATE',
  TransactionMatchDescription = 'TRANSACTION_MATCH_DESCRIPTION',
  TransactionMatchIvr = 'TRANSACTION_MATCH_IVR',
  TransactionMatchManual = 'TRANSACTION_MATCH_MANUAL',
  UnknownTransactionMatch = 'UNKNOWN_TRANSACTION_MATCH'
}

export type Invoice_Api_TransactionMatchResponseOutput = {
  readonly __typename: 'invoice_api_TransactionMatchResponseOutput';
  readonly changed: Scalars['Int'];
  readonly matched_transaction_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Invoice_Api_TransactionOutput = {
  readonly __typename: 'invoice_api_TransactionOutput';
  readonly amount: Scalars['Float'];
  readonly created: Scalars['String'];
  readonly currency: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly ivr: Scalars['String'];
  readonly matches?: Maybe<ReadonlyArray<Invoice_Api_TransactionPaymentMatchOutput>>;
  readonly offer_id?: Maybe<Scalars['String']>;
  readonly reconciliation: Invoice_Api_TransactionReconciliationEnum;
  readonly scheduled_records?: Maybe<Gateway_Impl_Resolvers_ScheduledRecordsTransactionOutput>;
  readonly status: Invoice_Api_TransactionStatusEnum;
  readonly type: Invoice_Api_TransactionTypeEnum;
};

export type Invoice_Api_TransactionPaymentMatchOutput = {
  readonly __typename: 'invoice_api_TransactionPaymentMatchOutput';
  readonly admin: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly comment: Scalars['String'];
  readonly matched?: Maybe<ReadonlyArray<Invoice_Api_TransactionMatchEnum>>;
  readonly payment?: Maybe<Invoice_Api_PaymentOutput>;
  readonly payment_id: Scalars['String'];
  readonly reconciliation: Invoice_Api_TransactionReconciliationEnum;
  readonly time_distance: Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationOutput;
  readonly transaction?: Maybe<Invoice_Api_TransactionOutput>;
  readonly transaction_id: Scalars['String'];
};

export type Invoice_Api_TransactionPullResponseOutput = {
  readonly __typename: 'invoice_api_TransactionPullResponseOutput';
  readonly created: Scalars['Int'];
  readonly matched: Scalars['Int'];
  readonly matched_transaction_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly transaction_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum Invoice_Api_TransactionReconciliationEnum {
  TransactionReconciliationFull = 'TRANSACTION_RECONCILIATION_FULL',
  TransactionReconciliationPartial = 'TRANSACTION_RECONCILIATION_PARTIAL',
  UnknownTransactionReconciliation = 'UNKNOWN_TRANSACTION_RECONCILIATION'
}

export enum Invoice_Api_TransactionStatusEnum {
  TransactionStatusFailureCanceled = 'TRANSACTION_STATUS_FAILURE_CANCELED',
  TransactionStatusFailureDeclined = 'TRANSACTION_STATUS_FAILURE_DECLINED',
  TransactionStatusFailureExpired = 'TRANSACTION_STATUS_FAILURE_EXPIRED',
  TransactionStatusFailureGeneric = 'TRANSACTION_STATUS_FAILURE_GENERIC',
  TransactionStatusFailureInsufficientFunds = 'TRANSACTION_STATUS_FAILURE_INSUFFICIENT_FUNDS',
  TransactionStatusFailureInvalidAmount = 'TRANSACTION_STATUS_FAILURE_INVALID_AMOUNT',
  TransactionStatusFailureInvalidCurrency = 'TRANSACTION_STATUS_FAILURE_INVALID_CURRENCY',
  TransactionStatusFailureInvalidQuote = 'TRANSACTION_STATUS_FAILURE_INVALID_QUOTE',
  TransactionStatusFailurePermissionDenied = 'TRANSACTION_STATUS_FAILURE_PERMISSION_DENIED',
  TransactionStatusFailureQuoteExpired = 'TRANSACTION_STATUS_FAILURE_QUOTE_EXPIRED',
  TransactionStatusInitiated = 'TRANSACTION_STATUS_INITIATED',
  TransactionStatusInvalidStatus = 'TRANSACTION_STATUS_INVALID_STATUS',
  TransactionStatusPending = 'TRANSACTION_STATUS_PENDING',
  TransactionStatusPendingExternalAuthorization = 'TRANSACTION_STATUS_PENDING_EXTERNAL_AUTHORIZATION',
  TransactionStatusProcessing = 'TRANSACTION_STATUS_PROCESSING',
  TransactionStatusSent = 'TRANSACTION_STATUS_SENT',
  TransactionStatusStatusNotAvailable = 'TRANSACTION_STATUS_STATUS_NOT_AVAILABLE',
  TransactionStatusSuccess = 'TRANSACTION_STATUS_SUCCESS'
}

export enum Invoice_Api_TransactionTypeEnum {
  TransactionTypeCredit = 'TRANSACTION_TYPE_CREDIT',
  TransactionTypeDebit = 'TRANSACTION_TYPE_DEBIT',
  UnknownTransactionType = 'UNKNOWN_TRANSACTION_TYPE'
}

export type Invoice_Api_UserContactsInput = {
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_UserContactsOutput = {
  readonly __typename: 'invoice_api_UserContactsOutput';
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
};

export type Invoice_Api_UserDataInput = {
  readonly contacts?: InputMaybe<Invoice_Api_UserContactsInput>;
  readonly correspondence?: InputMaybe<Invoice_Api_LocationInput>;
  readonly details?: InputMaybe<Invoice_Api_UserDetailsInput>;
  readonly location?: InputMaybe<Invoice_Api_LocationInput>;
};

export type Invoice_Api_UserDataOutput = {
  readonly __typename: 'invoice_api_UserDataOutput';
  readonly contacts: Invoice_Api_UserContactsOutput;
  readonly correspondence: Invoice_Api_LocationOutput;
  readonly details: Invoice_Api_UserDetailsOutput;
  readonly location: Invoice_Api_LocationOutput;
};

export type Invoice_Api_UserDetailsInput = {
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
};

export type Invoice_Api_UserDetailsOutput = {
  readonly __typename: 'invoice_api_UserDetailsOutput';
  readonly first_name?: Maybe<Scalars['String']>;
  readonly last_name?: Maybe<Scalars['String']>;
};

export type Invoice_Api_IsDownloadReportRequest_ReportOneofInput = {
  readonly addon_products?: InputMaybe<Invoice_Api_ReportFormatEnum>;
  readonly all_products?: InputMaybe<Invoice_Api_ReportFormatEnum>;
  readonly hei_and_drp_bordeaux?: InputMaybe<Invoice_Api_ReportHeiAndDrpBordeauxInput>;
  readonly hei_bordeaux?: InputMaybe<Invoice_Api_ReportHeiBordeauxInput>;
  readonly insurance_products?: InputMaybe<Invoice_Api_ReportFormatEnum>;
  readonly rgi_bordeaux?: InputMaybe<Invoice_Api_ReportRgiBordeauxInput>;
  readonly transactions?: InputMaybe<Invoice_Api_ReportTransactionsInput>;
};

export type Invoice_Api_IsPaymentSystemDataRequest_DataOneofInput = {
  readonly bank?: InputMaybe<Scalars['Boolean']>;
  readonly duologi?: InputMaybe<Invoice_Api_PaymentSystemDuologiRequestInput>;
  readonly plaid?: InputMaybe<Invoice_Api_PaymentSystemPlaidRequestInput>;
  readonly stripe?: InputMaybe<Invoice_Api_PaymentSystemStripeRequestInput>;
  readonly tokenio?: InputMaybe<Invoice_Api_PaymentSystemTokenioRequestInput>;
};

export type Invoice_Api_IsPaymentSystemDataResponse_DataOneofOutput = {
  readonly __typename: 'invoice_api_isPaymentSystemDataResponse_DataOneofOutput';
  readonly bank?: Maybe<Scalars['Boolean']>;
  readonly duologi?: Maybe<Invoice_Api_PaymentSystemDuologiResponseOutput>;
  readonly plaid?: Maybe<Scalars['String']>;
  readonly stripe?: Maybe<Invoice_Api_PaymentSystemStripeResponseOutput>;
  readonly tokenio?: Maybe<Invoice_Api_PaymentSystemTokenioResponseOutput>;
};

export type Invoice_Api_IsPaymentSystemData_DataOneofOutput = {
  readonly __typename: 'invoice_api_isPaymentSystemData_DataOneofOutput';
  readonly bank?: Maybe<Invoice_Api_PaymentSystemBankOutput>;
  readonly duologi?: Maybe<Invoice_Api_PaymentSystemDuologiOutput>;
  readonly plaid?: Maybe<Invoice_Api_PaymentSystemPlaidOutput>;
  readonly stripe?: Maybe<Invoice_Api_PaymentSystemStripeOutput>;
  readonly tokenio?: Maybe<Invoice_Api_PaymentSystemTokenioOutput>;
};

export type Invoice_Api_IsServicePaymentTransactionAddRequest_FindOneofInput = {
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly payment_id?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_AdminVerifyItemOutput = {
  readonly __typename: 'listing_api_AdminVerifyItemOutput';
  readonly admin: Scalars['String'];
  readonly verified: Scalars['Boolean'];
  readonly verified_at: Scalars['String'];
};

export type Listing_Api_BankDetailsInput = {
  readonly account_name?: InputMaybe<Scalars['String']>;
  readonly account_number?: InputMaybe<Scalars['String']>;
  readonly personal_reference?: InputMaybe<Scalars['String']>;
  readonly society?: InputMaybe<Scalars['String']>;
  readonly sort_code?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_BankDetailsOutput = {
  readonly __typename: 'listing_api_BankDetailsOutput';
  readonly account_name: Scalars['String'];
  readonly account_number: Scalars['String'];
  readonly personal_reference: Scalars['String'];
  readonly society: Scalars['String'];
  readonly sort_code: Scalars['String'];
};

export type Listing_Api_DescriptionInput = {
  readonly photos?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly plans?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly post_admin_pitch?: InputMaybe<Scalars['String']>;
  readonly post_area_pitch?: InputMaybe<Scalars['String']>;
  readonly post_comment?: InputMaybe<Scalars['String']>;
  readonly post_headline?: InputMaybe<Scalars['String']>;
  readonly post_outer_description?: InputMaybe<Scalars['String']>;
  readonly post_property_pitch?: InputMaybe<Scalars['String']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly video_embeds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Listing_Api_DescriptionOutput = {
  readonly __typename: 'listing_api_DescriptionOutput';
  readonly photos?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly plans?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly post_admin_pitch?: Maybe<Scalars['String']>;
  readonly post_area_pitch?: Maybe<Scalars['String']>;
  readonly post_comment?: Maybe<Scalars['String']>;
  readonly post_headline?: Maybe<Scalars['String']>;
  readonly post_outer_description?: Maybe<Scalars['String']>;
  readonly post_property_pitch?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly video_embeds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Listing_Api_DescriptionValidationOutput = {
  readonly __typename: 'listing_api_DescriptionValidationOutput';
  readonly photos?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly plans?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_admin_pitch?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_area_pitch?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_comment?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_headline?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_outer_description?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_property_pitch?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly tags?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly video_embeds?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
};

export type Listing_Api_DetailsInput = {
  readonly amenity_furniture?: InputMaybe<Listing_Api_PropertyFurnitureEnum>;
  readonly amenity_outside_space?: InputMaybe<ReadonlyArray<Listing_Api_PropertyOutsideSpaceEnum>>;
  readonly amenity_parking?: InputMaybe<ReadonlyArray<Listing_Api_PropertyParkingEnum>>;
  readonly area?: InputMaybe<Scalars['Float']>;
  readonly bathrooms_no?: InputMaybe<Scalars['Int']>;
  readonly bedrooms_no?: InputMaybe<Scalars['Int']>;
  readonly kitchen_no?: InputMaybe<Scalars['Int']>;
  readonly livingrooms_no?: InputMaybe<Scalars['Int']>;
  readonly pets_allowed?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<Listing_Api_PropertyTypeEnum>;
  readonly type_other?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_DetailsOutput = {
  readonly __typename: 'listing_api_DetailsOutput';
  readonly amenity_furniture: Listing_Api_PropertyFurnitureEnum;
  readonly amenity_outside_space?: Maybe<ReadonlyArray<Listing_Api_PropertyOutsideSpaceEnum>>;
  readonly amenity_parking?: Maybe<ReadonlyArray<Listing_Api_PropertyParkingEnum>>;
  readonly area?: Maybe<Scalars['Float']>;
  readonly bathrooms_no?: Maybe<Scalars['Int']>;
  readonly bedrooms_no?: Maybe<Scalars['Int']>;
  readonly kitchen_no?: Maybe<Scalars['Int']>;
  readonly livingrooms_no?: Maybe<Scalars['Int']>;
  readonly pets_allowed?: Maybe<Scalars['Boolean']>;
  readonly type: Listing_Api_PropertyTypeEnum;
  readonly type_other?: Maybe<Scalars['String']>;
};

export type Listing_Api_DetailsValidationOutput = {
  readonly __typename: 'listing_api_DetailsValidationOutput';
  readonly amenity_furniture?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly amenity_outside_space?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly amenity_parking?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly area?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly bathrooms_no?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly bedrooms_no?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly kitchen_no?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly livingrooms_no?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly pets_allowed?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly type?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly type_other?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
};

export type Listing_Api_FlagsOutput = {
  readonly __typename: 'listing_api_FlagsOutput';
  readonly auto?: Maybe<Scalars['Boolean']>;
  readonly disabled?: Maybe<Scalars['Boolean']>;
  readonly preface?: Maybe<Scalars['Boolean']>;
  readonly test?: Maybe<Scalars['Boolean']>;
  readonly withdrawn: Listing_Api_WithdrawalOutput;
};

export type Listing_Api_GpsCoordinatesInput = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
};

export type Listing_Api_GpsCoordinatesOutput = {
  readonly __typename: 'listing_api_GpsCoordinatesOutput';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Listing_Api_InvitationOutput = {
  readonly __typename: 'listing_api_InvitationOutput';
  readonly landlord_invitation: Listing_Api_InvitationStatusOutput;
  readonly manager_invitation: Listing_Api_InvitationStatusOutput;
  readonly manager_to_landlord_invitation: Listing_Api_InvitationStatusOutput;
};

export type Listing_Api_InvitationStatusOutput = {
  readonly __typename: 'listing_api_InvitationStatusOutput';
  readonly host_id: Scalars['String'];
  readonly invitation_id: Scalars['String'];
  readonly invitee_email: Scalars['String'];
  readonly invitee_id: Scalars['String'];
  readonly invitee_name: Scalars['String'];
  readonly invitee_phone: Scalars['String'];
  readonly pending: Scalars['Boolean'];
  readonly sent: Scalars['String'];
};

export type Listing_Api_InvitationUserInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
  readonly user_id?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_LocationInput = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly address3?: InputMaybe<Scalars['String']>;
  readonly address4?: InputMaybe<Scalars['String']>;
  readonly administrative_county?: InputMaybe<Scalars['String']>;
  readonly building?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly county?: InputMaybe<Scalars['String']>;
  readonly district?: InputMaybe<Scalars['String']>;
  readonly flat?: InputMaybe<Scalars['String']>;
  readonly geo_county?: InputMaybe<Scalars['String']>;
  readonly geo_region?: InputMaybe<Scalars['String']>;
  readonly geo_town?: InputMaybe<Scalars['String']>;
  readonly gps?: InputMaybe<Listing_Api_GpsCoordinatesInput>;
  readonly post_town?: InputMaybe<Scalars['String']>;
  readonly postal_county?: InputMaybe<Scalars['String']>;
  readonly postcode?: InputMaybe<Scalars['String']>;
  readonly street?: InputMaybe<Scalars['String']>;
  readonly traditional_county?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_LocationOutput = {
  readonly __typename: 'listing_api_LocationOutput';
  readonly address?: Maybe<Scalars['String']>;
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly address4?: Maybe<Scalars['String']>;
  readonly administrative_county?: Maybe<Scalars['String']>;
  readonly building?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly district?: Maybe<Scalars['String']>;
  readonly flat?: Maybe<Scalars['String']>;
  readonly geo_county?: Maybe<Scalars['String']>;
  readonly geo_region?: Maybe<Scalars['String']>;
  readonly geo_town?: Maybe<Scalars['String']>;
  readonly gps: Listing_Api_GpsCoordinatesOutput;
  readonly post_town?: Maybe<Scalars['String']>;
  readonly postal_county?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly traditional_county?: Maybe<Scalars['String']>;
};

export type Listing_Api_LocationValidationOutput = {
  readonly __typename: 'listing_api_LocationValidationOutput';
  readonly address?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly address1?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly address2?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly address3?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly address4?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly administrative_county?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly building?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly city?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly country?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly county?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly district?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly flat?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly geo_county?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly geo_region?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly geo_town?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly gps?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly post_town?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly postal_county?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly postcode?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly street?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly traditional_county?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
};

export type Listing_Api_NewLocationInput = {
  readonly raw?: InputMaybe<Listing_Api_LocationInput>;
  readonly udprn?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_PeopleInput = {
  readonly admin?: InputMaybe<Scalars['String']>;
  readonly landlord?: InputMaybe<Scalars['String']>;
  readonly manager?: InputMaybe<Scalars['String']>;
  readonly submitter?: InputMaybe<Scalars['String']>;
  readonly tenants?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Listing_Api_PeopleOutput = {
  readonly __typename: 'listing_api_PeopleOutput';
  readonly admin?: Maybe<Scalars['String']>;
  readonly landlord?: Maybe<Scalars['String']>;
  readonly manager?: Maybe<Scalars['String']>;
  readonly submitter?: Maybe<Scalars['String']>;
  readonly tenants?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type Listing_Api_PeopleValidationOutput = {
  readonly __typename: 'listing_api_PeopleValidationOutput';
  readonly landlord?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly manager?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly submitter?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
};

export enum Listing_Api_PeriodEnum {
  PeriodMonth = 'PERIOD_MONTH',
  PeriodUnknown = 'PERIOD_UNKNOWN',
  PeriodWeek = 'PERIOD_WEEK'
}

export type Listing_Api_PrefaceGetResponseOutput = {
  readonly __typename: 'listing_api_PrefaceGetResponseOutput';
  readonly preface: Listing_Api_PrefaceOutput;
};

export type Listing_Api_PrefaceOutput = {
  readonly __typename: 'listing_api_PrefaceOutput';
  readonly client_id: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly description: Listing_Api_DescriptionOutput;
  readonly details: Listing_Api_DetailsOutput;
  readonly location: Listing_Api_LocationOutput;
  readonly rental: Listing_Api_RentalOutput;
  readonly updated_at: Scalars['String'];
  readonly validation: Listing_Api_ValidationOutput;
  readonly valuation: Listing_Api_ValuationOutput;
};

export type Listing_Api_PrefaceSaveResponseOutput = {
  readonly __typename: 'listing_api_PrefaceSaveResponseOutput';
  readonly preface: Listing_Api_PrefaceOutput;
};

export enum Listing_Api_PropertyBillTypeEnum {
  PropertyBillElectricity = 'PROPERTY_BILL_ELECTRICITY',
  PropertyBillGas = 'PROPERTY_BILL_GAS',
  PropertyBillInternet = 'PROPERTY_BILL_INTERNET',
  PropertyBillSatcableTv = 'PROPERTY_BILL_SATCABLE_TV',
  PropertyBillTelephone = 'PROPERTY_BILL_TELEPHONE',
  PropertyBillTvLicense = 'PROPERTY_BILL_TV_LICENSE',
  PropertyBillTypeUnknown = 'PROPERTY_BILL_TYPE_UNKNOWN',
  PropertyBillWater = 'PROPERTY_BILL_WATER',
  PropertyCouncilTax = 'PROPERTY_COUNCIL_TAX'
}

export enum Listing_Api_PropertyFurnitureEnum {
  PropertyFurnitureFurnished = 'PROPERTY_FURNITURE_FURNISHED',
  PropertyFurniturePartlyFurnished = 'PROPERTY_FURNITURE_PARTLY_FURNISHED',
  PropertyFurnitureUnfurnished = 'PROPERTY_FURNITURE_UNFURNISHED',
  PropertyFurnitureUnknown = 'PROPERTY_FURNITURE_UNKNOWN'
}

export enum Listing_Api_PropertyOutsideSpaceEnum {
  PropertyOutsideSpaceBalcony = 'PROPERTY_OUTSIDE_SPACE_BALCONY',
  PropertyOutsideSpaceCommunalGarden = 'PROPERTY_OUTSIDE_SPACE_COMMUNAL_GARDEN',
  PropertyOutsideSpaceNone = 'PROPERTY_OUTSIDE_SPACE_NONE',
  PropertyOutsideSpacePrivateGarden = 'PROPERTY_OUTSIDE_SPACE_PRIVATE_GARDEN',
  PropertyOutsideSpaceTerrace = 'PROPERTY_OUTSIDE_SPACE_TERRACE',
  PropertyOutsideSpaceUnknown = 'PROPERTY_OUTSIDE_SPACE_UNKNOWN'
}

export enum Listing_Api_PropertyParkingEnum {
  PropertyParkingDoubleGarage = 'PROPERTY_PARKING_DOUBLE_GARAGE',
  PropertyParkingGarage = 'PROPERTY_PARKING_GARAGE',
  PropertyParkingNone = 'PROPERTY_PARKING_NONE',
  PropertyParkingOffstreet = 'PROPERTY_PARKING_OFFSTREET',
  PropertyParkingResidentsParking = 'PROPERTY_PARKING_RESIDENTS_PARKING',
  PropertyParkingUnknown = 'PROPERTY_PARKING_UNKNOWN'
}

export enum Listing_Api_PropertyTypeEnum {
  PropertyTypeApartment = 'PROPERTY_TYPE_APARTMENT',
  PropertyTypeBungalow = 'PROPERTY_TYPE_BUNGALOW',
  PropertyTypeCottage = 'PROPERTY_TYPE_COTTAGE',
  PropertyTypeDetached = 'PROPERTY_TYPE_DETACHED',
  PropertyTypeFlat = 'PROPERTY_TYPE_FLAT',
  PropertyTypeMaisonette = 'PROPERTY_TYPE_MAISONETTE',
  PropertyTypeOther = 'PROPERTY_TYPE_OTHER',
  PropertyTypeParkHome = 'PROPERTY_TYPE_PARK_HOME',
  PropertyTypePenthouse = 'PROPERTY_TYPE_PENTHOUSE',
  PropertyTypeSemiDetached = 'PROPERTY_TYPE_SEMI_DETACHED',
  PropertyTypeStudio = 'PROPERTY_TYPE_STUDIO',
  PropertyTypeTerraced = 'PROPERTY_TYPE_TERRACED',
  PropertyTypeTownHouse = 'PROPERTY_TYPE_TOWN_HOUSE',
  PropertyTypeUnknown = 'PROPERTY_TYPE_UNKNOWN'
}

export type Listing_Api_PublicationHistoryItemOutput = {
  readonly __typename: 'listing_api_PublicationHistoryItemOutput';
  readonly expire_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly invoice_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly platform: Listing_Api_PublicationPlatformEnum;
  readonly published_at: Scalars['String'];
  readonly published_by: Scalars['String'];
  readonly unpublished_at: Scalars['String'];
  readonly unpublished_by: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Listing_Api_PublicationItemOutput = {
  readonly __typename: 'listing_api_PublicationItemOutput';
  readonly expire_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly invoice_ids?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly published_at: Scalars['String'];
  readonly published_by: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Listing_Api_PublicationOutput = {
  readonly __typename: 'listing_api_PublicationOutput';
  readonly boomin: Listing_Api_PublicationItemOutput;
  readonly history_items?: Maybe<ReadonlyArray<Listing_Api_PublicationHistoryItemOutput>>;
  readonly mashroom: Listing_Api_PublicationItemOutput;
  readonly rightmove: Listing_Api_PublicationItemOutput;
  readonly zoopla: Listing_Api_PublicationItemOutput;
};

export enum Listing_Api_PublicationPlatformEnum {
  PublicationPlatformBoomin = 'PUBLICATION_PLATFORM_BOOMIN',
  PublicationPlatformMashroom = 'PUBLICATION_PLATFORM_MASHROOM',
  PublicationPlatformRightmove = 'PUBLICATION_PLATFORM_RIGHTMOVE',
  PublicationPlatformUnknown = 'PUBLICATION_PLATFORM_UNKNOWN',
  PublicationPlatformZoopla = 'PUBLICATION_PLATFORM_ZOOPLA'
}

export type Listing_Api_RentalInput = {
  readonly bank_details?: InputMaybe<Listing_Api_BankDetailsInput>;
  readonly bills_covered?: InputMaybe<ReadonlyArray<Listing_Api_PropertyBillTypeEnum>>;
  readonly bills_included?: InputMaybe<Scalars['Boolean']>;
  readonly deposit_replacement_fee?: InputMaybe<Scalars['Float']>;
  readonly dss_available?: InputMaybe<Scalars['Boolean']>;
  readonly duration_max_months?: InputMaybe<Scalars['Int']>;
  readonly duration_min_months?: InputMaybe<Scalars['Int']>;
  readonly earliest_movein?: InputMaybe<Scalars['Date']>;
  readonly inventory_report?: InputMaybe<Scalars['Boolean']>;
  readonly rental_price?: InputMaybe<Scalars['Float']>;
  readonly tenancy_deposit_insurance?: InputMaybe<Scalars['Boolean']>;
};

export type Listing_Api_RentalOutput = {
  readonly __typename: 'listing_api_RentalOutput';
  readonly bank_details: Listing_Api_BankDetailsOutput;
  readonly bills_covered?: Maybe<ReadonlyArray<Listing_Api_PropertyBillTypeEnum>>;
  readonly bills_included?: Maybe<Scalars['Boolean']>;
  readonly deposit_replacement_fee?: Maybe<Scalars['Float']>;
  readonly dss_available?: Maybe<Scalars['Boolean']>;
  readonly duration_max_months?: Maybe<Scalars['Int']>;
  readonly duration_min_months?: Maybe<Scalars['Int']>;
  readonly earliest_movein: Scalars['Date'];
  readonly inventory_report?: Maybe<Scalars['Boolean']>;
  readonly rental_price?: Maybe<Scalars['Float']>;
  readonly tenancy_deposit_insurance?: Maybe<Scalars['Boolean']>;
};

export type Listing_Api_RentalValidationOutput = {
  readonly __typename: 'listing_api_RentalValidationOutput';
  readonly bills_covered?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly bills_included?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly deposit_replacement_fee?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly dss_available?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly duration_max_months?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly duration_min_months?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly earliest_movein?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly inventory_report?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly rental_price?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly tenancy_deposit_insurance?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
};

export type Listing_Api_SearchInRadiusInput = {
  readonly center?: InputMaybe<Listing_Api_GpsCoordinatesInput>;
  readonly meters?: InputMaybe<Scalars['Int']>;
};

export enum Listing_Api_ValidationErrorEnum {
  EmptyValue = 'EMPTY_VALUE',
  InvalidValue = 'INVALID_VALUE',
  ValidationErrorUnknown = 'VALIDATION_ERROR_UNKNOWN'
}

export type Listing_Api_ValidationOutput = {
  readonly __typename: 'listing_api_ValidationOutput';
  readonly description: Listing_Api_DescriptionValidationOutput;
  readonly details: Listing_Api_DetailsValidationOutput;
  readonly document?: Maybe<ReadonlyArray<Listing_Api_ValidationErrorEnum>>;
  readonly location: Listing_Api_LocationValidationOutput;
  readonly people: Listing_Api_PeopleValidationOutput;
  readonly rental: Listing_Api_RentalValidationOutput;
  readonly valid: Scalars['Boolean'];
  readonly verifiable: Scalars['Boolean'];
};

export type Listing_Api_ValuationAdditionalOutput = {
  readonly __typename: 'listing_api_ValuationAdditionalOutput';
  readonly comparator_number_confidence: Scalars['Float'];
  readonly variation_confidence: Scalars['Float'];
};

export type Listing_Api_ValuationGetResponseOutput = {
  readonly __typename: 'listing_api_ValuationGetResponseOutput';
  readonly data: Scalars['String'];
};

export type Listing_Api_ValuationOutput = {
  readonly __typename: 'listing_api_ValuationOutput';
  readonly additional: Listing_Api_ValuationAdditionalOutput;
  readonly confidence: Scalars['Float'];
  readonly estimate: Scalars['Float'];
  readonly lower_bound: Scalars['Float'];
  readonly upper_bound: Scalars['Float'];
};

export type Listing_Api_VerificationOutput = {
  readonly __typename: 'listing_api_VerificationOutput';
  readonly address: Listing_Api_AdminVerifyItemOutput;
  readonly description: Listing_Api_AdminVerifyItemOutput;
  readonly documents: Listing_Api_AdminVerifyItemOutput;
  readonly fee: Listing_Api_AdminVerifyItemOutput;
  readonly landlord_ownership: Listing_Api_AdminVerifyItemOutput;
  readonly photos: Listing_Api_AdminVerifyItemOutput;
  readonly submitter_management: Listing_Api_AdminVerifyItemOutput;
};

export type Listing_Api_VersionCreateResponseOutput = {
  readonly __typename: 'listing_api_VersionCreateResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionFilterResponseOutput = {
  readonly __typename: 'listing_api_VersionFilterResponseOutput';
  readonly total: Scalars['Int'];
  readonly versions?: Maybe<ReadonlyArray<Listing_Api_VersionOutput>>;
};

export type Listing_Api_VersionFlagsResponseOutput = {
  readonly __typename: 'listing_api_VersionFlagsResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionGetResponseOutput = {
  readonly __typename: 'listing_api_VersionGetResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionListResponseOutput = {
  readonly __typename: 'listing_api_VersionListResponseOutput';
  readonly versions?: Maybe<ReadonlyArray<Listing_Api_VersionOutput>>;
};

export type Listing_Api_VersionOriginProductInput = {
  readonly product_id?: InputMaybe<Scalars['String']>;
  readonly product_name?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_VersionOriginProductOutput = {
  readonly __typename: 'listing_api_VersionOriginProductOutput';
  readonly product_id: Scalars['String'];
  readonly product_name: Scalars['String'];
};

export type Listing_Api_VersionOutput = {
  readonly __typename: 'listing_api_VersionOutput';
  readonly awards?: Maybe<Scalars['String']>;
  readonly created_at: Scalars['String'];
  readonly description: Listing_Api_DescriptionOutput;
  readonly details: Listing_Api_DetailsOutput;
  readonly flags: Listing_Api_FlagsOutput;
  readonly id: Scalars['String'];
  readonly invitation: Listing_Api_InvitationOutput;
  readonly ivr: Scalars['String'];
  readonly last_verified: Scalars['String'];
  readonly listing_id: Scalars['String'];
  readonly location: Listing_Api_LocationOutput;
  readonly nearby?: Maybe<ReadonlyArray<Geo_Api_PointRelativeOutput>>;
  readonly origin?: Maybe<Listing_Api_IsVersionOrigin_OriginOneofOutput>;
  readonly people: Listing_Api_PeopleOutput;
  readonly property_id: Scalars['String'];
  readonly publication: Listing_Api_PublicationOutput;
  readonly rental: Listing_Api_RentalOutput;
  readonly status: Listing_Api_VersionStatusEnum;
  readonly tenancy_id: Scalars['String'];
  readonly updated_at: Scalars['String'];
  readonly validation: Listing_Api_ValidationOutput;
  readonly valuation: Listing_Api_ValuationOutput;
  readonly verification: Listing_Api_VerificationOutput;
  readonly viewing_availability?: Maybe<ReadonlyArray<Listing_Api_ViewingAvailabilitySlotOutput>>;
};

export type Listing_Api_VersionPublicationChangeItemInput = {
  readonly expire_at?: InputMaybe<Scalars['String']>;
  readonly external_id?: InputMaybe<Scalars['String']>;
  readonly external_url?: InputMaybe<Scalars['String']>;
  readonly invoice_id?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_VersionPublishResponseOutput = {
  readonly __typename: 'listing_api_VersionPublishResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionSpecInput = {
  readonly details?: InputMaybe<Listing_Api_IsVersionSpec_DetailsOneofInput>;
  readonly user_id?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_VersionSpecSelectInput = {
  readonly id?: InputMaybe<Listing_Api_IsVersionSpecSelect_IdOneofInput>;
  readonly statuses?: InputMaybe<ReadonlyArray<Listing_Api_VersionStatusEnum>>;
};

export enum Listing_Api_VersionStatusEnum {
  VersionArchive = 'VERSION_ARCHIVE',
  VersionDraft = 'VERSION_DRAFT',
  VersionLet = 'VERSION_LET',
  VersionRentCollection = 'VERSION_RENT_COLLECTION',
  VersionStatusUnknown = 'VERSION_STATUS_UNKNOWN',
  VersionVerified = 'VERSION_VERIFIED',
  VersionVerifying = 'VERSION_VERIFYING',
  VersionWithdrawn = 'VERSION_WITHDRAWN'
}

export type Listing_Api_VersionSubmitResponseOutput = {
  readonly __typename: 'listing_api_VersionSubmitResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionUnpublishResponseOutput = {
  readonly __typename: 'listing_api_VersionUnpublishResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionUnsubmitResponseOutput = {
  readonly __typename: 'listing_api_VersionUnsubmitResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionUpdateResponseOutput = {
  readonly __typename: 'listing_api_VersionUpdateResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionValidateResponseOutput = {
  readonly __typename: 'listing_api_VersionValidateResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_VersionViewingAvailabilityResponseOutput = {
  readonly __typename: 'listing_api_VersionViewingAvailabilityResponseOutput';
  readonly version: Listing_Api_VersionOutput;
};

export type Listing_Api_ViewingAvailabilitySlotInput = {
  readonly end_offset?: InputMaybe<Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationInput>;
  readonly period?: InputMaybe<Listing_Api_PeriodEnum>;
  readonly start_offset?: InputMaybe<Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationInput>;
};

export type Listing_Api_ViewingAvailabilitySlotOutput = {
  readonly __typename: 'listing_api_ViewingAvailabilitySlotOutput';
  readonly end_offset: Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationOutput;
  readonly period: Listing_Api_PeriodEnum;
  readonly start_offset: Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationOutput;
};

export type Listing_Api_WithdrawalOutput = {
  readonly __typename: 'listing_api_WithdrawalOutput';
  readonly reason: Listing_Api_WithdrawalReasonEnum;
  readonly time: Scalars['String'];
};

export enum Listing_Api_WithdrawalReasonEnum {
  WithdrawalReasonAdvertisingBreak = 'WITHDRAWAL_REASON_ADVERTISING_BREAK',
  WithdrawalReasonFoundTenant = 'WITHDRAWAL_REASON_FOUND_TENANT',
  WithdrawalReasonListedWithAnotherOnlinePlatform = 'WITHDRAWAL_REASON_LISTED_WITH_ANOTHER_ONLINE_PLATFORM',
  WithdrawalReasonListedWithHighstreetLettingAgent = 'WITHDRAWAL_REASON_LISTED_WITH_HIGHSTREET_LETTING_AGENT',
  WithdrawalReasonOther = 'WITHDRAWAL_REASON_OTHER',
  WithdrawalReasonUnknown = 'WITHDRAWAL_REASON_UNKNOWN'
}

export type Listing_Api_IsVersionFlagsRequest_FlagOneofInput = {
  readonly active_offer?: InputMaybe<Scalars['String']>;
  readonly auto?: InputMaybe<Scalars['Boolean']>;
  readonly disabled?: InputMaybe<Scalars['Boolean']>;
  readonly test?: InputMaybe<Scalars['Boolean']>;
};

export type Listing_Api_IsVersionInvitationCreateRequest_RequestOneofInput = {
  readonly landlord_invitation?: InputMaybe<Scalars['Boolean']>;
  readonly manager_invitation?: InputMaybe<Scalars['Boolean']>;
};

export type Listing_Api_IsVersionOriginTool_ToolOneofInput = {
  readonly certificate_repository?: InputMaybe<Scalars['Boolean']>;
  readonly expense_tracker?: InputMaybe<Scalars['Boolean']>;
  readonly valuation?: InputMaybe<Scalars['Boolean']>;
};

export type Listing_Api_IsVersionOriginTool_ToolOneofOutput = {
  readonly __typename: 'listing_api_isVersionOriginTool_ToolOneofOutput';
  readonly certificate_repository?: Maybe<Scalars['Boolean']>;
  readonly expense_tracker?: Maybe<Scalars['Boolean']>;
  readonly valuation?: Maybe<Scalars['Boolean']>;
};

export type Listing_Api_IsVersionOrigin_OriginOneofInput = {
  readonly listing?: InputMaybe<Scalars['Boolean']>;
  readonly preface?: InputMaybe<Scalars['Boolean']>;
  readonly product?: InputMaybe<Listing_Api_VersionOriginProductInput>;
  readonly tool?: InputMaybe<Listing_Api_IsVersionOriginTool_ToolOneofInput>;
};

export type Listing_Api_IsVersionOrigin_OriginOneofOutput = {
  readonly __typename: 'listing_api_isVersionOrigin_OriginOneofOutput';
  readonly listing?: Maybe<Scalars['Boolean']>;
  readonly preface?: Maybe<Scalars['Boolean']>;
  readonly product?: Maybe<Listing_Api_VersionOriginProductOutput>;
  readonly tool?: Maybe<Listing_Api_IsVersionOriginTool_ToolOneofOutput>;
};

export type Listing_Api_IsVersionSpecSelect_IdOneofInput = {
  readonly invitation_id?: InputMaybe<Scalars['String']>;
  readonly listing_id?: InputMaybe<Scalars['String']>;
  readonly property_id?: InputMaybe<Scalars['String']>;
  readonly tenancy_id?: InputMaybe<Scalars['String']>;
};

export type Listing_Api_IsVersionSpec_DetailsOneofInput = {
  readonly client_id?: InputMaybe<Scalars['String']>;
  readonly invitation_id?: InputMaybe<Scalars['String']>;
  readonly select?: InputMaybe<Listing_Api_VersionSpecSelectInput>;
  readonly version_id?: InputMaybe<Scalars['String']>;
};

export type Maintenance_Api_AssignIssueLocalHeroesResponseOutput = {
  readonly __typename: 'maintenance_api_AssignIssueLocalHeroesResponseOutput';
  /** @deprecated Do not use */
  readonly error: Maintenance_Api_MaintenanceErrorEnum;
  readonly maintenance: Maintenance_Api_MaintenanceEnrichedOutput;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Maintenance_Api_AssignIssueOwnContractorResponseOutput = {
  readonly __typename: 'maintenance_api_AssignIssueOwnContractorResponseOutput';
  readonly maintenance: Maintenance_Api_MaintenanceEnrichedOutput;
};

export type Maintenance_Api_CreateResponseOutput = {
  readonly __typename: 'maintenance_api_CreateResponseOutput';
  readonly maintenance: Maintenance_Api_MaintenanceEnrichedOutput;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Maintenance_Api_GetJobDescriptionListResponseOutput = {
  readonly __typename: 'maintenance_api_GetJobDescriptionListResponseOutput';
  /** @deprecated Use taxonomies */
  readonly job_description?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly taxonomies?: Maybe<ReadonlyArray<Maintenance_Api_TaxonomyOutput>>;
};

export type Maintenance_Api_GetResponseOutput = {
  readonly __typename: 'maintenance_api_GetResponseOutput';
  readonly maintenance: Maintenance_Api_MaintenanceEnrichedOutput;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Maintenance_Api_GetSubcategoryListResponseOutput = {
  readonly __typename: 'maintenance_api_GetSubcategoryListResponseOutput';
  readonly subcategory?: Maybe<ReadonlyArray<Maintenance_Api_LocalHeroesSubcategoryEnum>>;
};

export enum Maintenance_Api_IssueDirectionEnum {
  IssueDirectionIncoming = 'ISSUE_DIRECTION_INCOMING',
  IssueDirectionOutgoing = 'ISSUE_DIRECTION_OUTGOING',
  IssueDirectionUnknown = 'ISSUE_DIRECTION_UNKNOWN'
}

export type Maintenance_Api_ListResponseOutput = {
  readonly __typename: 'maintenance_api_ListResponseOutput';
  readonly maintenance?: Maybe<ReadonlyArray<Maintenance_Api_MaintenanceEnrichedOutput>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export enum Maintenance_Api_LocalHeroesCategoryEnum {
  Drainage = 'DRAINAGE',
  Electrics = 'ELECTRICS',
  Handyperson = 'HANDYPERSON',
  Heating = 'HEATING',
  Locksmith = 'LOCKSMITH',
  Plumbing = 'PLUMBING',
  UnknownCategory = 'UNKNOWN_CATEGORY'
}

export enum Maintenance_Api_LocalHeroesSubcategoryEnum {
  Bath = 'BATH',
  BathroomSub = 'BATHROOM_SUB',
  Bidet = 'BIDET',
  Blockage = 'BLOCKAGE',
  Boiler = 'BOILER',
  CarbonMonoxideAlarm = 'CARBON_MONOXIDE_ALARM',
  Drains = 'DRAINS',
  Extractor = 'EXTRACTOR',
  FlatPack = 'FLAT_PACK',
  FuseBox = 'FUSE_BOX',
  HotWaterTank = 'HOT_WATER_TANK',
  Keys = 'KEYS',
  Lights = 'LIGHTS',
  Locks = 'LOCKS',
  Macerator = 'MACERATOR',
  Other = 'OTHER',
  OvenHob = 'OVEN_HOB',
  Pipes = 'PIPES',
  Radiator = 'RADIATOR',
  Rewiring = 'REWIRING',
  SecuritySystems = 'SECURITY_SYSTEMS',
  Shower = 'SHOWER',
  Sink = 'SINK',
  SmokeAlarm = 'SMOKE_ALARM',
  Sockets = 'SOCKETS',
  Tap = 'TAP',
  Thermostat = 'THERMOSTAT',
  Toilet = 'TOILET',
  UnknownSubcategory = 'UNKNOWN_SUBCATEGORY'
}

export type Maintenance_Api_MaintenanceEnrichedOutput = {
  readonly __typename: 'maintenance_api_MaintenanceEnrichedOutput';
  /** @deprecated Use taxonomy_id */
  readonly category?: Maybe<Maintenance_Api_LocalHeroesCategoryEnum>;
  readonly created_at: Scalars['String'];
  readonly description: Scalars['String'];
  readonly desired_repair_date: Scalars['String'];
  /** @deprecated Use files */
  readonly documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly files: ReadonlyArray<Document_Api_FileOutput>;
  readonly id: Scalars['String'];
  /** @deprecated Use title/description */
  readonly job_description?: Maybe<Scalars['String']>;
  readonly landlord: Scalars['String'];
  readonly listing: Scalars['String'];
  /** @deprecated Use desired_repair_date */
  readonly occurring_date?: Maybe<Scalars['String']>;
  readonly place: Maintenance_Api_MaintenancePlaceEnum;
  readonly property: Scalars['String'];
  readonly repair_date: Scalars['String'];
  readonly resolution: Maintenance_Api_MaintenanceResolutionEnum;
  readonly response: Maintenance_Api_MaintenanceResponseOutput;
  readonly specialist: Maintenance_Api_IsMaintenance_SpecialistOneofOutput;
  /** @deprecated Use specialist */
  readonly specialist_local_heroes?: Maybe<Maintenance_Api_SpecialistLocalHeroesOutput>;
  /** @deprecated Use specialist */
  readonly specialist_someone_else?: Maybe<Maintenance_Api_SpecialistOutput>;
  /** @deprecated Use specialist */
  readonly specialist_type?: Maybe<Maintenance_Api_MaintenanceSpecialistTypeEnum>;
  readonly status: Maintenance_Api_MaintenanceStatusEnum;
  /** @deprecated Use taxonomy_id */
  readonly subcategory?: Maybe<Maintenance_Api_LocalHeroesSubcategoryEnum>;
  readonly taxonomy: Scalars['String'];
  readonly tenants?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly title: Scalars['String'];
  readonly type: Maintenance_Api_MaintenanceTypeEnum;
  readonly updated_at: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Maintenance_Api_MaintenanceErrorEnum {
  MaintenanceCategoryRequired = 'MAINTENANCE_CATEGORY_REQUIRED',
  MaintenanceSubcategoryRequired = 'MAINTENANCE_SUBCATEGORY_REQUIRED',
  MaintenanceTimeSlotRequired = 'MAINTENANCE_TIME_SLOT_REQUIRED',
  MaintenanceUnknownError = 'MAINTENANCE_UNKNOWN_ERROR'
}

export type Maintenance_Api_MaintenanceInput = {
  /** Deprecated. Do not use. */
  readonly category?: InputMaybe<Maintenance_Api_LocalHeroesCategoryEnum>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly desired_repair_date?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly files?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly job_description?: InputMaybe<Scalars['String']>;
  readonly landlord?: InputMaybe<Scalars['String']>;
  readonly listing?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly occurring_date?: InputMaybe<Scalars['String']>;
  readonly place?: InputMaybe<Maintenance_Api_MaintenancePlaceEnum>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly repair_date?: InputMaybe<Scalars['String']>;
  readonly resolution?: InputMaybe<Maintenance_Api_MaintenanceResolutionEnum>;
  readonly response?: InputMaybe<Maintenance_Api_MaintenanceResponseInput>;
  readonly specialist?: InputMaybe<Maintenance_Api_IsMaintenance_SpecialistOneofInput>;
  /** Deprecated. Do not use. */
  readonly specialist_local_heroes?: InputMaybe<Maintenance_Api_SpecialistLocalHeroesInput>;
  readonly specialist_someone_else?: InputMaybe<Maintenance_Api_SpecialistInput>;
  /** Deprecated. Do not use. */
  readonly specialist_type?: InputMaybe<Maintenance_Api_MaintenanceSpecialistTypeEnum>;
  readonly status?: InputMaybe<Maintenance_Api_MaintenanceStatusEnum>;
  /** Deprecated. Do not use. */
  readonly subcategory?: InputMaybe<Maintenance_Api_LocalHeroesSubcategoryEnum>;
  readonly taxonomy?: InputMaybe<Scalars['String']>;
  readonly tenants?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly title?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Maintenance_Api_MaintenanceTypeEnum>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export enum Maintenance_Api_MaintenancePlaceEnum {
  Attic = 'ATTIC',
  Basement = 'BASEMENT',
  Bathroom = 'BATHROOM',
  Bedroom = 'BEDROOM',
  Garage = 'GARAGE',
  Hallway = 'HALLWAY',
  Kitchen = 'KITCHEN',
  MaintenancePlaceUnknown = 'MAINTENANCE_PLACE_UNKNOWN',
  Outdoor = 'OUTDOOR',
  ReceptionRoom = 'RECEPTION_ROOM'
}

export enum Maintenance_Api_MaintenanceResolutionEnum {
  MaintenanceResolutionUnknown = 'MAINTENANCE_RESOLUTION_UNKNOWN',
  /** @deprecated deprecated */
  ToBeeDiscussed = 'TO_BEE_DISCUSSED',
  ToBeDiscussed = 'TO_BE_DISCUSSED',
  ToBeReplaced = 'TO_BE_REPLACED'
}

export type Maintenance_Api_MaintenanceResponseInput = {
  readonly assign_status?: InputMaybe<Scalars['String']>;
  readonly reopen_status?: InputMaybe<Scalars['String']>;
};

export type Maintenance_Api_MaintenanceResponseOutput = {
  readonly __typename: 'maintenance_api_MaintenanceResponseOutput';
  readonly assign_status: Scalars['String'];
  readonly reopen_status: Scalars['String'];
};

export enum Maintenance_Api_MaintenanceSpecialistTypeEnum {
  LocalHeroes = 'LOCAL_HEROES',
  SomeoneElse = 'SOMEONE_ELSE',
  SpecialistTypeUnknown = 'SPECIALIST_TYPE_UNKNOWN',
  /** @deprecated deprecated */
  UnknownSpecialistType = 'UNKNOWN_SPECIALIST_TYPE'
}

export enum Maintenance_Api_MaintenanceStatusEnum {
  InWorks = 'IN_WORKS',
  MaintenanceStatusUnknown = 'MAINTENANCE_STATUS_UNKNOWN',
  NotAddressed = 'NOT_ADDRESSED',
  Repaired = 'REPAIRED'
}

export enum Maintenance_Api_MaintenanceTypeEnum {
  Issue = 'ISSUE',
  MaintenanceTypeUnknown = 'MAINTENANCE_TYPE_UNKNOWN',
  Task = 'TASK'
}

export type Maintenance_Api_SpecialistInput = {
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone_number?: InputMaybe<Scalars['String']>;
  readonly visit_at?: InputMaybe<Scalars['String']>;
};

export type Maintenance_Api_SpecialistLocalHeroesInput = {
  readonly description_job?: InputMaybe<Scalars['String']>;
  readonly request_id?: InputMaybe<Scalars['Int']>;
  readonly taxonomy_id?: InputMaybe<Scalars['String']>;
  readonly time_slot_end?: InputMaybe<Scalars['String']>;
  readonly time_slot_start?: InputMaybe<Scalars['String']>;
};

export type Maintenance_Api_SpecialistLocalHeroesOutput = {
  readonly __typename: 'maintenance_api_SpecialistLocalHeroesOutput';
  readonly description_job: Scalars['String'];
  readonly request_id: Scalars['Int'];
  readonly taxonomy_id: Scalars['String'];
  readonly time_slot_end: Scalars['String'];
  readonly time_slot_start: Scalars['String'];
};

export type Maintenance_Api_SpecialistOutput = {
  readonly __typename: 'maintenance_api_SpecialistOutput';
  readonly first_name: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly phone_number: Scalars['String'];
  readonly visit_at: Scalars['String'];
};

export type Maintenance_Api_SpecialistPrivateContractorInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly first_name?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phone_number?: InputMaybe<Scalars['String']>;
  readonly visit_at?: InputMaybe<Scalars['String']>;
};

export type Maintenance_Api_SpecialistPrivateContractorOutput = {
  readonly __typename: 'maintenance_api_SpecialistPrivateContractorOutput';
  readonly description: Scalars['String'];
  /** @deprecated Use name */
  readonly first_name: Scalars['String'];
  /** @deprecated Use name */
  readonly last_name: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phone_number: Scalars['String'];
  readonly visit_at: Scalars['String'];
};

export type Maintenance_Api_TaxonomyOutput = {
  readonly __typename: 'maintenance_api_TaxonomyOutput';
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
};

export type Maintenance_Api_TimeSlotInput = {
  readonly end_date_time?: InputMaybe<Scalars['String']>;
  readonly start_date_time?: InputMaybe<Scalars['String']>;
};

export type Maintenance_Api_UpdateResponseOutput = {
  readonly __typename: 'maintenance_api_UpdateResponseOutput';
  readonly maintenance: Maintenance_Api_MaintenanceEnrichedOutput;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Maintenance_Api_IsMaintenance_SpecialistOneofInput = {
  readonly local_heroes?: InputMaybe<Maintenance_Api_SpecialistLocalHeroesInput>;
  readonly someone_else?: InputMaybe<Maintenance_Api_SpecialistPrivateContractorInput>;
};

export type Maintenance_Api_IsMaintenance_SpecialistOneofOutput = {
  readonly __typename: 'maintenance_api_isMaintenance_SpecialistOneofOutput';
  readonly local_heroes?: Maybe<Maintenance_Api_SpecialistLocalHeroesOutput>;
  readonly someone_else?: Maybe<Maintenance_Api_SpecialistPrivateContractorOutput>;
};

export type Messaging_Api_ChannelEnrichedOutput = {
  readonly __typename: 'messaging_api_ChannelEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly created: Scalars['String'];
  readonly embeds?: Maybe<ReadonlyArray<Messaging_Api_EmbedEnrichedOutput>>;
  readonly last_message: Messaging_Api_MessageEnrichedOutput;
  readonly scope: Messaging_Api_ChannelScopeEnum;
  readonly unread: Scalars['Int'];
  readonly users?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum Messaging_Api_ChannelScopeEnum {
  ContractScope = 'CONTRACT_SCOPE',
  NotificationScope = 'NOTIFICATION_SCOPE',
  OfferScope = 'OFFER_SCOPE',
  PropertyManagersScope = 'PROPERTY_MANAGERS_SCOPE',
  PropertyScope = 'PROPERTY_SCOPE',
  PropertyTenantsScope = 'PROPERTY_TENANTS_SCOPE',
  UnknownChannelScope = 'UNKNOWN_CHANNEL_SCOPE',
  UserPropertyScope = 'USER_PROPERTY_SCOPE',
  UserScope = 'USER_SCOPE'
}

export type Messaging_Api_DeleteEventEnrichedOutput = {
  readonly __typename: 'messaging_api_DeleteEventEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly message?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly scope: Messaging_Api_ChannelScopeEnum;
};

export type Messaging_Api_DeleteMessagesByUserIdResponseOutput = {
  readonly __typename: 'messaging_api_DeleteMessagesByUserIDResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_DeleteResponseOutputOutput = {
  readonly __typename: 'messaging_api_DeleteResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_EditEventOutput = {
  readonly __typename: 'messaging_api_EditEventOutput';
  readonly message: Messaging_Api_MessageEnrichedOutput;
  readonly placeholder: Scalars['Boolean'];
};

export type Messaging_Api_EditResponseOutputOutput = {
  readonly __typename: 'messaging_api_EditResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_EmbedEnrichedOutput = {
  readonly __typename: 'messaging_api_EmbedEnrichedOutput';
  readonly account?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly embed_type: Messaging_Api_EmbedTypeEnum;
  readonly embed_value: Scalars['String'];
  readonly file?: Maybe<Document_Api_FileOutput>;
  readonly offer?: Maybe<Offer_Api_OfferEnrichedOutput>;
  readonly preview?: Maybe<Messaging_Api_EmbedPreviewValueEnrichedOutput>;
  readonly property?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly viewing?: Maybe<Viewing_Api_ViewingOutput>;
};

export type Messaging_Api_EmbedInput = {
  readonly embed_type?: InputMaybe<Scalars['Int']>;
  readonly embed_value?: InputMaybe<Scalars['String']>;
};

export type Messaging_Api_EmbedPreviewValueEnrichedOutput = {
  readonly __typename: 'messaging_api_EmbedPreviewValueEnrichedOutput';
  readonly audio?: Maybe<Messaging_Api_EmbedPreviewValue_AudioOutput>;
  readonly image?: Maybe<Messaging_Api_EmbedPreviewValue_ImageOutput>;
  readonly meta?: Maybe<Messaging_Api_EmbedPreviewValue_MetaOutput>;
  readonly video?: Maybe<Messaging_Api_EmbedPreviewValue_VideoOutput>;
};

export type Messaging_Api_EmbedPreviewValue_AudioOutput = {
  readonly __typename: 'messaging_api_EmbedPreviewValue_AudioOutput';
  readonly type: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Messaging_Api_EmbedPreviewValue_ImageOutput = {
  readonly __typename: 'messaging_api_EmbedPreviewValue_ImageOutput';
  readonly height: Scalars['Int'];
  readonly id: Scalars['String'];
  readonly type: Scalars['String'];
  readonly url: Scalars['String'];
  readonly width: Scalars['Int'];
};

export type Messaging_Api_EmbedPreviewValue_MetaOutput = {
  readonly __typename: 'messaging_api_EmbedPreviewValue_MetaOutput';
  readonly description: Scalars['String'];
  readonly site_name: Scalars['String'];
  readonly title: Scalars['String'];
  readonly type: Scalars['String'];
};

export type Messaging_Api_EmbedPreviewValue_VideoOutput = {
  readonly __typename: 'messaging_api_EmbedPreviewValue_VideoOutput';
  readonly height: Scalars['Int'];
  readonly type: Scalars['String'];
  readonly url: Scalars['String'];
  readonly width: Scalars['Int'];
};

export enum Messaging_Api_EmbedTypeEnum {
  EmbedTypeAuthOrigin = 'EMBED_TYPE_AUTH_ORIGIN',
  EmbedTypeContract = 'EMBED_TYPE_CONTRACT',
  EmbedTypeDate = 'EMBED_TYPE_DATE',
  EmbedTypeDays = 'EMBED_TYPE_DAYS',
  EmbedTypeDocument = 'EMBED_TYPE_DOCUMENT',
  EmbedTypeErrors = 'EMBED_TYPE_ERRORS',
  EmbedTypeExternalAudio = 'EMBED_TYPE_EXTERNAL_AUDIO',
  EmbedTypeExternalImage = 'EMBED_TYPE_EXTERNAL_IMAGE',
  EmbedTypeExternalVideo = 'EMBED_TYPE_EXTERNAL_VIDEO',
  EmbedTypeFile = 'EMBED_TYPE_FILE',
  EmbedTypeHours = 'EMBED_TYPE_HOURS',
  EmbedTypeInvitation = 'EMBED_TYPE_INVITATION',
  EmbedTypeInvoice = 'EMBED_TYPE_INVOICE',
  EmbedTypeJson = 'EMBED_TYPE_JSON',
  EmbedTypeListing = 'EMBED_TYPE_LISTING',
  EmbedTypeMaintenance = 'EMBED_TYPE_MAINTENANCE',
  EmbedTypeMinutes = 'EMBED_TYPE_MINUTES',
  EmbedTypeMonths = 'EMBED_TYPE_MONTHS',
  EmbedTypeMortgageLead = 'EMBED_TYPE_MORTGAGE_LEAD',
  EmbedTypeNotification = 'EMBED_TYPE_NOTIFICATION',
  EmbedTypeOffer = 'EMBED_TYPE_OFFER',
  EmbedTypePayment = 'EMBED_TYPE_PAYMENT',
  EmbedTypePaymentCost = 'EMBED_TYPE_PAYMENT_COST',
  EmbedTypePaymentItem = 'EMBED_TYPE_PAYMENT_ITEM',
  EmbedTypePaymentTime = 'EMBED_TYPE_PAYMENT_TIME',
  EmbedTypePreview = 'EMBED_TYPE_PREVIEW',
  EmbedTypeProcess = 'EMBED_TYPE_PROCESS',
  EmbedTypeProperty = 'EMBED_TYPE_PROPERTY',
  EmbedTypeSeconds = 'EMBED_TYPE_SECONDS',
  EmbedTypeTenancy = 'EMBED_TYPE_TENANCY',
  EmbedTypeTitle = 'EMBED_TYPE_TITLE',
  EmbedTypeToken = 'EMBED_TYPE_TOKEN',
  EmbedTypeUser = 'EMBED_TYPE_USER',
  EmbedTypeViewing = 'EMBED_TYPE_VIEWING',
  EmbedTypeWhatsappMediaUrl = 'EMBED_TYPE_WHATSAPP_MEDIA_URL',
  EmbedTypeYears = 'EMBED_TYPE_YEARS',
  UnknownEmbedType = 'UNKNOWN_EMBED_TYPE'
}

export type Messaging_Api_EventEnrichedOutput = {
  readonly __typename: 'messaging_api_EventEnrichedOutput';
  readonly event: MessagingEvent;
};

export type Messaging_Api_HistoryEventOutput = {
  readonly __typename: 'messaging_api_HistoryEventOutput';
  readonly messages?: Maybe<ReadonlyArray<Messaging_Api_MessageEnrichedOutput>>;
  readonly placeholder: Scalars['Boolean'];
};

export type Messaging_Api_HistoryResponseOutputOutput = {
  readonly __typename: 'messaging_api_HistoryResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  readonly messages?: Maybe<ReadonlyArray<Messaging_Api_MessageEnrichedOutput>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_InitEventOutput = {
  readonly __typename: 'messaging_api_InitEventOutput';
  readonly channels?: Maybe<ReadonlyArray<Messaging_Api_ChannelEnrichedOutput>>;
  readonly placeholder: Scalars['Boolean'];
};

export type Messaging_Api_JoinEventEnrichedOutput = {
  readonly __typename: 'messaging_api_JoinEventEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly scope: Messaging_Api_ChannelScopeEnum;
  readonly user: Scalars['String'];
};

export type Messaging_Api_JoinResponseOutputOutput = {
  readonly __typename: 'messaging_api_JoinResponseOutputOutput';
  readonly channel: Messaging_Api_ChannelEnrichedOutput;
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_LeaveEventEnrichedOutput = {
  readonly __typename: 'messaging_api_LeaveEventEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly scope: Messaging_Api_ChannelScopeEnum;
  readonly user: Scalars['String'];
};

export type Messaging_Api_LeaveResponseOutputOutput = {
  readonly __typename: 'messaging_api_LeaveResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_MarkAsReadResponseOutputOutput = {
  readonly __typename: 'messaging_api_MarkAsReadResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_MessageEnrichedOutput = {
  readonly __typename: 'messaging_api_MessageEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly created: Scalars['String'];
  readonly embeds?: Maybe<ReadonlyArray<Messaging_Api_EmbedEnrichedOutput>>;
  /** @deprecated use notification */
  readonly event: Notification_Api_EventTypeEnum;
  readonly id: Scalars['String'];
  readonly notification?: Maybe<Notification_Api_NotificationSpecOutput>;
  readonly scope: Messaging_Api_ChannelScopeEnum;
  readonly seen: Scalars['Boolean'];
  readonly text: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Messaging_Api_MessagingErrorEnum {
  ErrorAlreadyPerformed = 'ERROR_ALREADY_PERFORMED',
  ErrorAuthInvalid = 'ERROR_AUTH_INVALID',
  ErrorNotAllowed = 'ERROR_NOT_ALLOWED',
  ErrorNotOwnedMessage = 'ERROR_NOT_OWNED_MESSAGE',
  ErrorUnknown = 'ERROR_UNKNOWN',
  ErrorUnknownChannel = 'ERROR_UNKNOWN_CHANNEL',
  ErrorUnknownMessage = 'ERROR_UNKNOWN_MESSAGE',
  ErrorUnknownUser = 'ERROR_UNKNOWN_USER'
}

export type Messaging_Api_PingEventOutput = {
  readonly __typename: 'messaging_api_PingEventOutput';
  readonly corr: Scalars['String'];
  readonly sent: Scalars['String'];
};

export type Messaging_Api_PongResponseOutputOutput = {
  readonly __typename: 'messaging_api_PongResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export enum Messaging_Api_PresenceEnum {
  PresenceAway = 'PRESENCE_AWAY',
  PresenceInvisible = 'PRESENCE_INVISIBLE',
  PresenceOffline = 'PRESENCE_OFFLINE',
  PresenceOnline = 'PRESENCE_ONLINE',
  UnknownPresence = 'UNKNOWN_PRESENCE'
}

export type Messaging_Api_PresenceEventOutput = {
  readonly __typename: 'messaging_api_PresenceEventOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly users?: Maybe<ReadonlyArray<Messaging_Api_UserOutput>>;
};

export type Messaging_Api_PresenceResponseOutputOutput = {
  readonly __typename: 'messaging_api_PresenceResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_SeenEventEnrichedOutput = {
  readonly __typename: 'messaging_api_SeenEventEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly message?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly scope: Messaging_Api_ChannelScopeEnum;
  readonly user: Scalars['String'];
};

export type Messaging_Api_SeenResponseOutputOutput = {
  readonly __typename: 'messaging_api_SeenResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
  readonly unread: Scalars['Int'];
};

export type Messaging_Api_SendEventOutput = {
  readonly __typename: 'messaging_api_SendEventOutput';
  readonly message: Messaging_Api_MessageEnrichedOutput;
  readonly placeholder: Scalars['Boolean'];
};

export type Messaging_Api_SendResponseOutputOutput = {
  readonly __typename: 'messaging_api_SendResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_TypingEventEnrichedOutput = {
  readonly __typename: 'messaging_api_TypingEventEnrichedOutput';
  readonly channel: Scalars['String'];
  readonly scope: Messaging_Api_ChannelScopeEnum;
  readonly user: Scalars['String'];
};

export type Messaging_Api_TypingResponseOutputOutput = {
  readonly __typename: 'messaging_api_TypingResponseOutputOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_UnseenNotificationsResponseOutput = {
  readonly __typename: 'messaging_api_UnseenNotificationsResponseOutput';
  /** @deprecated Do not use */
  readonly errors?: Maybe<ReadonlyArray<Messaging_Api_MessagingErrorEnum>>;
  readonly messages?: Maybe<ReadonlyArray<Messaging_Api_MessageEnrichedOutput>>;
  /** @deprecated Do not use */
  readonly success: Scalars['Boolean'];
};

export type Messaging_Api_UserOutput = {
  readonly __typename: 'messaging_api_UserOutput';
  readonly presence: Messaging_Api_PresenceEnum;
  readonly user: Scalars['String'];
};

export type Messaging_Api_ViewingEventEnrichedOutput = {
  readonly __typename: 'messaging_api_ViewingEventEnrichedOutput';
  readonly ref: Messaging_Api_Viewing_ReferenceOutput;
  readonly subject: ViewingEventSubject;
};

export type Messaging_Api_ViewingOutput = {
  readonly __typename: 'messaging_api_ViewingOutput';
  readonly answers?: Maybe<ReadonlyArray<Messaging_Api_Viewing_SdpOutput>>;
  readonly broadcaster: Scalars['String'];
  readonly created: Scalars['String'];
  readonly offers?: Maybe<ReadonlyArray<Messaging_Api_Viewing_SdpOutput>>;
  readonly ref: Messaging_Api_Viewing_ReferenceOutput;
  readonly state: Messaging_Api_Viewing_StateEnum;
  readonly updated: Scalars['String'];
};

export type Messaging_Api_Viewing_PeerInput = {
  readonly session?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Messaging_Api_Viewing_PeerOutput = {
  readonly __typename: 'messaging_api_Viewing_PeerOutput';
  readonly session: Scalars['String'];
  readonly user: Scalars['String'];
};

export type Messaging_Api_Viewing_ReferenceInput = {
  readonly property?: InputMaybe<Scalars['String']>;
  readonly view_request?: InputMaybe<Scalars['String']>;
};

export type Messaging_Api_Viewing_ReferenceOutput = {
  readonly __typename: 'messaging_api_Viewing_ReferenceOutput';
  readonly property: Scalars['String'];
  readonly view_request: Scalars['String'];
};

export type Messaging_Api_Viewing_SdpOutput = {
  readonly __typename: 'messaging_api_Viewing_SDPOutput';
  readonly payload: Scalars['String'];
  readonly peer: Messaging_Api_Viewing_PeerOutput;
};

export enum Messaging_Api_Viewing_StateEnum {
  Completed = 'COMPLETED',
  NegotiationPending = 'NEGOTIATION_PENDING',
  Started = 'STARTED',
  Terminated = 'TERMINATED'
}

export enum Mortgage_Api_ApplicantTypeEnum {
  ApplicantTypeFirstTimeBuyer = 'APPLICANT_TYPE_FIRST_TIME_BUYER',
  ApplicantTypeUnknown = 'APPLICANT_TYPE_UNKNOWN'
}

export type Mortgage_Api_BookCallResponseOutput = {
  readonly __typename: 'mortgage_api_BookCallResponseOutput';
  readonly booking_id: Scalars['String'];
};

export type Mortgage_Api_BookingFilterListResponseOutput = {
  readonly __typename: 'mortgage_api_BookingFilterListResponseOutput';
  readonly bookings?: Maybe<ReadonlyArray<Mortgage_Api_BookingOutput>>;
  readonly total: Scalars['Int'];
};

export type Mortgage_Api_BookingOutput = {
  readonly __typename: 'mortgage_api_BookingOutput';
  readonly contacts: Mortgage_Api_ContactsOutput;
  readonly created: Scalars['String'];
  readonly criteria: Mortgage_Api_SourceCriteriaOutput;
  readonly id: Scalars['String'];
  readonly selected_product: Mortgage_Api_SourceResultEnrichedOutput;
  readonly user: Scalars['String'];
};

export type Mortgage_Api_ContactsInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
};

export type Mortgage_Api_ContactsOutput = {
  readonly __typename: 'mortgage_api_ContactsOutput';
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phone: Scalars['String'];
};

export type Mortgage_Api_DateRequestInput = {
  readonly from?: InputMaybe<Scalars['String']>;
  readonly to?: InputMaybe<Scalars['String']>;
};

export type Mortgage_Api_DealOutput = {
  readonly __typename: 'mortgage_api_DealOutput';
  readonly assigned_to: Scalars['String'];
  readonly created: Scalars['String'];
  readonly id: Scalars['String'];
  readonly notes?: Maybe<ReadonlyArray<Mortgage_Api_NoteOutput>>;
  readonly renewal_date: Scalars['String'];
  readonly source_request: Mortgage_Api_BookingOutput;
  readonly stage: Mortgage_Api_MortgageLeadStageEnum;
  readonly updated: Scalars['String'];
  readonly value: Scalars['Float'];
};

export type Mortgage_Api_LeadDealGetResponseOutput = {
  readonly __typename: 'mortgage_api_LeadDealGetResponseOutput';
  readonly deal: Mortgage_Api_DealOutput;
};

export type Mortgage_Api_LeadDealNoteGetResponseOutput = {
  readonly __typename: 'mortgage_api_LeadDealNoteGetResponseOutput';
  readonly note: Mortgage_Api_NoteOutput;
};

export type Mortgage_Api_LeadDealNoteSaveResponseOutput = {
  readonly __typename: 'mortgage_api_LeadDealNoteSaveResponseOutput';
  readonly note: Mortgage_Api_NoteOutput;
};

export type Mortgage_Api_LeadDealSaveResponseOutput = {
  readonly __typename: 'mortgage_api_LeadDealSaveResponseOutput';
  readonly deal: Mortgage_Api_DealOutput;
};

export type Mortgage_Api_LeadDocumentSaveResponseOutput = {
  readonly __typename: 'mortgage_api_LeadDocumentSaveResponseOutput';
  readonly document?: Maybe<Scalars['String']>;
};

export type Mortgage_Api_LeadGetResponseOutput = {
  readonly __typename: 'mortgage_api_LeadGetResponseOutput';
  readonly lead: Mortgage_Api_LeadOutput;
};

export type Mortgage_Api_LeadListResponseOutput = {
  readonly __typename: 'mortgage_api_LeadListResponseOutput';
  readonly leads?: Maybe<ReadonlyArray<Mortgage_Api_LeadOutput>>;
  readonly total: Scalars['Int'];
};

export type Mortgage_Api_LeadOutput = {
  readonly __typename: 'mortgage_api_LeadOutput';
  readonly admin_id: Scalars['String'];
  readonly assigned_to: Scalars['String'];
  readonly created: Scalars['String'];
  readonly deals?: Maybe<ReadonlyArray<Mortgage_Api_DealOutput>>;
  readonly documents?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly earnings: Scalars['Float'];
  readonly email: Scalars['String'];
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly note: Scalars['String'];
  readonly phone: Scalars['String'];
  readonly relationship_status: Mortgage_Api_RelationshipStatusEnum;
  readonly reminder_sent: Scalars['Boolean'];
  readonly schedule_date: Scalars['String'];
  readonly stage: Mortgage_Api_MortgageLeadStageEnum;
  readonly updated: Scalars['String'];
  readonly user_id: Scalars['String'];
};

export type Mortgage_Api_LeadSaveResponseOutput = {
  readonly __typename: 'mortgage_api_LeadSaveResponseOutput';
  readonly lead: Mortgage_Api_LeadOutput;
};

export type Mortgage_Api_LenderEnrichedOutput = {
  readonly __typename: 'mortgage_api_LenderEnrichedOutput';
  readonly address1: Scalars['String'];
  readonly address2: Scalars['String'];
  readonly address3: Scalars['String'];
  readonly affordability_calc_url: Scalars['String'];
  readonly code: Scalars['String'];
  readonly country: Scalars['String'];
  readonly email: Scalars['String'];
  readonly fax_number: Scalars['String'];
  readonly lender_criteria_url: Scalars['String'];
  readonly name: Scalars['String'];
  readonly phone_number: Scalars['String'];
  readonly post_code: Scalars['String'];
  readonly small_image?: Maybe<Document_Api_FileOutput>;
  readonly town: Scalars['String'];
  readonly web_site: Scalars['String'];
};

export enum Mortgage_Api_MortgageLeadStageEnum {
  Aip = 'AIP',
  Booked = 'BOOKED',
  FirstAppointment = 'FIRST_APPOINTMENT',
  Inactive = 'INACTIVE',
  PreCallCompleted = 'PRE_CALL_COMPLETED',
  PreCallUnsuccessful = 'PRE_CALL_UNSUCCESSFUL',
  SignUp = 'SIGN_UP'
}

export enum Mortgage_Api_MortgageTypeEnum {
  MortgageTypeBuyToLet = 'MORTGAGE_TYPE_BUY_TO_LET',
  MortgageTypeStandard = 'MORTGAGE_TYPE_STANDARD',
  MortgageTypeStandardOrSharedEquity = 'MORTGAGE_TYPE_STANDARD_OR_SHARED_EQUITY',
  MortgageTypeUnknown = 'MORTGAGE_TYPE_UNKNOWN'
}

export type Mortgage_Api_NoteOutput = {
  readonly __typename: 'mortgage_api_NoteOutput';
  readonly author: Scalars['String'];
  readonly created: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['String'];
  readonly title: Scalars['String'];
  readonly updated: Scalars['String'];
};

export enum Mortgage_Api_ReasonForMortgageEnum {
  ReasonForMortgagePurchase = 'REASON_FOR_MORTGAGE_PURCHASE',
  ReasonForMortgageRemortgage = 'REASON_FOR_MORTGAGE_REMORTGAGE',
  ReasonForMortgageUnknown = 'REASON_FOR_MORTGAGE_UNKNOWN'
}

export enum Mortgage_Api_RelationshipStatusEnum {
  InCivilUnion = 'IN_CIVIL_UNION',
  Married = 'MARRIED',
  RelationshipStatusUnknown = 'RELATIONSHIP_STATUS_UNKNOWN',
  Single = 'SINGLE',
  Windowed = 'WINDOWED'
}

export type Mortgage_Api_RunSourceResponseOutput = {
  readonly __typename: 'mortgage_api_RunSourceResponseOutput';
  readonly results?: Maybe<ReadonlyArray<Mortgage_Api_SourceResultEnrichedOutput>>;
  readonly source_id: Scalars['String'];
};

export type Mortgage_Api_SourceCriteriaOutput = {
  readonly __typename: 'mortgage_api_SourceCriteriaOutput';
  readonly applicant?: Maybe<Mortgage_Api_ApplicantTypeEnum>;
  readonly deposit: Scalars['Float'];
  readonly mortgage_type: Mortgage_Api_MortgageTypeEnum;
  readonly property_value: Scalars['Float'];
  readonly reason_for_mortgage: Mortgage_Api_ReasonForMortgageEnum;
  readonly term: Scalars['Int'];
};

export type Mortgage_Api_SourceResultEnrichedOutput = {
  readonly __typename: 'mortgage_api_SourceResultEnrichedOutput';
  readonly apr_lenders: Scalars['Float'];
  readonly fees_total: Scalars['Float'];
  readonly initial_monthly_payment: Scalars['Float'];
  readonly initial_pay_rate: Scalars['Float'];
  readonly initial_rate_period_months: Scalars['Int'];
  readonly lender_small_image?: Maybe<Document_Api_FileOutput>;
  readonly mortgage_class: Scalars['String'];
  readonly product_code: Scalars['String'];
  readonly standard_variable_rate: Scalars['Float'];
};

export enum Notes_Api_CorrelationTypeEnum {
  CorrelationTypeListing = 'CORRELATION_TYPE_LISTING',
  CorrelationTypeMortgage = 'CORRELATION_TYPE_MORTGAGE',
  CorrelationTypeProperty = 'CORRELATION_TYPE_PROPERTY',
  CorrelationTypeUnknown = 'CORRELATION_TYPE_UNKNOWN',
  CorrelationTypeUser = 'CORRELATION_TYPE_USER'
}

export type Notes_Api_NoteCreateResponseOutput = {
  readonly __typename: 'notes_api_NoteCreateResponseOutput';
  readonly note: Notes_Api_NoteOutput;
};

export type Notes_Api_NoteFilterResponseOutput = {
  readonly __typename: 'notes_api_NoteFilterResponseOutput';
  readonly notes?: Maybe<ReadonlyArray<Notes_Api_NoteOutput>>;
  readonly total: Scalars['Int'];
};

export type Notes_Api_NoteOutput = {
  readonly __typename: 'notes_api_NoteOutput';
  readonly correlated_property?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly correlated_user?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly correlation_id: Scalars['String'];
  readonly correlation_type: Notes_Api_CorrelationTypeEnum;
  readonly created: Scalars['String'];
  readonly id: Scalars['String'];
  readonly scheduled: Scalars['String'];
  readonly status: Notes_Api_StatusEnum;
  readonly text: Scalars['String'];
  readonly updated: Scalars['String'];
  readonly user?: Maybe<Account_Api_AccountEnrichedOutput>;
};

export type Notes_Api_NoteUpdateResponseOutput = {
  readonly __typename: 'notes_api_NoteUpdateResponseOutput';
  readonly note: Notes_Api_NoteOutput;
};

export enum Notes_Api_StatusEnum {
  StatusActive = 'STATUS_ACTIVE',
  StatusDisabled = 'STATUS_DISABLED',
  StatusUnknown = 'STATUS_UNKNOWN'
}

export enum Notification_Api_ActionEnum {
  ActionAccept = 'ACTION_ACCEPT',
  ActionCancel = 'ACTION_CANCEL',
  ActionComplete = 'ACTION_COMPLETE',
  ActionCreate = 'ACTION_CREATE',
  ActionExpire = 'ACTION_EXPIRE',
  ActionOwnersipVerificationCreate = 'ACTION_OWNERSIP_VERIFICATION_CREATE',
  ActionOwnersipVerificationResult = 'ACTION_OWNERSIP_VERIFICATION_RESULT',
  ActionPaymentLink = 'ACTION_PAYMENT_LINK',
  ActionPaymentOverdueLandlord = 'ACTION_PAYMENT_OVERDUE_LANDLORD',
  ActionPaymentOverdueTenant = 'ACTION_PAYMENT_OVERDUE_TENANT',
  ActionPending = 'ACTION_PENDING',
  ActionReject = 'ACTION_REJECT',
  ActionRemind = 'ACTION_REMIND',
  ActionRemoteSaleNewAccount = 'ACTION_REMOTE_SALE_NEW_ACCOUNT',
  ActionRemove = 'ACTION_REMOVE',
  ActionRtoSetupComplete = 'ACTION_RTO_SETUP_COMPLETE',
  ActionRtoStateActiveEssential = 'ACTION_RTO_STATE_ACTIVE_ESSENTIAL',
  ActionRtoStateActiveSecured = 'ACTION_RTO_STATE_ACTIVE_SECURED',
  ActionRtoStateWaitingForStandingOrderSetup = 'ACTION_RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP',
  ActionRtoTwoMonthsBeforeEndOfTenancyDate = 'ACTION_RTO_TWO_MONTHS_BEFORE_END_OF_TENANCY_DATE',
  ActionUnknown = 'ACTION_UNKNOWN',
  ActionUpdate = 'ACTION_UPDATE',
  ActionVerify = 'ACTION_VERIFY'
}

export enum Notification_Api_CategoryEnum {
  CategoryAccount = 'CATEGORY_ACCOUNT',
  CategoryComplianceProduct = 'CATEGORY_COMPLIANCE_PRODUCT',
  CategoryContracts = 'CATEGORY_CONTRACTS',
  CategoryDocument = 'CATEGORY_DOCUMENT',
  CategoryEndOfTenancy = 'CATEGORY_END_OF_TENANCY',
  CategoryHoldingDeposit = 'CATEGORY_HOLDING_DEPOSIT',
  CategoryInsurances = 'CATEGORY_INSURANCES',
  CategoryInvitation = 'CATEGORY_INVITATION',
  CategoryListing = 'CATEGORY_LISTING',
  CategoryMaintenance = 'CATEGORY_MAINTENANCE',
  CategoryOffer = 'CATEGORY_OFFER',
  CategoryPayment = 'CATEGORY_PAYMENT',
  CategoryReferencing = 'CATEGORY_REFERENCING',
  CategoryRenewal = 'CATEGORY_RENEWAL',
  CategoryUnknown = 'CATEGORY_UNKNOWN',
  CategoryViewing = 'CATEGORY_VIEWING'
}

export type Notification_Api_ContactsOutput = {
  readonly __typename: 'notification_api_ContactsOutput';
  readonly email: Scalars['String'];
  readonly fullname: Scalars['String'];
  readonly phone: Scalars['String'];
};

export enum Notification_Api_EntityEnum {
  EntityDocument = 'ENTITY_DOCUMENT',
  EntityDocumentExpiration = 'ENTITY_DOCUMENT_EXPIRATION',
  EntityInsuranceCiPayment = 'ENTITY_INSURANCE_CI_PAYMENT',
  EntityInsuranceHeiPayment = 'ENTITY_INSURANCE_HEI_PAYMENT',
  EntityInsuranceRgiPayment = 'ENTITY_INSURANCE_RGI_PAYMENT',
  EntityMessage = 'ENTITY_MESSAGE',
  EntityMortgageNote = 'ENTITY_MORTGAGE_NOTE',
  EntityOffer = 'ENTITY_OFFER',
  EntityOfferClause = 'ENTITY_OFFER_CLAUSE',
  EntityOfferDeposited = 'ENTITY_OFFER_DEPOSITED',
  EntityOfferDepositReplacement = 'ENTITY_OFFER_DEPOSIT_REPLACEMENT',
  EntityOfferExtraPayment = 'ENTITY_OFFER_EXTRA_PAYMENT',
  EntityOfferHoldingDepositPayment = 'ENTITY_OFFER_HOLDING_DEPOSIT_PAYMENT',
  EntityOfferOther = 'ENTITY_OFFER_OTHER',
  EntityOfferReferenceChecks = 'ENTITY_OFFER_REFERENCE_CHECKS',
  EntityOfferRenewal = 'ENTITY_OFFER_RENEWAL',
  EntityOfferRentAdvance = 'ENTITY_OFFER_RENT_ADVANCE',
  EntityOfferRentCollection = 'ENTITY_OFFER_RENT_COLLECTION',
  /** @deprecated deprecated */
  EntityOfferRentCollectionEssential = 'ENTITY_OFFER_RENT_COLLECTION_ESSENTIAL',
  EntityOfferRentCollectionManual = 'ENTITY_OFFER_RENT_COLLECTION_MANUAL',
  EntityOfferRentCollectionNotManual = 'ENTITY_OFFER_RENT_COLLECTION_NOT_MANUAL',
  /** @deprecated deprecated */
  EntityOfferRentCollectionSecured = 'ENTITY_OFFER_RENT_COLLECTION_SECURED',
  EntityOfferRentPayment = 'ENTITY_OFFER_RENT_PAYMENT',
  EntityOfferRto = 'ENTITY_OFFER_RTO',
  EntityOfferSignature = 'ENTITY_OFFER_SIGNATURE',
  EntityOfferSignatureInvitation = 'ENTITY_OFFER_SIGNATURE_INVITATION',
  EntityPayment = 'ENTITY_PAYMENT',
  EntityPaymentInvoice = 'ENTITY_PAYMENT_INVOICE',
  EntityPaymentMandate = 'ENTITY_PAYMENT_MANDATE',
  EntityPaymentOverdue = 'ENTITY_PAYMENT_OVERDUE',
  EntityPaymentTransaction = 'ENTITY_PAYMENT_TRANSACTION',
  EntityProductMuuv = 'ENTITY_PRODUCT_MUUV',
  EntityProperty = 'ENTITY_PROPERTY',
  EntityPropertyBankdetails = 'ENTITY_PROPERTY_BANKDETAILS',
  EntityPropertyInvitation = 'ENTITY_PROPERTY_INVITATION',
  EntityPropertyInvitationManager = 'ENTITY_PROPERTY_INVITATION_MANAGER',
  EntityPropertyInvitationManagerUnlisted = 'ENTITY_PROPERTY_INVITATION_MANAGER_UNLISTED',
  EntityPropertyInvitationReferLandlord = 'ENTITY_PROPERTY_INVITATION_REFER_LANDLORD',
  EntityPropertyIssue = 'ENTITY_PROPERTY_ISSUE',
  EntityPropertyIssueAssignContractor = 'ENTITY_PROPERTY_ISSUE_ASSIGN_CONTRACTOR',
  EntityPropertyIssueAssignLocalheroes = 'ENTITY_PROPERTY_ISSUE_ASSIGN_LOCALHEROES',
  EntityPropertyListing = 'ENTITY_PROPERTY_LISTING',
  EntityPropertyListingAmendment = 'ENTITY_PROPERTY_LISTING_AMENDMENT',
  EntityPropertyListingPayment = 'ENTITY_PROPERTY_LISTING_PAYMENT',
  EntityPropertyOwnershipVerif = 'ENTITY_PROPERTY_OWNERSHIP_VERIF',
  EntityPropertyPreface = 'ENTITY_PROPERTY_PREFACE',
  EntityPropertyPublication = 'ENTITY_PROPERTY_PUBLICATION',
  EntityPropertyQuestion = 'ENTITY_PROPERTY_QUESTION',
  EntityPropertyTask = 'ENTITY_PROPERTY_TASK',
  EntityPropertyTenancy = 'ENTITY_PROPERTY_TENANCY',
  EntityPropertyTenancyClaim = 'ENTITY_PROPERTY_TENANCY_CLAIM',
  EntityPropertyTenancyClaimDispute = 'ENTITY_PROPERTY_TENANCY_CLAIM_DISPUTE',
  EntityPropertyTenancyClaimPayment = 'ENTITY_PROPERTY_TENANCY_CLAIM_PAYMENT',
  EntityPropertyTenancyDeposit = 'ENTITY_PROPERTY_TENANCY_DEPOSIT',
  EntityPropertyTenancyTermination = 'ENTITY_PROPERTY_TENANCY_TERMINATION',
  EntityPropertyViewing = 'ENTITY_PROPERTY_VIEWING',
  EntityPropertyViewingBroadcasting = 'ENTITY_PROPERTY_VIEWING_BROADCASTING',
  EntityPropertyViewingFavorite = 'ENTITY_PROPERTY_VIEWING_FAVORITE',
  EntityPropertyViewingLettings = 'ENTITY_PROPERTY_VIEWING_LETTINGS',
  EntityPropertyViewingRarity = 'ENTITY_PROPERTY_VIEWING_RARITY',
  EntityPropertyViewingReschedule = 'ENTITY_PROPERTY_VIEWING_RESCHEDULE',
  EntityPropertyViewingReward = 'ENTITY_PROPERTY_VIEWING_REWARD',
  EntityUnknown = 'ENTITY_UNKNOWN',
  EntityUser = 'ENTITY_USER',
  EntityUserBankdetails = 'ENTITY_USER_BANKDETAILS',
  EntityUserEmail = 'ENTITY_USER_EMAIL',
  EntityUserPassword = 'ENTITY_USER_PASSWORD',
  EntityUserPhone = 'ENTITY_USER_PHONE',
  EntityUserSignin = 'ENTITY_USER_SIGNIN'
}

export enum Notification_Api_EventChannelEnum {
  EventChannelEmail = 'EVENT_CHANNEL_EMAIL',
  /** @deprecated deprecated */
  EventChannelEmailv2 = 'EVENT_CHANNEL_EMAILV2',
  EventChannelPlatform = 'EVENT_CHANNEL_PLATFORM',
  EventChannelSlack = 'EVENT_CHANNEL_SLACK',
  EventChannelSms = 'EVENT_CHANNEL_SMS',
  EventChannelWhatsapp = 'EVENT_CHANNEL_WHATSAPP',
  UnknownEventChannel = 'UNKNOWN_EVENT_CHANNEL'
}

export enum Notification_Api_EventTypeEnum {
  EventAdminUploadedDocNotifyLandlord = 'EVENT_ADMIN_UPLOADED_DOC_NOTIFY_LANDLORD',
  EventAdvancedRentPaymentConfirmedNotifyAdmin = 'EVENT_ADVANCED_RENT_PAYMENT_CONFIRMED_NOTIFY_ADMIN',
  EventAdvancedRentPaymentConfirmedNotifyLandlord = 'EVENT_ADVANCED_RENT_PAYMENT_CONFIRMED_NOTIFY_LANDLORD',
  EventAdvancedRentRequestAcceptedNotifyLandlord = 'EVENT_ADVANCED_RENT_REQUEST_ACCEPTED_NOTIFY_LANDLORD',
  EventAdvancedRentRequestAcceptedNotifyTenant = 'EVENT_ADVANCED_RENT_REQUEST_ACCEPTED_NOTIFY_TENANT',
  EventAdvancedRentRequestCancelledNotifyAdmin = 'EVENT_ADVANCED_RENT_REQUEST_CANCELLED_NOTIFY_ADMIN',
  EventAdvancedRentRequestCreatedNotifyAdmin = 'EVENT_ADVANCED_RENT_REQUEST_CREATED_NOTIFY_ADMIN',
  EventAdvancedRentRequestDeniedNotifyLandlord = 'EVENT_ADVANCED_RENT_REQUEST_DENIED_NOTIFY_LANDLORD',
  EventAuthSignUp = 'EVENT_AUTH_SIGN_UP',
  EventContractClauseAcceptLandlord = 'EVENT_CONTRACT_CLAUSE_ACCEPT_LANDLORD',
  EventContractClauseCreatedTenant = 'EVENT_CONTRACT_CLAUSE_CREATED_TENANT',
  EventContractClauseDeletedTenant = 'EVENT_CONTRACT_CLAUSE_DELETED_TENANT',
  EventContractClauseEditedTenant = 'EVENT_CONTRACT_CLAUSE_EDITED_TENANT',
  EventContractClauseRejectLandlord = 'EVENT_CONTRACT_CLAUSE_REJECT_LANDLORD',
  EventContractLandlordClauseCreatedNotifyTenant = 'EVENT_CONTRACT_LANDLORD_CLAUSE_CREATED_NOTIFY_TENANT',
  EventContractLandlordClauseEditedNotifyTenant = 'EVENT_CONTRACT_LANDLORD_CLAUSE_EDITED_NOTIFY_TENANT',
  EventContractLandlordProceededNotifyTenant = 'EVENT_CONTRACT_LANDLORD_PROCEEDED_NOTIFY_TENANT',
  EventContractLandlordProceededRemindTenant_2Hours = 'EVENT_CONTRACT_LANDLORD_PROCEEDED_REMIND_TENANT_2HOURS',
  EventContractTenantProceededNotifyLandlord = 'EVENT_CONTRACT_TENANT_PROCEEDED_NOTIFY_LANDLORD',
  EventContractTenantProceededRemindLandlord_2Hours = 'EVENT_CONTRACT_TENANT_PROCEEDED_REMIND_LANDLORD_2HOURS',
  EventDocumentExpiresReminder = 'EVENT_DOCUMENT_EXPIRES_REMINDER',
  EventHoldingDeposit_12HoursRemindTenant = 'EVENT_HOLDING_DEPOSIT_12HOURS_REMIND_TENANT',
  EventHoldingDepositBankConfirmPaymentNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_BANK_CONFIRM_PAYMENT_NOTIFY_LANDLORD',
  EventHoldingDepositBankConfirmPaymentNotifyTenant = 'EVENT_HOLDING_DEPOSIT_BANK_CONFIRM_PAYMENT_NOTIFY_TENANT',
  EventHoldingDepositBankVerifyingPaymentNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_BANK_VERIFYING_PAYMENT_NOTIFY_LANDLORD',
  EventHoldingDepositBankVerifyingPaymentNotifyTenant = 'EVENT_HOLDING_DEPOSIT_BANK_VERIFYING_PAYMENT_NOTIFY_TENANT',
  EventHoldingDepositExpired_24HoursNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_EXPIRED_24HOURS_NOTIFY_LANDLORD',
  EventHoldingDepositExpiredNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_EXPIRED_NOTIFY_LANDLORD',
  EventHoldingDepositLandlordWithdrawnNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_LANDLORD_WITHDRAWN_NOTIFY_LANDLORD',
  EventHoldingDepositLandlordWithdrawnNotifyTenant = 'EVENT_HOLDING_DEPOSIT_LANDLORD_WITHDRAWN_NOTIFY_TENANT',
  EventHoldingDepositTenantWithdrawnNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_TENANT_WITHDRAWN_NOTIFY_LANDLORD',
  EventHoldingDepositTenantWithdrawnNotifyTenant = 'EVENT_HOLDING_DEPOSIT_TENANT_WITHDRAWN_NOTIFY_TENANT',
  EventHoldingDepositTokenioConfirmPaymentNotifyLandlord = 'EVENT_HOLDING_DEPOSIT_TOKENIO_CONFIRM_PAYMENT_NOTIFY_LANDLORD',
  EventInvitationAccepted = 'EVENT_INVITATION_ACCEPTED',
  EventInvitationContactSigning = 'EVENT_INVITATION_CONTACT_SIGNING',
  EventInvitationContactValidation = 'EVENT_INVITATION_CONTACT_VALIDATION',
  EventInvitationCreate = 'EVENT_INVITATION_CREATE',
  EventInvitationCreateLandlord = 'EVENT_INVITATION_CREATE_LANDLORD',
  EventInvitationCreateManagerToLandlord = 'EVENT_INVITATION_CREATE_MANAGER_TO_LANDLORD',
  EventInvitationCreateTenant = 'EVENT_INVITATION_CREATE_TENANT',
  EventInvitationIgnored = 'EVENT_INVITATION_IGNORED',
  EventInvitationRejected = 'EVENT_INVITATION_REJECTED',
  EventInvitationToLandlordNoResponseOneDay = 'EVENT_INVITATION_TO_LANDLORD_NO_RESPONSE_ONE_DAY',
  EventInvitationToLandlordNoResponseThreeDays = 'EVENT_INVITATION_TO_LANDLORD_NO_RESPONSE_THREE_DAYS',
  EventInvitationToTenantNoResponseOneDay = 'EVENT_INVITATION_TO_TENANT_NO_RESPONSE_ONE_DAY',
  EventInvitationToTenantNoResponseThreeDays = 'EVENT_INVITATION_TO_TENANT_NO_RESPONSE_THREE_DAYS',
  EventLessThan_3ViewingsInAWeekNotifyLandlord = 'EVENT_LESS_THAN_3_VIEWINGS_IN_A_WEEK_NOTIFY_LANDLORD',
  EventListingAccounterrors = 'EVENT_LISTING_ACCOUNTERRORS',
  EventListingAlreadylisted = 'EVENT_LISTING_ALREADYLISTED',
  EventListingAwaitinglandlord = 'EVENT_LISTING_AWAITINGLANDLORD',
  EventListingAwaitingpublication = 'EVENT_LISTING_AWAITINGPUBLICATION',
  EventListingAwaitingVerificationLandlord = 'EVENT_LISTING_AWAITING_VERIFICATION_LANDLORD',
  EventListingDenied = 'EVENT_LISTING_DENIED',
  EventListingErrors = 'EVENT_LISTING_ERRORS',
  EventListingExpiryDateThreeDaysAdmin = 'EVENT_LISTING_EXPIRY_DATE_THREE_DAYS_ADMIN',
  EventListingExpiryDateThreeDaysLandlord = 'EVENT_LISTING_EXPIRY_DATE_THREE_DAYS_LANDLORD',
  EventListingExpiryDateZeroDaysAdmin = 'EVENT_LISTING_EXPIRY_DATE_ZERO_DAYS_ADMIN',
  EventListingInprocess = 'EVENT_LISTING_INPROCESS',
  EventListingInvitationRejectedLandlord = 'EVENT_LISTING_INVITATION_REJECTED_LANDLORD',
  EventListingInvitationRejectedTenant = 'EVENT_LISTING_INVITATION_REJECTED_TENANT',
  EventListingListed = 'EVENT_LISTING_LISTED',
  EventListingListedTenant = 'EVENT_LISTING_LISTED_TENANT',
  EventListingNeeddocument = 'EVENT_LISTING_NEEDDOCUMENT',
  EventListingNeedpay = 'EVENT_LISTING_NEEDPAY',
  EventListingNolandlord = 'EVENT_LISTING_NOLANDLORD',
  EventListingNotauthorized = 'EVENT_LISTING_NOTAUTHORIZED',
  EventListingSuccess = 'EVENT_LISTING_SUCCESS',
  EventMaintenanceAssigned = 'EVENT_MAINTENANCE_ASSIGNED',
  EventMaintenanceCreateLandlord = 'EVENT_MAINTENANCE_CREATE_LANDLORD',
  EventMaintenanceCreateTenant = 'EVENT_MAINTENANCE_CREATE_TENANT',
  EventMaintenanceLandlordAssignsContractorNotifyLandlord = 'EVENT_MAINTENANCE_LANDLORD_ASSIGNS_CONTRACTOR_NOTIFY_LANDLORD',
  EventMaintenanceLandlordAssignsContractorNotifyTenant = 'EVENT_MAINTENANCE_LANDLORD_ASSIGNS_CONTRACTOR_NOTIFY_TENANT',
  EventMaintenanceLandlordAssignsLocalheroesNotifyLandlord = 'EVENT_MAINTENANCE_LANDLORD_ASSIGNS_LOCALHEROES_NOTIFY_LANDLORD',
  EventMaintenanceLandlordAssignsLocalheroesNotifyTenant = 'EVENT_MAINTENANCE_LANDLORD_ASSIGNS_LOCALHEROES_NOTIFY_TENANT',
  EventMaintenanceLandlordCreatesIssueNotifyLandlord = 'EVENT_MAINTENANCE_LANDLORD_CREATES_ISSUE_NOTIFY_LANDLORD',
  EventMaintenanceLandlordCreatesIssueNotifyTenant = 'EVENT_MAINTENANCE_LANDLORD_CREATES_ISSUE_NOTIFY_TENANT',
  EventMaintenanceResolved = 'EVENT_MAINTENANCE_RESOLVED',
  EventMaintenanceTaskNotifyUser = 'EVENT_MAINTENANCE_TASK_NOTIFY_USER',
  EventMaintenanceTenantCreatesIssueNotifyLandlord = 'EVENT_MAINTENANCE_TENANT_CREATES_ISSUE_NOTIFY_LANDLORD',
  EventMaintenanceTenantCreatesIssueNotifyTenant = 'EVENT_MAINTENANCE_TENANT_CREATES_ISSUE_NOTIFY_TENANT',
  EventMaintenanceUpdateLandlord = 'EVENT_MAINTENANCE_UPDATE_LANDLORD',
  EventMaintenanceUpdateTenant = 'EVENT_MAINTENANCE_UPDATE_TENANT',
  EventMessageReceived = 'EVENT_MESSAGE_RECEIVED',
  EventMortgageReengageReminder = 'EVENT_MORTGAGE_REENGAGE_REMINDER',
  EventNewMessageReceived = 'EVENT_NEW_MESSAGE_RECEIVED',
  EventOfferAcceptedConfirmLandlord = 'EVENT_OFFER_ACCEPTED_CONFIRM_LANDLORD',
  EventOfferAcceptedWithdrawnConfirmTenant = 'EVENT_OFFER_ACCEPTED_WITHDRAWN_CONFIRM_TENANT',
  EventOfferAcceptedWithdrawnNotifyLandlord = 'EVENT_OFFER_ACCEPTED_WITHDRAWN_NOTIFY_LANDLORD',
  EventOfferAcceptTenant = 'EVENT_OFFER_ACCEPT_TENANT',
  EventOfferCancelManager = 'EVENT_OFFER_CANCEL_MANAGER',
  EventOfferDeclineTenant = 'EVENT_OFFER_DECLINE_TENANT',
  EventOfferDeniedAfterDepositAdmin = 'EVENT_OFFER_DENIED_AFTER_DEPOSIT_ADMIN',
  EventOfferDeniedAfterDepositTenant = 'EVENT_OFFER_DENIED_AFTER_DEPOSIT_TENANT',
  EventOfferGuarantorRequiredTenant = 'EVENT_OFFER_GUARANTOR_REQUIRED_TENANT',
  EventOfferHoldingDepositConfirmManager = 'EVENT_OFFER_HOLDING_DEPOSIT_CONFIRM_MANAGER',
  EventOfferHoldingDepositConfirmTenant = 'EVENT_OFFER_HOLDING_DEPOSIT_CONFIRM_TENANT',
  EventOfferHoldingDepositNotPaidManager = 'EVENT_OFFER_HOLDING_DEPOSIT_NOT_PAID_MANAGER',
  EventOfferHoldingDepositNotPaidTenant = 'EVENT_OFFER_HOLDING_DEPOSIT_NOT_PAID_TENANT',
  EventOfferJourneyCompleteNotifyLandlord = 'EVENT_OFFER_JOURNEY_COMPLETE_NOTIFY_LANDLORD',
  EventOfferLandlordAcceptedOtherTenant = 'EVENT_OFFER_LANDLORD_ACCEPTED_OTHER_TENANT',
  EventOfferReceivedNotRespondedRemindLandlord_1WeekLeft = 'EVENT_OFFER_RECEIVED_NOT_RESPONDED_REMIND_LANDLORD_1WEEK_LEFT',
  EventOfferReceivedNotRespondedRemindLandlord_3Hrs = 'EVENT_OFFER_RECEIVED_NOT_RESPONDED_REMIND_LANDLORD_3HRS',
  EventOfferReceivedNotRespondedRemindTenant_1WeekLeft = 'EVENT_OFFER_RECEIVED_NOT_RESPONDED_REMIND_TENANT_1WEEK_LEFT',
  EventOfferRejectedConfirmLandlord = 'EVENT_OFFER_REJECTED_CONFIRM_LANDLORD',
  EventOfferRejectedNotifyTenant = 'EVENT_OFFER_REJECTED_NOTIFY_TENANT',
  EventOfferRequestManager = 'EVENT_OFFER_REQUEST_MANAGER',
  EventOfferSentConfirmTenant = 'EVENT_OFFER_SENT_CONFIRM_TENANT',
  EventOfferServicePaidAdmin = 'EVENT_OFFER_SERVICE_PAID_ADMIN',
  EventOfferSubmitted = 'EVENT_OFFER_SUBMITTED',
  EventOfferSubmittedLandlord = 'EVENT_OFFER_SUBMITTED_LANDLORD',
  EventOfferTenancyClaimEndNotifyLandlord = 'EVENT_OFFER_TENANCY_CLAIM_END_NOTIFY_LANDLORD',
  EventOfferTenancyClaimEndNotifyTenant = 'EVENT_OFFER_TENANCY_CLAIM_END_NOTIFY_TENANT',
  EventOfferTenancyConfirmedTerminateNotifyLandlord = 'EVENT_OFFER_TENANCY_CONFIRMED_TERMINATE_NOTIFY_LANDLORD',
  EventOfferTenancyEndedDepositReleasedNotifyTenant = 'EVENT_OFFER_TENANCY_ENDED_DEPOSIT_RELEASED_NOTIFY_TENANT',
  EventOfferTenancyEndedDepositReminder_3DaysNotifyLandlord = 'EVENT_OFFER_TENANCY_ENDED_DEPOSIT_REMINDER_3DAYS_NOTIFY_LANDLORD',
  EventOfferTenancyEndedDepositReminderNotifyLandlord = 'EVENT_OFFER_TENANCY_ENDED_DEPOSIT_REMINDER_NOTIFY_LANDLORD',
  EventOfferTenancyEndedNotifyLandlord = 'EVENT_OFFER_TENANCY_ENDED_NOTIFY_LANDLORD',
  EventOfferTenancyEndNotifyLandlord = 'EVENT_OFFER_TENANCY_END_NOTIFY_LANDLORD',
  EventOfferTenancyEndNotifyTenant = 'EVENT_OFFER_TENANCY_END_NOTIFY_TENANT',
  EventOfferTenancyLandlordTerminateNotifyTenant = 'EVENT_OFFER_TENANCY_LANDLORD_TERMINATE_NOTIFY_TENANT',
  EventOfferTenancyNearEndNotifyLandlord = 'EVENT_OFFER_TENANCY_NEAR_END_NOTIFY_LANDLORD',
  EventOfferTenancyNearEndNotifyLandlordReminder = 'EVENT_OFFER_TENANCY_NEAR_END_NOTIFY_LANDLORD_REMINDER',
  EventOfferTenancyNearEndNotifyTenant = 'EVENT_OFFER_TENANCY_NEAR_END_NOTIFY_TENANT',
  EventOfferTenancyOfferedRenewalNotifyTenant = 'EVENT_OFFER_TENANCY_OFFERED_RENEWAL_NOTIFY_TENANT',
  EventOfferTenancyTenantTerminateNotifyLandlord = 'EVENT_OFFER_TENANCY_TENANT_TERMINATE_NOTIFY_LANDLORD',
  EventOfferTenancyTenantTerminateNotifyTenant = 'EVENT_OFFER_TENANCY_TENANT_TERMINATE_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimAgreed_3DaysNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_AGREED_3DAYS_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimAgreed_7DaysNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_AGREED_7DAYS_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimAgreedNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_AGREED_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimAgreeNotifyLandlord = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_AGREE_NOTIFY_LANDLORD',
  EventOfferTenancyTerminateClaimDisputed_1DayNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_DISPUTED_1DAY_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimDisputed_2DayNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_DISPUTED_2DAY_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimDisputedLastChanceNotifyLandlord = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_DISPUTED_LAST_CHANCE_NOTIFY_LANDLORD',
  EventOfferTenancyTerminateClaimDisputedLastChanceNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_DISPUTED_LAST_CHANCE_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimDisputedNotifyLandlord = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_DISPUTED_NOTIFY_LANDLORD',
  EventOfferTenancyTerminateClaimDisputedNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_DISPUTED_NOTIFY_TENANT',
  EventOfferTenancyTerminateClaimPaidNotifyLandlord = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_PAID_NOTIFY_LANDLORD',
  EventOfferTenancyTerminateClaimRaisedConfirmLandlord = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_RAISED_CONFIRM_LANDLORD',
  EventOfferTenancyTerminateClaimRaisedNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_CLAIM_RAISED_NOTIFY_TENANT',
  EventOfferTenancyTerminateDepositReleasedNotifyLandlord = 'EVENT_OFFER_TENANCY_TERMINATE_DEPOSIT_RELEASED_NOTIFY_LANDLORD',
  EventOfferTenancyTerminateNoClaimsNotifyTenant = 'EVENT_OFFER_TENANCY_TERMINATE_NO_CLAIMS_NOTIFY_TENANT',
  EventOfferTenantUpdatedConfirmTenant = 'EVENT_OFFER_TENANT_UPDATED_CONFIRM_TENANT',
  EventOfferUpdateManager = 'EVENT_OFFER_UPDATE_MANAGER',
  EventOfferVerificationLandlord = 'EVENT_OFFER_VERIFICATION_LANDLORD',
  EventOfferVerificationSuccessLandlord = 'EVENT_OFFER_VERIFICATION_SUCCESS_LANDLORD',
  EventOfferVerificationSuccessTenant = 'EVENT_OFFER_VERIFICATION_SUCCESS_TENANT',
  EventOfferVerificationTenant = 'EVENT_OFFER_VERIFICATION_TENANT',
  EventOfferWithdrawnLandlord = 'EVENT_OFFER_WITHDRAWN_LANDLORD',
  EventPasswordReset = 'EVENT_PASSWORD_RESET',
  EventPaymentInactiveMandate = 'EVENT_PAYMENT_INACTIVE_MANDATE',
  EventPaymentMashroomInvoice = 'EVENT_PAYMENT_MASHROOM_INVOICE',
  EventPaymentMissing = 'EVENT_PAYMENT_MISSING',
  EventPaymentNewUploaded = 'EVENT_PAYMENT_NEW_UPLOADED',
  EventPaymentPaidExtraLandlord = 'EVENT_PAYMENT_PAID_EXTRA_LANDLORD',
  EventPaymentPaidExtraTenant = 'EVENT_PAYMENT_PAID_EXTRA_TENANT',
  EventPaymentPaidHeiLandlord = 'EVENT_PAYMENT_PAID_HEI_LANDLORD',
  EventPaymentPaidLandlord = 'EVENT_PAYMENT_PAID_LANDLORD',
  EventPaymentPaidRgiLandlord = 'EVENT_PAYMENT_PAID_RGI_LANDLORD',
  EventPaymentPaidTenant = 'EVENT_PAYMENT_PAID_TENANT',
  EventPaymentPending = 'EVENT_PAYMENT_PENDING',
  EventPaymentReadyMoveinTenant = 'EVENT_PAYMENT_READY_MOVEIN_TENANT',
  EventPaymentUnpaidRemindLandlordEightDays = 'EVENT_PAYMENT_UNPAID_REMIND_LANDLORD_EIGHT_DAYS',
  EventPaymentUnpaidRemindLandlordFourDays = 'EVENT_PAYMENT_UNPAID_REMIND_LANDLORD_FOUR_DAYS',
  EventPaymentUnpaidRemindLandlordSixDays = 'EVENT_PAYMENT_UNPAID_REMIND_LANDLORD_SIX_DAYS',
  EventPaymentUnpaidRemindLandlordTenDays = 'EVENT_PAYMENT_UNPAID_REMIND_LANDLORD_TEN_DAYS',
  EventPaymentUnpaidRemindLandlordTwelveDays = 'EVENT_PAYMENT_UNPAID_REMIND_LANDLORD_TWELVE_DAYS',
  EventPaymentUnpaidRemindLandlordTwoDays = 'EVENT_PAYMENT_UNPAID_REMIND_LANDLORD_TWO_DAYS',
  EventPaymentUnpaidRemindTenantEightDays = 'EVENT_PAYMENT_UNPAID_REMIND_TENANT_EIGHT_DAYS',
  EventPaymentUnpaidRemindTenantFourDays = 'EVENT_PAYMENT_UNPAID_REMIND_TENANT_FOUR_DAYS',
  EventPaymentUnpaidRemindTenantSixDays = 'EVENT_PAYMENT_UNPAID_REMIND_TENANT_SIX_DAYS',
  EventPaymentUnpaidRemindTenantTenDays = 'EVENT_PAYMENT_UNPAID_REMIND_TENANT_TEN_DAYS',
  EventPaymentUnpaidRemindTenantTwelveDays = 'EVENT_PAYMENT_UNPAID_REMIND_TENANT_TWELVE_DAYS',
  EventPaymentUnpaidRemindTenantTwoDays = 'EVENT_PAYMENT_UNPAID_REMIND_TENANT_TWO_DAYS',
  EventPortalResponse = 'EVENT_PORTAL_RESPONSE',
  EventPropertyIncompleteOneDayLandlord = 'EVENT_PROPERTY_INCOMPLETE_ONE_DAY_LANDLORD',
  EventPropertyIncompleteOneWeekLandlord = 'EVENT_PROPERTY_INCOMPLETE_ONE_WEEK_LANDLORD',
  EventPropertyIncompleteSixHourLandlord = 'EVENT_PROPERTY_INCOMPLETE_SIX_HOUR_LANDLORD',
  EventPropertyIncompleteThreeDaysLandlord = 'EVENT_PROPERTY_INCOMPLETE_THREE_DAYS_LANDLORD',
  EventPropertyLinkTenant = 'EVENT_PROPERTY_LINK_TENANT',
  EventPropertyManageViewingByLandlordTenant = 'EVENT_PROPERTY_MANAGE_VIEWING_BY_LANDLORD_TENANT',
  EventPropertyManageViewingByTenantTenant = 'EVENT_PROPERTY_MANAGE_VIEWING_BY_TENANT_TENANT',
  EventPropertyPublishedManager = 'EVENT_PROPERTY_PUBLISHED_MANAGER',
  EventPropertyUnpublishedManager = 'EVENT_PROPERTY_UNPUBLISHED_MANAGER',
  EventPropertyWithdrawn = 'EVENT_PROPERTY_WITHDRAWN',
  EventSigningReadyManager = 'EVENT_SIGNING_READY_MANAGER',
  EventSigningReadyTenant = 'EVENT_SIGNING_READY_TENANT',
  EventSigningSignedManager = 'EVENT_SIGNING_SIGNED_MANAGER',
  EventSigningSignedTenant = 'EVENT_SIGNING_SIGNED_TENANT',
  EventTeamupLandlordAcceptsViewingHostNotifyLandlord = 'EVENT_TEAMUP_LANDLORD_ACCEPTS_VIEWING_HOST_NOTIFY_LANDLORD',
  EventTeamupLandlordAcceptsViewingHostNotifyTenant = 'EVENT_TEAMUP_LANDLORD_ACCEPTS_VIEWING_HOST_NOTIFY_TENANT',
  EventTeamupLandlordDeclinesViewingHostNotifyLandlord = 'EVENT_TEAMUP_LANDLORD_DECLINES_VIEWING_HOST_NOTIFY_LANDLORD',
  EventTeamupLandlordDeclinesViewingHostNotifyTenant = 'EVENT_TEAMUP_LANDLORD_DECLINES_VIEWING_HOST_NOTIFY_TENANT',
  EventTeamupLandlordEndViewingHostNotifyLandlord = 'EVENT_TEAMUP_LANDLORD_END_VIEWING_HOST_NOTIFY_LANDLORD',
  EventTeamupLandlordEndViewingHostNotifyTenant = 'EVENT_TEAMUP_LANDLORD_END_VIEWING_HOST_NOTIFY_TENANT',
  EventTeamupTenantAcceptsViewingHostBeforeListedNotifyLandlord = 'EVENT_TEAMUP_TENANT_ACCEPTS_VIEWING_HOST_BEFORE_LISTED_NOTIFY_LANDLORD',
  EventTeamupTenantAcceptsViewingHostNotifyLandlord = 'EVENT_TEAMUP_TENANT_ACCEPTS_VIEWING_HOST_NOTIFY_LANDLORD',
  EventTeamupTenantAcceptsViewingHostNotifyTenant = 'EVENT_TEAMUP_TENANT_ACCEPTS_VIEWING_HOST_NOTIFY_TENANT',
  EventTeamupTenantDeclinesViewingHostNotifyLandlord = 'EVENT_TEAMUP_TENANT_DECLINES_VIEWING_HOST_NOTIFY_LANDLORD',
  EventTeamupTenantInvites_24HourReminderLandlord = 'EVENT_TEAMUP_TENANT_INVITES_24HOUR_REMINDER_LANDLORD',
  EventTeamupTenantInvitesLandlordNotifyTenant = 'EVENT_TEAMUP_TENANT_INVITES_LANDLORD_NOTIFY_TENANT',
  EventTeamupTenantRequestsEndViewingHostNotifyLandlord = 'EVENT_TEAMUP_TENANT_REQUESTS_END_VIEWING_HOST_NOTIFY_LANDLORD',
  EventTeamupTenantRequestsEndViewingHostNotifyTenant = 'EVENT_TEAMUP_TENANT_REQUESTS_END_VIEWING_HOST_NOTIFY_TENANT',
  EventViewingAcceptedRemind_2Day = 'EVENT_VIEWING_ACCEPTED_REMIND_2DAY',
  EventViewingAcceptedRemindLandlord_1Day = 'EVENT_VIEWING_ACCEPTED_REMIND_LANDLORD_1DAY',
  EventViewingAcceptedRemindLandlord_12Hour = 'EVENT_VIEWING_ACCEPTED_REMIND_LANDLORD_12HOUR',
  EventViewingAcceptedRemindTenant_1Day = 'EVENT_VIEWING_ACCEPTED_REMIND_TENANT_1DAY',
  EventViewingAcceptedRemindTenant_12Hour = 'EVENT_VIEWING_ACCEPTED_REMIND_TENANT_12HOUR',
  EventViewingAcceptTenant = 'EVENT_VIEWING_ACCEPT_TENANT',
  EventViewingBroadcastingStarted = 'EVENT_VIEWING_BROADCASTING_STARTED',
  EventViewingCompleteManager = 'EVENT_VIEWING_COMPLETE_MANAGER',
  EventViewingCompleteManager_20Mins = 'EVENT_VIEWING_COMPLETE_MANAGER_20MINS',
  EventViewingCompleteRemind_1Day = 'EVENT_VIEWING_COMPLETE_REMIND_1DAY',
  EventViewingCompleteRemind_12Hour = 'EVENT_VIEWING_COMPLETE_REMIND_12HOUR',
  EventViewingCompleteTenantFav = 'EVENT_VIEWING_COMPLETE_TENANT_FAV',
  EventViewingDeclineTenant = 'EVENT_VIEWING_DECLINE_TENANT',
  EventViewingHostChanged = 'EVENT_VIEWING_HOST_CHANGED',
  EventViewingRequestManager = 'EVENT_VIEWING_REQUEST_MANAGER',
  EventViewingRequestManagerCancel = 'EVENT_VIEWING_REQUEST_MANAGER_CANCEL',
  EventViewingRequestManagerExpired = 'EVENT_VIEWING_REQUEST_MANAGER_EXPIRED',
  EventViewingRequestManagerRemind_3Hours = 'EVENT_VIEWING_REQUEST_MANAGER_REMIND_3HOURS',
  EventViewingRequestManagerRemind_12Hours = 'EVENT_VIEWING_REQUEST_MANAGER_REMIND_12HOURS',
  EventViewingRequestTenant = 'EVENT_VIEWING_REQUEST_TENANT',
  EventViewingRequestTenantExpired = 'EVENT_VIEWING_REQUEST_TENANT_EXPIRED',
  EventViewingRequestTenantFav = 'EVENT_VIEWING_REQUEST_TENANT_FAV',
  EventViewingRescheduleAcceptedLandlord = 'EVENT_VIEWING_RESCHEDULE_ACCEPTED_LANDLORD',
  EventViewingRescheduleAcceptLandlord = 'EVENT_VIEWING_RESCHEDULE_ACCEPT_LANDLORD',
  EventViewingRescheduleAcceptManager = 'EVENT_VIEWING_RESCHEDULE_ACCEPT_MANAGER',
  EventViewingRescheduleAcceptTenant = 'EVENT_VIEWING_RESCHEDULE_ACCEPT_TENANT',
  EventViewingRescheduleRequestManager = 'EVENT_VIEWING_RESCHEDULE_REQUEST_MANAGER',
  EventViewingRescheduleRequestManagerConfirm = 'EVENT_VIEWING_RESCHEDULE_REQUEST_MANAGER_CONFIRM',
  EventViewingRescheduleRequestTenant = 'EVENT_VIEWING_RESCHEDULE_REQUEST_TENANT',
  EventViewingRescheduleRequestTenantConfirm = 'EVENT_VIEWING_RESCHEDULE_REQUEST_TENANT_CONFIRM',
  EventViewingRewardPaid = 'EVENT_VIEWING_REWARD_PAID',
  EventViewingTodayNotifyLandlord = 'EVENT_VIEWING_TODAY_NOTIFY_LANDLORD',
  EventViewingTodayNotifyTenant = 'EVENT_VIEWING_TODAY_NOTIFY_TENANT',
  UnknownEventType = 'UNKNOWN_EVENT_TYPE'
}

export type Notification_Api_NotificationDefinitionEditResponseOutput = {
  readonly __typename: 'notification_api_NotificationDefinitionEditResponseOutput';
  readonly notification: Notification_Api_NotificationDefinitionOutput;
};

export type Notification_Api_NotificationDefinitionListResponseOutput = {
  readonly __typename: 'notification_api_NotificationDefinitionListResponseOutput';
  readonly notifications?: Maybe<ReadonlyArray<Notification_Api_NotificationDefinitionOutput>>;
  readonly total: Scalars['Int'];
};

export type Notification_Api_NotificationDefinitionOutput = {
  readonly __typename: 'notification_api_NotificationDefinitionOutput';
  readonly ID: Scalars['Int'];
  readonly action: Notification_Api_ActionEnum;
  readonly actor: Notification_Api_RoleEnum;
  readonly category: Notification_Api_CategoryEnum;
  readonly channel: Notification_Api_EventChannelEnum;
  readonly contacts: Notification_Api_ContactsOutput;
  readonly customized: Scalars['Boolean'];
  readonly data: ReadonlyArray<_String__StringOutputMap>;
  readonly disabled: Scalars['Boolean'];
  readonly duration: Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationOutput;
  readonly entity: Notification_Api_EntityEnum;
  readonly escape_html: Scalars['Boolean'];
  readonly target: Notification_Api_RoleEnum;
  readonly template: Scalars['String'];
};

export type Notification_Api_NotificationSpecOutput = {
  readonly __typename: 'notification_api_NotificationSpecOutput';
  readonly action: Notification_Api_ActionEnum;
  readonly actor: Notification_Api_RoleEnum;
  readonly associated_uri: Scalars['String'];
  readonly duration: Google_Golang_Org_Protobuf_Types_Known_Durationpb_DurationOutput;
  readonly entity: Notification_Api_EntityEnum;
  readonly target: Notification_Api_RoleEnum;
  readonly title: Scalars['String'];
};

export enum Notification_Api_RoleEnum {
  RoleAdmin = 'ROLE_ADMIN',
  RoleAuthor = 'ROLE_AUTHOR',
  RoleGuarantor = 'ROLE_GUARANTOR',
  RoleLandlord = 'ROLE_LANDLORD',
  RoleManager = 'ROLE_MANAGER',
  RoleTenant = 'ROLE_TENANT',
  RoleUnknown = 'ROLE_UNKNOWN'
}

export type Notification_Api_SendgridListSubscribeAddressInput = {
  readonly address_line1?: InputMaybe<Scalars['String']>;
  readonly address_line2?: InputMaybe<Scalars['String']>;
  readonly address_line3?: InputMaybe<Scalars['String']>;
  readonly address_line4?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly postcode?: InputMaybe<Scalars['String']>;
};

export type Notification_Api_SendgridListSubscriptionCreateResponseOutput = {
  readonly __typename: 'notification_api_SendgridListSubscriptionCreateResponseOutput';
  readonly subscription: Notification_Api_SendgridListSubscriptionOutput;
};

export type Notification_Api_SendgridListSubscriptionFetchEmailInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly user_id?: InputMaybe<Scalars['String']>;
};

export type Notification_Api_SendgridListSubscriptionFetchResponseOutput = {
  readonly __typename: 'notification_api_SendgridListSubscriptionFetchResponseOutput';
  readonly subscriptions?: Maybe<ReadonlyArray<Notification_Api_SendgridListSubscriptionOutput>>;
};

export type Notification_Api_SendgridListSubscriptionListResponseOutput = {
  readonly __typename: 'notification_api_SendgridListSubscriptionListResponseOutput';
  readonly subscriptions?: Maybe<ReadonlyArray<Notification_Api_SendgridListSubscriptionOutput>>;
  readonly total: Scalars['Int'];
};

export type Notification_Api_SendgridListSubscriptionOutput = {
  readonly __typename: 'notification_api_SendgridListSubscriptionOutput';
  readonly created: Scalars['String'];
  readonly email: Scalars['String'];
  readonly lists?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly types?: Maybe<ReadonlyArray<Notification_Api_SendgridListSubscriptionTypeEnum>>;
  readonly updated: Scalars['String'];
  readonly user_id: Scalars['String'];
};

export enum Notification_Api_SendgridListSubscriptionTypeEnum {
  SendgridListSubscriptionTypeAllLandlords = 'SENDGRID_LIST_SUBSCRIPTION_TYPE_ALL_LANDLORDS',
  SendgridListSubscriptionTypeAllTenants = 'SENDGRID_LIST_SUBSCRIPTION_TYPE_ALL_TENANTS',
  SendgridListSubscriptionTypeLeads = 'SENDGRID_LIST_SUBSCRIPTION_TYPE_LEADS',
  SendgridListSubscriptionTypeUnknown = 'SENDGRID_LIST_SUBSCRIPTION_TYPE_UNKNOWN'
}

export type Oauth_Api_FacebookGetUserInfoResponseOutput = {
  readonly __typename: 'oauth_api_FacebookGetUserInfoResponseOutput';
  readonly email: Scalars['String'];
  readonly first_name: Scalars['String'];
  readonly id: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly raw?: Maybe<ReadonlyArray<Scalars['Int']>>;
};

export type Oauth_Api_TokenAccessRequestInput = {
  readonly access_token?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_AcceptResponseOutput = {
  readonly __typename: 'offer_api_AcceptResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_AcceptUpdateResponseOutput = {
  readonly __typename: 'offer_api_AcceptUpdateResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_AddContractClauseResponseOutput = {
  readonly __typename: 'offer_api_AddContractClauseResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_AddGuarantorTenantInput = {
  readonly guarantor_billing_address?: InputMaybe<Scalars['String']>;
  readonly guarantor_email?: InputMaybe<Scalars['String']>;
  readonly guarantor_first_name?: InputMaybe<Scalars['String']>;
  readonly guarantor_last_name?: InputMaybe<Scalars['String']>;
  readonly guarantor_phone?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly tenant_email?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_AddGuarantorsResponseOutput = {
  readonly __typename: 'offer_api_AddGuarantorsResponseOutput';
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_AdvanceInput = {
  readonly available?: InputMaybe<Scalars['Boolean']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly fee_percentage?: InputMaybe<Scalars['Float']>;
  readonly fee_value?: InputMaybe<Scalars['Float']>;
  readonly request?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Offer_Api_AdvanceStateEnum>;
  readonly total?: InputMaybe<Scalars['Float']>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_AdvanceOutput = {
  readonly __typename: 'offer_api_AdvanceOutput';
  readonly available: Scalars['Boolean'];
  readonly created_at: Scalars['String'];
  readonly fee_percentage: Scalars['Float'];
  readonly fee_value: Scalars['Float'];
  readonly request: Scalars['String'];
  readonly state: Offer_Api_AdvanceStateEnum;
  readonly total: Scalars['Float'];
  readonly updated_at: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Offer_Api_AdvanceStateEnum {
  AdvanceCancelled = 'ADVANCE_CANCELLED',
  AdvanceDenied = 'ADVANCE_DENIED',
  AdvanceGranted = 'ADVANCE_GRANTED',
  AdvanceRequested = 'ADVANCE_REQUESTED',
  UnknownAdvanceState = 'UNKNOWN_ADVANCE_STATE'
}

export type Offer_Api_ApplicantCheckInput = {
  readonly skip?: InputMaybe<Scalars['Boolean']>;
  readonly tenants?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Offer_Api_ApproveResponseOutput = {
  readonly __typename: 'offer_api_ApproveResponseOutput';
  readonly approved: Scalars['Boolean'];
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly ok: Scalars['Boolean'];
};

export type Offer_Api_CancelResponseOutput = {
  readonly __typename: 'offer_api_CancelResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_CancelSigningStageResponseOutput = {
  readonly __typename: 'offer_api_CancelSigningStageResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_ChangeStateContractClausesResponseOutput = {
  readonly __typename: 'offer_api_ChangeStateContractClausesResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_CheckResponseOutput = {
  readonly __typename: 'offer_api_CheckResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_ClauseInput = {
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Offer_Api_ClauseStateEnum>;
  readonly text?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_ClauseOutput = {
  readonly __typename: 'offer_api_ClauseOutput';
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly state: Offer_Api_ClauseStateEnum;
  readonly text: Scalars['String'];
};

export enum Offer_Api_ClauseStateEnum {
  ClauseAccepted = 'CLAUSE_ACCEPTED',
  ClauseDeclined = 'CLAUSE_DECLINED',
  ClausePending = 'CLAUSE_PENDING'
}

export type Offer_Api_CreateResponseOutput = {
  readonly __typename: 'offer_api_CreateResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly id: Scalars['String'];
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly status: Offer_Api_OfferStatusEnum;
  readonly verification: Offer_Api_OfferVerificationOutput;
};

export type Offer_Api_DeleteOfferByIdResponseOutput = {
  readonly __typename: 'offer_api_DeleteOfferByIdResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_DenyResponseOutput = {
  readonly __typename: 'offer_api_DenyResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export enum Offer_Api_DepositTypeEnum {
  DepositReplacement = 'DEPOSIT_REPLACEMENT',
  DepositTraditional_5Week = 'DEPOSIT_TRADITIONAL_5WEEK',
  UnknownDepositType = 'UNKNOWN_DEPOSIT_TYPE'
}

export enum Offer_Api_EmploymentStatusEnum {
  Employed = 'EMPLOYED',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED',
  UnknownEmploymentStatus = 'UNKNOWN_EMPLOYMENT_STATUS'
}

export type Offer_Api_GetResponseOutput = {
  readonly __typename: 'offer_api_GetResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly verification: Offer_Api_OfferVerificationOutput;
};

export type Offer_Api_GuarantorEnrichedOutput = {
  readonly __typename: 'offer_api_GuarantorEnrichedOutput';
  readonly bill_documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly billing_address?: Maybe<Scalars['String']>;
  readonly check?: Maybe<Scalars['String']>;
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly identity_documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
  readonly rentprofile_created_at: Scalars['String'];
  readonly rentprofile_status?: Maybe<Scalars['String']>;
  readonly risk: Offer_Api_TenantRiskEnum;
  readonly risk_document?: Maybe<Scalars['String']>;
  readonly risk_profile?: Maybe<Scalars['String']>;
  readonly signature?: Maybe<ReadonlyArray<Offer_Api_SignStatusOutput>>;
  readonly user?: Maybe<Scalars['String']>;
};

export type Offer_Api_GuarantorInput = {
  readonly bill_documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly billing_address?: InputMaybe<Scalars['String']>;
  readonly check?: InputMaybe<Scalars['String']>;
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly identity_documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
  readonly rentprofile_created_at?: InputMaybe<Scalars['String']>;
  readonly rentprofile_status?: InputMaybe<Scalars['String']>;
  readonly risk?: InputMaybe<Offer_Api_TenantRiskEnum>;
  readonly risk_document?: InputMaybe<Scalars['String']>;
  readonly risk_profile?: InputMaybe<Scalars['String']>;
  readonly signature?: InputMaybe<ReadonlyArray<Offer_Api_SignStatusInput>>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_InviteResponseOutput = {
  readonly __typename: 'offer_api_InviteResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_LandlordInput = {
  readonly billing_address?: InputMaybe<Scalars['String']>;
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
  readonly signature?: InputMaybe<ReadonlyArray<Offer_Api_SignStatusInput>>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_LandlordOutput = {
  readonly __typename: 'offer_api_LandlordOutput';
  readonly bank_details?: Maybe<Account_Api_AccountBankDetailsOutput>;
  readonly billing_address?: Maybe<Scalars['String']>;
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
  readonly signature?: Maybe<ReadonlyArray<Offer_Api_SignStatusOutput>>;
  readonly user: Scalars['String'];
};

export type Offer_Api_ListResponseOutput = {
  readonly __typename: 'offer_api_ListResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offers?: Maybe<ReadonlyArray<Offer_Api_OfferEnrichedOutput>>;
};

export type Offer_Api_ListServicesResponseOutput = {
  readonly __typename: 'offer_api_ListServicesResponseOutput';
  readonly placeholder: Scalars['Boolean'];
  readonly services?: Maybe<ReadonlyArray<Extras_Api_ServiceSnapshotOutput>>;
};

export type Offer_Api_LogInput = {
  readonly action?: InputMaybe<Scalars['String']>;
  readonly dt?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_LogOutput = {
  readonly __typename: 'offer_api_LogOutput';
  readonly action: Scalars['String'];
  readonly dt: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Offer_Api_OfferActionEnum {
  DepositTypeChanged = 'DEPOSIT_TYPE_CHANGED',
  FirstPaymentDateChanged = 'FIRST_PAYMENT_DATE_CHANGED',
  GuarantorAdd = 'GUARANTOR_ADD',
  GuarantorAddressChanged = 'GUARANTOR_ADDRESS_CHANGED',
  GuarantorEmailChanged = 'GUARANTOR_EMAIL_CHANGED',
  GuarantorNameChanged = 'GUARANTOR_NAME_CHANGED',
  LandlordNameChanged = 'LANDLORD_NAME_CHANGED',
  MoveInDateChanged = 'MOVE_IN_DATE_CHANGED',
  PaymentFreqChanged = 'PAYMENT_FREQ_CHANGED',
  SecondLandlordAdd = 'SECOND_LANDLORD_ADD',
  TenancyLengthChanged = 'TENANCY_LENGTH_CHANGED',
  TenantAdd = 'TENANT_ADD',
  TenantAddressChanged = 'TENANT_ADDRESS_CHANGED',
  TenantEmailChanged = 'TENANT_EMAIL_CHANGED',
  TenantNameChanged = 'TENANT_NAME_CHANGED'
}

export type Offer_Api_OfferApproveInput = {
  readonly admin?: InputMaybe<Scalars['String']>;
  readonly approved?: InputMaybe<Scalars['Boolean']>;
  readonly approved_at?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_OfferApproveOutput = {
  readonly __typename: 'offer_api_OfferApproveOutput';
  readonly admin: Scalars['String'];
  readonly approved: Scalars['Boolean'];
  readonly approved_at: Scalars['String'];
};

export type Offer_Api_OfferApproveValidationOutput = {
  readonly __typename: 'offer_api_OfferApproveValidationOutput';
  readonly admin?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly approved?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly approved_at?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
};

export type Offer_Api_OfferChangelogInput = {
  readonly action?: InputMaybe<Offer_Api_OfferActionEnum>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly initiator?: InputMaybe<Scalars['String']>;
  readonly new?: InputMaybe<Scalars['String']>;
  readonly old?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_OfferChangelogOutput = {
  readonly __typename: 'offer_api_OfferChangelogOutput';
  readonly action: Offer_Api_OfferActionEnum;
  readonly created_at: Scalars['String'];
  readonly initiator: Scalars['String'];
  readonly new: Scalars['String'];
  readonly old: Scalars['String'];
};

export type Offer_Api_OfferConditionsInput = {
  readonly break_clause?: InputMaybe<Scalars['Int']>;
  readonly comments?: InputMaybe<Scalars['String']>;
  readonly deposit_type?: InputMaybe<Offer_Api_DepositTypeEnum>;
  readonly first_rent_payment_date?: InputMaybe<Scalars['String']>;
  readonly movein_date?: InputMaybe<Scalars['String']>;
  readonly payment_frequency?: InputMaybe<Scalars['Int']>;
  readonly pets?: InputMaybe<Scalars['Boolean']>;
  readonly pets_description?: InputMaybe<Scalars['String']>;
  readonly price?: InputMaybe<Scalars['Float']>;
  readonly rent_payment_day?: InputMaybe<Scalars['Int']>;
  readonly tenants_pitch?: InputMaybe<Scalars['String']>;
  readonly term_months?: InputMaybe<Scalars['Int']>;
};

export type Offer_Api_OfferConditionsOutput = {
  readonly __typename: 'offer_api_OfferConditionsOutput';
  readonly break_clause?: Maybe<Scalars['Int']>;
  readonly comments?: Maybe<Scalars['String']>;
  readonly deposit_type: Offer_Api_DepositTypeEnum;
  readonly first_rent_payment_date: Scalars['String'];
  readonly movein_date: Scalars['String'];
  readonly payment_frequency?: Maybe<Scalars['Int']>;
  readonly pets?: Maybe<Scalars['Boolean']>;
  readonly pets_description?: Maybe<Scalars['String']>;
  readonly price?: Maybe<Scalars['Float']>;
  readonly rent_payment_day?: Maybe<Scalars['Int']>;
  readonly tenants_pitch?: Maybe<Scalars['String']>;
  readonly term_months?: Maybe<Scalars['Int']>;
  readonly termination_allowed: Scalars['Boolean'];
};

export type Offer_Api_OfferConditionsValidationOutput = {
  readonly __typename: 'offer_api_OfferConditionsValidationOutput';
  readonly break_clause?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly comments?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly deposit_type?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly movein_date?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly payment_frequency?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly pets?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly pets_description?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly price?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly tenants_pitch?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly term_months?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
};

export type Offer_Api_OfferDetailsInput = {
  readonly adult_flatmates?: InputMaybe<Scalars['Boolean']>;
  readonly movein_alone?: InputMaybe<Scalars['Boolean']>;
};

export type Offer_Api_OfferDetailsOutput = {
  readonly __typename: 'offer_api_OfferDetailsOutput';
  readonly adult_flatmates?: Maybe<Scalars['Boolean']>;
  readonly movein_alone?: Maybe<Scalars['Boolean']>;
};

export type Offer_Api_OfferDetailsValidationOutput = {
  readonly __typename: 'offer_api_OfferDetailsValidationOutput';
  readonly adult_flatmates?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly movein_alone?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
};

export type Offer_Api_OfferEnrichedOutput = {
  readonly __typename: 'offer_api_OfferEnrichedOutput';
  readonly accepted_at_value: Scalars['String'];
  readonly administrator?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly approve?: Maybe<Offer_Api_OfferApproveOutput>;
  readonly cancelled_at_value: Scalars['String'];
  readonly changelog?: Maybe<ReadonlyArray<Offer_Api_OfferChangelogOutput>>;
  readonly clause?: Maybe<ReadonlyArray<Offer_Api_ClauseOutput>>;
  readonly conditions: Offer_Api_OfferConditionsOutput;
  readonly contract: Scalars['String'];
  readonly contract_created_at: Scalars['String'];
  readonly created_at_value: Scalars['String'];
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly denied_at_value: Scalars['String'];
  readonly details: Offer_Api_OfferDetailsOutput;
  readonly draft_contract: Scalars['String'];
  readonly draft_contract_created_at: Scalars['String'];
  readonly guarantor_required: Scalars['Boolean'];
  readonly guarantors?: Maybe<ReadonlyArray<Offer_Api_GuarantorEnrichedOutput>>;
  readonly id: Scalars['String'];
  readonly invoices: ReadonlyArray<Invoice_Api_InvoiceOutput>;
  readonly is_locked: Scalars['Boolean'];
  readonly is_rto: Scalars['Boolean'];
  readonly landlord: Offer_Api_LandlordOutput;
  readonly landlords?: Maybe<ReadonlyArray<Offer_Api_LandlordOutput>>;
  readonly log?: Maybe<ReadonlyArray<Offer_Api_LogOutput>>;
  readonly manager: Scalars['String'];
  readonly notifications: ReadonlyArray<_String_Google_Golang_Org_Protobuf_Types_Known_Timestamppb_TimestampOutputMap>;
  readonly original_offer: Scalars['String'];
  readonly paid_statuses: Offer_Api_PaidStatusesOutput;
  /** @deprecated use invoices field */
  readonly payment?: Maybe<Offer_Api_OfferPaymentOutput>;
  /** @deprecated use invoices field */
  readonly payment_history: ReadonlyArray<Offer_Api_OfferPaymentOutput>;
  readonly property?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly property_ivr: Scalars['String'];
  readonly property_listing: Scalars['String'];
  readonly ready_for_signing: Offer_Api_OfferReadySigningOutput;
  readonly rent_insurances_generated: Scalars['Boolean'];
  readonly rent_plan: Offer_Api_RentPlanEnum;
  readonly rent_plan_selected_at: Scalars['String'];
  readonly schedule_generated: Scalars['Boolean'];
  readonly schedule_notifications: ReadonlyArray<_Int32_Offer_Api_ScheduleNotificationOutputMap>;
  readonly state: Offer_Api_OfferStateEnum;
  readonly tenants?: Maybe<ReadonlyArray<Offer_Api_TenantEnrichedOutput>>;
  readonly test?: Maybe<Scalars['Boolean']>;
  readonly updated_at_value: Scalars['String'];
  readonly viewing?: Maybe<Viewing_Api_ViewingOutput>;
  readonly workflow_instance_id: Scalars['String'];
};

export enum Offer_Api_OfferErrorEnum {
  AlreadyPublished = 'ALREADY_PUBLISHED',
  ClausesInPending = 'CLAUSES_IN_PENDING',
  DocumentsVerificationError = 'DOCUMENTS_VERIFICATION_ERROR',
  DocumentRequired = 'DOCUMENT_REQUIRED',
  EmptyValue = 'EMPTY_VALUE',
  IntervalNotAvailable = 'INTERVAL_NOT_AVAILABLE',
  InvalidMaxValue = 'INVALID_MAX_VALUE',
  InvalidMinValue = 'INVALID_MIN_VALUE',
  InvalidOfferStatus = 'INVALID_OFFER_STATUS',
  InvalidRating = 'INVALID_RATING',
  InvalidSum = 'INVALID_SUM',
  MaxLessMin = 'MAX_LESS_MIN',
  NotReadyForPublication = 'NOT_READY_FOR_PUBLICATION',
  NoSuchClause = 'NO_SUCH_CLAUSE',
  NoUpdates = 'NO_UPDATES',
  OfferAlreadyDeleted = 'OFFER_ALREADY_DELETED',
  OfferLocked = 'OFFER_LOCKED',
  OfferNotDeleted = 'OFFER_NOT_DELETED',
  OfferNotExists = 'OFFER_NOT_EXISTS',
  PaymentRequired = 'PAYMENT_REQUIRED',
  PropertyEmpty = 'PROPERTY_EMPTY',
  UnknownOfferError = 'UNKNOWN_OFFER_ERROR',
  UnknownValue = 'UNKNOWN_VALUE',
  UserNotExists = 'USER_NOT_EXISTS',
  UserNotOwns = 'USER_NOT_OWNS',
  UserNoAccess = 'USER_NO_ACCESS',
  WrongCurrentOfferStatus = 'WRONG_CURRENT_OFFER_STATUS',
  ZeroValue = 'ZERO_VALUE'
}

export type Offer_Api_OfferInput = {
  readonly accepted_at_value?: InputMaybe<Scalars['String']>;
  readonly administrator?: InputMaybe<Scalars['String']>;
  readonly approve?: InputMaybe<Offer_Api_OfferApproveInput>;
  readonly cancelled_at_value?: InputMaybe<Scalars['String']>;
  readonly changelog?: InputMaybe<ReadonlyArray<Offer_Api_OfferChangelogInput>>;
  readonly clause?: InputMaybe<ReadonlyArray<Offer_Api_ClauseInput>>;
  readonly conditions?: InputMaybe<Offer_Api_OfferConditionsInput>;
  readonly contract?: InputMaybe<Scalars['String']>;
  readonly contract_created_at?: InputMaybe<Scalars['String']>;
  readonly created_at_value?: InputMaybe<Scalars['String']>;
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
  readonly denied_at_value?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Offer_Api_OfferDetailsInput>;
  readonly draft_contract?: InputMaybe<Scalars['String']>;
  readonly draft_contract_created_at?: InputMaybe<Scalars['String']>;
  readonly guarantor_required?: InputMaybe<Scalars['Boolean']>;
  readonly guarantors?: InputMaybe<ReadonlyArray<Offer_Api_GuarantorInput>>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly is_locked?: InputMaybe<Scalars['Boolean']>;
  readonly is_rto?: InputMaybe<Scalars['Boolean']>;
  readonly landlord?: InputMaybe<Offer_Api_LandlordInput>;
  readonly landlords?: InputMaybe<ReadonlyArray<Offer_Api_LandlordInput>>;
  readonly log?: InputMaybe<ReadonlyArray<Offer_Api_LogInput>>;
  readonly manager?: InputMaybe<Scalars['String']>;
  readonly notifications?: InputMaybe<ReadonlyArray<_String_Google_Golang_Org_Protobuf_Types_Known_Timestamppb_TimestampInputMap>>;
  readonly original_offer?: InputMaybe<Scalars['String']>;
  readonly paid_statuses?: InputMaybe<Offer_Api_PaidStatusesInput>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly property_ivr?: InputMaybe<Scalars['String']>;
  readonly property_listing?: InputMaybe<Scalars['String']>;
  readonly ready_for_signing?: InputMaybe<Offer_Api_OfferReadySigningInput>;
  readonly rent_insurances_generated?: InputMaybe<Scalars['Boolean']>;
  readonly rent_plan?: InputMaybe<Offer_Api_RentPlanEnum>;
  readonly rent_plan_selected_at?: InputMaybe<Scalars['String']>;
  readonly schedule_generated?: InputMaybe<Scalars['Boolean']>;
  readonly schedule_notifications?: InputMaybe<ReadonlyArray<_Int32_Offer_Api_ScheduleNotificationInputMap>>;
  readonly state?: InputMaybe<Offer_Api_OfferStateEnum>;
  readonly tenants?: InputMaybe<ReadonlyArray<Offer_Api_TenantInput>>;
  readonly test?: InputMaybe<Scalars['Boolean']>;
  readonly updated_at_value?: InputMaybe<Scalars['String']>;
  readonly viewing?: InputMaybe<Scalars['String']>;
  readonly workflow_instance_id?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_OfferLandlordValidationOutput = {
  readonly __typename: 'offer_api_OfferLandlordValidationOutput';
  readonly billing_address?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly email_primary?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly first_name?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly last_name?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly phone_primary?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly signature_value?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
};

export type Offer_Api_OfferPaymentOutput = {
  readonly __typename: 'offer_api_OfferPaymentOutput';
  readonly amount: Scalars['Float'];
  readonly created_at: Scalars['String'];
  readonly csrf_token: Scalars['String'];
  readonly invoice_number: Scalars['String'];
  readonly receipt_id: Scalars['String'];
  readonly services?: Maybe<ReadonlyArray<Extras_Api_ServiceSnapshotOutput>>;
  readonly status: Offer_Api_PaymentStatusEnum;
  readonly type: Offer_Api_PaymentTypeEnum;
  readonly updated_at: Scalars['String'];
};

export type Offer_Api_OfferPaymentValidationOutput = {
  readonly __typename: 'offer_api_OfferPaymentValidationOutput';
  readonly amount?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly created_at?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly csrf_token?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly invoice_number?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly receipt_id?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly services?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly type?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly updated_at?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
};

export type Offer_Api_OfferReadySigningInput = {
  readonly landlord?: InputMaybe<Scalars['Boolean']>;
  readonly tenant?: InputMaybe<Scalars['Boolean']>;
};

export type Offer_Api_OfferReadySigningOutput = {
  readonly __typename: 'offer_api_OfferReadySigningOutput';
  readonly landlord?: Maybe<Scalars['Boolean']>;
  readonly tenant?: Maybe<Scalars['Boolean']>;
};

export enum Offer_Api_OfferStateEnum {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  AwaitingApplicantCheckPayment = 'AWAITING_APPLICANT_CHECK_PAYMENT',
  AwaitingApprove = 'AWAITING_APPROVE',
  AwaitingChecks = 'AWAITING_CHECKS',
  AwaitingConfirm = 'AWAITING_CONFIRM',
  AwaitingConfirmExtras = 'AWAITING_CONFIRM_EXTRAS',
  AwaitingConfirmRent = 'AWAITING_CONFIRM_RENT',
  AwaitingDocuments = 'AWAITING_DOCUMENTS',
  AwaitingFees = 'AWAITING_FEES',
  AwaitingGuarantor = 'AWAITING_GUARANTOR',
  AwaitingRenew = 'AWAITING_RENEW',
  AwaitingTenancy = 'AWAITING_TENANCY',
  Canceled = 'CANCELED',
  Checking = 'CHECKING',
  ContractPreparation = 'CONTRACT_PREPARATION',
  ContractReviewAndClauses = 'CONTRACT_REVIEW_AND_CLAUSES',
  Denied = 'DENIED',
  DepositConfirmed = 'DEPOSIT_CONFIRMED',
  EarlyTermination = 'EARLY_TERMINATION',
  Error = 'ERROR',
  Expired = 'EXPIRED',
  ExtrasConfirmed = 'EXTRAS_CONFIRMED',
  Finished = 'FINISHED',
  HoldingDeposit = 'HOLDING_DEPOSIT',
  ManualCollecting = 'MANUAL_COLLECTING',
  Pending = 'PENDING',
  Rentpaid = 'RENTPAID',
  RtoStateActive = 'RTO_STATE_ACTIVE',
  RtoStateActiveRollingTenancy = 'RTO_STATE_ACTIVE_ROLLING_TENANCY',
  RtoStateCancelled = 'RTO_STATE_CANCELLED',
  RtoStateEnding = 'RTO_STATE_ENDING',
  RtoStateFinished = 'RTO_STATE_FINISHED',
  RtoStatePendingSetup = 'RTO_STATE_PENDING_SETUP',
  RtoStateWaitingForPayment = 'RTO_STATE_WAITING_FOR_PAYMENT',
  RtoStateWaitingForStandingOrderSetup = 'RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP',
  RtoStateWaitingForTenantConfirmation = 'RTO_STATE_WAITING_FOR_TENANT_CONFIRMATION',
  Signed = 'SIGNED',
  Signing = 'SIGNING',
  Terminated = 'TERMINATED',
  UnknownOfferState = 'UNKNOWN_OFFER_STATE'
}

export enum Offer_Api_OfferStatusEnum {
  Invalid = 'INVALID',
  Success = 'SUCCESS',
  UnknownOfferStatus = 'UNKNOWN_OFFER_STATUS'
}

export type Offer_Api_OfferVerificationOutput = {
  readonly __typename: 'offer_api_OfferVerificationOutput';
  readonly accepted_at_value?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly approve: Offer_Api_OfferApproveValidationOutput;
  readonly cancelled_at_value?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly conditions: Offer_Api_OfferConditionsValidationOutput;
  readonly contract?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly created_at_value?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly deleted?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly denied_at_value?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly details: Offer_Api_OfferDetailsValidationOutput;
  readonly id?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly landlord: Offer_Api_OfferLandlordValidationOutput;
  readonly log?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly payment: Offer_Api_OfferPaymentValidationOutput;
  readonly payment_history?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly property?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly state?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly tenants?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly test?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly updated_at_value?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly viewing?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
};

export type Offer_Api_PaidStatusesInput = {
  /** Deprecated. Do not use. */
  readonly deposit_replacement?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. Do not use. */
  readonly deposit_replacement_setup?: InputMaybe<Scalars['Boolean']>;
  readonly standing_order?: InputMaybe<Scalars['Boolean']>;
  readonly standing_order_setup?: InputMaybe<Scalars['Boolean']>;
  readonly tenants_services?: InputMaybe<Scalars['Boolean']>;
};

export type Offer_Api_PaidStatusesOutput = {
  readonly __typename: 'offer_api_PaidStatusesOutput';
  readonly deposit_replacement: Scalars['Boolean'];
  readonly deposit_replacement_setup: Scalars['Boolean'];
  readonly standing_order: Scalars['Boolean'];
  readonly standing_order_setup: Scalars['Boolean'];
  readonly tenants_services: Scalars['Boolean'];
};

export type Offer_Api_PaymentBankResponseOutput = {
  readonly __typename: 'offer_api_PaymentBankResponseOutput';
  readonly id: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export enum Offer_Api_PaymentProcedureEnum {
  PaymentProcedureConfirmation = 'PAYMENT_PROCEDURE_CONFIRMATION',
  PaymentProcedureCreate = 'PAYMENT_PROCEDURE_CREATE'
}

export type Offer_Api_PaymentResponseOutput = {
  readonly __typename: 'offer_api_PaymentResponseOutput';
  readonly bank: Offer_Api_PaymentBankResponseOutput;
  readonly plaid?: Maybe<Scalars['String']>;
  readonly stripe: Offer_Api_PaymentStripeResponseOutput;
  readonly success: Scalars['Boolean'];
  readonly tokenio: Offer_Api_PaymentTokenioResponseOutput;
};

export enum Offer_Api_PaymentStatusEnum {
  PaymentConfirm = 'PAYMENT_CONFIRM',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  UnknownPaymentStatus = 'UNKNOWN_PAYMENT_STATUS'
}

export type Offer_Api_PaymentStatusResponseOutput = {
  readonly __typename: 'offer_api_PaymentStatusResponseOutput';
  readonly offer: Scalars['String'];
  readonly ok: Scalars['Boolean'];
  readonly receipt_id: Scalars['String'];
};

export type Offer_Api_PaymentStripeResponseOutput = {
  readonly __typename: 'offer_api_PaymentStripeResponseOutput';
  readonly client_secret: Scalars['String'];
  readonly id: Scalars['String'];
};

export type Offer_Api_PaymentTokenioResponseOutput = {
  readonly __typename: 'offer_api_PaymentTokenioResponseOutput';
  readonly csrf: Scalars['String'];
  readonly url: Scalars['String'];
};

export enum Offer_Api_PaymentTypeEnum {
  Account = 'ACCOUNT',
  BankAccount = 'BANK_ACCOUNT',
  Bitcoin = 'BITCOIN',
  Card = 'CARD',
  Source = 'SOURCE',
  UnknownPaymentType = 'UNKNOWN_PAYMENT_TYPE'
}

export type Offer_Api_PayoutInput = {
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly state?: InputMaybe<Offer_Api_PayoutStateEnum>;
  readonly transaction?: InputMaybe<Scalars['String']>;
  readonly transitions?: InputMaybe<ReadonlyArray<Offer_Api_PayoutLogInput>>;
};

export type Offer_Api_PayoutLogInput = {
  readonly admin?: InputMaybe<Scalars['String']>;
  readonly next?: InputMaybe<Offer_Api_PayoutStateEnum>;
  readonly prev?: InputMaybe<Offer_Api_PayoutStateEnum>;
  readonly time?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_PayoutLogOutput = {
  readonly __typename: 'offer_api_PayoutLogOutput';
  readonly admin: Scalars['String'];
  readonly next: Offer_Api_PayoutStateEnum;
  readonly prev: Offer_Api_PayoutStateEnum;
  readonly time: Scalars['String'];
};

export type Offer_Api_PayoutOutput = {
  readonly __typename: 'offer_api_PayoutOutput';
  readonly invoice?: Maybe<Invoice_Api_InvoiceOutput>;
  readonly invoice_id: Scalars['String'];
  readonly state: Offer_Api_PayoutStateEnum;
  readonly transaction: Scalars['String'];
  readonly transitions?: Maybe<ReadonlyArray<Offer_Api_PayoutLogOutput>>;
};

export type Offer_Api_PayoutResponseOutput = {
  readonly __typename: 'offer_api_PayoutResponseOutput';
  /** @deprecated do not use */
  readonly success: Scalars['Boolean'];
  readonly tokenio: Offer_Api_PaymentTokenioResponseOutput;
};

export enum Offer_Api_PayoutStateEnum {
  PayoutConfirmed = 'PAYOUT_CONFIRMED',
  PayoutPaid = 'PAYOUT_PAID',
  PayoutPending = 'PAYOUT_PENDING',
  UnknownPayoutState = 'UNKNOWN_PAYOUT_STATE'
}

export type Offer_Api_ProgressToSigningResponseOutput = {
  readonly __typename: 'offer_api_ProgressToSigningResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_RemoveSecondLandlordResponseOutput = {
  readonly __typename: 'offer_api_RemoveSecondLandlordResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_RenderCsvResponseOutput = {
  readonly __typename: 'offer_api_RenderCsvResponseOutput';
  readonly file: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_RenewTenancyResponseOutput = {
  readonly __typename: 'offer_api_RenewTenancyResponseOutput';
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export enum Offer_Api_RentPlanEnum {
  RentPlanEssential = 'RENT_PLAN_ESSENTIAL',
  RentPlanLegacy = 'RENT_PLAN_LEGACY',
  RentPlanManual = 'RENT_PLAN_MANUAL',
  RentPlanSecured = 'RENT_PLAN_SECURED',
  RentPlanUnknown = 'RENT_PLAN_UNKNOWN'
}

export type Offer_Api_RequestChangeResponseOutput = {
  readonly __typename: 'offer_api_RequestChangeResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_RequestRequiredGuarantorResponseOutput = {
  readonly __typename: 'offer_api_RequestRequiredGuarantorResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_RestoreOfferByIdResponseOutput = {
  readonly __typename: 'offer_api_RestoreOfferByIdResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_ScheduleNotificationInput = {
  readonly is_notified?: InputMaybe<Scalars['Boolean']>;
  readonly started_at?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_ScheduleNotificationOutput = {
  readonly __typename: 'offer_api_ScheduleNotificationOutput';
  readonly is_notified: Scalars['Boolean'];
  readonly started_at: Scalars['String'];
};

export type Offer_Api_ScheduledPaymentAdvanceCancelResponseOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentAdvanceCancelResponseOutput';
  readonly item: Offer_Api_ScheduledPaymentOutput;
};

export type Offer_Api_ScheduledPaymentAdvanceCreateResponseOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentAdvanceCreateResponseOutput';
  /** @deprecated use items */
  readonly item: Offer_Api_ScheduledPaymentOutput;
  readonly items?: Maybe<ReadonlyArray<Offer_Api_ScheduledPaymentOutput>>;
};

export type Offer_Api_ScheduledPaymentDateInput = {
  readonly month?: InputMaybe<Scalars['Int']>;
  readonly year?: InputMaybe<Scalars['Int']>;
};

export type Offer_Api_ScheduledPaymentDateOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentDateOutput';
  readonly month: Scalars['Int'];
  readonly year: Scalars['Int'];
};

export type Offer_Api_ScheduledPaymentListResponseOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentListResponseOutput';
  readonly items?: Maybe<ReadonlyArray<Offer_Api_ScheduledPaymentOutput>>;
  readonly total: Scalars['Int'];
};

export type Offer_Api_ScheduledPaymentOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentOutput';
  readonly advance: Offer_Api_AdvanceOutput;
  readonly date: Offer_Api_ScheduledPaymentDateOutput;
  /** @deprecated use parts.payment_due */
  readonly due: Scalars['String'];
  readonly id: Scalars['String'];
  /** @deprecated use payout.invoice_id / parts.invoice_id */
  readonly invoice_id: Scalars['String'];
  readonly offer?: Maybe<Offer_Api_OfferEnrichedOutput>;
  readonly parts?: Maybe<ReadonlyArray<Offer_Api_ScheduledPaymentPartOutput>>;
  readonly payout: Offer_Api_PayoutOutput;
  /** @deprecated use parts.services */
  readonly services: ReadonlyArray<Extras_Api_ServiceSnapshotOutput>;
};

export type Offer_Api_ScheduledPaymentPartInput = {
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly payment_due?: InputMaybe<Scalars['String']>;
  readonly payment_id?: InputMaybe<Scalars['String']>;
  readonly payment_serial?: InputMaybe<Scalars['String']>;
  readonly payment_status?: InputMaybe<Offer_Api_PaymentStatusEnum>;
  readonly services?: InputMaybe<ReadonlyArray<Extras_Api_ServiceSnapshotInput>>;
};

export type Offer_Api_ScheduledPaymentPartOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentPartOutput';
  readonly invoice?: Maybe<Invoice_Api_InvoiceOutput>;
  readonly invoice_id: Scalars['String'];
  readonly payment?: Maybe<Invoice_Api_PaymentOutput>;
  readonly payment_due: Scalars['String'];
  readonly payment_id: Scalars['String'];
  readonly payment_serial: Scalars['String'];
  readonly payment_status: Offer_Api_PaymentStatusEnum;
  readonly services?: Maybe<ReadonlyArray<Extras_Api_ServiceSnapshotOutput>>;
};

export type Offer_Api_ScheduledPaymentPayResponseOutput = {
  readonly __typename: 'offer_api_ScheduledPaymentPayResponseOutput';
  readonly tokenio: Offer_Api_PaymentTokenioResponseOutput;
};

export type Offer_Api_SelectServicesResponseOutput = {
  readonly __typename: 'offer_api_SelectServicesResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_SignStatusInput = {
  readonly state?: InputMaybe<Offer_Api_SignStatusStateEnum>;
  readonly time?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_SignStatusOutput = {
  readonly __typename: 'offer_api_SignStatusOutput';
  readonly state: Offer_Api_SignStatusStateEnum;
  readonly time: Scalars['String'];
};

export enum Offer_Api_SignStatusStateEnum {
  AwaitingSignature = 'AWAITING_SIGNATURE',
  SignatureProvided = 'SIGNATURE_PROVIDED',
  SignatureRefused = 'SIGNATURE_REFUSED',
  UnknownSignStatusState = 'UNKNOWN_SIGN_STATUS_STATE'
}

export type Offer_Api_SkipHoldingDepositResponseOutput = {
  readonly __typename: 'offer_api_SkipHoldingDepositResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_SkipReferencingStageResponseOutput = {
  readonly __typename: 'offer_api_SkipReferencingStageResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_StandingOrderResponseOutput = {
  readonly __typename: 'offer_api_StandingOrderResponseOutput';
  readonly plaid?: Maybe<Scalars['String']>;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_SubmitResponseOutput = {
  readonly __typename: 'offer_api_SubmitResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly status: Offer_Api_OfferStatusEnum;
};

export type Offer_Api_TenantEnrichedOutput = {
  readonly __typename: 'offer_api_TenantEnrichedOutput';
  readonly account?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly age?: Maybe<Scalars['Int']>;
  readonly annual_income?: Maybe<Scalars['Float']>;
  readonly bill_documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly billing_address?: Maybe<Scalars['String']>;
  readonly check?: Maybe<Scalars['String']>;
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly employment_status: Offer_Api_EmploymentStatusEnum;
  readonly first_name?: Maybe<Scalars['String']>;
  /** @deprecated use offer.guarantors */
  readonly guarantor?: Maybe<Offer_Api_GuarantorEnrichedOutput>;
  readonly guarantor_required?: Maybe<Scalars['Boolean']>;
  readonly identity_documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly kids_no?: Maybe<Scalars['Int']>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly paying?: Maybe<Scalars['Float']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
  readonly rentprofile_created_at: Scalars['String'];
  readonly rentprofile_status?: Maybe<Scalars['String']>;
  readonly risk: Offer_Api_TenantRiskEnum;
  readonly risk_document?: Maybe<Scalars['String']>;
  readonly risk_profile?: Maybe<Scalars['String']>;
  readonly signature?: Maybe<ReadonlyArray<Offer_Api_SignStatusOutput>>;
  readonly user: Scalars['String'];
};

export type Offer_Api_TenantInput = {
  readonly age?: InputMaybe<Scalars['Int']>;
  readonly annual_income?: InputMaybe<Scalars['Float']>;
  readonly bill_documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly billing_address?: InputMaybe<Scalars['String']>;
  readonly check?: InputMaybe<Scalars['String']>;
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly employment_status?: InputMaybe<Offer_Api_EmploymentStatusEnum>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly guarantor_required?: InputMaybe<Scalars['Boolean']>;
  readonly identity_documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly kids_no?: InputMaybe<Scalars['Int']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly paying?: InputMaybe<Scalars['Float']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
  readonly rentprofile_created_at?: InputMaybe<Scalars['String']>;
  readonly rentprofile_status?: InputMaybe<Scalars['String']>;
  readonly risk?: InputMaybe<Offer_Api_TenantRiskEnum>;
  readonly risk_document?: InputMaybe<Scalars['String']>;
  readonly risk_profile?: InputMaybe<Scalars['String']>;
  readonly signature?: InputMaybe<ReadonlyArray<Offer_Api_SignStatusInput>>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export enum Offer_Api_TenantRiskEnum {
  HighRisk = 'HIGH_RISK',
  LowRisk = 'LOW_RISK',
  MediumRisk = 'MEDIUM_RISK',
  PendingRisk = 'PENDING_RISK',
  UnknownTenantRisk = 'UNKNOWN_TENANT_RISK'
}

export type Offer_Api_UpdateContractClausesResponseOutput = {
  readonly __typename: 'offer_api_UpdateContractClausesResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_UpdateDraftContractResponseOutput = {
  readonly __typename: 'offer_api_UpdateDraftContractResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Offer_Api_UpdateResponseOutput = {
  readonly __typename: 'offer_api_UpdateResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Offer_Api_OfferErrorEnum>>;
  readonly offer: Offer_Api_OfferEnrichedOutput;
  readonly status: Offer_Api_OfferStatusEnum;
  readonly verification: Offer_Api_OfferVerificationOutput;
};

export type Offer_Api_Rto_ConditionsOutput = {
  readonly __typename: 'offer_api_rto_ConditionsOutput';
  readonly break_up_clause_date: Scalars['String'];
  readonly end_date: Scalars['String'];
  readonly is_payment_day_was_last_day_in_month: Scalars['Boolean'];
  readonly move_in_date: Scalars['String'];
  readonly payment_frequency: Scalars['Int'];
  readonly price: Scalars['Float'];
  readonly rent_payment_day: Scalars['Int'];
  readonly term_months: Scalars['Int'];
};

export type Offer_Api_Rto_LandlordOutput = {
  readonly __typename: 'offer_api_rto_LandlordOutput';
  readonly billing_address: Scalars['String'];
  readonly email_primary: Scalars['String'];
  readonly first_name: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly phone_primary: Scalars['String'];
  readonly user: Scalars['String'];
};

export type Offer_Api_Rto_LeadTenantInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
};

export type Offer_Api_Rto_PaymentDataInput = {
  readonly break_up_clause_date?: InputMaybe<Scalars['String']>;
  readonly end_date?: InputMaybe<Scalars['String']>;
  readonly is_payment_day_was_last_day_in_month?: InputMaybe<Scalars['Boolean']>;
  readonly payment_day?: InputMaybe<Scalars['Int']>;
  readonly rent_amount?: InputMaybe<Scalars['Float']>;
  readonly start_date?: InputMaybe<Scalars['String']>;
};

export enum Offer_Api_Rto_RentPlanEnum {
  RentPlanEssential = 'RENT_PLAN_ESSENTIAL',
  RentPlanSecured = 'RENT_PLAN_SECURED',
  RentPlanUnknown = 'RENT_PLAN_UNKNOWN'
}

export type Offer_Api_Rto_RtoOutput = {
  readonly __typename: 'offer_api_rto_RtoOutput';
  readonly conditions: Offer_Api_Rto_ConditionsOutput;
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly invoice: Invoice_Api_InvoiceOutput;
  readonly invoice_id: Scalars['String'];
  readonly landlord: Offer_Api_Rto_LandlordOutput;
  readonly lead_tenant: Offer_Api_Rto_TenantOutput;
  readonly property: Scalars['String'];
  readonly property_address: Scalars['String'];
  readonly property_ivr: Scalars['String'];
  readonly property_listing: Scalars['String'];
  readonly rent_plan: Offer_Api_Rto_RentPlanEnum;
  readonly state: Offer_Api_Rto_RtoStateEnum;
  readonly updated_at: Scalars['String'];
};

export type Offer_Api_Rto_RtoResponseOutput = {
  readonly __typename: 'offer_api_rto_RtoResponseOutput';
  readonly rto: Offer_Api_Rto_RtoOutput;
};

export enum Offer_Api_Rto_RtoStateEnum {
  RtoStateActive = 'RTO_STATE_ACTIVE',
  RtoStateActiveRollingTenancy = 'RTO_STATE_ACTIVE_ROLLING_TENANCY',
  RtoStateCancelled = 'RTO_STATE_CANCELLED',
  RtoStateEnding = 'RTO_STATE_ENDING',
  RtoStateFinished = 'RTO_STATE_FINISHED',
  RtoStatePendingSetup = 'RTO_STATE_PENDING_SETUP',
  RtoStateUnknown = 'RTO_STATE_UNKNOWN',
  RtoStateWaitingForPayment = 'RTO_STATE_WAITING_FOR_PAYMENT',
  RtoStateWaitingForStandingOrderSetup = 'RTO_STATE_WAITING_FOR_STANDING_ORDER_SETUP',
  RtoStateWaitingForTenantConfirmation = 'RTO_STATE_WAITING_FOR_TENANT_CONFIRMATION'
}

export type Offer_Api_Rto_RtosResponseOutput = {
  readonly __typename: 'offer_api_rto_RtosResponseOutput';
  readonly rtos?: Maybe<ReadonlyArray<Offer_Api_Rto_RtoOutput>>;
};

export type Offer_Api_Rto_TenantOutput = {
  readonly __typename: 'offer_api_rto_TenantOutput';
  readonly email_primary: Scalars['String'];
  readonly first_name: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly phone_primary: Scalars['String'];
  readonly user: Scalars['String'];
};

export type Plaid_Api_CreateAdminLinkTokenResponseOutput = {
  readonly __typename: 'plaid_api_CreateAdminLinkTokenResponseOutput';
  readonly token: Scalars['String'];
};

export type Plaid_Api_UpdateAdminAccessTokenResponseOutput = {
  readonly __typename: 'plaid_api_UpdateAdminAccessTokenResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Property_Api_AcceptViewResponseOutput = {
  readonly __typename: 'property_api_AcceptViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly request: Property_Api_ViewRequestEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_AddNoteResponseOutput = {
  readonly __typename: 'property_api_AddNoteResponseOutput';
  readonly status: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Property_Api_AdminVerifyInput = {
  readonly address?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly description?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly documents?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly fee?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly landlord_ownership?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly photos?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly publication?: InputMaybe<Property_Api_AdminVerifyItemInput>;
  readonly submitter_management?: InputMaybe<Property_Api_AdminVerifyItemInput>;
};

export type Property_Api_AdminVerifyItemInput = {
  readonly admin?: InputMaybe<Scalars['String']>;
  readonly verified?: InputMaybe<Scalars['Boolean']>;
  readonly verified_at?: InputMaybe<Scalars['String']>;
};

export type Property_Api_AdminVerifyItemOutput = {
  readonly __typename: 'property_api_AdminVerifyItemOutput';
  readonly admin: Scalars['String'];
  readonly verified: Scalars['Boolean'];
  readonly verified_at: Scalars['String'];
};

export type Property_Api_AdminVerifyOutput = {
  readonly __typename: 'property_api_AdminVerifyOutput';
  readonly address: Property_Api_AdminVerifyItemOutput;
  readonly description: Property_Api_AdminVerifyItemOutput;
  readonly documents: Property_Api_AdminVerifyItemOutput;
  readonly fee: Property_Api_AdminVerifyItemOutput;
  readonly landlord_ownership: Property_Api_AdminVerifyItemOutput;
  readonly photos: Property_Api_AdminVerifyItemOutput;
  readonly publication: Property_Api_AdminVerifyItemOutput;
  readonly submitter_management: Property_Api_AdminVerifyItemOutput;
};

export type Property_Api_AverageRentPricesOutput = {
  readonly __typename: 'property_api_AverageRentPricesOutput';
  readonly avg_price_1_bedroom: Scalars['Float'];
  readonly avg_price_2_bedroom: Scalars['Float'];
  readonly avg_price_3_bedroom: Scalars['Float'];
  readonly avg_price_4plus_bedroom: Scalars['Float'];
  readonly properties_listed_1_bedroom: Scalars['Int'];
  readonly properties_listed_2_bedroom: Scalars['Int'];
  readonly properties_listed_3_bedroom: Scalars['Int'];
  readonly properties_listed_4plus_bedroom: Scalars['Int'];
  readonly time: Scalars['String'];
};

export type Property_Api_CancelViewResponseOutput = {
  readonly __typename: 'property_api_CancelViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly request: Property_Api_ViewRequestEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_CertificateStateInput = {
  readonly certificate_status?: InputMaybe<Property_Api_CertificateStatusEnum>;
  readonly certificate_type?: InputMaybe<Property_Api_CertificateTypeEnum>;
};

export type Property_Api_CertificateStateOutput = {
  readonly __typename: 'property_api_CertificateStateOutput';
  readonly certificate_status: Property_Api_CertificateStatusEnum;
  readonly certificate_type: Property_Api_CertificateTypeEnum;
};

export enum Property_Api_CertificateStatusEnum {
  CertificateStatusSelected = 'CertificateStatus_SELECTED',
  CertificateStatusUnknown = 'CertificateStatus_UNKNOWN',
  CertificateStatusUnselected = 'CertificateStatus_UNSELECTED'
}

export enum Property_Api_CertificateTypeEnum {
  CertificateTypeEpc = 'CertificateType_EPC',
  CertificateTypeGas = 'CertificateType_GAS'
}

export enum Property_Api_ChangeBankDetailsErrorEnum {
  BankDetailsRequired = 'BANK_DETAILS_REQUIRED',
  CaptchaInvalid = 'CAPTCHA_INVALID',
  CaptchaRequired = 'CAPTCHA_REQUIRED',
  PasswordInvalid = 'PASSWORD_INVALID',
  PasswordRequired = 'PASSWORD_REQUIRED',
  PhoneOtpInvalid = 'PHONE_OTP_INVALID',
  PhoneOtpRequired = 'PHONE_OTP_REQUIRED'
}

export type Property_Api_ChangeBankDetailsResponseOutput = {
  readonly __typename: 'property_api_ChangeBankDetailsResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_ChangeBankDetailsErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_ClaimResponseOutput = {
  readonly __typename: 'property_api_ClaimResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_CommitStateResponseOutput = {
  readonly __typename: 'property_api_CommitStateResponseOutput';
  readonly current: Property_Api_PropertyEnrichedOutput;
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_CompleteViewResponseOutput = {
  readonly __typename: 'property_api_CompleteViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly request: Property_Api_ViewRequestEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export enum Property_Api_ConsentReplyEnum {
  Approve = 'APPROVE',
  Deny = 'DENY',
  UnknownConsentReply = 'UNKNOWN_CONSENT_REPLY'
}

export type Property_Api_CreateResponseOutput = {
  readonly __typename: 'property_api_CreateResponseOutput';
  readonly already_exist: Scalars['Boolean'];
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly property: Property_Api_PropertyEnrichedOutput;
  readonly success: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verifiable: Scalars['Boolean'];
  readonly verification: Property_Api_PropertyVerificationOutput;
};

export type Property_Api_DeleteResponseOutput = {
  readonly __typename: 'property_api_DeleteResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_ExtraListingPaymentInput = {
  readonly counted?: InputMaybe<Scalars['Boolean']>;
  readonly invoice_number?: InputMaybe<Scalars['Int']>;
  readonly paid_at?: InputMaybe<Scalars['String']>;
};

export type Property_Api_ExtraListingPaymentOutput = {
  readonly __typename: 'property_api_ExtraListingPaymentOutput';
  readonly counted: Scalars['Boolean'];
  readonly invoice_number: Scalars['Int'];
  readonly paid_at: Scalars['String'];
};

export type Property_Api_FailViewResponseOutput = {
  readonly __typename: 'property_api_FailViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly request: Property_Api_ViewRequestEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_GetAdminResponseOutput = {
  readonly __typename: 'property_api_GetAdminResponseOutput';
  readonly admin: Scalars['String'];
};

export type Property_Api_GetAverageRentPricesResponseOutput = {
  readonly __typename: 'property_api_GetAverageRentPricesResponseOutput';
  readonly average_rent_prices: Property_Api_AverageRentPricesOutput;
};

export type Property_Api_GetResponseOutput = {
  readonly __typename: 'property_api_GetResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly ownership_verified: Scalars['Boolean'];
  readonly property: Property_Api_PropertyEnrichedOutput;
  readonly success: Scalars['Boolean'];
  readonly user_verified: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verifiable: Scalars['Boolean'];
  readonly verification: Property_Api_PropertyVerificationOutput;
};

export type Property_Api_GetTenancyResponseOutput = {
  readonly __typename: 'property_api_GetTenancyResponseOutput';
  readonly success: Scalars['Boolean'];
  readonly tenancy?: Maybe<ReadonlyArray<Property_Api_TenancyOutput>>;
};

export type Property_Api_GetValuationResponseOutput = {
  readonly __typename: 'property_api_GetValuationResponseOutput';
  readonly data: Scalars['String'];
};

export type Property_Api_GpsCoordinatesInput = {
  readonly latitude?: InputMaybe<Scalars['Float']>;
  readonly longitude?: InputMaybe<Scalars['Float']>;
};

export type Property_Api_GpsCoordinatesOutput = {
  readonly __typename: 'property_api_GpsCoordinatesOutput';
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
};

export type Property_Api_GuarantorInput = {
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
};

export type Property_Api_GuarantorOutput = {
  readonly __typename: 'property_api_GuarantorOutput';
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
};

export enum Property_Api_HeiDocumentTypeEnum {
  HeiIpid = 'HEI_IPID',
  HeiPolicySchedule = 'HEI_POLICY_SCHEDULE',
  HeiPolicyWording = 'HEI_POLICY_WORDING',
  HeiQuote = 'HEI_QUOTE',
  HeiSof = 'HEI_SOF',
  HeiTobaMashroom = 'HEI_TOBA_MASHROOM'
}

export type Property_Api_HomeEmergencyInsuranceInput = {
  readonly corresp_address?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly file_id?: InputMaybe<Scalars['String']>;
  readonly hei_document_type?: InputMaybe<Property_Api_HeiDocumentTypeEnum>;
  readonly pol_number?: InputMaybe<Scalars['String']>;
  readonly start_of_insurance?: InputMaybe<Scalars['String']>;
};

export type Property_Api_HomeEmergencyInsuranceOutput = {
  readonly __typename: 'property_api_HomeEmergencyInsuranceOutput';
  readonly corresp_address: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly file_id: Scalars['String'];
  readonly hei_document_type: Property_Api_HeiDocumentTypeEnum;
  readonly pol_number: Scalars['String'];
  readonly start_of_insurance: Scalars['String'];
};

export type Property_Api_InsurancesInput = {
  readonly home_emergency_insurances?: InputMaybe<ReadonlyArray<Property_Api_HomeEmergencyInsuranceInput>>;
  readonly rent_guarantee_insurances?: InputMaybe<ReadonlyArray<Property_Api_RentGuaranteeInsuranceInput>>;
};

export type Property_Api_InsurancesOutput = {
  readonly __typename: 'property_api_InsurancesOutput';
  readonly home_emergency_insurances?: Maybe<ReadonlyArray<Property_Api_HomeEmergencyInsuranceOutput>>;
  readonly rent_guarantee_insurances?: Maybe<ReadonlyArray<Property_Api_RentGuaranteeInsuranceOutput>>;
};

export type Property_Api_InvitationStatusEnrichedOutput = {
  readonly __typename: 'property_api_InvitationStatusEnrichedOutput';
  /** @deprecated use invitee_email */
  readonly email: Scalars['String'];
  readonly invitation_id: Scalars['String'];
  readonly invitee?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly invitee_email: Scalars['String'];
  readonly invitee_first_name: Scalars['String'];
  readonly invitee_id: Scalars['String'];
  readonly invitee_last_name: Scalars['String'];
  readonly invitee_phone: Scalars['String'];
  readonly inviter?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly inviter_id: Scalars['String'];
  readonly pending: Scalars['Boolean'];
  readonly sent: Scalars['String'];
  readonly workflow: Scalars['String'];
};

export type Property_Api_InvitationStatusInput = {
  readonly invitation_id?: InputMaybe<Scalars['String']>;
  readonly invitee_email?: InputMaybe<Scalars['String']>;
  readonly invitee_first_name?: InputMaybe<Scalars['String']>;
  readonly invitee_id?: InputMaybe<Scalars['String']>;
  readonly invitee_last_name?: InputMaybe<Scalars['String']>;
  readonly invitee_phone?: InputMaybe<Scalars['String']>;
  readonly inviter_id?: InputMaybe<Scalars['String']>;
  readonly pending?: InputMaybe<Scalars['Boolean']>;
  readonly sent?: InputMaybe<Scalars['String']>;
  readonly workflow?: InputMaybe<Scalars['String']>;
};

export type Property_Api_LeaveFromListingFormResponseOutput = {
  readonly __typename: 'property_api_LeaveFromListingFormResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Property_Api_ListViewResponseOutput = {
  readonly __typename: 'property_api_ListViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly list?: Maybe<ReadonlyArray<Property_Api_ViewRequestEnrichedOutput>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_LogInput = {
  readonly action?: InputMaybe<Scalars['String']>;
  readonly dt?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Property_Api_LogOutput = {
  readonly __typename: 'property_api_LogOutput';
  readonly action: Scalars['String'];
  readonly dt: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Property_Api_ManagerRewardStatusEnum {
  AwaitingRewardPayment = 'AWAITING_REWARD_PAYMENT',
  EntitledToReward = 'ENTITLED_TO_REWARD',
  NoReward = 'NO_REWARD',
  RewardPaid = 'REWARD_PAID',
  UnknownManagerRewardStatus = 'UNKNOWN_MANAGER_REWARD_STATUS'
}

export type Property_Api_MortgageEntryInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly since?: InputMaybe<Scalars['String']>;
};

export type Property_Api_MortgageEntryOutput = {
  readonly __typename: 'property_api_MortgageEntryOutput';
  readonly amount: Scalars['Float'];
  readonly since: Scalars['String'];
};

export enum Property_Api_MoveinDateEnum {
  MoveinDateAsSoonAsPossible = 'MOVEIN_DATE_AS_SOON_AS_POSSIBLE',
  MoveinDateOneToTwoMonth = 'MOVEIN_DATE_ONE_TO_TWO_MONTH',
  MoveinDateTwoPlusMonths = 'MOVEIN_DATE_TWO_PLUS_MONTHS',
  MoveinDateTwoToFourWeeks = 'MOVEIN_DATE_TWO_TO_FOUR_WEEKS',
  UnknownMoveinDate = 'UNKNOWN_MOVEIN_DATE'
}

export enum Property_Api_PropertyAvailabilityEnum {
  PropertyAvailabilityMonthly = 'PROPERTY_AVAILABILITY_MONTHLY',
  PropertyAvailabilityWeekly = 'PROPERTY_AVAILABILITY_WEEKLY',
  UnknownPropertyAvailability = 'UNKNOWN_PROPERTY_AVAILABILITY'
}

export type Property_Api_PropertyAvailabilitySlotInput = {
  readonly endsec?: InputMaybe<Scalars['Int']>;
  readonly startsec?: InputMaybe<Scalars['Int']>;
  readonly type?: InputMaybe<Property_Api_PropertyAvailabilityEnum>;
};

export type Property_Api_PropertyAvailabilitySlotOutput = {
  readonly __typename: 'property_api_PropertyAvailabilitySlotOutput';
  readonly endsec: Scalars['Int'];
  readonly startsec: Scalars['Int'];
  readonly type: Property_Api_PropertyAvailabilityEnum;
};

export type Property_Api_PropertyAvailabilitySlotVerificationOutput = {
  readonly __typename: 'property_api_PropertyAvailabilitySlotVerificationOutput';
  readonly endsec?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly startsec?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly type?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyBankDetailsInput = {
  readonly account_name?: InputMaybe<Scalars['String']>;
  readonly account_number?: InputMaybe<Scalars['String']>;
  readonly personal_reference?: InputMaybe<Scalars['String']>;
  readonly society?: InputMaybe<Scalars['String']>;
  readonly sort_code?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyBankDetailsOutput = {
  readonly __typename: 'property_api_PropertyBankDetailsOutput';
  readonly account_name?: Maybe<Scalars['String']>;
  readonly account_number?: Maybe<Scalars['String']>;
  readonly personal_reference?: Maybe<Scalars['String']>;
  readonly society?: Maybe<Scalars['String']>;
  readonly sort_code?: Maybe<Scalars['String']>;
};

export enum Property_Api_PropertyBillTypeEnum {
  PropertyBillElectricity = 'PROPERTY_BILL_ELECTRICITY',
  PropertyBillGas = 'PROPERTY_BILL_GAS',
  PropertyBillInternet = 'PROPERTY_BILL_INTERNET',
  PropertyBillSatcableTv = 'PROPERTY_BILL_SATCABLE_TV',
  PropertyBillTelephone = 'PROPERTY_BILL_TELEPHONE',
  PropertyBillTvLicense = 'PROPERTY_BILL_TV_LICENSE',
  PropertyBillWater = 'PROPERTY_BILL_WATER',
  PropertyCouncilTax = 'PROPERTY_COUNCIL_TAX',
  UnknownPropertyBillType = 'UNKNOWN_PROPERTY_BILL_TYPE'
}

export type Property_Api_PropertyBoominInput = {
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly is_publish?: InputMaybe<Scalars['Boolean']>;
  readonly published_by?: InputMaybe<Scalars['String']>;
  readonly url?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyBoominOutput = {
  readonly __typename: 'property_api_PropertyBoominOutput';
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly is_publish: Scalars['Boolean'];
  readonly published_by: Scalars['String'];
  readonly url: Scalars['String'];
};

export enum Property_Api_PropertyCompleteEnum {
  PropertyCompleteDocuments = 'PROPERTY_COMPLETE_DOCUMENTS',
  PropertyCompleteEmpty = 'PROPERTY_COMPLETE_EMPTY',
  PropertyCompleteFeatures = 'PROPERTY_COMPLETE_FEATURES',
  PropertyCompleteLandlord = 'PROPERTY_COMPLETE_LANDLORD',
  PropertyCompleteListable = 'PROPERTY_COMPLETE_LISTABLE',
  PropertyCompleteLocation = 'PROPERTY_COMPLETE_LOCATION',
  PropertyCompleteManagement = 'PROPERTY_COMPLETE_MANAGEMENT',
  PropertyCompleteMedia = 'PROPERTY_COMPLETE_MEDIA',
  PropertyCompletePayment = 'PROPERTY_COMPLETE_PAYMENT',
  PropertyCompletePostcode = 'PROPERTY_COMPLETE_POSTCODE',
  PropertyCompletePostDetails = 'PROPERTY_COMPLETE_POST_DETAILS',
  PropertyCompleteRentalDetails = 'PROPERTY_COMPLETE_RENTAL_DETAILS',
  PropertyCompleteRooms = 'PROPERTY_COMPLETE_ROOMS',
  UnknownPropertyComplete = 'UNKNOWN_PROPERTY_COMPLETE'
}

export type Property_Api_PropertyDescriptionEnrichedOutput = {
  readonly __typename: 'property_api_PropertyDescriptionEnrichedOutput';
  readonly photos?: Maybe<ReadonlyArray<Document_Api_FileOutput>>;
  readonly plans?: Maybe<ReadonlyArray<Document_Api_FileOutput>>;
  readonly post_area_pitch?: Maybe<Scalars['String']>;
  readonly post_comment?: Maybe<Scalars['String']>;
  readonly post_headline?: Maybe<Scalars['String']>;
  readonly post_outer_description?: Maybe<Scalars['String']>;
  readonly post_property_admin_pitch?: Maybe<Scalars['String']>;
  readonly post_property_pitch?: Maybe<Scalars['String']>;
  readonly tags?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly video_embeds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly videos?: Maybe<ReadonlyArray<Document_Api_FileOutput>>;
};

export type Property_Api_PropertyDescriptionInput = {
  readonly photos?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly plans?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly post_area_pitch?: InputMaybe<Scalars['String']>;
  readonly post_comment?: InputMaybe<Scalars['String']>;
  readonly post_headline?: InputMaybe<Scalars['String']>;
  readonly post_outer_description?: InputMaybe<Scalars['String']>;
  readonly post_property_admin_pitch?: InputMaybe<Scalars['String']>;
  readonly post_property_pitch?: InputMaybe<Scalars['String']>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly video_embeds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly videos?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Property_Api_PropertyDescriptionVerificationOutput = {
  readonly __typename: 'property_api_PropertyDescriptionVerificationOutput';
  readonly photos?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly plans?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly post_area_pitch?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly post_comment?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly post_headline?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly post_property_pitch?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly tags?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly video_embeds?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly videos?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyDetailsInput = {
  readonly amenity_furniture?: InputMaybe<Property_Api_PropertyFurnitureEnum>;
  readonly amenity_outside_space?: InputMaybe<ReadonlyArray<Property_Api_PropertyOutsideSpaceEnum>>;
  readonly amenity_parking?: InputMaybe<ReadonlyArray<Property_Api_PropertyParkingEnum>>;
  readonly area?: InputMaybe<Scalars['Float']>;
  readonly bathrooms_no?: InputMaybe<Scalars['Int']>;
  readonly bedrooms_no?: InputMaybe<Scalars['Int']>;
  readonly kitchen_no?: InputMaybe<Scalars['Int']>;
  readonly livingrooms_no?: InputMaybe<Scalars['Int']>;
  readonly pets_allowed?: InputMaybe<Scalars['Boolean']>;
  readonly type?: InputMaybe<Property_Api_PropertyTypeEnum>;
  readonly type_other?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyDetailsOutput = {
  readonly __typename: 'property_api_PropertyDetailsOutput';
  readonly amenity_furniture: Property_Api_PropertyFurnitureEnum;
  readonly amenity_outside_space?: Maybe<ReadonlyArray<Property_Api_PropertyOutsideSpaceEnum>>;
  readonly amenity_parking?: Maybe<ReadonlyArray<Property_Api_PropertyParkingEnum>>;
  readonly area?: Maybe<Scalars['Float']>;
  readonly bathrooms_no?: Maybe<Scalars['Int']>;
  readonly bedrooms_no?: Maybe<Scalars['Int']>;
  readonly kitchen_no?: Maybe<Scalars['Int']>;
  readonly livingrooms_no?: Maybe<Scalars['Int']>;
  readonly pets_allowed?: Maybe<Scalars['Boolean']>;
  readonly type: Property_Api_PropertyTypeEnum;
  readonly type_other?: Maybe<Scalars['String']>;
};

export type Property_Api_PropertyDetailsVerificationOutput = {
  readonly __typename: 'property_api_PropertyDetailsVerificationOutput';
  readonly amenity_furniture?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly amenity_outside_space?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly amenity_parking?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly area?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly bathrooms_no?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly bedrooms_no?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly kitchen_no?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly livingrooms_no?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly type?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly type_other?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyDocumentsVerificationOutput = {
  readonly __typename: 'property_api_PropertyDocumentsVerificationOutput';
  readonly bills?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly certificate?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyEnrichedOutput = {
  readonly __typename: 'property_api_PropertyEnrichedOutput';
  readonly admin_verify: Property_Api_AdminVerifyOutput;
  readonly bank_details: Property_Api_PropertyBankDetailsOutput;
  readonly boomin: Property_Api_PropertyBoominOutput;
  readonly certificates_statuses?: Maybe<ReadonlyArray<Property_Api_CertificateStateOutput>>;
  readonly completion_state: Property_Api_PropertyCompleteEnum;
  readonly created_at: Scalars['String'];
  readonly deleted?: Maybe<Scalars['Boolean']>;
  readonly description: Property_Api_PropertyDescriptionEnrichedOutput;
  readonly details: Property_Api_PropertyDetailsOutput;
  readonly documents?: Maybe<ReadonlyArray<Document_Api_DocumentOutput>>;
  readonly extra_listing_payments?: Maybe<ReadonlyArray<Property_Api_ExtraListingPaymentOutput>>;
  readonly favorited?: Maybe<Scalars['Boolean']>;
  readonly finances: PropertyFinancesOutput;
  readonly id: Scalars['String'];
  readonly incoming_invitations: ReadonlyArray<Maybe<Invitation_Api_InvitationInfoOutput>>;
  readonly insurances: Property_Api_InsurancesOutput;
  readonly is_archived?: Maybe<Scalars['Boolean']>;
  readonly is_created_when_ordering?: Maybe<Scalars['Boolean']>;
  readonly ivr: Scalars['String'];
  readonly legacy_id?: Maybe<Scalars['String']>;
  readonly legacy_ivr?: Maybe<Scalars['String']>;
  readonly listing: Property_Api_PropertyListingOutput;
  readonly listing_expiry_date: Scalars['String'];
  readonly location: Property_Api_PropertyLocationOutput;
  readonly log?: Maybe<ReadonlyArray<Property_Api_LogOutput>>;
  readonly maintenance: ReadonlyArray<Maintenance_Api_MaintenanceEnrichedOutput>;
  readonly missed_extra_services?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nearby?: Maybe<ReadonlyArray<Geo_Api_PointRelativeOutput>>;
  readonly notes?: Maybe<ReadonlyArray<Property_Api_PropertyNoteOutput>>;
  readonly offers_list: ReadonlyArray<Offer_Api_OfferEnrichedOutput>;
  readonly origin?: Maybe<Listing_Api_IsVersionOrigin_OriginOneofOutput>;
  readonly pending_extra_services: ReadonlyArray<Scalars['String']>;
  readonly people: Property_Api_PropertyPeopleEnrichedOutput;
  readonly products: ReadonlyArray<Invoice_Api_ProductOutput>;
  readonly property_of_day_date: Scalars['String'];
  readonly rental: Property_Api_PropertyRentalOutput;
  readonly rightmove: Property_Api_PropertyRightmoveOutput;
  readonly rto_state: Scalars['String'];
  readonly status: Property_Api_PropertyStatusEnum;
  readonly status_history: ReadonlyArray<Property_Api_PropertyStatusDataOutput>;
  readonly test?: Maybe<Scalars['Boolean']>;
  readonly updated_at: Scalars['String'];
  readonly valuation: Property_Api_ValuationOutput;
  readonly version_id: Scalars['String'];
  readonly viewings_list: ReadonlyArray<Viewing_Api_ViewingOutput>;
  readonly withdrawn_reason: Property_Api_PropertyWithdrawnReasonEnum;
  readonly zoopla: Property_Api_PropertyZooplaOutput;
};


export type Property_Api_PropertyEnrichedOutputDocumentsArgs = {
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Common_Api_Generic_Query_QueryParamsPaginationInput>;
  property?: InputMaybe<Scalars['String']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  sorts?: InputMaybe<ReadonlyArray<Common_Api_Generic_Query_QueryParamsSortInput>>;
  type_codes?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  types?: InputMaybe<ReadonlyArray<Document_Api_DocumentTypeEnum>>;
  user?: InputMaybe<Scalars['String']>;
};

export enum Property_Api_PropertyErrorEnum {
  AlreadyPublished = 'ALREADY_PUBLISHED',
  DocumentsVerificationError = 'DOCUMENTS_VERIFICATION_ERROR',
  DocumentRequired = 'DOCUMENT_REQUIRED',
  EmptyValue = 'EMPTY_VALUE',
  IntervalNotAvailable = 'INTERVAL_NOT_AVAILABLE',
  InvalidMaxValue = 'INVALID_MAX_VALUE',
  InvalidMinValue = 'INVALID_MIN_VALUE',
  InvalidRating = 'INVALID_RATING',
  InvalidSum = 'INVALID_SUM',
  MaxLessMin = 'MAX_LESS_MIN',
  NotReadyForPublication = 'NOT_READY_FOR_PUBLICATION',
  PaymentRequired = 'PAYMENT_REQUIRED',
  PropertyAlreadyDeleted = 'PROPERTY_ALREADY_DELETED',
  PropertyLocked = 'PROPERTY_LOCKED',
  PropertyNotDeleted = 'PROPERTY_NOT_DELETED',
  PropertyNotExists = 'PROPERTY_NOT_EXISTS',
  PropertyRented = 'PROPERTY_RENTED',
  UnknownPropertyError = 'UNKNOWN_PROPERTY_ERROR',
  UnknownValue = 'UNKNOWN_VALUE',
  UserNotExists = 'USER_NOT_EXISTS',
  UserNotOwns = 'USER_NOT_OWNS',
  ZeroValue = 'ZERO_VALUE'
}

export type Property_Api_PropertyFinancesInput = {
  readonly mortgage?: InputMaybe<ReadonlyArray<Property_Api_MortgageEntryInput>>;
  readonly placeholder?: InputMaybe<Scalars['Boolean']>;
};

export enum Property_Api_PropertyFurnitureEnum {
  PropertyFurnitureFurnished = 'PROPERTY_FURNITURE_FURNISHED',
  PropertyFurniturePartlyFurnished = 'PROPERTY_FURNITURE_PARTLY_FURNISHED',
  PropertyFurnitureUnfurnished = 'PROPERTY_FURNITURE_UNFURNISHED',
  UnknownPropertyFurniture = 'UNKNOWN_PROPERTY_FURNITURE'
}

export type Property_Api_PropertyInput = {
  /** Deprecated. Do not use. */
  readonly admin_verify?: InputMaybe<Property_Api_AdminVerifyInput>;
  readonly bank_details?: InputMaybe<Property_Api_PropertyBankDetailsInput>;
  /** Deprecated. Do not use. */
  readonly boomin?: InputMaybe<Property_Api_PropertyBoominInput>;
  /** Deprecated. Do not use. */
  readonly certificates_statuses?: InputMaybe<ReadonlyArray<Property_Api_CertificateStateInput>>;
  /** Deprecated. Do not use. */
  readonly completion_state?: InputMaybe<Property_Api_PropertyCompleteEnum>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly deleted?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. Do not use. */
  readonly description?: InputMaybe<Property_Api_PropertyDescriptionInput>;
  /** Deprecated. Do not use. */
  readonly details?: InputMaybe<Property_Api_PropertyDetailsInput>;
  /** Deprecated. Do not use. */
  readonly extra_listing_payments?: InputMaybe<ReadonlyArray<Property_Api_ExtraListingPaymentInput>>;
  /** Deprecated. Do not use. */
  readonly finances?: InputMaybe<Property_Api_PropertyFinancesInput>;
  readonly id?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly insurances?: InputMaybe<Property_Api_InsurancesInput>;
  /** Deprecated. Do not use. */
  readonly is_archived?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. Do not use. */
  readonly is_created_when_ordering?: InputMaybe<Scalars['Boolean']>;
  readonly ivr?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly legacy_id?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly legacy_ivr?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly listing?: InputMaybe<Property_Api_PropertyListingInput>;
  /** Deprecated. Do not use. */
  readonly listing_expiry_date?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly location?: InputMaybe<Property_Api_PropertyLocationInput>;
  /** Deprecated. Do not use. */
  readonly log?: InputMaybe<ReadonlyArray<Property_Api_LogInput>>;
  /** Deprecated. Do not use. */
  readonly missed_extra_services?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Deprecated. Do not use. */
  readonly nearby?: InputMaybe<ReadonlyArray<Geo_Api_PointRelativeInput>>;
  /** Deprecated. Do not use. */
  readonly notes?: InputMaybe<ReadonlyArray<Property_Api_PropertyNoteInput>>;
  /** Deprecated. Do not use. */
  readonly origin?: InputMaybe<Listing_Api_IsVersionOrigin_OriginOneofInput>;
  /** Deprecated. Do not use. */
  readonly pending_extra_services?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  /** Deprecated. Do not use. */
  readonly people?: InputMaybe<Property_Api_PropertyPeopleInput>;
  /** Deprecated. Do not use. */
  readonly property_of_day_date?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly rental?: InputMaybe<Property_Api_PropertyRentalInput>;
  /** Deprecated. Do not use. */
  readonly rightmove?: InputMaybe<Property_Api_PropertyRightmoveInput>;
  readonly rto_state?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly status?: InputMaybe<Property_Api_PropertyStatusEnum>;
  /** Deprecated. Do not use. */
  readonly test?: InputMaybe<Scalars['Boolean']>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly valuation?: InputMaybe<Property_Api_ValuationInput>;
  readonly version_id?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly withdrawn_reason?: InputMaybe<Property_Api_PropertyWithdrawnReasonEnum>;
  /** Deprecated. Do not use. */
  readonly zoopla?: InputMaybe<Property_Api_PropertyZooplaInput>;
};

export type Property_Api_PropertyListResponseOutput = {
  readonly __typename: 'property_api_PropertyListResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly properties?: Maybe<ReadonlyArray<Property_Api_PropertyEnrichedOutput>>;
  readonly success: Scalars['Boolean'];
  readonly total: Scalars['Int'];
};

export type Property_Api_PropertyListingInput = {
  readonly availability?: InputMaybe<ReadonlyArray<Property_Api_PropertyAvailabilitySlotInput>>;
  readonly ended_listing_at?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly no_payments?: InputMaybe<Scalars['Boolean']>;
  readonly started_listing_at?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyListingOutput = {
  readonly __typename: 'property_api_PropertyListingOutput';
  readonly availability?: Maybe<ReadonlyArray<Property_Api_PropertyAvailabilitySlotOutput>>;
  readonly ended_listing_at: Scalars['String'];
  readonly id?: Maybe<Scalars['String']>;
  readonly no_payments?: Maybe<Scalars['Boolean']>;
  readonly started_listing_at: Scalars['String'];
};

export type Property_Api_PropertyListingVerificationOutput = {
  readonly __typename: 'property_api_PropertyListingVerificationOutput';
  readonly availability?: Maybe<ReadonlyArray<Property_Api_PropertyAvailabilitySlotVerificationOutput>>;
  readonly ended_listing_at?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly no_payments?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly started_listing_at?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyLocationInput = {
  readonly address?: InputMaybe<Scalars['String']>;
  readonly address1?: InputMaybe<Scalars['String']>;
  readonly address2?: InputMaybe<Scalars['String']>;
  readonly address3?: InputMaybe<Scalars['String']>;
  readonly address4?: InputMaybe<Scalars['String']>;
  readonly administrative_county?: InputMaybe<Scalars['String']>;
  readonly building?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly country?: InputMaybe<Scalars['String']>;
  readonly county?: InputMaybe<Scalars['String']>;
  readonly district?: InputMaybe<Scalars['String']>;
  readonly flat?: InputMaybe<Scalars['String']>;
  readonly geo_county?: InputMaybe<Scalars['String']>;
  readonly geo_region?: InputMaybe<Scalars['String']>;
  readonly geo_town?: InputMaybe<Scalars['String']>;
  readonly gps?: InputMaybe<Property_Api_GpsCoordinatesInput>;
  readonly post_town?: InputMaybe<Scalars['String']>;
  readonly postal_county?: InputMaybe<Scalars['String']>;
  readonly postcode?: InputMaybe<Scalars['String']>;
  readonly street?: InputMaybe<Scalars['String']>;
  readonly traditional_county?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyLocationOutput = {
  readonly __typename: 'property_api_PropertyLocationOutput';
  readonly address?: Maybe<Scalars['String']>;
  readonly address1?: Maybe<Scalars['String']>;
  readonly address2?: Maybe<Scalars['String']>;
  readonly address3?: Maybe<Scalars['String']>;
  readonly address4?: Maybe<Scalars['String']>;
  readonly administrative_county?: Maybe<Scalars['String']>;
  readonly building?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly country?: Maybe<Scalars['String']>;
  readonly county?: Maybe<Scalars['String']>;
  readonly district?: Maybe<Scalars['String']>;
  readonly flat?: Maybe<Scalars['String']>;
  readonly geo_county?: Maybe<Scalars['String']>;
  readonly geo_region?: Maybe<Scalars['String']>;
  readonly geo_town?: Maybe<Scalars['String']>;
  readonly gps: Property_Api_GpsCoordinatesOutput;
  readonly post_town?: Maybe<Scalars['String']>;
  readonly postal_county?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
  readonly traditional_county?: Maybe<Scalars['String']>;
};

export type Property_Api_PropertyLocationVerificationOutput = {
  readonly __typename: 'property_api_PropertyLocationVerificationOutput';
  readonly address1?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly address2?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly address3?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly address4?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly building?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly city?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly country?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly flat?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly gps?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly postcode?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly street?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyNoteInput = {
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly note?: InputMaybe<Scalars['String']>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyNoteOutput = {
  readonly __typename: 'property_api_PropertyNoteOutput';
  readonly created_at: Scalars['String'];
  readonly note: Scalars['String'];
  readonly user: Scalars['String'];
};

export enum Property_Api_PropertyOutsideSpaceEnum {
  PropertyOutsideSpaceBalcony = 'PROPERTY_OUTSIDE_SPACE_BALCONY',
  PropertyOutsideSpaceCommunalGarden = 'PROPERTY_OUTSIDE_SPACE_COMMUNAL_GARDEN',
  PropertyOutsideSpaceNone = 'PROPERTY_OUTSIDE_SPACE_NONE',
  PropertyOutsideSpacePrivateGarden = 'PROPERTY_OUTSIDE_SPACE_PRIVATE_GARDEN',
  PropertyOutsideSpaceTerrace = 'PROPERTY_OUTSIDE_SPACE_TERRACE',
  UnknownPropertyOutsideSpace = 'UNKNOWN_PROPERTY_OUTSIDE_SPACE'
}

export enum Property_Api_PropertyParkingEnum {
  PropertyParkingDoubleGarage = 'PROPERTY_PARKING_DOUBLE_GARAGE',
  PropertyParkingGarage = 'PROPERTY_PARKING_GARAGE',
  PropertyParkingNone = 'PROPERTY_PARKING_NONE',
  PropertyParkingOffstreet = 'PROPERTY_PARKING_OFFSTREET',
  PropertyParkingResidentsParking = 'PROPERTY_PARKING_RESIDENTS_PARKING',
  UnknownPropertyParking = 'UNKNOWN_PROPERTY_PARKING'
}

export type Property_Api_PropertyPeopleEnrichedOutput = {
  readonly __typename: 'property_api_PropertyPeopleEnrichedOutput';
  readonly administrator?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly guarantors?: Maybe<ReadonlyArray<Property_Api_GuarantorOutput>>;
  readonly landlord?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly landlord_invitation?: Maybe<Property_Api_InvitationStatusEnrichedOutput>;
  readonly manager?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly manager_invitation?: Maybe<Property_Api_InvitationStatusEnrichedOutput>;
  readonly manager_to_landlord_invitation: Property_Api_InvitationStatusEnrichedOutput;
  readonly submitter?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly tenants: ReadonlyArray<Property_Api_TenantEnrichedOutput>;
};

export type Property_Api_PropertyPeopleInput = {
  readonly administrator?: InputMaybe<Scalars['String']>;
  readonly guarantors?: InputMaybe<ReadonlyArray<Property_Api_GuarantorInput>>;
  readonly landlord?: InputMaybe<Scalars['String']>;
  readonly landlord_invitation?: InputMaybe<Property_Api_InvitationStatusInput>;
  readonly manager?: InputMaybe<Scalars['String']>;
  readonly manager_invitation?: InputMaybe<Property_Api_InvitationStatusInput>;
  readonly manager_to_landlord_invitation?: InputMaybe<Property_Api_InvitationStatusInput>;
  readonly submitter?: InputMaybe<Scalars['String']>;
  readonly tenants?: InputMaybe<ReadonlyArray<Property_Api_TenantInput>>;
};

export type Property_Api_PropertyPeopleVerificationOutput = {
  readonly __typename: 'property_api_PropertyPeopleVerificationOutput';
  readonly landlord?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly manager?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly submitter?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly tenants?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyRentalInput = {
  readonly bills_covered?: InputMaybe<ReadonlyArray<Property_Api_PropertyBillTypeEnum>>;
  readonly bills_included?: InputMaybe<Scalars['Boolean']>;
  readonly deposit_replacement_fee?: InputMaybe<Scalars['Float']>;
  readonly dss_available?: InputMaybe<Scalars['Boolean']>;
  readonly duration_max_months?: InputMaybe<Scalars['Int']>;
  readonly duration_min_months?: InputMaybe<Scalars['Int']>;
  readonly earliest_movein?: InputMaybe<Scalars['Date']>;
  readonly inventory_report?: InputMaybe<Scalars['Boolean']>;
  readonly rental_price?: InputMaybe<Scalars['Float']>;
  readonly tenancy_deposit_insurance?: InputMaybe<Scalars['Boolean']>;
};

export type Property_Api_PropertyRentalOutput = {
  readonly __typename: 'property_api_PropertyRentalOutput';
  readonly bills_covered?: Maybe<ReadonlyArray<Property_Api_PropertyBillTypeEnum>>;
  readonly bills_included?: Maybe<Scalars['Boolean']>;
  readonly deposit_replacement_fee?: Maybe<Scalars['Float']>;
  readonly dss_available?: Maybe<Scalars['Boolean']>;
  readonly duration_max_months?: Maybe<Scalars['Int']>;
  readonly duration_min_months?: Maybe<Scalars['Int']>;
  readonly earliest_movein: Scalars['Date'];
  readonly inventory_report?: Maybe<Scalars['Boolean']>;
  readonly rental_price?: Maybe<Scalars['Float']>;
  readonly tenancy_deposit_insurance?: Maybe<Scalars['Boolean']>;
};

export type Property_Api_PropertyRentalVerificationOutput = {
  readonly __typename: 'property_api_PropertyRentalVerificationOutput';
  readonly bills_covered?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly bills_included?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly deposit_replacement_fee?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly dss_available?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly duration_max_months?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly duration_min_months?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly earliest_movein?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly inventory_report?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly rental_price?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly tenancy_deposit_insurance?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export type Property_Api_PropertyRightmoveInput = {
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly is_publish?: InputMaybe<Scalars['Boolean']>;
  readonly published_by?: InputMaybe<Scalars['String']>;
  readonly url?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyRightmoveOutput = {
  readonly __typename: 'property_api_PropertyRightmoveOutput';
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly is_publish: Scalars['Boolean'];
  readonly published_by: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Property_Api_PropertySearchFilterInput = {
  readonly area?: InputMaybe<Property_Api_PropertySearchNumericValueInput>;
  readonly bedrooms?: InputMaybe<Property_Api_PropertySearchNumericValueInput>;
  readonly building?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly city?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly country?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly freeform?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly geo_county?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly geo_region?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly geo_town?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly postcode?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly price?: InputMaybe<Property_Api_PropertySearchNumericValueInput>;
  readonly status?: InputMaybe<Property_Api_PropertySearchPropertyStatusValueInput>;
  readonly street?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly tags?: InputMaybe<Property_Api_PropertySearchStringValueInput>;
  readonly type?: InputMaybe<Property_Api_PropertySearchPropertyTypeValueInput>;
};

export type Property_Api_PropertySearchNumericValueInput = {
  readonly operator?: InputMaybe<Property_Api_PropertySearchOperatorEnum>;
  readonly value?: InputMaybe<ReadonlyArray<Scalars['Float']>>;
};

export enum Property_Api_PropertySearchOperatorEnum {
  All = 'ALL',
  Any = 'ANY',
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  Freeform = 'FREEFORM',
  Less = 'LESS',
  More = 'MORE',
  Oneof = 'ONEOF',
  Range = 'RANGE',
  Startwith = 'STARTWITH',
  UnknownOperator = 'UNKNOWN_OPERATOR'
}

export type Property_Api_PropertySearchPropertyStatusValueInput = {
  readonly operator?: InputMaybe<Property_Api_PropertySearchOperatorEnum>;
  readonly value?: InputMaybe<ReadonlyArray<Property_Api_PropertyStatusEnum>>;
};

export type Property_Api_PropertySearchPropertyTypeValueInput = {
  readonly operator?: InputMaybe<Property_Api_PropertySearchOperatorEnum>;
  readonly value?: InputMaybe<ReadonlyArray<Property_Api_PropertyTypeEnum>>;
};

export type Property_Api_PropertySearchResponseOutput = {
  readonly __typename: 'property_api_PropertySearchResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly properties?: Maybe<ReadonlyArray<Property_Api_PropertyEnrichedOutput>>;
  readonly success: Scalars['Boolean'];
  readonly total: Scalars['Int'];
};

export enum Property_Api_PropertySearchSortTypeEnum {
  Highestprice = 'HIGHESTPRICE',
  Lowestprice = 'LOWESTPRICE',
  Mostrecent = 'MOSTRECENT',
  Rpopdaydatedesc = 'RPOPDAYDATEDESC'
}

export type Property_Api_PropertySearchStringValueInput = {
  readonly operator?: InputMaybe<Property_Api_PropertySearchOperatorEnum>;
  readonly value?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type Property_Api_PropertyStatusDataOutput = {
  readonly __typename: 'property_api_PropertyStatusDataOutput';
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly prev_status: Property_Api_PropertyStatusEnum;
  readonly property: Scalars['String'];
  readonly status: Property_Api_PropertyStatusEnum;
  readonly user: Scalars['String'];
};

export enum Property_Api_PropertyStatusEnum {
  Active = 'ACTIVE',
  Amendment = 'AMENDMENT',
  Archive = 'ARCHIVE',
  AwaitingDeposit = 'AWAITING_DEPOSIT',
  Incomplete = 'INCOMPLETE',
  Listed = 'LISTED',
  Missing = 'MISSING',
  Offering = 'OFFERING',
  Rented = 'RENTED',
  RentCollection = 'RENT_COLLECTION',
  UnknownStatus = 'UNKNOWN_STATUS',
  Verifying = 'VERIFYING',
  Withdrawn = 'WITHDRAWN'
}

export enum Property_Api_PropertyTypeEnum {
  PropertyTypeApartment = 'PROPERTY_TYPE_APARTMENT',
  PropertyTypeBungalow = 'PROPERTY_TYPE_BUNGALOW',
  PropertyTypeCottage = 'PROPERTY_TYPE_COTTAGE',
  PropertyTypeDetached = 'PROPERTY_TYPE_DETACHED',
  PropertyTypeFlat = 'PROPERTY_TYPE_FLAT',
  PropertyTypeMaisonette = 'PROPERTY_TYPE_MAISONETTE',
  PropertyTypeOther = 'PROPERTY_TYPE_OTHER',
  PropertyTypeParkHome = 'PROPERTY_TYPE_PARK_HOME',
  PropertyTypePenthouse = 'PROPERTY_TYPE_PENTHOUSE',
  PropertyTypeSemiDetached = 'PROPERTY_TYPE_SEMI_DETACHED',
  PropertyTypeStudio = 'PROPERTY_TYPE_STUDIO',
  PropertyTypeTerraced = 'PROPERTY_TYPE_TERRACED',
  PropertyTypeTownHouse = 'PROPERTY_TYPE_TOWN_HOUSE',
  UnknownPropertyType = 'UNKNOWN_PROPERTY_TYPE'
}

export type Property_Api_PropertyVerificationOutput = {
  readonly __typename: 'property_api_PropertyVerificationOutput';
  readonly completion_state?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly created_at?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly description: Property_Api_PropertyDescriptionVerificationOutput;
  readonly details: Property_Api_PropertyDetailsVerificationOutput;
  readonly documents: Property_Api_PropertyDocumentsVerificationOutput;
  readonly id?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly listing: Property_Api_PropertyListingVerificationOutput;
  readonly location: Property_Api_PropertyLocationVerificationOutput;
  readonly payments?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly people: Property_Api_PropertyPeopleVerificationOutput;
  readonly rental: Property_Api_PropertyRentalVerificationOutput;
  readonly status?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
};

export enum Property_Api_PropertyWithdrawnReasonEnum {
  PropertyWithdrawReasonAdvertisingBreak = 'PROPERTY_WITHDRAW_REASON_ADVERTISING_BREAK',
  PropertyWithdrawReasonFoundTenant = 'PROPERTY_WITHDRAW_REASON_FOUND_TENANT',
  PropertyWithdrawReasonListedWithAnothorOnlinePlatform = 'PROPERTY_WITHDRAW_REASON_LISTED_WITH_ANOTHOR_ONLINE_PLATFORM',
  PropertyWithdrawReasonListedWithHighstreetLettingAgent = 'PROPERTY_WITHDRAW_REASON_LISTED_WITH_HIGHSTREET_LETTING_AGENT',
  PropertyWithdrawReasonOther = 'PROPERTY_WITHDRAW_REASON_OTHER',
  UnknownPropertyWithdrawReason = 'UNKNOWN_PROPERTY_WITHDRAW_REASON'
}

export type Property_Api_PropertyZooplaInput = {
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly is_publish?: InputMaybe<Scalars['Boolean']>;
  readonly published_by?: InputMaybe<Scalars['String']>;
  readonly url?: InputMaybe<Scalars['String']>;
};

export type Property_Api_PropertyZooplaOutput = {
  readonly __typename: 'property_api_PropertyZooplaOutput';
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  readonly is_publish: Scalars['Boolean'];
  readonly published_by: Scalars['String'];
  readonly url: Scalars['String'];
};

export type Property_Api_PublishConsentResponseOutput = {
  readonly __typename: 'property_api_PublishConsentResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_PublishResponseOutput = {
  readonly __typename: 'property_api_PublishResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly listing: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Property_Api_RejectViewResponseOutput = {
  readonly __typename: 'property_api_RejectViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly request: Property_Api_ViewRequestEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_RentGuaranteeInsuranceInput = {
  readonly corresp_address?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly file_id?: InputMaybe<Scalars['String']>;
  readonly pol_number?: InputMaybe<Scalars['String']>;
  readonly rgi_document_type?: InputMaybe<Property_Api_RgiDocumentTypeEnum>;
  readonly start_of_insurance?: InputMaybe<Scalars['String']>;
};

export type Property_Api_RentGuaranteeInsuranceOutput = {
  readonly __typename: 'property_api_RentGuaranteeInsuranceOutput';
  readonly corresp_address: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly file_id: Scalars['String'];
  readonly pol_number: Scalars['String'];
  readonly rgi_document_type: Property_Api_RgiDocumentTypeEnum;
  readonly start_of_insurance: Scalars['String'];
};

export enum Property_Api_ReorderPhotosErrorEnum {
  PhotosRequired = 'PHOTOS_REQUIRED',
  PropertyInvalid = 'PROPERTY_INVALID',
  PropertyOrPhotosListed = 'PROPERTY_OR_PHOTOS_LISTED',
  PropertyRequired = 'PROPERTY_REQUIRED',
  StageRequired = 'STAGE_REQUIRED',
  UnknownError = 'UNKNOWN_ERROR'
}

export type Property_Api_ReorderPhotosResponseOutput = {
  readonly __typename: 'property_api_ReorderPhotosResponseOutput';
  readonly error: Property_Api_ReorderPhotosErrorEnum;
  readonly property: Property_Api_PropertyEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export enum Property_Api_ReorderPhotosStageEnum {
  ReorderPhotosDiscard = 'REORDER_PHOTOS_DISCARD',
  ReorderPhotosEditStatus = 'REORDER_PHOTOS_EDIT_STATUS',
  ReorderPhotosUpdate = 'REORDER_PHOTOS_UPDATE',
  UnknownStage = 'UNKNOWN_STAGE'
}

export type Property_Api_RepublishResponseOutput = {
  readonly __typename: 'property_api_RepublishResponseOutput';
  readonly property: Property_Api_PropertyEnrichedOutput;
};

export type Property_Api_RequestViewResponseOutput = {
  readonly __typename: 'property_api_RequestViewResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly request: Property_Api_ViewRequestEnrichedOutput;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_RestoreResponseOutput = {
  readonly __typename: 'property_api_RestoreResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export enum Property_Api_RgiDocumentTypeEnum {
  RgiIpid = 'RGI_IPID',
  RgiPolicyWording = 'RGI_POLICY_WORDING',
  RgiQuote = 'RGI_QUOTE',
  RgiSof = 'RGI_SOF',
  RgiTobaMrm = 'RGI_TOBA_MRM'
}

export type Property_Api_SaveListingReminderResponseOutput = {
  readonly __typename: 'property_api_SaveListingReminderResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Property_Api_TakenIntervalOutput = {
  readonly __typename: 'property_api_TakenIntervalOutput';
  readonly day?: Maybe<Scalars['Int']>;
  readonly month?: Maybe<Scalars['Int']>;
  readonly time_endsec?: Maybe<Scalars['Int']>;
  readonly time_startsec?: Maybe<Scalars['Int']>;
  readonly year?: Maybe<Scalars['Int']>;
};

export type Property_Api_TenancyClaimOutput = {
  readonly __typename: 'property_api_TenancyClaimOutput';
  readonly created_at: Scalars['String'];
  readonly damages?: Maybe<ReadonlyArray<Property_Api_TenancyClaim_DamageOutput>>;
  readonly id: Scalars['String'];
  readonly property_id: Scalars['String'];
  readonly state: Property_Api_TenancyClaim_StateEnum;
  readonly tenancy_id: Scalars['String'];
  readonly total_cost: Scalars['Float'];
  readonly updated_at: Scalars['String'];
};

export type Property_Api_TenancyClaim_CreateResponseOutput = {
  readonly __typename: 'property_api_TenancyClaim_CreateResponseOutput';
  readonly claim: Property_Api_TenancyClaimOutput;
};

export type Property_Api_TenancyClaim_DamageInput = {
  readonly details?: InputMaybe<Property_Api_TenancyClaim_Damage_DetailsInput>;
  readonly dispute?: InputMaybe<Property_Api_TenancyClaim_Damage_DisputeInput>;
  readonly id?: InputMaybe<Scalars['String']>;
};

export type Property_Api_TenancyClaim_DamageOutput = {
  readonly __typename: 'property_api_TenancyClaim_DamageOutput';
  readonly details: Property_Api_TenancyClaim_Damage_DetailsOutput;
  readonly dispute: Property_Api_TenancyClaim_Damage_DisputeOutput;
  readonly id: Scalars['String'];
};

export type Property_Api_TenancyClaim_Damage_DetailsInput = {
  readonly cost?: InputMaybe<Scalars['Float']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly proofs?: InputMaybe<ReadonlyArray<Property_Api_TenancyClaim_ProofInput>>;
};

export type Property_Api_TenancyClaim_Damage_DetailsOutput = {
  readonly __typename: 'property_api_TenancyClaim_Damage_DetailsOutput';
  readonly cost: Scalars['Float'];
  readonly description: Scalars['String'];
  readonly name: Scalars['String'];
  readonly proofs?: Maybe<ReadonlyArray<Property_Api_TenancyClaim_ProofOutput>>;
};

export type Property_Api_TenancyClaim_Damage_DisputeInput = {
  readonly explanation?: InputMaybe<Scalars['String']>;
  readonly pay?: InputMaybe<Scalars['Float']>;
  readonly proofs?: InputMaybe<ReadonlyArray<Property_Api_TenancyClaim_ProofInput>>;
};

export type Property_Api_TenancyClaim_Damage_DisputeOutput = {
  readonly __typename: 'property_api_TenancyClaim_Damage_DisputeOutput';
  readonly explanation: Scalars['String'];
  readonly pay: Scalars['Float'];
  readonly proofs?: Maybe<ReadonlyArray<Property_Api_TenancyClaim_ProofOutput>>;
};

export type Property_Api_TenancyClaim_GetResponseOutput = {
  readonly __typename: 'property_api_TenancyClaim_GetResponseOutput';
  readonly claim: Property_Api_TenancyClaimOutput;
};

export type Property_Api_TenancyClaim_ProofInput = {
  readonly file_id?: InputMaybe<Scalars['String']>;
  readonly filename?: InputMaybe<Scalars['String']>;
};

export type Property_Api_TenancyClaim_ProofOutput = {
  readonly __typename: 'property_api_TenancyClaim_ProofOutput';
  readonly file_id: Scalars['String'];
  readonly filename: Scalars['String'];
};

export type Property_Api_TenancyClaim_RemoveDamageResponseOutput = {
  readonly __typename: 'property_api_TenancyClaim_RemoveDamageResponseOutput';
  readonly claim: Property_Api_TenancyClaimOutput;
};

export type Property_Api_TenancyClaim_RemoveResponseOutput = {
  readonly __typename: 'property_api_TenancyClaim_RemoveResponseOutput';
  readonly success: Scalars['Boolean'];
};

export enum Property_Api_TenancyClaim_StateEnum {
  Active = 'ACTIVE',
  Disputed = 'DISPUTED',
  Paid = 'PAID',
  Resolved = 'RESOLVED',
  Settlement = 'SETTLEMENT',
  Unspecified = 'UNSPECIFIED'
}

export type Property_Api_TenancyClaim_UpdateDamageResponseOutput = {
  readonly __typename: 'property_api_TenancyClaim_UpdateDamageResponseOutput';
  readonly claim: Property_Api_TenancyClaimOutput;
};

export enum Property_Api_TenancyIntentionEnum {
  IntentionRenew = 'INTENTION_RENEW',
  IntentionTerminate = 'INTENTION_TERMINATE',
  IntentionUnknown = 'INTENTION_UNKNOWN'
}

export type Property_Api_TenancyOutput = {
  readonly __typename: 'property_api_TenancyOutput';
  readonly end_date: Scalars['String'];
  readonly expiration_date: Scalars['String'];
  readonly guarantors?: Maybe<ReadonlyArray<Property_Api_GuarantorOutput>>;
  readonly id: Scalars['String'];
  readonly intention_notified: Scalars['Boolean'];
  readonly landlord_intention: Property_Api_TenancyIntentionEnum;
  readonly landlord_intention_date: Scalars['String'];
  readonly movedin: Scalars['String'];
  readonly movedout: Scalars['String'];
  readonly new_offer?: Maybe<Scalars['String']>;
  readonly offer?: Maybe<Scalars['String']>;
  readonly penalty_consent_date: Scalars['String'];
  readonly property?: Maybe<Scalars['String']>;
  readonly tenancy_state: Property_Api_TenancyStateEnum;
  readonly tenants?: Maybe<ReadonlyArray<Property_Api_TenantEnrichedOutput>>;
  readonly tenants_intention: Property_Api_TenancyIntentionEnum;
  readonly tenants_intention_date: Scalars['String'];
  readonly termination_date: Scalars['String'];
  readonly updated_at: Scalars['String'];
};

export enum Property_Api_TenancyStateEnum {
  TenancyActive = 'TENANCY_ACTIVE',
  TenancyClosed = 'TENANCY_CLOSED',
  TenancyTerminating = 'TENANCY_TERMINATING'
}

export type Property_Api_TenantContactInput = {
  readonly email_primary?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone_primary?: InputMaybe<Scalars['String']>;
};

export type Property_Api_TenantContactOutput = {
  readonly __typename: 'property_api_TenantContactOutput';
  readonly email_primary?: Maybe<Scalars['String']>;
  readonly first_name?: Maybe<Scalars['String']>;
  readonly last_name?: Maybe<Scalars['String']>;
  readonly phone_primary?: Maybe<Scalars['String']>;
};

export type Property_Api_TenantEnrichedOutput = {
  readonly __typename: 'property_api_TenantEnrichedOutput';
  readonly active?: Maybe<Scalars['Boolean']>;
  readonly contract?: Maybe<Scalars['String']>;
  readonly movedin: Scalars['String'];
  readonly movedout: Scalars['String'];
  readonly risk: Property_Api_TenantRiskEnum;
  readonly tenant_contact: Property_Api_TenantContactOutput;
  readonly user?: Maybe<Account_Api_AccountEnrichedOutput>;
};

export type Property_Api_TenantInput = {
  readonly active?: InputMaybe<Scalars['Boolean']>;
  readonly contract?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly movedin?: InputMaybe<Scalars['String']>;
  /** Deprecated. Do not use. */
  readonly movedout?: InputMaybe<Scalars['String']>;
  readonly risk?: InputMaybe<Property_Api_TenantRiskEnum>;
  readonly tenant_contact?: InputMaybe<Property_Api_TenantContactInput>;
  readonly user?: InputMaybe<Scalars['String']>;
};

export enum Property_Api_TenantRiskEnum {
  HighRisk = 'HIGH_RISK',
  LowRisk = 'LOW_RISK',
  MediumRisk = 'MEDIUM_RISK',
  PendingRisk = 'PENDING_RISK',
  UnknownTenantRisk = 'UNKNOWN_TENANT_RISK'
}

export type Property_Api_UnpublishResponseOutput = {
  readonly __typename: 'property_api_UnpublishResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_UpdateResponseOutput = {
  readonly __typename: 'property_api_UpdateResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly property: Property_Api_PropertyEnrichedOutput;
  readonly success: Scalars['Boolean'];
  readonly valid: Scalars['Boolean'];
  readonly verifiable: Scalars['Boolean'];
  readonly verification: Property_Api_PropertyVerificationOutput;
};

export type Property_Api_UpdateTenancyResponseOutput = {
  readonly __typename: 'property_api_UpdateTenancyResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export enum Property_Api_UserRoleEnum {
  UnknownUserRole = 'UNKNOWN_USER_ROLE',
  UserRoleAdministrator = 'USER_ROLE_ADMINISTRATOR',
  UserRoleLandlord = 'USER_ROLE_LANDLORD',
  UserRoleManager = 'USER_ROLE_MANAGER',
  UserRoleSubmitter = 'USER_ROLE_SUBMITTER',
  UserRoleTenant = 'USER_ROLE_TENANT'
}

export type Property_Api_ValuationAdditionalInput = {
  readonly comparator_number_confidence?: InputMaybe<Scalars['Float']>;
  readonly variation_confidence?: InputMaybe<Scalars['Float']>;
};

export type Property_Api_ValuationAdditionalOutput = {
  readonly __typename: 'property_api_ValuationAdditionalOutput';
  readonly comparator_number_confidence: Scalars['Float'];
  readonly variation_confidence: Scalars['Float'];
};

export type Property_Api_ValuationInput = {
  readonly additional?: InputMaybe<Property_Api_ValuationAdditionalInput>;
  readonly confidence?: InputMaybe<Scalars['Float']>;
  readonly estimate?: InputMaybe<Scalars['Float']>;
  readonly lower_bound?: InputMaybe<Scalars['Float']>;
  readonly upper_bound?: InputMaybe<Scalars['Float']>;
};

export type Property_Api_ValuationOutput = {
  readonly __typename: 'property_api_ValuationOutput';
  readonly additional: Property_Api_ValuationAdditionalOutput;
  readonly confidence: Scalars['Float'];
  readonly estimate: Scalars['Float'];
  readonly lower_bound: Scalars['Float'];
  readonly upper_bound: Scalars['Float'];
};

export type Property_Api_ViewRequestDetailsInput = {
  readonly benefit_details?: InputMaybe<Scalars['String']>;
  readonly combined_household?: InputMaybe<Scalars['Boolean']>;
  readonly country_court_judgements?: InputMaybe<Scalars['Boolean']>;
  readonly flatmates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly have_house_benefits?: InputMaybe<Scalars['Boolean']>;
  readonly have_pets?: InputMaybe<Scalars['Boolean']>;
  readonly movein_date?: InputMaybe<Property_Api_MoveinDateEnum>;
  readonly pets_description?: InputMaybe<Scalars['String']>;
  readonly video?: InputMaybe<Scalars['Boolean']>;
  readonly viewing_description?: InputMaybe<Scalars['String']>;
};

export type Property_Api_ViewRequestDetailsOutput = {
  readonly __typename: 'property_api_ViewRequestDetailsOutput';
  readonly benefit_details?: Maybe<Scalars['String']>;
  readonly combined_household?: Maybe<Scalars['Boolean']>;
  readonly country_court_judgements?: Maybe<Scalars['Boolean']>;
  readonly flatmates?: Maybe<ReadonlyArray<Account_Api_AccountEnrichedOutput>>;
  readonly have_house_benefits?: Maybe<Scalars['Boolean']>;
  readonly have_pets?: Maybe<Scalars['Boolean']>;
  readonly movein_date: Property_Api_MoveinDateEnum;
  readonly pets_description?: Maybe<Scalars['String']>;
  readonly video?: Maybe<Scalars['Boolean']>;
  readonly viewing_description?: Maybe<Scalars['String']>;
};

export type Property_Api_ViewRequestEnrichedOutput = {
  readonly __typename: 'property_api_ViewRequestEnrichedOutput';
  readonly accepted_at: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly details: Property_Api_ViewRequestDetailsOutput;
  readonly feedback?: Maybe<ReadonlyArray<Property_Api_ViewRequestFeedbackEntryOutput>>;
  readonly id: Scalars['String'];
  readonly manager?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly offer?: Maybe<Offer_Api_OfferEnrichedOutput>;
  readonly property?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly remotely?: Maybe<Scalars['Boolean']>;
  readonly reward_status: Property_Api_ManagerRewardStatusEnum;
  readonly sender?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly status: Property_Api_ViewRequestStatusEnum;
  readonly time: Property_Api_ViewRequestTimeOutput;
  readonly updated_at: Scalars['String'];
};

export type Property_Api_ViewRequestFeedbackEntryInput = {
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly initiator?: InputMaybe<Scalars['String']>;
  readonly rating?: InputMaybe<Scalars['Int']>;
  readonly transition?: InputMaybe<Property_Api_ViewRequestStatusEnum>;
};

export type Property_Api_ViewRequestFeedbackEntryOutput = {
  readonly __typename: 'property_api_ViewRequestFeedbackEntryOutput';
  readonly comment?: Maybe<Scalars['String']>;
  readonly initiator: Scalars['String'];
  readonly rating?: Maybe<Scalars['Int']>;
  readonly transition: Property_Api_ViewRequestStatusEnum;
};

export type Property_Api_ViewRequestInput = {
  readonly accepted_at?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Property_Api_ViewRequestDetailsInput>;
  readonly feedback?: InputMaybe<ReadonlyArray<Property_Api_ViewRequestFeedbackEntryInput>>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly manager?: InputMaybe<Scalars['String']>;
  readonly offer?: InputMaybe<Scalars['String']>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly remotely?: InputMaybe<Scalars['Boolean']>;
  readonly reward_status?: InputMaybe<Property_Api_ManagerRewardStatusEnum>;
  readonly sender?: InputMaybe<Scalars['String']>;
  readonly status?: InputMaybe<Property_Api_ViewRequestStatusEnum>;
  readonly time?: InputMaybe<Property_Api_ViewRequestTimeInput>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
};

export enum Property_Api_ViewRequestStatusEnum {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  ManagerDecision = 'MANAGER_DECISION',
  Offer = 'OFFER',
  Rejected = 'REJECTED',
  UnknownViewRequestStatus = 'UNKNOWN_VIEW_REQUEST_STATUS',
  ViewerDecision = 'VIEWER_DECISION'
}

export type Property_Api_ViewRequestTimeInput = {
  readonly day?: InputMaybe<Scalars['Int']>;
  readonly month?: InputMaybe<Scalars['Int']>;
  readonly time_endsec?: InputMaybe<Scalars['Int']>;
  readonly time_startsec?: InputMaybe<Scalars['Int']>;
  readonly year?: InputMaybe<Scalars['Int']>;
};

export type Property_Api_ViewRequestTimeOutput = {
  readonly __typename: 'property_api_ViewRequestTimeOutput';
  readonly day?: Maybe<Scalars['Int']>;
  readonly month?: Maybe<Scalars['Int']>;
  readonly time_endsec?: Maybe<Scalars['Int']>;
  readonly time_startsec?: Maybe<Scalars['Int']>;
  readonly year?: Maybe<Scalars['Int']>;
};

export type Property_Api_ViewReservedResponseOutput = {
  readonly __typename: 'property_api_ViewReservedResponseOutput';
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly list?: Maybe<ReadonlyArray<Property_Api_TakenIntervalOutput>>;
  readonly success: Scalars['Boolean'];
};

export type Property_Api_WithdrawnResponseOutput = {
  readonly __typename: 'property_api_WithdrawnResponseOutput';
  readonly error_msg: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Property_Api_PropertyErrorEnum>>;
  readonly success: Scalars['Boolean'];
};

export type Rightmove_Api_DeletePropertyResponseOutput = {
  readonly __typename: 'rightmove_api_DeletePropertyResponseOutput';
  readonly property_id: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export enum Rightmove_Api_RightmoveErrorEnum {
  AlreadyPublished = 'ALREADY_PUBLISHED',
  PropertyNotExists = 'PROPERTY_NOT_EXISTS',
  PropertyNotListed = 'PROPERTY_NOT_LISTED',
  PropertyRented = 'PROPERTY_RENTED',
  UnknownRightmoveError = 'UNKNOWN_RIGHTMOVE_ERROR',
  UserNotFound = 'USER_NOT_FOUND'
}

export type Rightmove_Api_SendPropertyResponseOutput = {
  readonly __typename: 'rightmove_api_SendPropertyResponseOutput';
  readonly error_msg: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Rightmove_Api_RightmoveErrorEnum>>;
  readonly property: Scalars['String'];
  readonly rightmove_id: Scalars['String'];
  readonly rightmove_url: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Signature_Api_AdminRequestMetadataInput = {
  readonly contract_id?: InputMaybe<Scalars['String']>;
  readonly offer_id?: InputMaybe<Scalars['String']>;
  readonly property_id?: InputMaybe<Scalars['String']>;
  readonly viewing_id?: InputMaybe<Scalars['String']>;
};

export type Signature_Api_AdminResponseOutput = {
  readonly __typename: 'signature_api_AdminResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Signature_Api_CancelResponseOutput = {
  readonly __typename: 'signature_api_CancelResponseOutput';
  readonly success: Scalars['Boolean'];
};

export type Signature_Api_DocumentFormFieldInput = {
  readonly height?: InputMaybe<Scalars['Int']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly required?: InputMaybe<Scalars['Boolean']>;
  readonly signer?: InputMaybe<Scalars['Int']>;
  readonly type?: InputMaybe<Scalars['String']>;
  readonly width?: InputMaybe<Scalars['Int']>;
  readonly x?: InputMaybe<Scalars['Int']>;
  readonly y?: InputMaybe<Scalars['Int']>;
};

export type Signature_Api_GetPdfResponseOutput = {
  readonly __typename: 'signature_api_GetPDFResponseOutput';
  readonly fileid: Scalars['String'];
};

export type Signature_Api_SignatureOutput = {
  readonly __typename: 'signature_api_SignatureOutput';
  readonly decline_reason: Scalars['String'];
  readonly last_reminded_at: Scalars['String'];
  readonly last_viewed_at: Scalars['String'];
  readonly signature_id: Scalars['String'];
  readonly signed_at: Scalars['String'];
  readonly signer_email_address: Scalars['String'];
  readonly signer_name: Scalars['String'];
  readonly status: Signature_Api_SignatureStatusEnum;
};

export type Signature_Api_SignatureResponseOutput = {
  readonly __typename: 'signature_api_SignatureResponseOutput';
  readonly has_error: Scalars['Boolean'];
  readonly is_complete: Scalars['Boolean'];
  readonly is_declined: Scalars['Boolean'];
  readonly signature_request_id: Scalars['String'];
  readonly signatures: ReadonlyArray<_String_Signature_Api_SignatureOutputMap>;
};

export enum Signature_Api_SignatureStatusEnum {
  SignatureStatusDeclined = 'SIGNATURE_STATUS_DECLINED',
  SignatureStatusPending = 'SIGNATURE_STATUS_PENDING',
  SignatureStatusSigned = 'SIGNATURE_STATUS_SIGNED',
  SignatureStatusUnknown = 'SIGNATURE_STATUS_UNKNOWN'
}

export type Signature_Api_SignerInput = {
  readonly email?: InputMaybe<Scalars['String']>;
  readonly id_documents?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type Tokenio_Api_GetPaymentsResponseOutput = {
  readonly __typename: 'tokenio_api_GetPaymentsResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Tokenio_Api_PaymentOutput>>;
};

export type Tokenio_Api_GetScheduleResponseOutput = {
  readonly __typename: 'tokenio_api_GetScheduleResponseOutput';
  readonly offer: Scalars['String'];
  readonly schedule: Tokenio_Api_ScheduleOutput;
  readonly success: Scalars['Boolean'];
};

export type Tokenio_Api_MatchPropertyPaymentOutput = {
  readonly __typename: 'tokenio_api_MatchPropertyPaymentOutput';
  readonly payments?: Maybe<ReadonlyArray<Tokenio_Api_PaymentOutput>>;
  readonly schedule: Scalars['String'];
  readonly schedule_order: Scalars['Int'];
};

export type Tokenio_Api_MatchPropertyPaymentResponseOutput = {
  readonly __typename: 'tokenio_api_MatchPropertyPaymentResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Tokenio_Api_PaymentOutput>>;
  readonly schedule: Scalars['String'];
  readonly schedule_order: Scalars['Int'];
};

export type Tokenio_Api_MatchPropertyPaymentsAllResponseOutput = {
  readonly __typename: 'tokenio_api_MatchPropertyPaymentsAllResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Tokenio_Api_MatchPropertyPaymentOutput>>;
};

export enum Tokenio_Api_PaymentDestinationEnum {
  Landlord = 'LANDLORD',
  Platform = 'PLATFORM',
  Viewinghost = 'VIEWINGHOST'
}

export type Tokenio_Api_PaymentOutput = {
  readonly __typename: 'tokenio_api_PaymentOutput';
  readonly amount: Scalars['Float'];
  readonly correlation_id: Scalars['String'];
  readonly correlation_type: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly description: Scalars['String'];
  readonly destination: Tokenio_Api_PaymentDestinationEnum;
  readonly id: Scalars['String'];
  readonly invoice_id: Scalars['String'];
  /** @deprecated use correlation_id / correlation_type */
  readonly offer: Scalars['String'];
  readonly origin: Tokenio_Api_PaymentPurposeEnum;
  readonly payee: Scalars['String'];
  readonly payment_id: Scalars['String'];
  readonly property: Scalars['String'];
  /** @deprecated use payment_id */
  readonly receipt_id: Scalars['String'];
  readonly reconcile_state: Tokenio_Api_PaymentReconcileStatusEnum;
  readonly ref_num: Scalars['String'];
  readonly source: Tokenio_Api_PaymentSourceEnum;
  readonly standing_order_info: Tokenio_Api_StandingOrderInfoOutput;
  readonly status: Tokenio_Api_PaymentStatusEnum;
  readonly tokenio_created_at: Scalars['String'];
  readonly tranaction_id: Scalars['String'];
  readonly transaction_type: Tokenio_Api_TransactionTypeEnum;
  readonly transfer_id: Scalars['String'];
  readonly type: Tokenio_Api_PaymentTypeEnum;
  readonly updated_at: Scalars['String'];
};

export enum Tokenio_Api_PaymentPurposeEnum {
  Payout = 'PAYOUT',
  RentPayment = 'RENT_PAYMENT',
  StandingOrderPayment = 'STANDING_ORDER_PAYMENT',
  UnknownPayment = 'UNKNOWN_PAYMENT',
  UpfrontPayment = 'UPFRONT_PAYMENT'
}

export enum Tokenio_Api_PaymentReconcileStatusEnum {
  MatchFound = 'MATCH_FOUND',
  NoMatchFound = 'NO_MATCH_FOUND',
  PartialMatchFound = 'PARTIAL_MATCH_FOUND',
  Reconciled = 'RECONCILED',
  ToBeReconciled = 'TO_BE_RECONCILED',
  Unknown = 'UNKNOWN'
}

export enum Tokenio_Api_PaymentSourceEnum {
  Transaction = 'TRANSACTION',
  Transfer = 'TRANSFER'
}

export enum Tokenio_Api_PaymentStatusEnum {
  Failure = 'FAILURE',
  Init = 'INIT',
  Initiated = 'INITIATED',
  Pending = 'PENDING',
  PendingExternalAuthorization = 'PENDING_EXTERNAL_AUTHORIZATION',
  Processing = 'PROCESSING',
  Sent = 'SENT',
  Success = 'SUCCESS'
}

export enum Tokenio_Api_PaymentTypeEnum {
  FuturePayment = 'FUTURE_PAYMENT',
  SinglePayment = 'SINGLE_PAYMENT',
  StandingOrder = 'STANDING_ORDER'
}

export type Tokenio_Api_PaymentUpdateInput = {
  readonly amount?: InputMaybe<Scalars['Float']>;
  readonly correlation_id?: InputMaybe<Scalars['String']>;
  readonly correlation_type?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly destination?: InputMaybe<Tokenio_Api_PaymentDestinationEnum>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly invoice_id?: InputMaybe<Scalars['String']>;
  readonly offer?: InputMaybe<Scalars['String']>;
  readonly origin?: InputMaybe<Tokenio_Api_PaymentPurposeEnum>;
  readonly payee?: InputMaybe<Scalars['String']>;
  readonly payment_id?: InputMaybe<Scalars['String']>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly receipt_id?: InputMaybe<Scalars['String']>;
  readonly reconcile_state?: InputMaybe<Tokenio_Api_PaymentReconcileStatusEnum>;
  readonly ref_num?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Tokenio_Api_PaymentSourceEnum>;
  readonly standing_order_info?: InputMaybe<Tokenio_Api_StandingOrderInfoInput>;
  readonly status?: InputMaybe<Tokenio_Api_PaymentStatusEnum>;
  readonly tokenio_created_at?: InputMaybe<Scalars['String']>;
  readonly tranaction_id?: InputMaybe<Scalars['String']>;
  readonly transaction_type?: InputMaybe<Tokenio_Api_TransactionTypeEnum>;
  readonly transfer_id?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<Tokenio_Api_PaymentTypeEnum>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
};

export type Tokenio_Api_ProcessPaymentsResponseOutput = {
  readonly __typename: 'tokenio_api_ProcessPaymentsResponseOutput';
  readonly payments?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export enum Tokenio_Api_ProcessingStateEnum {
  NotProcessed = 'NOT_PROCESSED',
  Processed = 'PROCESSED'
}

export type Tokenio_Api_ScheduleOutput = {
  readonly __typename: 'tokenio_api_ScheduleOutput';
  readonly correlation_id: Scalars['String'];
  readonly correlation_type: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly id: Scalars['String'];
  /** @deprecated use correlation_id / correlation_type */
  readonly offer: Scalars['String'];
  readonly property: Scalars['String'];
  readonly schedule?: Maybe<ReadonlyArray<Tokenio_Api_SchedulePaymentOutput>>;
  readonly updated_at: Scalars['String'];
};

export type Tokenio_Api_SchedulePaymentOutput = {
  readonly __typename: 'tokenio_api_SchedulePaymentOutput';
  readonly amount: Scalars['Float'];
  readonly order_id: Scalars['Int'];
  readonly pay_at: Scalars['String'];
  readonly payments?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly reconcile_status: Tokenio_Api_PaymentReconcileStatusEnum;
  readonly state: Tokenio_Api_ProcessingStateEnum;
  readonly type: Tokenio_Api_SchedulePaymentTypeEnum;
  readonly update_at: Scalars['String'];
};

export enum Tokenio_Api_SchedulePaymentTypeEnum {
  HoldingDeposit = 'HOLDING_DEPOSIT',
  LastPayment = 'LAST_PAYMENT',
  MoveinPayment = 'MOVEIN_PAYMENT',
  RegularPayment = 'REGULAR_PAYMENT'
}

export type Tokenio_Api_SetMatchPaymentResponseOutput = {
  readonly __typename: 'tokenio_api_SetMatchPaymentResponseOutput';
  readonly success: Scalars['Boolean'];
};

export enum Tokenio_Api_StandingOrderFrequencyEnum {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  TwiceAYear = 'TWICE_A_YEAR',
  TwiceMonthly = 'TWICE_MONTHLY',
  TwiceWeekly = 'TWICE_WEEKLY',
  Unlnown = 'UNLNOWN',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type Tokenio_Api_StandingOrderInfoInput = {
  readonly end_at?: InputMaybe<Scalars['String']>;
  readonly frequency?: InputMaybe<Tokenio_Api_StandingOrderFrequencyEnum>;
  readonly start_at?: InputMaybe<Scalars['String']>;
};

export type Tokenio_Api_StandingOrderInfoOutput = {
  readonly __typename: 'tokenio_api_StandingOrderInfoOutput';
  readonly end_at: Scalars['String'];
  readonly frequency: Tokenio_Api_StandingOrderFrequencyEnum;
  readonly start_at: Scalars['String'];
};

export enum Tokenio_Api_TransactionTypeEnum {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  UnknownTransactionType = 'UNKNOWN_TRANSACTION_TYPE'
}

export type Tokenio_Api_UpdatePaymentResponseOutput = {
  readonly __typename: 'tokenio_api_UpdatePaymentResponseOutput';
  readonly payment: Tokenio_Api_PaymentOutput;
  readonly success: Scalars['Boolean'];
};

export type Tokenio_Api_UpdateScheduleInput = {
  readonly correlation_id?: InputMaybe<Scalars['String']>;
  readonly correlation_type?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly offer?: InputMaybe<Scalars['String']>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly schedule?: InputMaybe<ReadonlyArray<Tokenio_Api_UpdateSchedulePaymentInput>>;
};

export type Tokenio_Api_UpdateSchedulePaymentInput = {
  readonly order_id?: InputMaybe<Scalars['Int']>;
  readonly pay_at?: InputMaybe<Scalars['String']>;
  readonly payments?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly rreconcile_status?: InputMaybe<Tokenio_Api_PaymentReconcileStatusEnum>;
  readonly state?: InputMaybe<Tokenio_Api_ProcessingStateEnum>;
  readonly type?: InputMaybe<Tokenio_Api_SchedulePaymentTypeEnum>;
};

export type Tokenio_Api_UpdateScheduleResponseOutput = {
  readonly __typename: 'tokenio_api_UpdateScheduleResponseOutput';
  readonly schedule: Tokenio_Api_ScheduleOutput;
  readonly success: Scalars['Boolean'];
};

export enum Viewing_Api_ManagerRewardStatusEnum {
  ManagerRewardStatusAwaitingRewardPayment = 'MANAGER_REWARD_STATUS_AWAITING_REWARD_PAYMENT',
  ManagerRewardStatusEntitledToReward = 'MANAGER_REWARD_STATUS_ENTITLED_TO_REWARD',
  ManagerRewardStatusNoReward = 'MANAGER_REWARD_STATUS_NO_REWARD',
  ManagerRewardStatusRewardPaid = 'MANAGER_REWARD_STATUS_REWARD_PAID',
  ManagerRewardStatusUnknown = 'MANAGER_REWARD_STATUS_UNKNOWN'
}

export enum Viewing_Api_MoveinDateEnum {
  MoveinDateAsSoonAsPossible = 'MOVEIN_DATE_AS_SOON_AS_POSSIBLE',
  MoveinDateOneToTwoMonth = 'MOVEIN_DATE_ONE_TO_TWO_MONTH',
  MoveinDateTwoPlusMonths = 'MOVEIN_DATE_TWO_PLUS_MONTHS',
  MoveinDateTwoToFourWeeks = 'MOVEIN_DATE_TWO_TO_FOUR_WEEKS',
  MoveinDateUnknown = 'MOVEIN_DATE_UNKNOWN'
}

export type Viewing_Api_SenderDetailsInput = {
  readonly avatar?: InputMaybe<Scalars['String']>;
  readonly first_name?: InputMaybe<Scalars['String']>;
  readonly last_name?: InputMaybe<Scalars['String']>;
  readonly phone?: InputMaybe<Scalars['String']>;
};

export type Viewing_Api_SenderDetailsOutput = {
  readonly __typename: 'viewing_api_SenderDetailsOutput';
  readonly avatar: Scalars['String'];
  readonly first_name: Scalars['String'];
  readonly last_name: Scalars['String'];
  readonly phone: Scalars['String'];
};

export type Viewing_Api_ServiceViewingRewardResponseOutput = {
  readonly __typename: 'viewing_api_ServiceViewingRewardResponseOutput';
  readonly viewing: Viewing_Api_ViewingOutput;
};

export type Viewing_Api_ViewingDetailsInput = {
  readonly benefit_details?: InputMaybe<Scalars['String']>;
  readonly combined_household?: InputMaybe<Scalars['Boolean']>;
  readonly country_court_judgements?: InputMaybe<Scalars['Boolean']>;
  readonly flatmates?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly have_house_benefits?: InputMaybe<Scalars['Boolean']>;
  readonly have_pets?: InputMaybe<Scalars['Boolean']>;
  readonly movein_date?: InputMaybe<Viewing_Api_MoveinDateEnum>;
  readonly pets_description?: InputMaybe<Scalars['String']>;
  readonly video?: InputMaybe<Scalars['Boolean']>;
  readonly viewing_description?: InputMaybe<Scalars['String']>;
};

export type Viewing_Api_ViewingDetailsOutput = {
  readonly __typename: 'viewing_api_ViewingDetailsOutput';
  readonly benefit_details: Scalars['String'];
  readonly combined_household: Scalars['Boolean'];
  readonly country_court_judgements: Scalars['Boolean'];
  readonly flatmates?: Maybe<ReadonlyArray<Account_Api_AccountEnrichedOutput>>;
  readonly have_house_benefits: Scalars['Boolean'];
  readonly have_pets: Scalars['Boolean'];
  readonly movein_date: Viewing_Api_MoveinDateEnum;
  readonly pets_description: Scalars['String'];
  readonly video: Scalars['Boolean'];
  readonly viewing_description: Scalars['String'];
};

export type Viewing_Api_ViewingFeedbackEntryEnrichedOutput = {
  readonly __typename: 'viewing_api_ViewingFeedbackEntryEnrichedOutput';
  readonly comment: Scalars['String'];
  readonly initiator?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly rating: Scalars['Int'];
  readonly transition: Viewing_Api_ViewingStateEnum;
};

export type Viewing_Api_ViewingFeedbackEntryInput = {
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly initiator?: InputMaybe<Scalars['String']>;
  readonly rating?: InputMaybe<Scalars['Int']>;
  readonly transition?: InputMaybe<Viewing_Api_ViewingStateEnum>;
};

export type Viewing_Api_ViewingInput = {
  readonly accepted_at?: InputMaybe<Scalars['String']>;
  readonly created_at?: InputMaybe<Scalars['String']>;
  readonly details?: InputMaybe<Viewing_Api_ViewingDetailsInput>;
  readonly feedback?: InputMaybe<ReadonlyArray<Viewing_Api_ViewingFeedbackEntryInput>>;
  readonly id?: InputMaybe<Scalars['String']>;
  readonly landlord?: InputMaybe<Scalars['String']>;
  readonly listing?: InputMaybe<Scalars['String']>;
  readonly manager?: InputMaybe<Scalars['String']>;
  readonly offer?: InputMaybe<Scalars['String']>;
  readonly property?: InputMaybe<Scalars['String']>;
  readonly remotely?: InputMaybe<Scalars['Boolean']>;
  readonly reward_status?: InputMaybe<Viewing_Api_ManagerRewardStatusEnum>;
  readonly sender?: InputMaybe<Scalars['String']>;
  readonly sender_details?: InputMaybe<Viewing_Api_SenderDetailsInput>;
  readonly status?: InputMaybe<Viewing_Api_ViewingStateEnum>;
  readonly time?: InputMaybe<Viewing_Api_ViewingTimeInput>;
  readonly updated_at?: InputMaybe<Scalars['String']>;
};

export type Viewing_Api_ViewingListResponseOutput = {
  readonly __typename: 'viewing_api_ViewingListResponseOutput';
  readonly viewings?: Maybe<ReadonlyArray<Viewing_Api_ViewingOutput>>;
};

export type Viewing_Api_ViewingOutput = {
  readonly __typename: 'viewing_api_ViewingOutput';
  readonly accepted_at: Scalars['String'];
  readonly created_at: Scalars['String'];
  readonly details: Viewing_Api_ViewingDetailsOutput;
  readonly feedback?: Maybe<ReadonlyArray<Viewing_Api_ViewingFeedbackEntryEnrichedOutput>>;
  readonly flatmates?: Maybe<ReadonlyArray<Account_Api_AccountEnrichedOutput>>;
  readonly id: Scalars['String'];
  readonly landlord: Scalars['String'];
  readonly listing: Scalars['String'];
  readonly manager?: Maybe<Account_Api_AccountEnrichedOutput>;
  readonly offer?: Maybe<Offer_Api_OfferEnrichedOutput>;
  readonly property?: Maybe<Property_Api_PropertyEnrichedOutput>;
  readonly remotely: Scalars['Boolean'];
  readonly reward_status: Viewing_Api_ManagerRewardStatusEnum;
  readonly sender: Scalars['String'];
  readonly sender_details: Viewing_Api_SenderDetailsOutput;
  readonly status: Viewing_Api_ViewingStateEnum;
  readonly time: Viewing_Api_ViewingTimeOutput;
  readonly updated_at: Scalars['String'];
};

export type Viewing_Api_ViewingReservedResponseOutput = {
  readonly __typename: 'viewing_api_ViewingReservedResponseOutput';
  readonly intervals?: Maybe<ReadonlyArray<Viewing_Api_ViewingTimeOutput>>;
};

export type Viewing_Api_ViewingSaveResponseOutput = {
  readonly __typename: 'viewing_api_ViewingSaveResponseOutput';
  readonly viewing: Viewing_Api_ViewingOutput;
};

export type Viewing_Api_ViewingSpecInput = {
  readonly select?: InputMaybe<Viewing_Api_ViewingSpecSelectInput>;
  readonly user_id?: InputMaybe<Scalars['String']>;
};

export type Viewing_Api_ViewingSpecSelectInput = {
  readonly listing_id?: InputMaybe<Scalars['String']>;
  readonly offer_id?: InputMaybe<Scalars['String']>;
  readonly property_id?: InputMaybe<Scalars['String']>;
  readonly states?: InputMaybe<ReadonlyArray<Viewing_Api_ViewingStateEnum>>;
  readonly token?: InputMaybe<Scalars['String']>;
  readonly viewing_id?: InputMaybe<Scalars['String']>;
};

export enum Viewing_Api_ViewingStateEnum {
  ViewingStateAccepted = 'VIEWING_STATE_ACCEPTED',
  ViewingStateCancelled = 'VIEWING_STATE_CANCELLED',
  ViewingStateCompleted = 'VIEWING_STATE_COMPLETED',
  ViewingStateFailed = 'VIEWING_STATE_FAILED',
  ViewingStateManagerDecision = 'VIEWING_STATE_MANAGER_DECISION',
  ViewingStateOffer = 'VIEWING_STATE_OFFER',
  ViewingStateRejected = 'VIEWING_STATE_REJECTED',
  ViewingStateUnknown = 'VIEWING_STATE_UNKNOWN',
  ViewingStateViewerDecision = 'VIEWING_STATE_VIEWER_DECISION'
}

export type Viewing_Api_ViewingTimeInput = {
  readonly day?: InputMaybe<Scalars['Int']>;
  readonly month?: InputMaybe<Scalars['Int']>;
  readonly time_endsec?: InputMaybe<Scalars['Int']>;
  readonly time_startsec?: InputMaybe<Scalars['Int']>;
  readonly year?: InputMaybe<Scalars['Int']>;
};

export type Viewing_Api_ViewingTimeOutput = {
  readonly __typename: 'viewing_api_ViewingTimeOutput';
  readonly day: Scalars['Int'];
  readonly month: Scalars['Int'];
  readonly time_endsec: Scalars['Int'];
  readonly time_startsec: Scalars['Int'];
  readonly year: Scalars['Int'];
};

export type Zoopla_Api_DeletePropertyResponseOutput = {
  readonly __typename: 'zoopla_api_DeletePropertyResponseOutput';
  readonly property_id: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type Zoopla_Api_SendPropertyResponseOutput = {
  readonly __typename: 'zoopla_api_SendPropertyResponseOutput';
  readonly error_msg: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Zoopla_Api_ZooplaErrorEnum>>;
  readonly property: Scalars['String'];
  readonly success: Scalars['Boolean'];
  readonly zoopla_id: Scalars['String'];
  readonly zoopla_url: Scalars['String'];
};

export enum Zoopla_Api_ZooplaErrorEnum {
  AlreadyPublished = 'ALREADY_PUBLISHED',
  PropertyNotExists = 'PROPERTY_NOT_EXISTS',
  PropertyNotListed = 'PROPERTY_NOT_LISTED',
  PropertyRented = 'PROPERTY_RENTED',
  UnknownZooplaError = 'UNKNOWN_ZOOPLA_ERROR',
  UserNotFound = 'USER_NOT_FOUND'
}
