export enum Routes {
  AboutUs = "/about-us",
  Home = "/",
  Helpcentre = "/helpcentre",
  HelpcentreCommunity = "/helpcentre/community",
  HelpcentreChat = "/helpcentre/chat",
  Dashboard = "/dashboard",
  DashboardPropertyOverview = "/dashboard/[propertyId]/overview",
  DashboardPropertyRentCollection = "/dashboard/[propertyId]/rent-collection",
  FAQ = "/faq",
  AdvancedRentService = "/services/advanced-rent",
  LetAndProtectService = "/services/let-and-protect",
  PropertyPhotographyService = "/services/property-photography",
  GasSafetyService = "/services/gas-safety",
  EpcService = "/services/epc",
  TenantReferencingService = "/services/tenant-referencing",
  InventoryService = "/services/inventory",
  HomeEmergencyService = "/services/home-emergency",
  CookiePolicy = "/p/cookies-policy",
  PrivacyPolicy = "/p/privacy-policy",
  TermsAndConditions = "/p/terms-and-conditions",
  News = "/news",
  NewsCategory = "/news/[category]",
  NewsSubcategory = "/news/[category]/[subcategory]",
  NewsAll = "/news/all",
  Listing = "/listing",
  ListingNew = "/listing/new",
  ListingProperty = "/listing/[propertyId]",
  ListingPropertyActivity = "/listing/[propertyId]/[activity]",
  Property = "/property/[slug]",
  CreateAccount = "/auth/createaccount",
  Login = "/auth/login",
  Logout = "/auth/logout",
  RightToRent = "/right-to-rent",
  Sitemap = "/sitemap",
  AreaGuides = "/area-guides",
  ToRent = "/to-rent",
  InviteLandlord = "/invite/landlord",
  ContentsInsuranceActivity = "/contents-insurance/[insuranceId]/[activity]",
}
