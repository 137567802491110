// Do not rearrange, this block must be first import
import React, { ComponentType, ErrorInfo } from "react";

import { NextPage } from "next";
import App, { NextWebVitalsMetric } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import Router from "next/router";

import { captureException } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { DehydratedState, Hydrate } from "react-query/hydration";
import { Context as ReactResponsiveContext } from "react-responsive";

import "core-js/actual/object/from-entries";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";

///
import "@/core/effector/init";
import { isCookieConsentGiven } from "@/components/CookiePopUp";
import { fetchUserFx } from "@/core/Auth/effector";
import { ChatCoreInitial } from "@/core/Chat";
import { logPageView } from "@/features/analytics";
import { GAEvent } from "@/lib/dataLayersPush";
import initQueryClient from "@/lib/init-query-client";
import { isDOM } from "@/lib/isDom";
import withApolloClient from "@/lib/with-apollo-client";

import "@/ui/styles/global.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper.min.css";

const Chat = dynamic<unknown>(
  () =>
    import(
      /* webpackMode "weak" */
      "@/features/private/chat-new/components/Chat"
    ).then((module) => module.Chat),
  {
    ssr: false,
  }
);

const CookiePopUp = dynamic<unknown>(
  () => import("@/components/CookiePopUp").then((module) => module.CookiePopUp),
  {
    ssr: false,
  }
);

const OpenReplayTracker = dynamic(
  () => import("@/components/OpenReplayTracker"),
  {
    ssr: false,
  }
);

declare global {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  interface Window {
    dataLayer?: GAEvent[];
    /* Intercom  start */
    Intercom?: (...args: any) => any;
    intercomSettings?: any;
    attachEvent?: any;
    /* Intercom  end */
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

if (
  process.env.XSTATE_INSPECT === "true" &&
  process.env.NODE_ENV === "development" &&
  isDOM
) {
  const { inspect } = require("@xstate/inspect");
  inspect({
    iframe: false,
    url: "https://stately.ai/viz?inspect=1",
    /* options */
  });
}

type Props = NextPage<Record<string, never>, { error: string | undefined }> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any;
  dehydratedState: DehydratedState | undefined;
};
interface State {
  error: string | undefined;
  queryClient: QueryClient | undefined;
  ChatCore: ComponentType;
  captureException: typeof captureException | undefined;
}
const DEFAULT_MOBILE_WIDTH = { width: 499 };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class MyApp extends App<Props, any, State> {
  state: State = {
    error: undefined,
    queryClient: undefined,
    ChatCore: ChatCoreInitial,
    captureException: undefined,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props);
    this.state = {
      error: undefined,
      queryClient: initQueryClient(),
      ChatCore: ChatCoreInitial,
      captureException: undefined,
    };
  }

  componentDidMount() {
    if (isDOM) {
      void fetchUserFx();
      if (process.env.NODE_ENV !== "development") {
        void import("@sentry/react").then((m) => {
          m.init({
            dsn: process.env.SENTRY_DSN,

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            // For testing purposes sample just 0.5% of all interactions. We don't want to overspend money
            tracesSampleRate: 0.005,
          });
          this.setState({ captureException: m.captureException });
        });
      }
    }

    if (process.env.NODE_ENV !== "development" && isCookieConsentGiven()) {
      logPageView(window.location.pathname);
      Router.events.on("routeChangeComplete", logPageView);
    }
    void import(
      /* webpackMode "weak" */ "@/core/Chat/ChatCoreAuthenticated"
    ).then((component) => {
      this.setState({ ChatCore: component.default });
    });
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error("componentDidCatch", error, errorInfo);
    this.setState({ error: error.toString() });
    if (!process.env.IS_DEV) {
      this.state.captureException?.(error);
    }
  }

  render() {
    /* eslint-disable react/prop-types */
    const { Component, pageProps, dehydratedState } = this.props;

    /* eslint-enable react/prop-types */
    return (
      <QueryClientProvider client={this.state.queryClient!}>
        <Head>
          <title>Mashroom platform</title>
          <meta
            content="width=device-width, initial-scale=1.0"
            name="viewport"
          />
        </Head>
        <Hydrate
          state={
            pageProps.dehydratedState /* getInitialProps */ ??
            dehydratedState /* getStaticProps */
          }
        >
          <ReactResponsiveContext.Provider
            value={!isDOM ? DEFAULT_MOBILE_WIDTH : undefined}
          >
            <OpenReplayTracker />
            <ReactQueryDevtools initialIsOpen={false} />
            <Component {...pageProps} />
            {!isCookieConsentGiven() && <CookiePopUp />}
            <this.state.ChatCore>
              <Chat />
            </this.state.ChatCore>
          </ReactResponsiveContext.Provider>
        </Hydrate>
      </QueryClientProvider>
    );
  }
}
export function reportWebVitals(metric: NextWebVitalsMetric) {
  // eslint-disable-next-line no-console
  console.info(metric);
}
export default withApolloClient(MyApp);
